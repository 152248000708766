import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ScHttpService } from "src/app/services/http/sc-http.service";
import { ScCommonService } from "src/app/services/common/sc-common.service";

import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ServerURLS } from "src/environments/environment";
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { ListDataServiceService } from "src/app/services/list-data-service.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoadingService } from "src/app/services/loader.service";

@Component({
  selector: "app-child-customer-details",
  templateUrl: "./child-customer-details.component.html",
  styleUrls: ["./child-customer-details.component.scss"],
})
export class ChildCustomerDetailsComponent implements OnInit {
  @ViewChild("loadContent", undefined) loadContent;
  customerId: any;
  orderList: any = [];
  modelFromDate: NgbDateStruct;
  modelToDate: NgbDateStruct;
  sortBy: any;
  selectedQuoteID: any;
  selectedOrderID: any;
  selectedStatus: any;
  trackingData: any;
  shipperZip: any;
  consigneeZip: any;
  page = 0;
  pageSize = 20;
  fiterStatus: string = "all";
  popOverDataPrice: any[];
  isDesc: boolean = false;
  searchKey: string = "";
  isAscending: boolean = true;
  sortParam: any = "carrierName";
  parentQuoteId: string;

  constructor(
    private scHttpService: ScHttpService,
    private commenService: ScCommonService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private router: Router,
    private location: Location,
    private listDataService: ListDataServiceService,
    private commanService: ScCommonService,
    private fb: FormBuilder,
    private _loaderService: LoadingService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit() {
    localStorage.setItem("_adminreferrer", "childCustomerOrders");
    this.addFevForm = this.fb.group({
      addFev: ["", [Validators.required]],
    });
    this.sortBy = {
      orderId: "down",
      carrierName: "up",
      orderSource: "down",
      pickupDate: "down",
      shipper: "down",
      origin: "down",
      consignee: "down",
      destination: "down",
      totalCost: "down",
    };
    this.customerId = this.commenService.customerId;

    this.checkDataIsPresentInServiceFileOrNot();
  }
  isDataLoading: any = "";
  checkDataIsPresentInServiceFileOrNot() {
    let dataFromServiceFile =
      this.listDataService.getAllDataOfCustomerOrderHistory();
    if (dataFromServiceFile.dataList.length > 0) {
      console.log(dataFromServiceFile);
      this.page = dataFromServiceFile.pageCount;
      this.pageSize = dataFromServiceFile.pageSize;
      this.orderList = dataFromServiceFile.dataList;
      if (dataFromServiceFile.filterValue != "") {
        this.fiterStatus = dataFromServiceFile.filterValue;
      }
      if (dataFromServiceFile.searchKey != "") {
        this.searchKey = dataFromServiceFile.searchKey;
      }
      if (dataFromServiceFile.fromDate && dataFromServiceFile.toDate) {
        this.modelFromDate = {
          year: parseInt(dataFromServiceFile.fromDate.split("-")[0]),
          month: parseInt(dataFromServiceFile.fromDate.split("-")[1]),
          day: parseInt(dataFromServiceFile.fromDate.split("-")[2]),
        };
        this.modelToDate = {
          year: parseInt(dataFromServiceFile.toDate.split("-")[0]),
          month: parseInt(dataFromServiceFile.toDate.split("-")[1]),
          day: parseInt(dataFromServiceFile.toDate.split("-")[2]),
        };
      }
    } else {
      this.fiterStatus = "all";
      this.searchKey = "";
      this.modelFromDate = undefined;
      this.modelToDate = undefined;
      this.listDataService.removeAllDataOfCustomerOrderHistory();
      this.fetchOrders();
    }
  }

  fetchOrders() {
    this.allDataLoaded = false;
    this.isDataLoading = "";
    this._loaderService.stringSubject.next(true);
    this.parentQuoteId = sessionStorage.getItem("parentQuoteId");
    console.log("Retrieved parentQuoteId:", this.parentQuoteId);
    if (!this.parentQuoteId) {
      this.isDataLoading = "No Parent Quote ID Found!";
      this._loaderService.stringSubject.next(false);
      return;
    }
    // let url =
    //   ServerURLS.getCustomerOrder.action +
    //   this.page +
    //   this.pageSize +

    //   "?parentQuoteId=" +
    //   this.parentQuoteId;

    let url = `${ServerURLS.getCustomerOrder.action}/${this.page}/${this.pageSize}?parentQuoteId=${this.parentQuoteId}&isAscending=${this.isAscending}`;
    console.log("Constructed URL:", url);
    if (this.sortParam != "") {
      url = url + "&sortParam=" + this.sortParam;
    }
    if (this.searchKey.length > 0) {
      url = url + `&searchKey=${this.searchKey}`;
    }
    if (this.fiterStatus !== "all") {
      url = url + `&shipmentStatus=${this.fiterStatus}`;
    }
    if (this.modelToDate && this.modelFromDate) {
      let fromDate = `${this.modelFromDate.year}-${String(
        this.modelFromDate.month
      ).padStart(2, "0")}-${String(this.modelFromDate.day).padStart(2, "0")}`;
      let toDate = `${this.modelToDate.year}-${String(
        this.modelToDate.month
      ).padStart(2, "0")}-${String(this.modelToDate.day).padStart(2, "0")}`;
      url = url + `&startDate=${fromDate}&endDate=${toDate}`;
    }
    let promise = this.scHttpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        console.log(res);
        let iter = 0;
        if (res["response"].length > 0) {
          for (let i = 0; i < res["response"].length; i++) {
            res["response"][i].price = res
              ? res["response"][i].quoteDetails.totalAmount
              : 0;
            res["response"][i].currency = res
              ? res["response"][i].quoteDetails.currency
              : "";
            res["response"][i].pickupDate = res["response"][i].quoteDetails
              .pickupDate
              ? res["response"][i].quoteDetails.pickupDate
              : "N/A";
            res["response"][i].shipper = res["response"][i].quoteDetails
              .shipperAddress.company
              ? res["response"][i].quoteDetails.shipperAddress.company
              : "N/A";
            res["response"][
              i
            ].origin = `${res["response"][i].quoteDetails.shipperAddress.city}, ${res["response"][i].quoteDetails.shipperAddress.state}`;
            res["response"][
              i
            ].destination = `${res["response"][i].quoteDetails.consigneeAddress.city}, ${res["response"][i].quoteDetails.consigneeAddress.state}`;
            res["response"][i].consignee = res["response"][i].quoteDetails
              .consigneeAddress.company
              ? res["response"][i].quoteDetails.consigneeAddress.company
              : "N/A";
            res["response"][i].consigneeName =
              `${res["response"][i].quoteDetails.consigneeAddress.firstName}`
                ? `${
                    res["response"][i].quoteDetails.consigneeAddress.firstName
                  } ${
                    res["response"][i].quoteDetails.consigneeAddress.lastName
                      ? res["response"][i].quoteDetails.consigneeAddress
                          .lastName
                      : ""
                  }`
                : "N/A";
            res["response"][i].carrierName = res["response"][i].quoteDetails
              .carrierName
              ? res["response"][i].quoteDetails.carrierName
              : "N/A";
            console.log(res["response"][i]);
            iter = iter + 1;
            if (iter == res["response"].length) {
              this.isDataLoading = "";
              this._loaderService.stringSubject.next(false);
              this.orderList = res["response"];
              let data = {
                dataList: this.orderList,
                pageCount: this.page,
                pageSize: this.pageSize,
              };
              this.listDataService.setAllDataOfCustomerOrderHistory(data);
            }
          }
        } else {
          this.isDataLoading = "No Data Found!";
          this._loaderService.stringSubject.next(false);
          this.orderList = [];
          let data = {
            dataList: [],
            pageCount: this.page,
            pageSize: this.pageSize,
          };
          this.listDataService.setAllDataOfCustomerOrderHistory(data);
        }
      })
      .catch((err) => {
        this._loaderService.stringSubject.next(false);
        this.modalService.dismissAll();
        console.log(err);
        this.toaster.error(
          err.message ? err.message : "Something went wrong",
          "",
          {
            timeOut: 4000,
          }
        );
      });
  }

  back() {
    this.router.navigate(["shipcarte/customer/list/generalDetail/edit/orders"]);
  }

  setStatusFilter(status) {
    this.fiterStatus = status;
    this.listDataService.setFilterValueOfCustomerOrderHistory(status);
    this.applyFilter();
  }
  timer: any;
  setSearchKey(searchParam) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchParam.length > 2) {
        this.listDataService.setSearchKeyOfCustomerOrderHistory(searchParam);
        this.searchKey = searchParam;
        this.page = 0;
        this.applyFilter();
      } else if (searchParam.length == 0) {
        this.listDataService.setSearchKeyOfCustomerOrderHistory("");
        this.searchKey = searchParam;
        this.page = 0;
        this.applyFilter();
      }
    }, 500);
  }
  resetFilter() {
    this.fiterStatus = "all";
    this.page = 0;
    this.searchKey = "";
    this.modelFromDate = undefined;
    this.modelToDate = undefined;
    this.listDataService.removeAllDataOfCustomerOrderHistory();
    this.checkDataIsPresentInServiceFileOrNot();
  }
  applyFilter() {
    if (this.modelFromDate && this.modelToDate) {
      let startDate = Date.parse(
        this.modelFromDate.year +
          "-" +
          this.modelFromDate.month +
          "-" +
          this.modelFromDate.day
      );
      let endDate = Date.parse(
        this.modelToDate.year +
          "-" +
          this.modelToDate.month +
          "-" +
          this.modelToDate.day
      );
      console.log(this.modelFromDate);
      console.log(this.modelToDate);
      console.log(startDate, endDate);
      if (startDate > endDate) {
        this.toaster.error("'From Date' should be smaller than 'To Date'");
        this.fiterStatus = "all";
        return;
      } else {
        this.listDataService.setFromDateToDateValueOfCustomerOrderHistory(
          this.modelFromDate.year +
            "-" +
            String(this.modelFromDate.month).padStart(2, "0") +
            "-" +
            String(this.modelFromDate.day).padStart(2, "0"),
          this.modelToDate.year +
            "-" +
            String(this.modelToDate.month).padStart(2, "0") +
            "-" +
            String(this.modelToDate.day).padStart(2, "0")
        );
        this.fetchOrders();
      }
    } else {
      this.fetchOrders();
    }
  }
  getAllKeysArray() {
    return Object.keys(this.sortBy);
  }
  sort(property) {
    this.getAllKeysArray().forEach((element, index) => {
      if (element != property) {
        this.sortBy[element] = "down";
      }
    });
    if (this.sortBy[property] == "up") {
      this.sortBy[property] = "down";
      this.isAscending = false;
      this.sortParam = property;
    } else {
      this.sortBy[property] = "up";
      this.isAscending = true;
      this.sortParam = property;
    }
    this.page = 0;
    this.fetchOrders();

    // this.isDesc = !this.isDesc; //change the direction

    // let direction = this.isDesc ? 1 : -1;

    // this.sortBy[property] = !this.isDesc ? "down" : "up";

    // if (property == "price") {
    //   this.orderList.sort(function (a, b) {
    //     if (parseFloat(a[property]) < parseFloat(b[property])) {
    //       return -1 * direction;
    //     }
    //     else if (parseFloat(a[property]) > parseFloat(b[property])) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }
    // else {
    //   this.orderList.sort(function (a, b) {

    //     if (a[property].toLowerCase() < b[property].toLowerCase()) {
    //       return -1 * direction;
    //     }
    //     else if (a[property].toLowerCase() > b[property].toLowerCase()) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }
  }
  // orderView(id){
  //      this.router.navigateByUrl("shipcarte/order/view;order-id=" + id);
  // }
  orderView(
    quoteid,
    customOrId,
    orderId,
    orderStatus,
    customerName,
    customerid,
    carrierName,
    carrierLogo,
    orderSource
  ) {
    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource,
    };
    this.commanService.quoteId = quoteid;
    sessionStorage.setItem(
      "_adminuserInformation",
      JSON.stringify(userInforamation)
    );
    this.router.navigate(["/shipcarte/orders/orderDetail"]);
  }

  traceOrder(id, carrierServiceName): void {
    let url = "";
    url = ServerURLS.getTraceOrder.action + "?order-id=" + id;
    if (carrierServiceName != null && carrierServiceName != "") {
      url += "&carrier-service=" + carrierServiceName;
    }
    this.modalService.open(this.loadContent, {
      centered: true,
      backdropClass: "light-blue-backdrop",
      windowClass: "dark-modal",
      backdrop: "static",
    });
    let promise1 = this.scHttpService.getHttpService(url, "");
    promise1
      .then((value) => {
        if (value["data"]) {
          this.modalService.dismissAll();
          this.router.navigateByUrl("shipcarte/order/view;order-id=" + id);
        } else {
          this.modalService.dismissAll();
        }
      })
      .catch((error) => {
        this.modalService.dismissAll();
      });
  }

  repeatOrder(quoteId): void {
    this.router.navigateByUrl("shipcarte/quote/rates;quote-id=" + quoteId);
  }
  // **************** ************** ***************** ******************* ****************** ***********************
  // display popup data for quotes summary ************************ ************* *************************** *****************************
  popOverIndex(i) {
    this.popOverDataPrice = [];
    let url2 =
      `${ServerURLS.getCustomerOrderPriceComponent.action}?quoteId=` +
      this.orderList[i].quoteDetails.quoteId +
      `&customerId=${this.customerId}`;
    let promise2 = this.scHttpService.getHttpService(url2, "");
    promise2.then((res: any) => {
      this.popOverDataPrice = res;
      // console.log(this.popOverDataPrice)
      this.popOverDataPrice["customId"] =
        this.orderList[i]["quoteDetails"].customQuoteId;
      this.popOverDataPrice["shpmtModeName"] =
        this.orderList[i]["quoteDetails"].shipmentModeName;
      this.popOverDataPrice["shipmentModeStyleCode"] =
        this.orderList[i]["quoteDetails"].shipmentModeStyleCode;
      //this.popOverDataPrice['carrierName'] = this.shipments[i]['quoteDetails'].carrierName;
      var charges = [];

      if (this.popOverDataPrice["charges"]) {
        this.popOverDataPrice["charges"].forEach((item, index) => {
          for (var key of Object.keys(item)) {
            charges.push([key, item[key]]);
          }
        });
      }
      this.popOverDataPrice["price_breakUp"] = charges;
      var totalWeight = 0;
      this.orderList[i]["quoteDetails"]["items"].forEach((item, index) => {
        totalWeight +=
          (item.wghtUnit.toLowerCase() == "lbs"
            ? item.totalWeight
            : item.totalWeight / 2.2
          ).toFixed(2) * item.qty;
      });
      this.popOverDataPrice["weight"] = totalWeight;
    });
  }
  //************************ ************* *************************** *****************************
  // popup data for delivered ************************ ************* *************************** *****************************
  showPopupDeleivered(quoteid, orderId, orderStatus) {
    // console.log(quoteid, orderId,orderStatus);
    this.selectedQuoteID = quoteid;
    this.selectedOrderID = orderId;
    this.selectedStatus = orderStatus;
    let url = `${ServerURLS.traceOrder.action}?orderId=` + orderId;

    let promise = this.scHttpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.trackingData = res;
      })
      .catch((err) => {});
  }
  // popup data for in-transit ************************ ************* *************************** *****************************
  showPopupInTransit(quoteid, orderId, orderStatus) {
    this.selectedQuoteID = quoteid;
    this.selectedOrderID = orderId;
    this.selectedStatus = orderStatus;
    this.orderList.filter((element) => {
      if (element.orderId == orderId) {
        this.shipperZip = element.quoteDetails.shipperAddress.zipCode;
        this.consigneeZip = element.quoteDetails.consigneeAddress.zipCode;
      }
    });
    let url = `${ServerURLS.traceOrder.action}?orderId=` + orderId;
    let promise = this.scHttpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.trackingData = res;
      })
      .catch((err) => {});
  }
  viewQuote(
    quoteid,
    customOrId,
    orderId,
    orderStatus,
    customerName,
    customerid,
    status,
    carrierName,
    carrierLogo,
    orderSource
  ) {
    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource,
    };
    this.commenService.quoteId = quoteid;
    sessionStorage.setItem(
      "_adminuserInformation",
      JSON.stringify(userInforamation)
    );
    this.router.navigate(["/shipcarte/orders/orderDetail"]);
    if (
      localStorage.getItem("_adminreferrer") != "salesRep" &&
      localStorage.getItem("_adminreferrer") != "assoc_cust"
    ) {
      localStorage.setItem("_adminreferrer", "customer");
    }
    {
      localStorage.setItem("_adminreferrer", "childCustomerOrders");
    }
  }

  // handleOrderClick(orderList: any, index: number) {
  //   this.viewQuote(
  //     orderList.quoteDetails.quoteId,
  //     orderList.customOrderId,
  //     orderList.orderId,
  //     orderList.status,
  //     orderList.consigneeName,
  //     orderList.quoteDetails.customerId,
  //     orderList.status,
  //     orderList.quoteDetails.carrierName,
  //     orderList.quoteDetails.carrierLogo,
  //     orderList.source
  //   );
  //   sessionStorage.setItem("parentQuoteId", orderList.quoteDetails.quoteId);
  //   console.log("Order:", orderList.orderId);
  //   console.log("Setting parentQuoteId:", orderList.quoteDetails.quoteId);

  //   if (orderList.quoteType === "Multiple") {
  //     this.router.navigate([
  //       `/${orderList.quoteDetails.quoteId}/child-customer-details`,
  //     ]);
  //   } else {
  //     this.router.navigate(["/shipcarte/orders/orderDetail"]);
  //   }
  // }
  isLoading: boolean = false;
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false;
  ngAfterViewInit() {
    this.scrollChangeCallback = () =>
      this.scrolHandlerCustomerOrderHistory(event);
    window.addEventListener("scroll", this.scrollChangeCallback, true);
  }
  allDataLoaded: any = false;
  scrolHandlerCustomerOrderHistory(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !this.isLoading
      ) {
        this.isLoading = true;
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {
      this.showButton = true;
    }
    this.currentPosition = scroll;
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scrollChangeCallback, true);
  }
  // let url =`${ServerURLS.getCustomerOrder.action}/${this.page}/${this.pageSize}?customerId=${this.customerId}&isAscending=${this.isAscending}`;
  fetchListOfDataToAppend() {
    this.page = this.page + 1;

    let url = `${ServerURLS.getCustomerOrder.action}/${this.page}/${this.pageSize}?parentQuoteId=${this.parentQuoteId}&isAscending=${this.isAscending}`;

    if (this.sortParam != "") {
      url = url + "&sortParam=" + this.sortParam;
    }
    if (this.searchKey.length > 0) {
      url = url + `&searchKey=${this.searchKey}`;
    }
    if (this.fiterStatus !== "all") {
      url = url + `&shipmentStatus=${this.fiterStatus}`;
    }
    if (this.modelToDate && this.modelFromDate) {
      let fromDate = `${this.modelFromDate.year}-${String(
        this.modelFromDate.month
      ).padStart(2, "0")}-${String(this.modelFromDate.day).padStart(2, "0")}`;
      let toDate = `${this.modelToDate.year}-${String(
        this.modelToDate.month
      ).padStart(2, "0")}-${String(this.modelToDate.day).padStart(2, "0")}`;
      url = url + `&startDate=${fromDate}&endDate=${toDate}`;
    }
    let promise = this.scHttpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.isLoading = false;
        let iter = 0;
        if (res["response"].length > 0) {
          for (let i = 0; i < res["response"].length; i++) {
            res["response"][i].price = res
              ? res["response"][i].quoteDetails.totalAmount
              : 0;
            res["response"][i].currency = res
              ? res["response"][i].quoteDetails.currency
              : "";
            res["response"][i].pickupDate = res["response"][i].quoteDetails
              .pickupDate
              ? res["response"][i].quoteDetails.pickupDate
              : "N/A";
            res["response"][i].shipper = res["response"][i].quoteDetails
              .shipperAddress.company
              ? res["response"][i].quoteDetails.shipperAddress.company
              : "N/A";
            res["response"][
              i
            ].origin = `${res["response"][i].quoteDetails.shipperAddress.city}, ${res["response"][i].quoteDetails.shipperAddress.state}`;
            res["response"][
              i
            ].destination = `${res["response"][i].quoteDetails.consigneeAddress.city}, ${res["response"][i].quoteDetails.consigneeAddress.state}`;
            res["response"][i].consignee = res["response"][i].quoteDetails
              .consigneeAddress.company
              ? res["response"][i].quoteDetails.consigneeAddress.company
              : "N/A";
            res["response"][i].consigneeName =
              `${res["response"][i].quoteDetails.consigneeAddress.firstName}`
                ? `${
                    res["response"][i].quoteDetails.consigneeAddress.firstName
                  } ${
                    res["response"][i].quoteDetails.consigneeAddress.lastName
                      ? res["response"][i].quoteDetails.consigneeAddress
                          .lastName
                      : ""
                  }`
                : "N/A";
            res["response"][i].carrierName = res["response"][i].quoteDetails
              .carrierName
              ? res["response"][i].quoteDetails.carrierName
              : "N/A";
            console.log(res["response"][i]);
            iter = iter + 1;
            if (iter == res["response"].length) {
              this.orderList = this.orderList.concat(res["response"]);
              let data = {
                dataList: this.orderList,
                pageCount: this.page,
                pageSize: this.pageSize,
              };
              this.listDataService.setAllDataOfCustomerOrderHistory(data);
            }
          }
        } else {
          this.isLoading = false;
          this.allDataLoaded = true;
        }
      })
      .catch((err) => {
        this.isLoading = false;
      });
  }

  tabStyle: number = 1;
  pleaseDataRemove: boolean = true;
  orderid: any;
  quote: any;
  addFevForm: FormGroup;
  @ViewChild("confirmWarn", undefined) confirmWarn;
  savingToFavValue: string = "";
  changeStatusOfOrder(mode, value, i) {
    this.modalService.open(this.loadContent, {
      centered: true,
      backdropClass: "light-blue-backdrop",
      windowClass: "dark-modal",
      backdrop: "static",
    });
    let key = "";
    if (mode === "pickup") {
      key = "PICKEDUP";
    } else if (mode === "nopickup") {
      key = "NO_PICKUP";
    } else if (mode === "del") {
      key = "DELIVERED";
    } else if (mode === "notDel") {
      key = "NO_DELIVERED";
    }
    let key2 = value.status;
    console.log(key2);

    let url =
      ServerURLS.changeStatuOfOrder.action +
      "?orderId=" +
      value.orderId +
      "&shipmentStatus=" +
      key;
    let promise = this.scHttpService.putHttpService(url, "");
    promise
      .then((res: any) => {
        console.log(mode);
        this.modalService.dismissAll();
        this.toaster.success("Status Updated Successfully");

        this.orderList[i].status = res.data;
        console.log(this.orderList, "history");
      })
      .catch((err: any) => {
        this.modalService.dismissAll();
        this.toaster.error("Something Went Wrong! Please try again later.");
      });
  }
  cancelOrder(data, index) {
    console.log(data);
    let url =
      ServerURLS.cancelOrderFromList.action + "?order-id=" + data.orderId;
    let promise = this.scHttpService.postHttpService(url, "");
    promise
      .then((res: any) => {
        if (this.tabStyle == 1) {
          //if status=='sched then remove elemetn from sched order list
          if (data.status == "SCHED") {
            this.listDataService.removeElementFromTheListAccordingToTabId(
              4,
              data.orderId
            );
          }
          //if status==intransit then remove element from intransit order list
          if (data.status == "INTRANSIT") {
            this.listDataService.removeElementFromTheListAccordingToTabId(
              2,
              data.orderId
            );
          }
        } else if (this.tabStyle == 2) {
          //remove element from intransit order list
          this.listDataService.removeElementFromTheListAccordingToTabId(
            2,
            data.orderId
          );
        } else if (this.tabStyle == 4) {
          // remoe elemetn from scheed order list
          this.listDataService.removeElementFromTheListAccordingToTabId(
            4,
            data.orderId
          );
        }
        this.listDataService.updateDataListOfOrderWhenChangeTheStatus(
          data.orderId,
          "CANCELLED",
          1
        );
        this.orderList[index]["status"] = "CANCELLED";
      })
      .catch((err: any) => {});
  }
  getOrderMetaDataById(
    quoteid,
    customOrId,
    orderId,
    orderStatus,
    customerName,
    customerid,
    status,
    carrierName,
    carrierLogo,
    orderSource
  ) {
    this.pleaseDataRemove = false;

    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource,
    };
    this.listDataService.setSelectedOrderId(orderId, this.tabStyle);
    this.commanService.quoteId = quoteid;
    sessionStorage.setItem(
      "_adminuserInformation",
      JSON.stringify(userInforamation)
    );
    this.router.navigate([
      "shipcarte/bookedOrder/edit",
      userInforamation.orderId,
    ]);

    this.orderid = orderId;
    this.quote = quoteid;
  }
  open(content, type, orderId) {
    this.modalService
      .open(content, { size: "sm", centered: true, windowClass: "AddfevModal" })
      .result.then(
        (result) => {
          if (this.addFevForm.valid) {
            this.modalService
              .open(this.confirmWarn, {
                size: "sm",
                centered: true,
                windowClass: "confirmModal",
              })
              .result.then(
                (result) => {
                  this.postFevData(orderId);
                },
                (reason) => {}
              );
          } else {
            this.addFevForm.markAllAsTouched();
            this.open(content, "", orderId);
          }
        },
        (reason) => {}
      );
  }
  postFevData(orderId) {
    this.modalService.open(this.loadContent, {
      centered: true,
      backdropClass: "light-blue-backdrop",
      windowClass: "dark-modal",
      backdrop: "static",
    });

    let url = ServerURLS.addFevOrder.action;
    let payload = {
      orderId: orderId,
      isFavourite: true,
      favouriteOrderName: this.savingToFavValue,
    };

    let promise = this.scHttpService.putHttpService(url, payload);
    promise
      .then((res) => {
        this.toaster.success("Shipment Successfully added to favorites.");

        this.modalService.dismissAll();
      })
      .catch((err) => {
        this.modalService.dismissAll();

        this.toaster.error(
          err.message ? err.message : "Something Went Wrong",
          "",
          {}
        );
      });
  }
  setValue(value: any) {
    this.savingToFavValue = value;
  }
  shipAgain(quoteId, backToQuote, orderId) {
    if (backToQuote == "On Call Order") {
      this.router.navigate([
        "/shipcarte/orders/newOrderEntry/edit",
        quoteId,
        orderId,
      ]);
    } else if (backToQuote == "Quoted") {
      this.router.navigate(["/shipcarte/step-one/" + quoteId]);
    }
  }
}
