import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseDocumentComponent } from './case-document/case-document.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CaseListComponent } from './case-list/case-list.component';
import { MyCaseListComponent } from './my-case-list/my-case-list.component';
import { CaseViewComponent } from './case-view/case-view.component';
import { CaseHistoryListComponent } from './case-history-list/case-history-list.component';
import { SpinnerModule } from 'src/app/spinner/spinner.module';

const routes: Routes =[
  { path: '', redirectTo: 'shipcarte/case/document/add', pathMatch: 'full' },
  { path: 'shipcarte/case/document/add', component: CaseDocumentComponent},
  { path: 'shipcarte/case/list', component: CaseListComponent},
  { path: 'shipcarte/my/case/list', component: MyCaseListComponent},
  { path: 'shipcarte/case/view', component: CaseViewComponent},
  { path: 'shipcarte/case/history/list', component: CaseHistoryListComponent}

];

@NgModule({
  declarations: [CaseDocumentComponent, CaseListComponent, MyCaseListComponent, CaseViewComponent, CaseHistoryListComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SpinnerModule
  ]
})
export class CaseModule { }
