import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { AllOrderComponent } from "./allOrder/allOrder.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrdersComponent } from "./orders.component";
import { NewOrderComponent } from "./new-order/new-order.component";
import { CarrierContractComponent } from "./../commons/carrier-contract/carrier-contract.component";
import { ViewPodComponent } from "../commons/view-pod/view-pod.component";
import { UploadPodComponent } from "../commons/upload-pod/upload-pod.component";
import { ViewPodRequestComponent } from "../commons/view-pod-request/view-pod-request.component";
import { SpinnerModule } from "src/app/spinner/spinner.module";
import { ChildOrderComponent } from "./child-order/child-order.component";

const routes: Routes = [
  {
    path: "",
    component: OrdersComponent,
  },
  {
    path: "shipcarte/orders",
    component: OrdersComponent,
  },
  {
    path: "shipcarte/orders/filter/:fromDate/:toDate/isDashBoardView",
    component: OrdersComponent,
  },

  {
    path: "shipcarte/orders/filter/:fromDate/:toDate/isDashBoardView/:currency",
    component: OrdersComponent,
  },
  {
    path: "shipcarte/orders/orderDetail",
    component: AllOrderComponent,
    loadChildren: () =>
      import("./allOrder/allOrder.module").then((m) => m.AllOrderModule),
  },
  {
    path: "shipcarte/orders/newOrderEntry",
    loadChildren: () =>
      import("./new-order/new-order.module").then((m) => m.NewOrderModule),
  },
  {
    path: "shipcarte/bookedOrder",
    loadChildren: () =>
      import("./new-order/new-order.module").then((m) => m.NewOrderModule),
  },
  {
    path: "shipcarte/orders/child-orders",
    component: ChildOrderComponent,
  },
  {
    path: "shipcarte/orders/:orderId/child-orders",
    component: ChildOrderComponent,
  },
];
@NgModule({
  declarations: [AllOrderComponent, OrdersComponent, ChildOrderComponent],
  entryComponents: [
    CarrierContractComponent,
    ViewPodComponent,
    UploadPodComponent,
    ViewPodRequestComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
  ],
})
export class OrdersModule {}
