import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { FormsModule } from '@angular/forms';
import { InsuranceComponent } from '../insurance/insurance.component';
import { HttpHeaders } from '@angular/common/http';
import { LoadingService } from 'src/app/services/loader.service';
declare var $: any;
@Component({
  selector: 'app-carrier-selection',
  templateUrl: './carrier-selection.component.html',
  styleUrls: ['./carrier-selection.component.scss']
})
export class CarrierSelectionComponent implements OnInit {
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };
  displayData = true;
  carrierData: any = [];
  carrierDataTmp: any;
  start: any;
  FailedCarriers: any = [];
  end: any;
  sourceZip: any;
  destinationZip: any;
  sumColab: any;
  carrierID: any; carrier_req_id: any;
  carrierName: string;
  displayErrorMessage = true;
  errorMessage = '';
  totalWeight: any;
  itemClass: string;
  cad: number;
  quoteId: any;
  opened: boolean = true;
  maxBusinessDays: number;
  quoteDetailsData: any;
  ifitemhasClass: boolean;
  failedCarriers: any = [];
  carrierDataTemp: any = [];
  cYear: any;
  carrierDatacount: any;
  currencyConversionRate: any;
  sourceCurrency: any;
  insauraceOpted: boolean;
  totalamount = 0;
  value: any;
  userType: any;
  show: boolean = true;
  totalInsauranceAmount = 0.00;
  editableOrderId = '';
  toolTipElement: any[];
  customerId: any = '';
  weightUnit: any;
  pickUpDateDisplay: any;
  
  constructor(private commonService: ScCommonService, private router: Router, private activatedRoute: ActivatedRoute, private httpService: ScHttpService, private modalService: NgbModal, private toastr: ToastrService, private  _loaderService: LoadingService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('_adminorderInsuranceData')==''){
      sessionStorage.removeItem('_adminorderInsuranceData');
    }
    this._loaderService.stringSubject.next(true);
    this.customerId = sessionStorage.getItem('_admingetQuoteCustomerId');
    this.totalWeight = 0;
    this.itemClass = '';
    this.maxBusinessDays = 10;
    this.sourceZip = "";
    this.destinationZip = "";
    this.ratio = 100;
    this.posX = 239;
    this.ifitemhasClass = false;
    this.insauraceOpted = false;

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.quoteId = id;
    this.getExtraservicesData(id);

    this.displayData = false;
    let inputData = {
      "name": null,
      "id": null,
      "data": {
        "carrierRates": [
          {
            "charges": "Total Charge: 0 USD",
            "carrierCode": "ABF",
            "carrierId": "102",
            "carrierName": "ABF",
            "logoId": "9fd8386f-9737-4f37-a7e5-5f8f4ced7fe5",
            "totalCost": "1166.45",
            "quoteId": "87ea00bf-42bc-4eca-9505-9557599293a8",
            "carrierReqId": "632312f0-0656-4a9c-ba20-0c2c7eb9afae",
            "downloadLink": "https://dev-shipcarte-docs.s3.amazonaws.com/1613140377156-ABF_(1).jpg",
            "subCarrierName": "ABF",
            "subCarrierServiceName": "Regular",
            "carrierMsg": null,
            "transitTime": "1",
            "curUnit": "USD"
          },
          {
            "charges": "Base Charges: 11.71 CAD, Fuel Charges: 1.46 CAD, Insurance Charges: 0 CAD",
            "carrierCode": "ICS",
            "carrierId": "8d0e4aa0-bcca-11eb-a757-16dd6d5c7fbb",
            "carrierName": "ICS Couriers",
            "logoId": "978af3f1-313b-4aa8-835f-6a7804ef7436",
            "totalCost": "13.17",
            "quoteId": "87ea00bf-42bc-4eca-9505-9557599293a8",
            "carrierReqId": "c2aa2d26-7bdd-41a6-81c3-71bc5795ae06",
            "downloadLink": "https://dev-shipcarte-docs.s3.amazonaws.com/1626013336741-ICSLogo.jpg",
            "subCarrierName": null,
            "subCarrierServiceName": null,
            "carrierMsg": null,
            "transitTime": "1",
            "curUnit": "CAD"
          },
          {
            "charges": "Total Charge: 72.83000183105469 USD",
            "carrierCode": "YRC",
            "carrierId": "3334d38f-3139-11ea-943e-16dd6d5c7fbb",
            "carrierName": "YRC",
            "logoId": "faf2e2c2-8e43-47b4-ac5a-8dee927dd06a",
            "totalCost": "72.83 ",
            "quoteId": "87ea00bf-42bc-4eca-9505-9557599293a8",
            "carrierReqId": "31a89751-b727-4ed4-947c-e3e7b43b11b1",
            "downloadLink": "https://dev-shipcarte-docs.s3.amazonaws.com/1578392709218-YRC.png",
            "subCarrierName": null,
            "subCarrierServiceName": "Non DFQ",
            "carrierMsg": null,
            "transitTime": "0",
            "curUnit": "USD"
          }
        ],
        "carriers": [
          "DR",
          "YRC",
          "EP-UPS",
          "EP-CP",
          "EP-FED",
          "EP-LE",
          "EP-PU",
          "XPO",
          "ABF",
          "ICS"
        ]
      },
      "message": null,
      "error": null,
      "loggedInUserId": null,
      "entityType": null,
      "code": 0
    }

    this.displayData = true;

    let url = ServerURLS.getcurrencyConversionRate.action;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result) => {
      this.currencyConversionRate = result
    }).catch((error) => {
      console.error(error);
    });

    let url1 = ServerURLS.getCarrierListing.action + '?quote-id=' + this.quoteId
    let promise1 = this.httpService.getHttpService(url1, '');
    promise1.then((result) => {
      this.displayData = false;
      // this._loaderService.stringSubject.next(false);
      if (result['data']['carrierRates'].length != 0) {
        this.carrierData = this.carrierDataTmp = result['data']['carrierRates'];
        this.carrierDatacount = this.carrierData.length
        this.carrierData.forEach((item, index) => {
          var charges = [];
          var tem = 0
          if (item['curUnit'] == "CAD") {
            tem = item['totalCost'] / (this.currencyConversionRate ? this.currencyConversionRate : 1.26)
          } else if (item['curUnit'] == "USD") {
            tem = item['totalCost']
          }
          if (item.charges) {
            var charge = item.charges;
            var chargesArr = charge.split(",");
            chargesArr.forEach((char, i) => {
              charges[i] = char.split(":");
            });
          }
          this.carrierData[index]['conversionRate'] = tem;
          this.carrierData[index]['charges'] = charges;
          this.carrierData[index]['quoted'] = true;
        });

        this.lowtohigh('low');
      } else {
        this.displayErrorMessage = false;
        this.errorMessage = 'No results found!';
      }
      this.displayData = false;
      // this._loaderService.stringSubject.next(false);
    }).catch((error) => {
      console.error(error);
      // this._loaderService.stringSubject.next(false);
    });

    let url3 = ServerURLS.quoteDetails.action + '?quoteId=' + this.quoteId;
    let promise3 = this.httpService.getHttpService(url3, '');

    promise3.then((x) => {
      if (x['data'] !== null && x['data'] !== undefined) {
        this.quoteDetailsData = x['data'];
        console.log("this.quoteDetailsData",this.quoteDetailsData);
        this.pickUpDateDisplay = this.quoteDetailsData.pickupDate;
        localStorage.setItem('_adminquoteCustomId', x['data'].customId);
        localStorage.setItem('_adminquoteId', x['data'].quoteId);
        this.getAddress(x['data'].destAddr);
        this.getAddress2(x['data'].sourceAddr);
      } else {
        console.error('Data object is null or undefined');
      }
    }, (error) => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    }).catch((error) => {
      console.error(error);
    });

    let url6 = ServerURLS.summaryColabesDetails.action + '?quoteId=' + this.quoteId;
    let promise6 = this.httpService.getHttpService(url6, '');
    promise6.then((c) => {
      this.sumColab = c['data']['content'];
      this.sumColab.sort(function (a, b) {
        return parseFloat(a.displayOrder) - parseFloat(b.displayOrder)
      });
      this.totalWeight = 0;
      this.totalamount = 0
      this.sumColab.forEach((data, index) => {
        if (data['freightClassType'] === null) {

          this.ifitemhasClass = false;
        }
        else {
          this.ifitemhasClass = true;
        }


        this.weightUnit=data.wghtUnit;
        this.totalWeight += (parseFloat(data.totalWeight) * parseInt(data.qty));

        
                this.itemClass += this.itemClass !== null ? (index == 0 ? '' : ',') + data.freightClassType : data.freightClassType;
        if (this.itemClass !== null) {
          this.itemClass = this.itemClass;
        } else {
          this.itemClass = '--';
        }
        if (data.unit == 'cm') {
          this.sumColab[index]['length'] = this.sumColab[index]['length'];
          this.sumColab[index]['breadth'] = this.sumColab[index]['breadth'];
          this.sumColab[index]['height'] = this.sumColab[index]['height'];
        }
        this.totalamount = this.totalamount + parseFloat(data['costValue']);
        this.shipmentValueCurrency = this.sumColab[0].costValueCurrency
        let countryData = JSON.parse(sessionStorage.getItem('_admincountryDataForMarchInsurance'))
        if (countryData.shipmentMode == '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
          this.isMarshApplicable = false
          this.applyCustomInsuranceLogic();
        } else {
          this.isMarshApplicable = true;
          let data = JSON.parse(sessionStorage.getItem('_adminorderInsuranceData'));
          if (data) {
            if (data.accepted) {
              this.removeInsurance('')
            }
          }
          sessionStorage.removeItem('_adminorderInsuranceData')

          this.createMarshInsurace();
        }

      })

    }).catch((error) => {
      console.error(error);
    });


    this.userType = localStorage.getItem('_adminloggedInUserDetailsFullData') ? JSON.parse(localStorage.getItem('_adminloggedInUserDetailsFullData')).userRole : null;
  }
  marshInuranseData: any;
  insuranceError: boolean = false;
  isMarshApplicable: boolean = true;
  shipmentValueCurrency: any = ''
  popUPConditions: any = {
    'currency': "",
    'conditionArray': [],
  };
  applyCustomInsuranceLogic() {
    if (this.totalamount <= 10000) {
      this.totalInsauranceAmount = 45;
      this.value = this.totalInsauranceAmount

    }
    else if (this.totalamount > 10000 && this.totalamount <= 50000) {
      this.totalInsauranceAmount = 85;
      this.value = this.totalInsauranceAmount
    } else if (this.totalamount > 50000 && this.totalamount <= 75000) {
      this.totalInsauranceAmount = 145
      this.value = this.totalInsauranceAmount
    } else if (this.totalamount > 75000 && this.totalamount <= 125000) {
      this.totalInsauranceAmount = 195
      this.value = this.totalInsauranceAmount
    } else if (this.totalamount > 125000 && this.totalamount <= 185000) {
      this.totalInsauranceAmount = 295
      this.value = this.totalInsauranceAmount
    }
    else {
      this.totalInsauranceAmount = ((3 * this.totalamount) / 100);

      this.value = this.formatNumberIntoCADFormat(this.totalInsauranceAmount)
    }
    this.popUPConditions.currency = this.value + ' ' + this.shipmentValueCurrency
    sessionStorage.setItem('_adminorderInsuranceData', JSON.stringify({ accepted: false, certificateNumber: '', amount: parseFloat(this.totalInsauranceAmount.toString()) }))
    this._loaderService.stringSubject.next(false);
    
  }

  getFormatedLocalDate(localPickupDate) {
    const year = localPickupDate.getFullYear();
    const month = localPickupDate.getMonth() + 1;
    const day = localPickupDate.getDate();
    let formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    // add time string in date to make ISO format string
    formattedDate= formattedDate+"T00:00:00+00:00";
    return formattedDate;
  }

  createMarshInsurace() {
    this.displayData = true;
    this._loaderService.stringSubject.next(true);
    let pickupDate = JSON.parse(sessionStorage.getItem('_adminorderPickupDate')).savedDate;
    const localPickupDate = new Date(pickupDate);
    const iso8601Date = this.getFormatedLocalDate(localPickupDate);
    let totalAmount = 0;
    this.sumColab.forEach((element) => {
      totalAmount = totalAmount + parseFloat(element.costValue)
    })
    let countryData = JSON.parse(sessionStorage.getItem('_admincountryDataForMarchInsurance'));
    let data = {
      "dateOfDeparture": iso8601Date,
      'quoteId': this.quoteId,
      'insuredValueCurrency': this.sumColab[0]['costValueCurrency'],
      'insuredValueAmount': totalAmount,
      'carrierName': 'Shipcarte',
      'descriptionOfCargo': " ",
      'origin': countryData.origin,
      'destination': countryData.destination
    }
    let url = ServerURLS.createMarshInsurance.action + '?customerId=' + this.customerId;
    let promise = this.httpService.postHttpService(url, data);
    promise.then((res: any) => {

      this.isMarshApplicable = true
      this.marshInuranseData = res
      this.value = (res.totalPremiumAmount).toFixed()
      this.totalInsauranceAmount = res.totalPremiumAmount
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      if (res.generalConditions) {
        this.popUPConditions.conditionArray.push(res.generalConditions)
      }
      if (res.specialConditions) {
        this.popUPConditions.conditionArray.push(res.specialConditions)
      }
      this.popUPConditions.currency = res.totalPremiumAmount + ' ' + res.premiumCurrencyISO
      sessionStorage.setItem('_adminorderInsuranceData', JSON.stringify({ accepted: false, certificateNumber: this.marshInuranseData.certificateNumber, amount: this.marshInuranseData.totalPremiumAmount }))
      this.displayData = false;
      // this._loaderService.stringSubject.next(false);
    }).catch((err: any) => {
      this.displayData = false;
      this.isMarshApplicable = false;
      this.applyCustomInsuranceLogic()

    })
  }
  formatNumberIntoCADFormat(insuranceAmount) {
    const formattedCurrency = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(insuranceAmount);
    return formattedCurrency
  }
  formatNumber(insuranceAmount) {
    insuranceAmount = insuranceAmount.toString().replace(/[^0-9.]/g, '');
    if (insuranceAmount < 1000) {
      return insuranceAmount;
    }
    let si = [

      { v: 1E3, s: "K" },

      { v: 1E6, s: "M" },

      { v: 1E9, s: "B" },

      { v: 1E12, s: "T" },

      { v: 1E15, s: "P" },

      { v: 1E18, s: "E" }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (insuranceAmount >= si[index].v) {
        break;
      }
    }
    return (insuranceAmount / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
  }
  selectedShipperAddressCountry: any;
  selectedCosigneeAddressCountry: any;
  // orderIsWithInCanada: boolean = true;
  classToSet: any[] = [];
  getAddress(id): void {
    let url4 = ServerURLS.getAddressSummary.action + "?id=" + id;
    let promise4 = this.httpService.getHttpService(url4, '');

    promise4.then((result) => {

      this.selectedShipperAddressCountry = result['data'].country;
      // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada()
      if (result['data'] !== null && result['data'] !== undefined) {
        this.destinationZip = result['data'].zipCode;
      }
    });
  }
  getAddress2(id) {
    let url5 = ServerURLS.getAddressSummary.action + "?id=" + id;
    let promise5 = this.httpService.getHttpService(url5, '');

    promise5.then((y) => {
      this.selectedCosigneeAddressCountry = y['data'].country;
      // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada()
      if (y['data'] !== null && y['data'] !== undefined) {
        this.sourceZip = y['data'].zipCode;
      }
    });
  }

  // removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada() {
  //   if (this.selectedShipperAddressCountry && this.selectedCosigneeAddressCountry) {

  //     if (this.selectedShipperAddressCountry === 'CA' && this.selectedCosigneeAddressCountry === 'CA') {
  //       this.orderIsWithInCanada = true;
  //       this.classToSet = []
  //     } else {
  //       this.orderIsWithInCanada = false;
  //     }
  //   }
  // }

  selectCarrier(carrierData) {
    this.displayData = true;
    this._loaderService.stringSubject.next(true);
    //Termporary set
    localStorage.setItem('_admincharge', carrierData['totalCost']);
    localStorage.setItem('_admincarrierLogo', carrierData['downloadLink']);
    let url7 = ServerURLS.getStartSummary.action + "?quote-id=" + carrierData.quoteId + "&carrier-id=" + carrierData.carrierId + "&carrier-req-id=" + carrierData.carrierReqId;
    let promise7 = this.httpService.getHttpService(url7, '');
    promise7.then((c) => {
      if (c['data'].length == 0) {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        this.toastr.error('Something went wrong, Please try again!', '', {
          timeOut: 10000,
        });
      }
      else {

        if (this.insauraceOpted == true) {
          let amount = this.popUPConditions.currency.split(' ')[0]
          this.submitInsaurance(carrierData.quoteId, amount);
        }
        else {
          let amount = "0";
          this.submitInsaurance(carrierData.quoteId, amount);
        }
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        this.createShipment(c['data'][0]['carrierQuoteReq'], id)
        this.router.navigate(['/shipcarte/confirm-order/', this.quoteId])
      }
    })
  }

  submitInsaurance(quoteId, amount) {
    let url8 = ServerURLS.submitInsaurance.action + "?quoteId=" + quoteId + "&insuranceAmount=" + amount + '&customerId=' + this.customerId;
    let promise8 = this.httpService.putHttpService(url8, { withCredentials: true });

    promise8.then((res) => {
    });
  }

  createShipment(carrierQuoteReq, quoteId) {
    let url9 = ServerURLS.createShipment.action + "?quoteId=" + quoteId + "&shpmtId=" + carrierQuoteReq;
    let promise9 = this.httpService.putHttpService(url9, { params: {} });

    promise9.then((data) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.router.navigate(['/shipcarte/confirm-order/' + this.quoteId])
    });
  }

  showToolTip(value) {
    this.carrierData.filter(element => {
      if (element.carrierId == value) {
        this.toolTipElement = element;

      }
    })
  }


  lowtohigh(value) {
    if (value == 'low') {
      this.carrierData.sort(function (a, b) {
        return parseFloat(a.conversionRate) - parseFloat(b.conversionRate)
      });
      this.carrierDataTmp.sort(function (a, b) {
        return parseFloat(a.conversionRate) - parseFloat(b.conversionRate)
      });


    } else {
      this.carrierData.sort(function (a, b) {
        return parseFloat(b.conversionRate) - parseFloat(a.conversionRate)
      });
      this.carrierDataTmp.sort(function (a, b) {
        return parseFloat(b.conversionRate) - parseFloat(a.conversionRate)
      });

    }

  }

  getExtraservicesData(quoteId) {
    let url = ServerURLS.getExtraservicesData.action + '?quoteId=' + quoteId + '&locType=start';
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result) => {
      this.start = result['data'];
    })

    let url2 = ServerURLS.getExtraservicesData.action + '?quoteId=' + quoteId + '&locType=end';
    let promise2 = this.httpService.getHttpService(url2, '');
    promise2.then((result) => {
      this.end = result['data'];
    })
  }

  redirectTo() {
    this.router.navigate(['/shipcarte/step-one/' + this.quoteId]);
  }
  isInsuranceSelected: boolean = false
  dialogOpen() {
    const modalRef = this.modalService.open(InsuranceComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal22' });
    modalRef.componentInstance.quoteId = this.quoteId
    modalRef.componentInstance.popUpConditions = this.popUPConditions;
    modalRef.componentInstance.isMarshApplicable = this.isMarshApplicable
    modalRef.result.then((result) => {
      if (result.success == true) {

        sessionStorage.setItem('_adminorderInsuranceData', JSON.stringify({ accepted: true, certificateNumber: this.isMarshApplicable ? this.marshInuranseData.certificateNumber : '', amount: this.isMarshApplicable ? this.marshInuranseData.totalPremiumAmount : parseInt(this.popUPConditions.currency.split(' ')[0]) }))
        this.insauraceOpted = true
      } else {
        sessionStorage.setItem('_adminorderInsuranceData', JSON.stringify({ accepted: false, certificateNumber: this.isMarshApplicable ? this.marshInuranseData.certificateNumber : '', amount: this.isMarshApplicable ? this.marshInuranseData.totalPremiumAmount : parseInt(this.popUPConditions.currency.split(' ')[0]) }))
        this.insauraceOpted = false;
      }
    });
  }
  onChange(event) {
    // this.dialogOpen()
    if (event) {
      this.dialogOpen()
    } else {
      this.removeInsurance('click')
    }
  }
  removeInsurance(mode) {
    if (mode == 'click') {
      this.displayData = true
      this._loaderService.stringSubject.next(true);
    }
    let url = ServerURLS.removeInsurance.action + '?quoteId=' + this.quoteId;
    let promise = this.httpService.putHttpService(url, '');
    promise.then((res: any) => {
      if (mode == 'click') {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        sessionStorage.setItem('_adminorderInsuranceData', JSON.stringify({ accepted: false, certificateNumber: this.isMarshApplicable ? this.marshInuranseData.certificateNumber : '', amount: this.isMarshApplicable ? this.marshInuranseData.totalPremiumAmount : parseInt(this.popUPConditions.currency.split('$')[1].split(' ')[0]) }))
        this.toastr.success("Insurance Removed")
      }

    }).catch((error: any) => {
      console.log(error)
      if (mode == 'click') {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
      }
      this.toastr.error("Something Went Wrong33!")
    })

  }
  backToQuote() {
    this.router.navigate(['/shipcarte/step-one/' + this.quoteId]);
  }

  // ----------------------range slider-----------------
  // .range 
  @ViewChild('range', undefined) $range: ElementRef;
  // .range-track 요소의 정보
  rangeTrackRect = { left: 0, right: 0, width: 0 };
  @Output() change = new EventEmitter<number>();

  // mousedown 
  active = false;
  // .range-track 
  posX = 0;
  // .range-track 
  ratio = 0;

  ngAfterViewInit() {
    this.rangeTrackRect
      = this.$range.nativeElement.getBoundingClientRect();
  }

  getTransformVal() {
    return `translate3d(${this.posX}px, 0, 0)`;
  }


  rangeHandler(e: MouseEvent) {
    const { clientX } = e;
    const { left, right, width } = this.rangeTrackRect;
    if (clientX < left || clientX > right) return;
    this.posX = e.clientX - left;


    this.ratio = (this.posX / width) * 100;
    this.change.emit(Math.round((360 * this.ratio) / 100));
    this.maxBusinessDays = this.ratio / 10;
    this.maxBusinessDays = Number((this.maxBusinessDays).toFixed());
    this.sortByTransitDays(this.maxBusinessDays);
  }

  sortByTransitDays(daysMax) {
    if (daysMax > 0) {
      var filteredByTransitDays = [];
      this.carrierDataTmp.forEach((data, index) => {
        if (parseInt(data.transitTimeNum) <= daysMax) {
          filteredByTransitDays.push(data);
        }
        if (data.transitTime === 'Not Available') {
          filteredByTransitDays.push(data)
        }
      });
      this.carrierData = filteredByTransitDays;
    } else {
      this.maxBusinessDays = 1
    }

  }


  mousedownHanlder(e: MouseEvent) {
    e.preventDefault();
    this.active = true;
    this.rangeHandler(e);
  }

  mousemoveHanlder(e: MouseEvent) {

    if (!this.active) return;
    e.preventDefault();
    this.rangeHandler(e);
  }

  mouseupHanlder() {
    this.active = false;
  }

  loadFailedCarriers(flag) {
    if (!flag) {
      for (let index = this.carrierData.length - 1; index >= this.carrierDatacount; index--) {
        this.carrierData.splice(index, 1);
      }
      return;
    }
    this.displayData = true;
    this._loaderService.stringSubject.next(true);
    let url = ServerURLS.getAllFailedCarriers.action + '?quoteId=' + this.quoteId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result) => {
      if (result) {
        this.carrierDatacount = this.carrierData.length
        this.failedCarriers = result;
        this.failedCarriers.forEach((item, index) => {
          this.carrierData.push({
            "carrierCode": item.carrierCode,
            "carrierId": item.carrierId,
            "carrierMsg": null,
            "carrierName": item.carrierName,
            "carrierReqId": item.carrierRequestId,
            "charges": null,
            "curUnit": null,
            "downloadLink": item.logoUrl,
            "logoId": null,
            "markup": null,
            "quoteId": item.quoteId,
            "subCarrierName": null,
            "subCarrierServiceName": item.serviceName,
            "totalCost": null,
            "transitTime": null,
            "quoted": false,
            "errorMessage": item.errorMessage
          })
        });
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
      }
    }, (error) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      if (error['status'] === 404) {
        this.toastr.success('No failed carriers out there.', 'Success', {
          timeOut: 10000,
        });
      } else {
        this.toastr.error('Please try again after sometime', 'Something went wrong!', {
          timeOut: 10000,
        });
      }


    })
  }
  expandedIndex: number;
  expand(index){
    if(index == this.expandedIndex){
      $(`.currier_selection_section ngb-accordion .card:eq(${index})`).removeAttr("id");
      this.expandedIndex = undefined;
    }else{
      $(`.currier_selection_section ngb-accordion .card:eq(${this.expandedIndex})`).removeAttr("id");
      $(`.currier_selection_section ngb-accordion .card:eq(${index})`).attr("id","expanded-show");
      this.expandedIndex = index;
    }
  }
}
