import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { CalculateDistanceComponent } from '../calculate-distance/calculate-distance.component';
// import { CommonService } from '../../../../services/common.service';

@Component({
  selector: 'app-zip-postal-code',
  templateUrl: './zip-postal-code.component.html',
  styleUrls: ['./zip-postal-code.component.scss']
})
export class ZipPostalCodeComponent implements OnInit {

  addressObject:any;
  addressStatus:any;
  zipcode:any;
  submitted:boolean;
  invalidCode: boolean;
  constructor(public activeModal: NgbActiveModal,private httpService:ScHttpService,private modalService:NgbModal,private router:Router) { }

  ngOnInit(): void {
    this.invalidCode = false;

  }

  closeModal() { 
    this.activeModal.close(); 
  }
   
  getAddresses(zipcode){
    // console.log(zipcode)
    this.submitted = true;
    if(!this.zipcode){
      this.invalidCode = true;
      return;
    }

    // this.zipcode = zipcode;
    this.invalidCode = false;
    let getAddressByZip = ServerURLS.getAddressByZip.action + this.zipcode;;
    let promise = this.httpService.getHttpService(getAddressByZip, ''); 
    promise.then((result) => {
      this.addressObject = result;
      this.addressStatus = false;
      this.submitted = false;
    }, (error) => {
      this.submitted = true; //for show error
      this.zipcode = '';
      this.invalidCode = true;
    })
  }
  @ViewChild('form', { static: false }) form: NgForm;

  resetFields() {
    this.zipcode='';
  }

  open_modal(){
    this.closeModal();
  const modalRef = this.modalService.open(CalculateDistanceComponent, { size: 'lg', centered: false,backdropClass: 'light-blue-backdrop', backdrop: 'static', windowClass: 'dark-modal' });
  this.router.events.subscribe((val) => {
    modalRef.close();
  });
  modalRef.result.then((result) => {
 });
}
}
