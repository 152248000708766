import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbDateStruct,NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
 

import pdfMake from '../../../../../node_modules/pdfmake/build/pdfmake';
import pdfFonts from '../../../../../node_modules/pdfmake/build/vfs_fonts';
 

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit {
  @ViewChild('loadContent',undefined) loadContent;
    isEdit:boolean=false;
  imageData: any = null;
  showPassword:boolean=false;
  showConfirmPassword:boolean=false;
  uploadImageShow: any = '';
  showPercentageSign:boolean=false;
  modelToDate: NgbDateStruct;
  modelFromDate: NgbDateStruct;
  adminProfileForm:FormGroup;
  userLoggedInId:any;
  countryData = {
    success: [
      {
        countryPhCode: "+1",
        countryName: "Canada",
        countryCode: "CA",
        provinceState: [
          {
            code: "AB",
            name: "Alberta"
          },
          {
            code: "BC",
            name: "British Columbia"
          },
          {
            code: "MB",
            name: "Manitoba"
          },
          {
            code: "NB",
            name: "New Brunswick"
          },
          {
            code: "NL",
            name: "Newfoundland and Labrador"
          },
          {
            code: "NT",
            name: "Northwest Territories"
          },
          {
            code: "NS",
            name: "Nova Scotia"
          },
          {
            code: "NU",
            name: "Nunavut"
          },
          {
            code: "ON",
            name: "Ontario"
          },
          {
            code: "PE",
            name: "Prince Edward Island"
          },
          {
            code: "QC",
            name: "Quebec"
          },
          {
            code: "SK",
            name: "Saskatchewan"
          },
          {
            code: "YT",
            name: "Yukon"
          }
        ]
      },
      {
        countryPhCode: "+1",
        countryName: "United States",
        countryCode: "US",
        provinceState: [
          {
            code: "AL",
            name: "Alabama"
          },
          {
            code: "AK",
            name: "Alaska"
          },
          {
            code: "AS",
            name: "American Samoa"
          },
          {
            code: "AZ",
            name: "Arizona"
          },
          {
            code: "AR",
            name: "Arkansas"
          },
          {
            code: "CA",
            name: "California"
          },
          {
            code: "CO",
            name: "Colorado"
          },
          {
            code: "CT",
            name: "Connecticut"
          },
          {
            code: "DE",
            name: "Delaware"
          },
          {
            code: "DC",
            name: "District of Columbia"
          },
          {
            code: "FL",
            name: "Florida"
          },
          {
            code: "GA",
            name: "Georgia"
          },
          {
            code: "GU",
            name: "Guam"
          },
          {
            code: "HI",
            name: "Hawaii"
          },
          {
            code: "ID",
            name: "Idaho"
          },
          {
            code: "IL",
            name: "Illinois"
          },
          {
            code: "IN",
            name: "Indiana"
          },
          {
            code: "IA",
            name: "Iowa"
          },
          {
            code: "KS",
            name: "Kansas"
          },
          {
            code: "KY",
            name: "Kentucky"
          },
          {
            code: "LA",
            name: "Louisiana"
          },
          {
            code: "ME",
            name: "Maine"
          },
          {
            code: "MD",
            name: "Maryland"
          },
          {
            code: "MA",
            name: "Massachusetts"
          },
          {
            code: "MI",
            name: "Michigan"
          },
          {
            code: "MN",
            name: "Minnesota"
          },
          {
            code: "MS",
            name: "Mississippi"
          },
          {
            code: "MO",
            name: "Missouri"
          },
          {
            code: "MT",
            name: "Montana"
          },
          {
            code: "NE",
            name: "Nebraska"
          },
          {
            code: "NV",
            name: "Nevada"
          },
          {
            code: "NH",
            name: "New Hampshire"
          },
          {
            code: "NJ",
            name: "New Jersey"
          },
          {
            code: "NM",
            name: "New Mexico"
          },
          {
            code: "NY",
            name: "New York"
          },
          {
            code: "NC",
            name: "North Carolina"
          },
          {
            code: "ND",
            name: "North Dakota"
          },
          {
            code: "OH",
            name: "Ohio"
          },
          {
            code: "OK",
            name: "Oklahoma"
          },
          {
            code: "OR",
            name: "Oregon"
          },
          {
            code: "PA",
            name: "Pennsylvania"
          },
          {
            code: "PR",
            name: "Puerto Rico"
          },
          {
            code: "RI",
            name: "Rhode Island"
          },
          {
            code: "SC",
            name: "South Carolina"
          },
          {
            code: "SD",
            name: "South Dakota"
          },
          {
            code: "TN",
            name: "Tennessee"
          },
          {
            code: "TX",
            name: "Texas"
          },
          {
            code: "VI",
            name: "U.S. Virgin Islands"
          },
          {
            code: "UT",
            name: "Utah"
          },
          {
            code: "VT",
            name: "Vermont"
          },
          {
            code: "VA",
            name: "Virginia"
          },
          {
            code: "WA",
            name: "Washington"
          },
          {
            code: "WV",
            name: "West Virginia"
          },
          {
            code: "WI",
            name: "Wisconsin"
          },
          {
            code: "WY",
            name: "Wyoming"
          }
        ]
      },
    ]
  };
    constructor(
      private domSanitizer: DomSanitizer,
      private formBuilder: FormBuilder,
      private httpService:ScHttpService,
      private toastr:ToastrService,
      private modelService:NgbModal
    ){}
    ngOnInit(): void {
      this.userLoggedInId=JSON.parse(localStorage.getItem('_adminentity')).uuid;
 
          this.adminProfileForm=this.formBuilder.group({
            emailNotification:[''],
            firstName:['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
            secondName:['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
            phoneCountryCode:['',[Validators.required]],
            phoneNumber:['',[Validators.required,Validators.maxLength(10),Validators.minLength(10),Validators.pattern(/^[0-9]/)]],
            phoneExt:['',[Validators.pattern(/^[0-9]*$/)]],
            username:['',[Validators.required,Validators.pattern(/^[a-zA-Z0-9]*$/)]],
            // fax:['',[Validators.maxLength(20), Validators.pattern(/^[0-9]*$/)]],
            email:['',[Validators.required]],
            
          
          })
          this.getUserDetails()
    }
    getUserDetails(){
      this.openModel()
      let url=ServerURLS.getDetailsOfUserInAdminSetting.action+'?userId='+this.userLoggedInId;
      let promise=this.httpService.getHttpService(url,'');
      promise.then((res:any)=>{
        this.adminProfileForm.patchValue({
          firstName:res.firstName?res.firstName:'',
          secondName:res.lastName?res.lastName:'',
          phoneCountryCode:res.phnContryCode?res.phnContryCode:'',
          phoneNumber:res.phoneNumber?res.phoneNumber:'',
          phoneExt:res.phone_number_ext?res.phone_number_ext:'',
          username:res.userName?res.userName:'',
          emailNotification:res.emailNotification?res.emailNotification:false,
          email:res.emailAddress?res.emailAddress:''
        })
        this.uploadImageShow=res.image?res.image:''
        this.closeModel()
      })
      .catch((err:any)=>{
        this.closeModel();
        this.toastr.error("Something Went Wrong!",'',{timeOut:2000})
      })
    }
    uploadImage(event): void {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2 && event.target.files[0]) {
          this.uploadImageShow = this.domSanitizer.bypassSecurityTrustUrl(reader.result as string)
          this.imageData = event.target.files[0];
        }
      }
      if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  
    removeImage(): void {
      this.uploadImageShow = ''
      this.imageData = null;
    }
    toggleShow(value){
      if(value==='con'){
        this.showConfirmPassword=!this.showConfirmPassword
      }else if(value==='pass'){
      this.showPassword=!this.showPassword;
  
      }
    }
    passwordError:string;
    confirmPasswordError:string;
    passwordValue:string='';
    confirmPasswordValue:string='';
    bothPasswordShouldBeSameError:string;
    checkPasswordErros(value,type ){
      
       
      if(type==='first'){
        this.bothPasswordShouldBeSameError=undefined
        this.passwordValue=value;
       if(!value .match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/) && type==='first' && value!=''){
          this.passwordError='Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character*'
       }else if(value==='' && type==='first'){
        this.passwordError=undefined;
       }else{
        this.passwordError=undefined
       }
      }


       if(type==='second'){
        this.bothPasswordShouldBeSameError=undefined
        this.confirmPasswordValue=value
        if(!value .match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/) && type==='second' && value!=''){
          this.confirmPasswordError='Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character*'
       }else if(value==='' && type==='second'){
        this.confirmPasswordError=undefined;
       }else{
        this.confirmPasswordError=undefined
       }
       }
       if(this.passwordValue!=='' && this.confirmPasswordValue!=='' && this.passwordValue!= null && this.confirmPasswordValue!=null){
        if(this.passwordValue!==this.confirmPasswordValue){
          this.bothPasswordShouldBeSameError="Password and Confirm password should be same"
        }else{
          this.bothPasswordShouldBeSameError=undefined
        }
       }else{
        this.bothPasswordShouldBeSameError=undefined
       }
    }
    saveData(){
 
      if(this.adminProfileForm.invalid){
 
        this.adminProfileForm.markAllAsTouched();
        return;
      }else if((this.passwordValue!=='' && this.confirmPasswordValue==='') || (this.passwordValue=='' && this.confirmPasswordValue!=='')){
        this.bothPasswordShouldBeSameError   ='Both value are required together'
        return ;
      }
      else if( this.passwordError!==undefined  || this.confirmPasswordError!==undefined  || this.bothPasswordShouldBeSameError!==undefined ){
        this.toastr.error('Please Enter the valid value');
        return;
      }
      else{
        this.openModel();
        let generaDetailsData = {
          'user_id': this.userLoggedInId,
          'user_name': this.adminProfileForm.get('username').value ? this.adminProfileForm.get('username').value : '',
          'user_code': null,
          'first_name': this.adminProfileForm.get('firstName').value ? this.adminProfileForm.get('firstName').value : "",
          'last_name': this.adminProfileForm.get('secondName').value ? this.adminProfileForm.get('secondName').value : "",
          // "password":  "",
          // 'confirm_password':  "",
          'fax': null,
          'email_address': this.adminProfileForm.get('email').value ? this.adminProfileForm.get('email').value : '',
          'phone_number': this.adminProfileForm.get('phoneNumber').value ? this.adminProfileForm.get('phoneNumber').value : '',
          'phone_number_ext': this.adminProfileForm.get('phoneExt').value ? this.adminProfileForm.get('phoneExt').value : '',
          'country_code': this.adminProfileForm.get('phoneCountryCode').value ? this.adminProfileForm.get('phoneCountryCode').value : '',
          // 'status': true,
          'user_permission': {
            'user_permission_id': '',
            'user_id': this.userLoggedInId,
            // "user_role": this.adminProfileForm.get('userrole').value ? this.adminProfileForm.get('userrole').value : '',
            'enabled': false,
            'is_customer_admin': false,
            'is_user_management': false,
            'view_invoice': false,
            'view_rate': false,
            'view_address_book': false,
            'is_vendor': false,
            "mail_notification_enabled": this.adminProfileForm.get('emailNotification').value ? this.adminProfileForm.get('emailNotification').value : false
          }
           
        }
         
        if(this.uploadImageShow==''){
          generaDetailsData['is_image_removed']=true
        }
        const formData:FormData = new FormData();
        formData.append('userDetails',JSON.stringify(generaDetailsData));
        if(this.imageData){
          formData.append('userImage',this.imageData)
        }
        let url=ServerURLS.updateUserInAdminSetting.action;
        let promise=this.httpService.putFileHttpService(url,formData);
        promise.then((res:any)=>{
            this.closeModel();
            this.toastr.success("Data updated successfully",'',{timeOut:2000})
            this.getUserDetails();
        })
        .catch((err:any)=>{
          this.closeModel();
          this.toastr.error("Something Went Wrong!",'',{timeOut:2000})
        })
        if((this.passwordValue===this.confirmPasswordValue) && this.passwordValue!='' && this.confirmPasswordValue!='' && (this.passwordError===undefined && this.confirmPasswordError===undefined && this.bothPasswordShouldBeSameError===undefined)){
          this.openModel();
        
          let resetObj={
            'userId':this.userLoggedInId,
            'newPassword':this.passwordValue
          }
          let url2=ServerURLS.resetUserPassword.action;
          let promise2=this.httpService.postHttpService(url2,resetObj);
          promise2.then((res:any) =>{
            this.closeModel();
            this.passwordValue='';
            this.confirmPasswordValue=''
            this.toastr.success("Password Updated Successfully!",'',{timeOut:3000})
          })
          .catch((err:any)=>{
            this.closeModel();
            this.toastr.error("Password Updation Failed!")
          })
        }else{
 
        }
       
      }
    }
    openModel() {
      this.modelService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
    }
    closeModel() {
      this.modelService.dismissAll();
    }
}
