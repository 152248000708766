import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerListComponent } from "./customer-list/customer-list.component";
import { Routes, RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OfferModule } from "../offer/offer.module";
import { AddUserComponent } from "./add-user/add-user.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SpinnerModule } from "src/app/spinner/spinner.module";
import { ChildCustomerDetailsComponent } from "./child-customer-details/child-customer-details.component";

// import { CustomerDetailModule } from './customer-details/customer-detail.module';
// import { GeneralDetailComponent } from './customer-details/general-detail/general-detail.component';

const routes: Routes = [
  { path: "", redirectTo: "shipcarte/signin", pathMatch: "full" },
  {
    path: "shipcarte/customer/list",
    component: CustomerListComponent,
  },

  {
    path: "shipcarte/customer/list/filter/:fromDate/:toDate",
    component: CustomerListComponent,
  },
  {
    path: "shipcarte/customer/list/generalDetail",
    loadChildren: () =>
      import("./customer-details/customer-detail.module").then(
        (m) => m.CustomerDetailModule
      ),
  },
  // {path:'shipcarte/customer/list/generalDetail',component:GeneralDetailComponent},
  // {path:'shipcarte/customer/list/generalDetail/:id',component:GeneralDetailComponent}
  { path: "shipcarte/customer/list/adduser", component: AddUserComponent },
  { path: "shipcarte/customer/list/edituser/:id", component: AddUserComponent },

  {
    path: "shipcarte/customer/list/generalDetail/edituser/orders/child-customer-details",
    component: ChildCustomerDetailsComponent,
  },
  {
    path: "shipcarte/customer/list/generalDetail/edituser/orders/:orderId/child-customer-details",
    component: ChildCustomerDetailsComponent,
  },
];
@NgModule({
  declarations: [
    CustomerListComponent,
    AddUserComponent,
    ChildCustomerDetailsComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    GooglePlaceModule,
    OfferModule,
    NgSelectModule,
    SpinnerModule,
    // CustomerDetailModule
  ],
})
export class CustomerModule {}
