import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dimensional-weight-calculator',
  templateUrl: './dimensional-weight-calculator.component.html',
  styleUrls: ['./dimensional-weight-calculator.component.scss']
})


export class DimensionalWeightCalculatorComponent implements OnInit {
  dimentionForm: FormGroup;
  weightInLBS: any;
  incr:number=0;
  dicr:number=0;
  matrixUnit:boolean=true;
  imperialUnit:boolean;
  domestic:boolean=true;
  international:boolean;
  count: number = 0;
  countWidth:number=0;
  countHeight:number=0;
  countLength:number=0;
  
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
 
    this.domestic=true;
    this.imperialUnit=true;

    this.dimentionForm = this.formBuilder.group({
      'Length': new FormControl('', [
        Validators.required
      ]),
      'Width': new FormControl('', [
        Validators.required
      ]),
      'Height': new FormControl('', [
        Validators.required
      ])
    })
    this.dimentionForm.get('Length').valueChanges.subscribe(value => {
      this.countLength = value;
    });
    this.dimentionForm.get('Width').valueChanges.subscribe(value => {
      this.countWidth = value;
    });
    this.dimentionForm.get('Height').valueChanges.subscribe(value => {
      this.countHeight = value;
    });
  }
  closeModal() { this.activeModal.close(); }

  submit() {

    let Length = parseFloat(this.dimentionForm.controls['Length'].value);
    let Width = parseFloat(this.dimentionForm.controls['Width'].value);
    let Height = parseFloat(this.dimentionForm.controls['Height'].value);

    var volume
    volume = Length * Width * Height;

    if(this.imperialUnit){
      this.weightInLBS = volume / 139;
    }else{
      this.weightInLBS = volume / 5000;
    }
    
  }
  resetFields(){
    this.weightInLBS='';
    this.dimentionForm.reset();
  }
  
  getValue(checked: boolean, value: string){
    if(checked==true && value ==='Metric'){
     this.imperialUnit=false;
     this.matrixUnit=true;
  
    }else if(checked==true && value ==='Imperial'){
     this.matrixUnit=false;
    this.imperialUnit=true;
    }
 
    if(checked==true && value ==='Domestic'){
     this.international=false
     this.domestic=true
    }else if(checked==true && value ==='International'){
     this.international=true; 
     this.domestic=false;
    }



    if (this.matrixUnit && value === 'Metric') {
      const factorInchToCm = 2.54;
      const factorLbsToKg = 0.45359237;
      this.weightInLBS='';
  
      if (this.dimentionForm.controls['Length'].value) {
        const lengthInches = parseInt(this.dimentionForm.controls['Length'].value);
        this.dimentionForm.patchValue({
          Length: (lengthInches * factorInchToCm).toFixed(2)
        });
      }
  
      if (this.dimentionForm.controls['Width'].value) {
        const widthInches = parseInt(this.dimentionForm.controls['Width'].value);
        this.dimentionForm.patchValue({
          Width: (widthInches * factorInchToCm).toFixed(2)
        });
      }
  
      if (this.dimentionForm.controls['Height'].value) {
        const heightInches = parseInt(this.dimentionForm.controls['Height'].value);
        this.dimentionForm.patchValue({
          Height: (heightInches * factorInchToCm).toFixed(2)
        });
      }
  
      if (this.weightInLBS) {
        this.weightInLBS = (this.weightInLBS * factorLbsToKg).toFixed(2);
      }
    }

    if (this.imperialUnit && value === 'Imperial') {
      const factorCmToInch = 0.393701;
      const factorKgToLbs = 2.20462;
      this.weightInLBS='';
  
      if (this.dimentionForm.controls['Length'].value) {
        const lengthCm = parseFloat(this.dimentionForm.controls['Length'].value);
        this.dimentionForm.patchValue({
          Length: (lengthCm * factorCmToInch).toFixed(2)
        });
      }
  
      if (this.dimentionForm.controls['Width'].value) {
        const widthCm = parseFloat(this.dimentionForm.controls['Width'].value);
        this.dimentionForm.patchValue({
          Width: (widthCm * factorCmToInch).toFixed(2)
        });
      }
  
      if (this.dimentionForm.controls['Height'].value) {
        const heightCm = parseFloat(this.dimentionForm.controls['Height'].value);
        this.dimentionForm.patchValue({
          Height: (heightCm * factorCmToInch).toFixed(2)
        });
      }
  
      if (this.weightInLBS) {
        this.weightInLBS = (this.weightInLBS * factorKgToLbs).toFixed(2);
      }
      // console.log(this.weightInLBS,'dsdg');
    }
    
    
 }

 

 incrementWidth() {
  this.countWidth++;
  this.dimentionForm.patchValue({
    Width:this.countWidth
  })
}

decrementWidth() {
  if (this.countWidth > 0) {
    this.countWidth--;
    this.dimentionForm.patchValue({
      Width:this.countWidth
    })
  }
}

incrementHeight() {
  this.countHeight++;
  this.dimentionForm.patchValue({
    Height:this.countHeight
  })
}

decrementHeight() {
  if (this.countHeight > 0) {
    this.countHeight--;
    this.dimentionForm.patchValue({
      Height:this.countHeight
    })
  }
}

incrementlength() {
  this.countLength++;
  this.dimentionForm.patchValue({
    Length:this.countLength
  })
}

decrementlength() {
  if (this.countLength > 0) {
    this.countLength--;
    this.dimentionForm.patchValue({
      Length:this.countLength
    })
  }
}

} 
