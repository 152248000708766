import { ENAMETOOLONG } from "constants";

export const environment = {
  production: true,
  version: "AppVersion v-0.3",
};

export class EnvironmentConstants {
  public static DOMAINPATH = "https://stage-api.shipcarte.com/shipcarte";
  public static NO_PROFILE_IMAGE = 'assets/img/shipcarte/assets/profiles/default-user.jpg';
  public static IMAGE_LOADER = 'assets/img/shipcarte/commons/image-loader.gif';
  public static US_NET30_PAYMENT_WS = 'http://localhost:9098/payment-module/net30/receive/usd/ack';
  public static CA_NET30_PAYMENT_WS = 'http://localhost:9098/payment-module/net30/receive/cad/ack';
  public static GOOGLE_CLIENT_ID = '100154224989-s04fma668s316kfl3aolelev2v1l8t5e.apps.googleusercontent.com';
  // public static GooglePlacesApiKey = 'AIzaSyCX954-_oa1DJ-W9W0tI9kmcZKwFraRdn8';
  public static GooglePlacesApiKey = "AIzaSyB6UrbHNiACEp5nHr9sRGlb8CBCN152t-0";
}

export class StorageKeys {
  public static LOGGED_USER = "SHIPLOGGEDINUSERID";
  public static LOGGEDIN_ENTITY_TYPE = "SHIPLOGGEDINUSERTYPE";
}

export class ServerURLS {
  // Authorization
  public static login = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/autz",
    method: "POST",
    storageKey: "auth",
  };
  public static logout = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/session/logout",
    method: "POST",
    storageKey: "unauth",
  };
  public static autzActor = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/generate/autz/code",
    method: "POST",
    storageKey: "actorautz",
  };
  public static verfiyActor = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/user/ws/verify/autz/code/for/contact",
    method: "POST",
    storageKey: "Verfiyactor",
  };
  public static verfiyEmailAutzcode = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/user/ws/verify/email/autz/code",
    method: "POST",
    storageKey: "verfiyEmailAutzcode",
  };
  public static getCountryList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/country",
    method: "GET",
    storageKey: "getCountryList",
  };

  // Customer
  public static registerCustomer = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/cust/ws/save",
    method: "POST",
    storageKey: "custregister",
  };
  public static getCustomerList = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/cust/ws/get/all",
    method: "GET",
    storageKey: "customerList",
  };
  public static getCustomerListAdmin = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/getCustomerList",
    method: "GET",
    storageKey: "customerList",
  };
  public static getCreateCustomerForm = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/getCreateCustomerForm",
    method: "GET",
    storageKey: "createCustomerForm",
  };
  public static saveCustApproval = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/customer/approval/ws/save",
    method: "POST",
    storageKey: "saveCustApproval",
  };
  public static deleteCustomerServicePackageAccesorial = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/additionalfeature/disable",
    method: "DELETE",
    storageKey: "deleteCustomerServicePackageAccesorial",
  };
  public static getAccessorialType = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/loc/shpmt/services/get/all",
    method: "GET",
    storageKey: "accessorialType",
  };
  public static getAllServiceMapPackageType = {
    action: EnvironmentConstants.DOMAINPATH + "/api/getMapping/shipmentPackage",
    method: "GET",
    storageKey: "serviceMapPackageType",
  };
  public static getCustomerAccountInfo = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/customer/account/info",
    method: "GET",
    storageKey: "getCustomerAccountInfo",
  };
  public static postCustomerAccountInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/customer/account/info/save",
    method: "POST",
    storageKey: "postCustomerAccountInfo",
  };
  public static updateCustomerAccountInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/customer/account/info/updateById",
    method: "PUT",
    storageKey: "postCustomerAccountInfo",
  };
  public static getAddressBookData = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/getAll",
    method: "GET",
    storageKey: "getAddressBookData",
  };
  public static createUpdateCustomer = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/createUpdateCustomer",
    method: "POST",
    storageKey: "createUpdateCustomer",
  };
  public static getCustomerById = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/getCustomerDetailsById",
    method: "GET",
    storageKey: "getCustomerById",
  };
  public static changeCustomerStatus = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/updateCustomerStatus",
    method: "DELETE",
    storageKey: "changeCustomerStatus",
  };
  public static removeCustomerContactInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/removeCustomerContactInfo",
    method: "DELETE",
    storageKey: "removeCustomerContactInfo",
  };
  public static removeCustomerBillingInfo = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/removeBillingLocation",
    method: "DELETE",
    storageKey: "removeCustomerBillingInfo",
  };
  public static updateCustomerBillingStatus = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/updateBillingLocationStatus",
    method: "DELETE",
    storageKey: "updateCustomerBillingStatus",
  };
  public static updateCustomerContactStatus = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/updateCustomerContactInfoStatus",
    method: "DELETE",
    storageKey: "updateCustomerContactStatus",
  };
  public static getCustomerCarrieronLoad = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/loadCustomerCarrierForm",
    method: "GET",
    storageKey: "customerCarrieronLoad",
  };
  public static getCustomerCarrierById = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/getCustCarrierDetailsById",
    method: "GET",
    storageKey: "customerCarrierById",
  };
  public static saveUpdateCustomerCarrier = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/saveUpdateCustomerCarrier",
    method: "POST",
    storageKey: "saveUpdateCustomerCarrier",
  };
  public static saveUpdateCustomerCustom = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/admin/customer/custom_broker/save",
    method: "POST",
    storageKey: "saveUpdateCustomerCustom",
  };
  public static getAllCustomerBrokers = {
    action: EnvironmentConstants.DOMAINPATH + "/api/customs/getAllBrokerInfo",
    method: "GET",
    storageKey: "allCustomerBrokers",
  };
  public static getCustomById = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/admin/customer/custom_broker/get/all",
    method: "GET",
    storageKey: "getCustomById",
  };
  public static getCustomerUsers = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/customer/user/get/all",
    method: "GET",
    storageKey: "getCustomerUsers",
  };
  public static changeStatusCustomerUser = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/customer/user/enable",
    method: "DELETE",
    storageKey: "changeStatusCustomerUser",
  };
  public static getCustomerUserById = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/admin/customer/user/getDetailsById",
    method: "GET",
    storageKey: "getCustomerUserById",
  };
  public static saveCustomerUser = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/customer/user/save",
    method: "POST",
    storageKey: "saveCustomerUser",
  };
  public static updateCustomerUser = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/customer/user/update",
    method: "PUT",
    storageKey: "updateCustomerUser",
  };
  public static getCustomerOrder = {
    action: EnvironmentConstants.DOMAINPATH + "/api/order/getAll",
    method: "GET",
    storageKey: "getCustomerOrder",
  };
  public static getCustomerOrderPriceComponent = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/priceComponent/get",
    method: "GET",
    storageKey: "getCustomerOrderPriceComponent",
  };
  public static getCustomerOrderPriceComponentmultiProBill = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/allPriceComponents",
    method: "GET",
    storageKey: "getCustomerOrderPriceComponentmultiProbill",
  };
  public static postCustomerOrderPriceComponentmultiProBill = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/orderDetails/manualOrderSavePayment",
    method: "POST",
    storageKey: "postCustomerOrderPriceComponentmultiProBill",
  };
  public static traceOrder = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/trace/order",
    method: "GET",
    storageKey: "traceOrder",
  };
  public static getCustomerAvailableTime = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/admin/customer/availabilityTime/get",
    method: "GET",
    storageKey: "getCustomerAvailableTime",
  };
  public static postCustomerAvailableTime = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/admin/customer/availabilityTime/save",
    method: "POST",
    storageKey: "postCustomerAvailableTime",
  };
  public static getCustomerCreditData = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/customer/credit/getDetailsById",
    method: "GET",
    storageKey: "getCustomerCreditData",
  };
  public static saveCustomerCreditData = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/customer/credit/save",
    method: "POST",
    storageKey: "saveCustomerCreditData",
  };
  public static updateCustomerCreditData = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/customer/credit/update",
    method: "PUT",
    storageKey: "updateCustomerCreditData",
  };
  public static fetchPackageManagerData = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/customPackage/getPackageList",
    method: "GET",
    storageKey: "fetchPackageManagerData",
  };
  public static createCustomPackage = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/customPackage/createPackage",
    method: "POST",
    storageKey: "createCustomPackage",
  };
  public static updateCustomPackage = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/customPackage/updatePackage",
    method: "PUT",
    storageKey: "updateCustomPackage",
  };
  public static deleteCustomPackage = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/customPackage/deletePackage",
    method: "DELETE",
    storageKey: "deleteCustomPackage",
  };
  public static resetUserPassword = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/admin/customer/user/resetPassword",
    method: "POST",
    storageKey: "resetUserPassword",
  };
  public static getGoogleTimeByPlaceId = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/google/details",
    method: "POST",
    storageKey: "getGoogleTimeByPlaceId",
  };
  public static getdefaultShipper = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/ws/addr/get/inActive/shipperConsignee",
    method: "GET",
    storageKey: "getdefaultShipper",
  };
  public static removeShipperConsignee = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/default/addr/ws/remove",
    method: "PUT",
    storageKey: "removeShipperConsignee",
  };

  //new api of carrier tab in customer
  public static getCarrierGetailById = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/getCustInsurerDetailsById?customerId=",
    methodd: "GET",
    storagekey: "getCarrierGetailById",
  };
  public static loadCarrierListForCustomer = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/loadCustomerInsurerForm",
    method: "GET",
    storageKey: "loadCarreierListForCustomer",
  };
  public static saveCarrierInsurance = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/saveCustomerInsurer",
    method: "POST",
    storageKey: "saveCarrierInsurance",
  };

  //Partner
  public static registerPartner = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/partner/ws/save",
    method: "POST",
    storageKey: "partnrregister",
  };
  public static getPartnerList = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/partner/ws/get/all",
    method: "GET",
    storageKey: "partnerList",
  };
  public static getPartList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/partner/getPartnerList",
    method: "GET",
    storageKey: "partList",
  };
  public static getRegistrationData = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/partner/registration/",
    method: "GET",
    storageKey: "RegistrationData",
  };
  public static postRegistrationData = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/partner/registration/save",
    method: "POST",
    storageKey: "postregistrationdata",
  };
  public static updateRegistrationData = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/partner/registration/update",
    method: "POST",
    storageKey: "updateregistrationdata",
  };
  public static getPartnerInsurance = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/partner/insurance",
    method: "GET",
    storageKey: "getPartnerInsurance",
  };
  public static postPartnerInsurance = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/partner/insurance/save",
    method: "POST",
    storageKey: "postPartnerInsurance",
  };
  public static updatePartnerInsurance = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/partner/insurance/update",
    method: "PUT",
    storageKey: "updatePartnerInsurance",
  };
  public static getPartnerAccount = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/partner/account",
    method: "GET",
    StorageKey: "accountGet",
  };
  public static updatePartnerAccount = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/partner/account/update",
    method: "PUT",
    StorageKey: "accountGet",
  };
  public static postPartnerAccount = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/partner/account/save",
    method: "POST",
    StorageKey: "accountGet",
  };
  public static changePartnerStatus = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/partner/updatePartnerStatus",
    method: "DELETE",
    StorageKey: "changePartnerStatus",
  };
  public static getPartnerGeneralDetails = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/partner/getPartnerGeneralDetailsById?partnerId=",
    method: "GET",
    storageKey: "generalDetailsPartner",
  };
  public static createUpdatePartner = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/partner/createUpdatePartnerGeneralDetails",
    method: "POST",
    storageKey: "createUpdatePartner",
  };
  public static removePartnerContact = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/partner/removeContactInfo?contactMapId=",
    method: "DELETE",
    StorageKey: "removePartnerContact",
  };
  public static getOrderHistoryOfPatner = {
    action: EnvironmentConstants.DOMAINPATH + "/api/order/getAll/",
    method: "GET",
    StorageKey: "orderHistory",
  };
  public static invocePrtner = {
    action: EnvironmentConstants.DOMAINPATH + "/api/invoices/getAll/",
    method: "GET",
    StorageKey: "invocePrtner",
  };

  //Manual-Order
  public static getDetailsOfChildOrders = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/getChildOrderDetailsByParentOrderId",
    method: "GET",
    storageKey: "getDetailsOfChildOrders",
  };
  public static changeStatuOfOrder = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/ws/order/updateShipmentStatus",
    method: "PUT",
    storageKey: "changeStatuOfOrder",
  };
  public static getManualOrder = {
    action: EnvironmentConstants.DOMAINPATH + "/api/order/getAll/",
    method: "GET",
    StorageKeys: "manualOrder",
  };
  public static addFevOrder = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/order/updateFavouriteStatus",
    method: "PUT",
    StorageKeys: "addFevOrder",
  };

  public static getManualOrderProbill = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/probill/getProbillDetailsById",
    method: "GET",
    StorageKeys: "manualOrder",
  };
  public static getManualOrderRate = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/manualOrder/getRateList",
    method: "GET",
    StorageKeys: "manualOrder",
  };

  public static generateProbill = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/generateProbill",
    method: "GET",
    StorageKeys: "manualOrder",
  };
  public static orderDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/getOrderMetaDataById",
    method: "GET",
    StorageKeys: "orderDetails",
  };
  public static orderDetailsAddress = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/quote/getQuoteAddressDetailsById",
    method: "GET",
    StorageKeys: "getQuoteAddressDetailsById",
  };
  public static estimatedDeleveryDate = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/updateEstimateDeliveryDate/order",
    method: "PUT",
    StorageKeys: "estimatedDeleveryDate",
  };
  public static shipmentItemData = {
    action: EnvironmentConstants.DOMAINPATH + "/api/item/getDetailsByQuoteId",
    method: "GET",
    StorageKeys: "shipmentItemData",
  };
  public static ratePopulatedData = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/shpmt/carrier/get/all",
    method: "GET",
    StorageKeys: "ratePopulatedData",
  };
  public static getAllFormDataCarrierContract = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/shpmt/carrier/get/all",
    method: "GET",
    StorageKeys: "getAllFormDataCarrierContract",
  };
  public static getOrderCardDetailsById = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/getOrderMetaDataById",
    method: "GET",
    StorageKeys: "getOrderCardDetailsById",
  };
  public static getTrackingData = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/trace/order",
    method: "GET",
    StorageKeys: "getTrackingData",
  };
  public static cancelOrderFromList = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/ws/order/cancel",
    method: "POST",
    StorageKeys: "cancelOrderFromList",
  };
  // public static priceSummeryData={action:EnvironmentConstants.DOMAINPATH+'/api/quote/priceComponent/get',method:'GET',StorageKeys:'priceSummeryDatat'}
  public static getBolData = {
    action: EnvironmentConstants.DOMAINPATH + "/api/bol/getData",
    method: "GET",
    StorageKeys: "getBolData",
  };
  public static commercialInvoiceSavePDF = {
    action: EnvironmentConstants.DOMAINPATH + "/api/commercialInvoice/savePDF",
    method: "PUT",
    StorageKeys: "commercialInvoiceSavePDF",
  };
  public static carrierContractSavePDF = {
    action: EnvironmentConstants.DOMAINPATH + "/api/carrierContract/savePDF",
    method: "PUT",
    StorageKeys: "carrierContractSavePDF",
  };
  public static sendCarrierContractEmail = {
    action: EnvironmentConstants.DOMAINPATH + "/api/carrierContract/sendEmail",
    method: "PUT",
    StorageKeys: "sendCarrierContractEmail",
  };
  public static generateBOLNumber = {
    action: EnvironmentConstants.DOMAINPATH + "/api/bol/generateBOLNumber",
    method: "GET",
    StorageKeys: "generateBOLNumber",
  };
  public static sendBolEmail = {
    action: EnvironmentConstants.DOMAINPATH + "/api/bol/sendEmail",
    method: "POST",
    StorageKeys: "sendBolEmail",
  };
  public static sendInsuranceEmail = {
    action: EnvironmentConstants.DOMAINPATH + "/api/insurance/sendEmail",
    method: "POST",
    StorageKeys: "sendInsuranceEmail",
  };
  public static sendOrderInvoiceEmail = {
    action: EnvironmentConstants.DOMAINPATH + "/api/OrderInvoice/sendEmail",
    method: "POST",
    StorageKeys: "sendOrderInvoiceEmail",
  };
  public static saveBolPdf = {
    action: EnvironmentConstants.DOMAINPATH + "/api/bol/savePDF",
    method: "POST",
    StorageKeys: "saveBolPdf",
  };
  public static sendCommercialInvoiceEmail = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/commercialInvoice/sendEmail",
    method: "POST",
    StorageKeys: "sendCommercialInvoiceEmail",
  };
  public static getInvoiceDetailsById = {
    action: EnvironmentConstants.DOMAINPATH + "/api/invoices/getById",
    method: "GET",
    StorageKeys: "getInvoiceDetailsById",
  };
  public static getOrderLabel = {
    action: EnvironmentConstants.DOMAINPATH + "/api/order/getLabel",
    method: "GET",
    StorageKeys: "getOrderLabel",
  };
  public static CarrirData = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/getDetailsById",
    method: "GET",
    StorageKeys: "CarrirData",
  };
  public static updateEstimatedDelDate = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/order/updateEstimateDeliveryDate",
    method: "GET",
    StorageKeys: "updateEstimatedDelDate",
  };
  public static getQuoteAddressDetails = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/quote/getQuoteAddressDetailsById",
    method: "GET",
    StorageKeys: "getQuoteAddressDetails",
  };
  public static getAddressBookById = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/getById",
    method: "GET",
    StorageKeys: "getAddressBookById",
  };
  public static saveManualOrderDetails = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/orderDetails/saveUpdate",
    method: "POST",
    StorageKeys: "saveManualOrderDetails",
  };
  public static getCommercialInvoiceData = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/invoices/commercial/getById",
    method: "GET",
    StorageKeys: "getCommercialInvoiceData",
  };
  public static getTaxPercentage = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/province/tax/get",
    method: "POST",
    StorageKeys: "getTaxPercentage",
  };
  public static placeManaulOrder = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/placeManualOrder",
    method: "POST",
    StorageKeys: "placeManaulOrder",
  };
  public static getTaxList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/getTaxList",
    method: "GET",
  };
  public static getPickUpList = {
    action: EnvironmentConstants.DOMAINPATH + "/pickup/delivery/info",
    method: "GET",
  };
  public static getContractInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/carrier/contract/getContractInfo",
    method: "GET",
  };
  public static getContractInfoSummery = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/carrier/contract/getContractInfoSummery",
    method: "GET",
  };
  public static saveCarrierMultiContractInfo = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/multiContractInfo/save",
    method: "POST",
    storageKey: "carrierContractInfo",
  };
  //Edit Manual Order
  public static fetchBookedManualOrderDetail = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/manualOrder/getOrderDetailsById",
    method: "GET",
    StorageKeys: "fetchBookedManualOrderDetail",
  };

  //Get quote
  public static getExtraservicesData = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/extra/services/get/all",
    method: "GET",
    StorageKeys: "getExtraservicesData",
  };
  public static getcurrencyConversionRate = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/currencyConversionRate/get?from=USD&to=CAD",
    method: "GET",
    StorageKeys: "getcurrencyConversionRate",
  };
  public static submitInsaurance = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/addInsurance",
    method: "PUT",
    StorageKeys: "submitInsaurance",
  };
  public static getAllFailedCarriers = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/getAllFailedCarriers",
    method: "GET",
    StorageKeys: "getAllFailedCarriers",
  };
  public static createShipment = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/shpmt/ws/update",
    method: "PUT",
    StorageKeys: "createShipment",
  };
  public static getStartSummary = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/cost/breakup/ws/get/by/carrier",
    method: "GET",
    StorageKeys: "getStartSummary",
  };
  public static getCarrierListing = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/shpmt/carrier/get/all",
    method: "GET",
    StorageKeys: "getCarrierListing",
  };
  public static getAddressSummary = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/addr/detail/ws/get",
    method: "GET",
    StorageKeys: "getAddressSummary",
  };
  public static quoteDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/get",
    method: "GET",
    StorageKeys: "quoteDetails",
  };
  public static summaryColabesDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/item/ws/get/all/0/20",
    method: "GET",
    StorageKeys: "summaryColabesDetails",
  };
  public static getShippingHours = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/pickup/time/ws/get/all",
    method: "GET",
    storageKey: "getShippingHours",
  };
  public static getAllBrokers = {
    action: EnvironmentConstants.DOMAINPATH + "/api/customs/getAllBrokerInfo",
    method: "GET",
    storageKey: "getAllBrokers",
  };
  public static addContact = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/contact/add",
    method: "POST",
    storageKey: "addContact",
  };
  public static getAllDropdownValues = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/customs/getAllDropdownValues",
    method: "GET",
    storageKey: "getAllDropdownValues",
  };
  public static getPriceData = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/priceComponent/get",
    method: "GET",
    StorageKeys: "getPriceData",
  };
  public static saveCustoms = {
    action: EnvironmentConstants.DOMAINPATH + "/api/customs/saveData",
    method: "POST",
    storageKey: "saveCustoms",
  };
  public static saveDataToAddressBook = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/saveByQuoteId",
    method: "POST",
    storageKey: "saveDataToAddressBook",
  };
  public static hsTariffdescription = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/customs/hsTariff/description/update",
    method: "PUT",
    storageKey: "hsTariffdescription",
  };
  public static saveBroker = {
    action: EnvironmentConstants.DOMAINPATH + "/api/customs/saveBrokerInfo",
    method: "POST",
    storageKey: "saveBroker",
  };
  public static searchByKeyWord = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/search?key",
    method: "GET",
    StorageKeys: "searchByKeyWord",
  };
  public static doPaymentOfGetQuote = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/payment/creditcard/charge",
    method: "POST",
    storageKey: "doPaymentOfGetQuote",
  };

  // Quick Action
  public static ExtCommercialInvoiceList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/invoices/ext_ci/getAll",
    method: "GET",
    StorageKeys: "ExtCommercialInvoiceList",
  };
  public static ExtCommercialInvoiceView = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/invoices/commercial/getById",
    method: "GET",
    StorageKeys: "ExtCommercialInvoiceView",
  };
  public static saveCommercialInvoice = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/invoices/commercial/save?actionType=",
    method: "POST",
    StorageKeys: "saveCommercialInvoice",
  };
  public static getComInvData = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/invoices/commercial/getById?extCommercialInvoiceId=",
    method: "GET",
    StorageKeys: "getComInvData",
  };
  public static getAddressBookget = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/getAll/",
    method: "GET",
    StorageKeys: "getAddressBookgetquote",
  };

  public static createMarshInsurance = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/insurance/marshInsuranceQuote",
    method: "POST",
    storageKey: "createMarshInsurance",
  };
  public static acceptInsurace = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/insurance/acceptQuote",
    method: "PUT",
    storageKey: "acceptInsurace",
  };
  public static removeInsurance = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/removeInsurance",
    method: "PUT",
    storageKey: "removeInsurance",
  };
  public static udpateCarrierMarshInsurace = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/insurance/updateInsuranceQuote",
    method: "POST",
    storageKey: "udpateCarrierMarshInsurace",
  };

  //user
  public static userCreation = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/save",
    method: "POST",
    storageKey: "userCreation",
  };
  public static geoLocationDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/google/pincode/ws",
    method: "GET",
    storageKey: "pincodeAddress",
  };
  public static geoDistanceDetails = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/google/distance/calculator/ws",
    method: "GET",
    storageKey: "calculatedistance",
  };
  public static getUserList = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/get/all",
    method: "GET",
    storageKey: "userList",
  };

  //Invoice
  public static getAllInvoiceList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/invoices/getAll",
    method: "GET",
    storageKey: "allInvoiceList",
  };
  public static getInvoiceDocument = {
    action: EnvironmentConstants.DOMAINPATH + "/api/invoices/getById",
    method: "GET",
    storageKey: "invoiceDoc",
  };
  public static getConsolidateInvoice = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/invoice/process/consolidate",
    method: "GET",
    StorageKey: "consolidatedInvoice",
  };
  public static uploadInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/invoice/upload",
    method: "POST",
    storageKey: "uploadInvoiceDoc",
  };

  //profile
  public static changePassword = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/autz/ws/info/change/password",
    method: "PUT",
    storageKey: "changePassword",
  };

  public static getCustomerDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/cust/ws/get",
    method: "GET",
    storageKey: "getCustomerDetails",
  };
  public static getPartnerDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/partner/ws/get",
    method: "GET",
    storageKey: "getPartnerDetails",
  };
  public static getUserDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/get",
    method: "GET",
    storageKey: "getUserDetails",
  };

  public static updateCustomer = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/cust/ws/update",
    method: "PUT",
    storageKey: "updateCustomer",
  };
  public static updatePartner = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/partner/ws/update",
    method: "PUT",
    storageKey: "updatePartner",
  };
  public static updateUser = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/update",
    method: "PUT",
    storageKey: "updateUser",
  };

  //Forgot Password
  public static passwordRequest = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/autz/ws/verify/reset/password/request",
    method: "POST",
    storageKey: "passwordrequest",
  };
  public static verifyAutzCode = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/verify/autz/code",
    method: "POST",
    storageKey: "verifyAutzCode",
  };
  public static resetForgotPassword = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/autz/ws/reset/forgot/password",
    method: "PUT",
    storageKey: "resetforgotpassword",
  };

  //Reset Password
  public static resetPassword = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/autz/ws/reset/password",
    method: "PUT",
    storageKey: "resetPassword",
  };

  //address
  public static saveAddress = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/addr/ws/save",
    method: "POST",
    storageKey: "saveAddress",
  };
  public static getAddressDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/addr/ws/get",
    method: "GET",
    storageKey: "getAddressDetails",
  };
  public static getAddressById = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/addr/detail/ws/get",
    method: "GET",
    storageKey: "getAddressById",
  };
  public static saveAddressBook = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/addr/book/ws/save",
    method: "POST",
    storageKey: "saveadressbook",
  };

  public static getAllAddressBook = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/addr/get/all",
    method: "GET",
    storageKey: "getAllAddress",
  };
  public static getAllConsigneeBook = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/consignee/get/all",
    method: "GET",
    storageKey: "getAllConsignee",
  };

  public static getAddressNames = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/address/book/ws/name",
    method: "GET",
    storageKey: "getAddressNames",
  };
  public static getInsuraceDoudment = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/insurance/getInsuranceDoc",
    method: "GET",
    StorageKeys: "getInsuraceDoudment",
  };

  //Invoice url
  public static getinauditAndInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/invoices/getAll/",
    method: "GET",
    storageKey: "Inauditandinvoice",
  };
  public static getContractProcessingContractList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/contractInfo/get/all/",
    method: "GET",
    storageKey: "ContractList",
  };
  public static getChargeItemListInInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/payment/charge",
    method: "GET",
    storageKey: "chargeitemlist",
  };
  public static getBillingInfoCarrierInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/billingInfo/get",
    method: "GET",
    storageKey: "gettBillingInfo",
  };
  public static getCarrierContractInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/carrier/contract/getPaymentInfo",
    method: "GET",
    storageKey: "getCarrierDetails",
  };
  public static getEquimentListInInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/getAll/equipments",
    method: "GET",
    storageKey: "Equiments",
  };
  public static getCarrierContractDetailsInInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/carrier/getCarrierDetails",
    method: "GET",
    storage: "carrierContarctDetails",
  };
  public static saveCarrierContractInfoInInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/contractInfo/save",
    method: "POST",
    storageKey: "carrierContractInfo",
  };
  public static savePaymentDetailsInInvoice = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/request/ws/updateRates",
    method: "POST",
    storageKey: "savePaymentDetails",
  };
  public static saveCarrierContractPaymentDetails = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/carrier/contract/payment/save",
    method: "POST",
    storageKey: "paymentDetails",
  };
  public static fetchIdOfCarrierInInvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/carrier/getDetails",
    method: "GET",
    storageKey: "carrierId",
  };
  public static carrierInvoiceReceiptSave = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/carrier/contract/invoice/save",
    method: "POST",
    storageKey: "carrierInvoiceReceiptSave",
  };
  public static getAllInvoices = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/invoices/getAllSubmittedInvoices",
    method: "GET",
    storageKeys: "getAllInvoices",
  };
  public static getDetailsOfConsolidateInvoices = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/invoices/getInvoiceDetailsByParentInvoiceId",
    storageKey: "getDetailsOfConsolidateInvoices",
  };
  public static changeStatusOfInvoiceByManaul = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/payment/creditcard/charge/manual",
    method: "POST",
    storageKey: "changeStatusOfInvoiceByManaul",
  };

  //sale reps url
  public static createSaleRepresentative = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/salesRep/save",
    method: "POST",
    storageKey: "createSaleRepresentative",
  };
  public static updateSaleRepresentative = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/salesRep/update",
    method: "PUT",
    storagekey: "updateSaleRepresentative",
  };
  public static getSaleRepresentativeList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/salesRep/getAll",
    method: "GET",
    storageKey: "getSaleRepresentativeList",
  };
  public static getReportDataOfSaleRepresentative = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/get/salesRepStatics?salesRepId",
    method: "GET",
    storageKey: "getReportDataOfSaleRepresentative",
  };
  public static activeAndInactiveSaleRepresentative = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/change/salesRep/status",
    method: "PUT",
    storageKey: "activeAndInactiveSaleRepresentative",
  };
  public static getSaleRepresentativeGeneralDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/get/salesRep/info",
    method: "GET",
    storageKey: "getSaleRepresentativeGeneralDetails",
  };
  public static getListOfAssociatedCustomerOfSalesRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/getAll/SalesRep/Customer/Info/",
    method: "GET",
    storageKey: "getListOfAssociatedCustomerOfSalesRep",
  };
  public static getListOfAssociatedOrderOfSalesRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/customerOrders/getAll",
    method: "GET",
    storageKey: "getListOfAssociatedOrderOfSalesRep",
  };
  public static getListOfInvoiceCreatedInReportTabSalesRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/INVOICE_CREATED",
    method: "GET",
    storageKey: "getListOfInvoiceCreatedInReportTabSalesRep",
  };
  public static getListOfQuoteGeneratedInReportTabSalesRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/QUOTES_GENERATED",
    method: "GET",
    storageKey: "getListOfQuoteGeneratedInReportTabSalesRep",
  };

  public static getDataOfOrderCreatedSalesRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/get/salesRepStatics/ORDERS_CREATED",
    method: "GET",
    storageKey: "getDataOfOrderCreatedSalesRep",
  };
  public static getDataofAbandanCartSalesRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/get/salesRepStatics/ABANDON_CARTS",
    method: "GET",
    storageKey: "getDataofAbandanCartSalesRep",
  };
  public static getDataofSalesInUSDSalRep = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/get/salesRepStatics/SALES_USD",
    method: "GET",
    storageKey: "getDataofSalesInUSDSalRep",
  };
  public static getDataofSalesInCADSalRep = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/get/salesRepStatics/SALES_CAD",
    method: "GET",
    storageKey: "getDataofSalesInCADSalRep",
  };
  public static getDataOfInvoiceCreatedSaleRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/get/salesRepStatics/INVOICE_CREATED",
    method: "GET",
    storageKey: "getDataOfInvoiceCreatedSaleRep",
  };
  public static getDataOfQuoteGeneratedSaleRep = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/get/salesRepStatics/QUOTES_GENERATED",
    method: "GET",
    storageKey: "getDataOfQuoteGeneratedSaleRep",
  };

  public static getDataForReportGenerationOfAbandanCart = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/ABANDON_CARTS",
    method: "GET",
    storageKey: "getDataForReportGenerationOfAbandanCart",
  };
  public static getDataForReportGenerationOfInvoiceCreated = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/INVOICE_CREATED",
    method: "GET",
    storageKey: "getDataForReportGenerationOfInvoiceCreated",
  };
  public static getDataForReportGenerationOfOrderCreated = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/ORDERS_CREATED",
    method: "GET",
    storageKey: "getDataForReportGenerationOfOrderCreated",
  };
  public static getDataForReportGenerationOfQuoteGenerated = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/QUOTES_GENERATED",
    method: "GET",
    storageKey: "getDataForReportGenerationOfQuoteGenerated",
  };
  public static getDataForReportGenerationOfSalesInUSD = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/SALES_USD",
    method: "GET",
    storageKey: "getDataForReportGenerationofSalesInUSD",
  };
  public static getDataForReportGenerationOfSalesInCAD = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/salesRep/report/generate/SALES_CAD",
    method: "GET",
    storageKey: "getDataForReportGenerationOfSalesInCAD",
  };

  // admin setting urls
  public static getListOfStandardChargesInAdminSetting = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/settings/getAllStandardCharges",
    method: "GET",
    storageKey: "getListOfStandardChargesInAdminSetting",
  };
  public static saveStandardChargeInAdminSetting = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/settings/standardCharge/saveUpdate",
    method: "POST",
    storageKey: "saveStandardChargeInAdminSetting",
  };
  public static getListOfUsermanagementInAdminSetting = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/get/all/users",
    method: "GET",
    storageKey: "getListOfUsermanagementInAdminSetting",
  };
  public static createUserInAdminSetting = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/user/create",
    method: "POST",
    storageKey: "createUserInAdminSetting",
  };
  public static updateUserInAdminSetting = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/user/update",
    method: "PUT",
    storageKey: "updateUserInAdminSetting",
  };
  public static getDetailsOfUserInAdminSetting = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/admin/user/getDetailsById",
    method: "GET",
    storageKey: "getDetailsOfUserInAdminSetting",
  };
  public static deleteStandardChargesInAdminSettingById = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/settings/removeStandardChargeById",
    method: "DELETE",
    storageKey: "deleteStandardChargesInAdminSettingById",
  };

  // admin dashboar url
  public static getAbandanCartDateKeyIndicator = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/dashboard/ABANDON_CARTS",
    method: "GET",
    storageKey: "getAbandanCartDateKeyIndicator",
  };
  public static getSalesCADDateKeyIndicator = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/dashboard/SALES_CAD",
    method: "GET",
    storageKey: "getSalesCADDateKeyIndicator",
  };
  public static getSalesUSDDateKeyIndicator = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/dashboard/SALES_USD",
    method: "GET",
    storageKey: "getSalesUSDDateKeyIndicator",
  };
  public static getInvoiceCreatedKeyIndictaor = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/INVOICE_CREATED",
    method: "GET",
    storageKey: "getInvoiceCreatedKeyIndictaor",
  };
  public static getQuoteGeneratedKeyIndictaor = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/QUOTES_GENERATED",
    method: "GET",
    storageKey: "getQuoteGeneratedKeyIndictaor",
  };
  public static getShipmentsKeyIndictaor = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/admin/dashboard/SHIPMENTS",
    method: "GET",
    storageKey: "getShipmentsKeyIndictaor",
  };
  public static getNewCustomerAddedKeyIndictaor = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/NEW_CUSTOMER_ADDED",
    method: "GET",
    storageKey: "getNewCustomerAddedKeyIndictaor",
  };

  public static getGraphDataOfQuotesPerMonth = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/all/customers/quote/getCount",
    method: "GET",
    storageKey: "getGraphDataOfQuotesPerMonth",
  };
  public static getGraphDataOfUserAccounts = {
    action: EnvironmentConstants.DOMAINPATH + "/api/userAccount/getCount",
    method: "GET",
    storageKey: "getGraphDataOfUserAccounts",
  };
  public static getGraphDataOfShipmentByCountry = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/all/customer/shipment/byCountry",
    method: "GET",
    storageKey: "getGraphDataOfShipmentByCountry",
  };
  public static getGraphDataOfTopCarrierList = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/all/customer/top/carrierList",
    method: "GET",
    storageKey: "getGraphDataOfTopCarrierList",
  };
  public static getGraphDateOfShipmentsPermonths = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/all/customers/shipment/getCount",
    method: "GET",
    storgeKey: "getGraphDateOfShipmentsPermonths",
  };
  public static getGraphDateOfCourierAndLTLPermonths = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/shipments/shipmentMode/getCount",
    method: "GET",
    storgeKey: "getGraphDateOfCourierAndLTLPermonths",
  };

  public static getReportOfAbandantCart = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/report/generate/ABANDON_CARTS",
    method: "GET",
    storageKey: "getReportOfAbandantCart",
  };
  public static getReportOfInvoiceCreated = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/report/generate/INVOICE_CREATED",
    method: "GET",
    storageKey: "getReportOfInvoiceCreated",
  };
  public static getReportOfSalesCAD = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/report/generate/SALES_CAD",
    method: "GET",
    storageKey: "getReportOfSalesCAD",
  };
  public static getReportOfQuoteGenerated = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/report/generate/QUOTES_GENERATED",
    method: "GET",
    storageKey: "getReportOfQuoteGenerated",
  };
  public static getReportOfShipments = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/report/generate/ORDERS_CREATED",
    method: "GET",
    storageKey: "getReportOfShipments",
  };
  public static getReportOfSalesUSD = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/report/generate/SALES_USD",
    method: "GET",
    storageKey: "getReportOfSalesUSD",
  };
  public static getReportOfNewCustomerAdded = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/dashboard/report/generate/NEW_CUSTOMER_ADDED",
    method: "GET",
    storageKey: "getReportOfNewCustomerAdded",
  };

  //quote history
  public static getListOfQuoteHistory = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/getAll",
    method: "GET",
    StorageKeys: "getListOfQuoteHistory",
  };

  //Get Quote
  public static getAddressBookgetquote = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/getAll/",
    method: "GET",
    StorageKeys: "getAddressBookgetquote",
  };
  public static getLocationTy = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/loc/type/ws/get/all",
    method: "GET",
    StorageKeys: "getLocationType",
  };
  public static getLocationExtraServices = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/loc/shpmt/services/get/all",
    method: "GET",
    StorageKeys: "getLocationExtraServices",
  };
  public static getShipperConsigneeAddress = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/ws/addr/get/default/shipperConsignee",
    method: "GET",
    StorageKeys: "getShipperConsigneeAddress",
  };
  public static getShipmentModeData = {
    action: EnvironmentConstants.DOMAINPATH + "/api/getMapping/shipmentPackage",
    method: "GET",
    StorageKeys: "getShipmentModeData",
  };
  public static getQuoteDetailsById = {
    action: EnvironmentConstants.DOMAINPATH + "/api/quote/getDetailsById",
    method: "GET",
    StorageKeys: "getQuoteDetailsById",
  };
  public static getFreightClass = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/freight/class/type/ws/get/all",
    method: "GET",
    StorageKeys: "getFreightClass",
  };
  public static saveQuoteStep_one = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/request/ws/save",
    method: "POST",
    StorageKeys: "saveQuote",
  };
  public static getContactDetailsById = {
    action: EnvironmentConstants.DOMAINPATH + "/api/addressBook/getById",
    method: "POST",
    StorageKeys: "getContactDetailsById",
  };
  public static getCustomPackageList = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/customPackage/getPackageList/",
    method: "GET",
    StorageKeys: "getCustomPackageList",
  };
  public static editCustomPackage = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/customPackage/updatePackage",
    method: "PUT",
    StorageKeys: "editCustomPackage",
  };
  public static createCustomPacakge = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/sc/customPackage/createPackage",
    method: "POST",
    StorageKeys: "createCustomPacakge",
  };

  // quote
  public static getShpmtModeList = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/shpmt/mode/ws/get/all",
    method: "GET",
    storageKey: "shimpmnetmodes",
  };
  public static getLocationTypes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/loc/type/ws/get/all",
    method: "GET",
    storageKey: "locationtypes",
  };
  public static getLocationShpmtServcs = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/loc/shpmt/services/get/all",
    method: "GET",
    storageKey: "locationshpmtservices",
  };
  public static getFreightClassTypes = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/freight/class/type/ws/get/all",
    method: "GET",
    storageKey: "getfreightclasstype",
  };
  public static getPackageTypes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/package/type/ws/get/all",
    method: "GET",
    storageKey: "getpackagetype",
  };
  public static getQuotes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/get/all",
    method: "GET",
    storageKey: "getquotes",
  };
  public static getQuotes_Admin = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/filter/quote",
    method: "GET",
    storageKey: "getquotes",
  };
  public static getQuoteOrders = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/order/ws/get/all/",
    method: "GET",
    storageKey: "getquoteorders",
  };
  public static getQuoteOrders_admin = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/filter/order/",
    method: "GET",
    storageKey: "getQuoteOrders_admin",
  };

  public static saveQuote = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/request/ws/save",
    method: "POST",
    storageKey: "savequote",
  };
  public static getQuote = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/get",
    method: "GET",
    storageKey: "getquote",
  };
  public static getQuoteItems = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/item/ws/get/all",
    method: "GET",
    storageKey: "getquoteitems",
  };
  public static getQuoteExtraService = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/extra/services/get/all",
    method: "GET",
    storageKey: "getquoteextraservice",
  };
  public static getPickupTimes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/pickup/time/ws/get/all",
    method: "GET",
    storageKey: "getTimes",
  };
  public static getCustomTypes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/custom/from/type/ws/get/all",
    method: "GET",
    storageKey: "getTimes",
  };
  public static updateQuoteLocation = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/source/destination/address/ws/update",
    method: "PUT",
    storageKey: "savelocation",
  };
  public static updateShpmt = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/shpmt/ws/update",
    method: "PUT",
    storageKey: "updateShpmt",
  };
  public static getShpmtCarriers = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/shpmt/carrier/ws/get/all",
    method: "GET",
    storageKey: "getShpmtCarriers",
  };

  public static getQuoteService = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/servcs/and/shpmt/mode/ws/get",
    method: "GET",
    storageKey: "getquoteservice",
  };
  public static updateQuote = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/request/ws/update",
    method: "PUT",
    storageKey: "updatequote",
  };

  public static getQuoteItemsDetail = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/item/ws/get/by/quote/id",
    method: "GET",
    storageKey: "getquoteitemsdetail",
  };

  public static getQuoteDeliveryDetails = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/delivery/detail/ws/get",
    method: "GET",
    storageKey: "getQuoteDeliveryDetails",
  };
  public static getQuoteCustomDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/customs/detail/ws/get",
    method: "GET",
    storageKey: "getQuoteCustomDetails",
  };
  public static repeatOrder = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/request/ws/save/from/order/history",
    method: "POST",
    storageKey: "repeatOrder",
  };
  public static getShipmentTypes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/shipment/type/ws/get/all",
    method: "GET",
    storageKey: "shipmentTypes",
  };
  public static getShipmentPackaging = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/shipment/packaging/ws/get/all",
    method: "GET",
    storageKey: "shipmentPackaging",
  };
  public static getPrimaryContactList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/getPrimaryContactList",
    method: "GET",
    storageKey: "getPrimaryContactList",
  };
  public static savePrimaryContactToList = {
    action: EnvironmentConstants.DOMAINPATH + "/api/savePrimaryContact",
    method: "PORT",
    storageKey: "savePrimaryContactToList",
  };

  //Quote order
  public static getOrderDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/order/detail/ws/get",
    method: "GET",
    storageKey: "getOrderDetails",
  };
  public static getOrderQuoteItems = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/item/ws/get/by/order/id",
    method: "GET",
    storageKey: "getOrderQuoteItems",
  };
  public static getOrderExtraService = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/order/extra/services/get",
    method: "GET",
    storageKey: "getOrderExtraService",
  };
  public static getBOLDocuments = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/order/bol/document/ws/get/by/order/id",
    method: "GET",
    storageKey: "getBOLdocuments",
  };
  public static getCustomsDocuments = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/order/customs/document/ws/get/by/order/id",
    method: "GET",
    storageKey: "getcustomsdocuments",
  };
  public static getLoadOfferDocuments = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/order/load/offer/document/ws/get/by/order/id",
    method: "GET",
    storageKey: "getloadofferdocuments",
  };
  public static getTraceOrder = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/track/order",
    method: "GET",
    storageKey: "traceOrder",
  };
  public static getCustIdByOrderId = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/get/customer/id/by/order/id",
    method: "GET",
    storageKey: "getCustIdByOrderId",
  };

  // Quote document
  public static getQuoteDocument = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/associated/docmnt/ws/get/by/quote",
    method: "GET",
    storageKey: "getquotedocument",
  };
  public static getAllDocumentTypes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/docmnt/type/get/all",
    method: "GET",
    storageKey: "getalldocumenttypes",
  };
  public static uploadDocument = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/associate/document",
    method: "POST",
    storageKey: "uploaddocument",
  };
  public static uploadFile = {
    action: EnvironmentConstants.DOMAINPATH + "/storage/uploadFile",
    method: "POST",
    storageKey: "uploadfile",
  };
  public static updateQuoteShipmentDetail = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/ws/shipment/detail/save",
    method: "PUT",
    storageKey: "updatequoteshipmentdetail",
  };
  public static getUploadedDocuments = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/associated/document/ws/get/by/quote/id",
    method: "GET",
    storageKey: "getuploadeddocuments",
  };
  public static removeDocument = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/associated/document/ws/delete",
    method: "DELETE",
    storageKey: "removedocument",
  };
  public static getShipmentDetail = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/shipment/detail/ws/get",
    method: "GET",
    storageKey: "getshipmentdetail",
  };

  //Customs broker
  public static getAllCustomsBrokers = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/customs/broker/names/get/all",
    method: "GET",
    storageKey: "getallcustomsbrokers",
  };
  public static getCustomBroker = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/customs/broker/get",
    method: "GET",
    storageKey: "getCustomBroker",
  };

  //profile Image
  public static getImage = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/file/master/ws/get",
    method: "GET",
    storageKey: "getImage",
  };

  //quote payment
  public static saveQuotePayment = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/payment/ws/save",
    method: "POST",
    storageKey: "saveQuotePayment",
  };
  public static quotePaymtMethods = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/payment/method/get/all",
    method: "GET",
    storageKey: "quotePaymtMethods",
  };
  public static getQuotePrice = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/price/component/get",
    method: "GET",
    storageKey: "getQuotePrice",
  };
  public static getQuotePaymentAddrId = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/payment/address/id/get",
    method: "GET",
    storageKey: "getQuotePaymentAddrId",
  };
  public static getQuotePaymentList = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/payment/ws/get/all",
    method: "GET",
    storageKey: "getQuotePaymentList",
  };
  public static getCouponCode = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/coupon/code/ws/get",
    method: "GET",
    storageKey: "getcouponcode",
  };

  public static setPaymentAsPaid = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/update/quote/payment/status",
    method: "PUT",
    storageKey: "setPaymentAsPaid",
  };

  //shipment carrier
  public static carriers = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/shpmt/carrier/get/all",
    method: "GET",
    storageKey: "carriers",
  };
  public static getValidCarriers = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/valid/shpmt/carrier/ws/get",
    method: "GET",
    storageKey: "getvalidcarriers",
  };
  public static nonIntegratorCarriers = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/non/integrator/shpmt/carrier/ws/get/all",
    method: "GET",
    storageKey: "nonIntegratorCarriers",
  };
  public static getAllCarriers = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/integrator/shpmt/carrier/ws/get/all",
    method: "GET",
    storageKey: "getAllCarriers",
  };

  //Operational Variable
  public static operationalVarValue = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/operational/variable/ws/get",
    method: "GET",
    storageKey: "operationalVarValue",
  };

  //Warehouse Aggregator
  public static getAllWarehouse = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/warehouse/aggregator/get/all",
    method: "GET",
    storageKey: "getallwarehouse",
  };

  //Credit Applicant
  public static saveBillingInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/billing/info/ws/save",
    method: "POST",
    storageKey: "saveBillingInfo",
  };
  public static saveCreditReference = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/reference/ws/save",
    method: "POST",
    storageKey: "savecreditInfo",
  };
  public static saveCreditAppInfo = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/credit/applicant/ws/save",
    method: "POST",
    storageKey: "saveCreditAppInfo",
  };
  public static getCompanyTypes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/company/type/ws/get/all",
    method: "GET",
    storageKey: "getCompanyTypes",
  };
  public static getCompanyLocationTypes = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/company/location/type/ws/get/all",
    method: "GET",
    storageKey: "getCompanyLocationTypes",
  };
  public static updateCompanyInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/company/info/ws/update",
    method: "GET",
    storageKey: "updateCompanyInfo",
  };
  public static getApplicantDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/credit/applicant/ws/get",
    method: "GET",
    storageKey: "getApplicantDetails",
  };
  public static getCreditApplicant = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/credit/applicant/details/ws/get",
    method: "GET",
    storageKey: "creditapplicant",
  };
  public static updatePayment = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/payment/info/ws/save",
    method: "PUT",
    storageKey: "updatePayment",
  };
  public static getCreditPaymentMethods = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/payment/method/get/all/credit/applicant",
    method: "GET",
    storageKey: "quotePaymtMethods",
  };
  public static getCreditReferences = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/reference/ws/get/all",
    method: "GET",
    storageKey: "getCreditReferences",
  };
  public static getCreditRefDetails = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/credit/applicant/reference/ws/get",
    method: "GET",
    storageKey: "getCreditRefDetails",
  };
  public static getCreditPayments = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/billing/payment/ws/get/by/id",
    method: "GET",
    storageKey: "creditpaymtdata",
  };
  public static getCreditBillingAddress = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/billing/address/ws/get/by/id",
    method: "GET",
    storageKey: "creditbillingaddress",
  };
  public static getCreditApplicants = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/credit/applicant/ws/get/all",
    method: "GET",
    storageKey: "applicants",
  };
  public static getCreditApplicantStatus = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/status/ws/get/all",
    method: "GET",
    storageKey: "getceditapplicantstatus",
  };
  public static getApplicantStatus = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/status/ws/get/by/applicantid",
    method: "GET",
    storageKey: "applicantstatus",
  };
  public static uploadCreditApplicantDoc = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/ws/upload/document",
    method: "POST",
    storageKey: "uploaddoc",
  };
  public static getCreditApplicantDocs = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/document/ws/get/all",
    method: "GET",
    storageKey: "getddoc",
  };
  public static deleteReferences = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/reference/ws/delete",
    method: "DELETE",
    storageKey: "deleteReferences",
  };
  public static deleteDocument = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/document/ws/delete",
    method: "DELETE",
    storageKey: "deletedoc",
  };
  public static getApplicantStatusHistory = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/status/tracker/ws/get/all",
    method: "GET",
    storageKey: "getApplicantStatusHistory",
  };
  public static getCreditApplicantDoc = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/credit/applicant/document/ws/get",
    method: "GET",
    storageKey: "getApplicantStatusHistory",
  };
  public static updateCreditApplicantDoc = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/ws/update/document",
    method: "PUT",
    storageKey: "uploaddoc",
  };
  //Credit Applicant banking info
  public static saveCreditApplicantBankInfo = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/credit/banking/info/ws/save",
    method: "POST",
    storageKey: "saveceditapplicantbankinfo",
  };
  public static updateCreditApplicantBankInfo = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/credit/banking/info/ws/update",
    method: "PUT",
    storageKey: "updateceditapplicantbankinfo",
  };
  public static getCreditApplicantBankInfo = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/banking/info/ws/get/by/applicant/id",
    method: "GET",
    storageKey: "getceditapplicantbankinfo",
  };

  //Credit applicant status tracker
  public static saveCreditApplicantSttsTracker = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/applicant/status/tracker/ws/save",
    method: "POST",
    storageKey: "saveceditapplicantsttstracker",
  };

  //Credit User Validity
  public static setCreditUserValidity = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/credit/user/validity/ws/set/by/applicantid",
    method: "PUT",
    storageKey: "setcredituservalidity",
  };

  //Currency Unit
  public static getCurrencyRate = {
    action: EnvironmentConstants.DOMAINPATH + "/api/currencyConversionRate/get",
    method: "GET",
    storageKey: "getCurrencyRate",
  };
  public static getCurrencyRateHistory = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/currencyConversionRate/getHistory/0/10",
    method: "GET",
    storageKey: "getCurrencyRateHistory",
  };
  public static updateCurrencyRate = {
    action:
      EnvironmentConstants.DOMAINPATH + "/api/currencyConversionRate/update",
    method: "PUT",
    storageKey: "updateCurrencyRate",
  };

  //coupon
  public static getOfferTypes = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/type/ws/get/all",
    method: "GET",
    storageKey: "getOfferTypes",
  };
  public static getOfferMethods = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/method/ws/get/all",
    method: "GET",
    storageKey: "getOfferMethods",
  };
  public static saveOffer = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/ws/save",
    method: "POST",
    storageKey: "saveOffer",
  };
  public static getOfferDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/details/ws/get",
    method: "GET",
    storageKey: "getOfferDetails",
  };

  public static addCouponDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/coupon/details/ws/save",
    method: "POST",
    storageKey: "saveCoupon",
  };
  public static updateCouponDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/coupon/details/ws/update",
    method: "PUT",
    storageKey: "updateCoupon",
  };
  public static getCouponDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/coupon/details/ws/get",
    method: "GET",
    storageKey: "getCoupon",
  };
  public static getOffers = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/ws/get/all",
    method: "GET",
    storageKey: "getOffers",
  };
  public static getCoupons = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/coupons/ws/get/all",
    method: "GET",
    storageKey: "getCoupons",
  };
  public static getVouchers = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/voucher/ws/get/all",
    method: "GET",
    storageKey: "getVouchers",
  };
  public static updateArchiveOrUnarchive = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/offer/ws/archive/unarchive/",
    method: "PUT",
    storageKey: "updateArchiveOrUnarchive",
  };

  //Voucher details
  public static addVoucherDetail = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/voucher/details/ws/save",
    method: "POST",
    storageKey: "addvoucherdetail",
  };
  public static updateVoucherDetail = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/voucher/details/ws/update",
    method: "PUT",
    storageKey: "updatevoucherdetail",
  };
  public static getVoucherDetail = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/voucher/details/ws/get",
    method: "GET",
    storageKey: "getvoucherdetail",
  };

  //Case Incident Type
  public static getCaseIncidentTypes = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/case/incident/type/ws/get/all",
    method: "GET",
    storageKey: "getcaseincidenttypes",
  };

  //Case Incident
  public static saveCaseIncident = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/case/incident/ws/save",
    method: "POST",
    storageKey: "savecaseincident",
  };
  public static getCaseIncident = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/case/incident/ws/get",
    method: "GET",
    storageKey: "getcaseincident",
  };
  public static getAllCaseIncidents = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/user/case/incident/ws/get/all",
    method: "GET",
    storageKey: "getallcaseincident",
  };
  public static getAllAdminCaseIncidents = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/admin/case/incident/ws/get/all",
    method: "GET",
    storageKey: "getAllAdminCaseIncidents",
  };

  public static saveCaseStatusHistory = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/case/status/tracker/ws/save",
    method: "POST",
    storageKey: "saveCaseStatusHistory",
  };
  public static getCaseStatus = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/case/status/ws/get/by/caseid",
    method: "GET",
    storageKey: "getCaseStatus",
  };
  public static getAllCaseStatus = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/case/status/ws/get/all",
    method: "GET",
    storageKey: "getAllCaseStatus",
  };

  //Case document
  public static saveCaseDocument = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/case/ws/save/document",
    method: "POST",
    storageKey: "savecasedocument",
  };
  public static deleteCaseDocument = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/case/ws/delete/document",
    method: "DELETE",
    storageKey: "deletecasedoc",
  };
  public static caseDocumentList = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/case/document/ws/get/all/by/caseid",
    method: "GET",
    storageKey: "getcasedocuments",
  };

  //Case incident history
  public static getAllCaseIncidentHistory = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/case/incident/history/ws/get",
    method: "GET",
    storageKey: "getallcaseincidenthistory",
  };

  //easypost track order
  //public static trackEasyPostOrder = { action: EnvironmentConstants.DOMAINPATH + '/sc/track/order', method: 'POST', storageKey: 'trackEasyPostOrder'};

  //currency
  public static getAllCurrencyUnits = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/currency/ws/get/all",
    method: "GET",
    storageKey: "getAllCurrencyUnits",
  };

  //get quote carrier request and response
  public static getCarrierQuoteReqResponse = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/quote/carrier/request/response",
    method: "GET",
    storageKey: "getCarrierQuoteReqResponse",
  };

  //Cancel Order
  public static cancelOrder = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/carrier/ws/cancel/order",
    method: "POST",
    storageKey: "cancelOrder",
  };

  //save profle image
  public static saveCustProfileImage = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/cust/ws/save/profile/image",
    method: "POST",
    storageKey: "saveCustProfileImage",
  };
  public static savePartnerProfileImage = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/partner/ws/save/profile/image",
    method: "POST",
    storageKey: "saveCustProfileImage",
  };
  public static saveShipUserProfileImage = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/user/ws/save/profile/image",
    method: "POST",
    storageKey: "saveCustProfileImage",
  };

  //Payment gateway
  public static getPaymentActionUrl = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/payment/gateway/get/action/url",
    method: "GET",
    storageKey: "getpaymentactionurl",
  };

  //Update Item description or cost
  public static updateItemDesrCost = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/quote/item/ws/update/by/id",
    method: "PUT",
    storageKey: "getpaymentactionurl",
  };

  public static getDRSImagePDF = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/drs/get/image/pdf",
    method: "GET",
    storageKey: "getDrsImagePdf",
  };

  public static resetPasswordByAdmin = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/autz/ws/reset/password/by/admin",
    method: "PUT",
    storageKey: "resetPasswordbyadmin",
  };
  public static getGroups = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/group/ws/get/all",
    method: "GET",
    storageKey: "grouplist",
  };
  public static assoicateUserGroup = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/application/user/association/ws/save",
    method: "POST",
    storageKey: "assoicateUserGroup",
  };
  public static updateTimezone = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/autz/ws/update/timezone",
    method: "PUT",
    storageKey: "updateTimezone",
  };

  //Carrier order response
  public static getOrderResonseDetails = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/order/response/detail/ws/get",
    method: "GET",
    storageKey: "getOrderResonseDetails",
  };

  //Partner Carrier association
  public static associatePartnerCarrier = {
    action:
      EnvironmentConstants.DOMAINPATH + "/sc/partner/carrier/ws/association",
    method: "POST",
    storageKey: "associatePartnerCarrier",
  };

  //fetch yrc submit bol documents
  public static fetchYRCDocs = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/yrc/order/documents",
    method: "GET",
    storageKey: "fetchYRCDocs",
  };

  //Carrier graph
  public static fetchNoOfShipmentForCarrier = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/no/of/shipments/for/carrier",
    method: "GET",
    storageKey: "fetchNoOfShipmentForCarrier",
  };

  //fetchNoOfRatesVsCarrier
  public static fetchNoOfRatesVsCarrier = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/no/of/rates/carrier/ws/get",
    method: "GET",
    storageKey: "fetchNoOfRatesVsCarrier",
  };

  //update addres tag
  public static updateAddressTag = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/address/name/ws/update",
    method: "PUT",
    storageKey: "updateAddressTag",
  };

  //Freight Calculator
  public static getFreightCalc = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/calculate/freight/class",
    method: "GET",
    storageKey: "getfreightcalc",
  };

  //Carrier quote cost breakup
  public static getCostBreakupList = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/sc/quote/cost/breakup/ws/get/by/carrier",
    method: "GET",
    storageKey: "getCostBreakupList",
  };

  // payment-checkout
  public static paymentCheckoutCharge =
    EnvironmentConstants.DOMAINPATH + "/sc/payment/creditcard/charge";

  // payment-order
  public static paymentOrder =
    EnvironmentConstants.DOMAINPATH + "/sc/quote/payment/ws/save";

  public static getCareerAll = {
    action: EnvironmentConstants.DOMAINPATH + "/api/carrier/getDetails",
    method: "GET",
  };
  public static FuelSurchargeList = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/partner/fuel-surcharge-rate/list",
    method: "GET",
  };
  public static rateUpload = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/rateupload",
    method: "POST",
  };
  public static fuelSurchargeRate = {
    action:
      EnvironmentConstants.DOMAINPATH +
      "/api/sc/admin/partner/fuel-surcharge-rate/save",
    method: "POST",
  };
  public static updateMarkup = {
    action: EnvironmentConstants.DOMAINPATH + "/api/carrier/updateMarkup",
    method: "POST",
  };

  //Update Credit Limit Available and Utilized Figures
  public static updateCreditApprovedLimit = {
    action: EnvironmentConstants.DOMAINPATH + "/api/updateCreditLimit/",
    method: "PUT",
    storageKey: "updateCreditApprovedLimit",
  };

  public static updateCreditRemainingLimit = {
    action: EnvironmentConstants.DOMAINPATH + "/api/updatePaidCredit",
    method: "PUT",
    storageKey: "updateCreditRemainingLimit",
  };
  //DOWNLOAD invoice
  public static downloadinvoice = {
    action: EnvironmentConstants.DOMAINPATH + "/api/invoices/downloadData",
    method: "GET",
    storageKey: "downloadinvoice",
  };

  //Quick Action
  public static getAddressByZip = {
    action: EnvironmentConstants.DOMAINPATH + "/sc/google/pincode/ws/",
    method: "GET",
    storageKey: "getAddressByZip",
  };
  public static getBolListWithFilter = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/ext_bol/getAll/",
    method: "GET",
    StorageKeys: "getBolListWithFilter",
  };
  public static fetchQuickBolFormData = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/ext_bol/getById",
    method: "GET",
    StorageKeys: "fetchQuickBolFormData",
  };
  public static saveUpdateFn = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/admin/ext_bol/save",
    method: "POST",
    StorageKeys: "saveUpdateFn",
  };
  public static getHazmatDetails = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/quote/getHazmatDetails",
    method: "GET",
    StorageKeys: "getHazmatDetails",
  };

  //POD
  public static getPodDoc = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/saas/getPodDoc",
    method: "GET",
    storageKey: "getPodDoc",
  };
  public static uploadPodDoc = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/saas/uploadPodDoc",
    method: "POST",
    storageKey: "uploadPodDoc",
  };
  public static sendEmail = {
    action: EnvironmentConstants.DOMAINPATH + "/api/pod/sendEmail",
    method: "POST",
    storageKey: "sendEmail",
  };
  public static getPodDocl = {
    action: EnvironmentConstants.DOMAINPATH + "/api/sc/pod/getPodDoc",
    method: "GET",
    storageKey: "getPodDocl",
  };
}
