import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewContainerRef, ViewChild, ElementRef, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, } from '@angular/forms';
import { NgbDateStruct, NgbDatepickerConfig, NgbModal, ModalDismissReasons, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, } from 'rxjs/operators';
import { $, element, promise } from 'protractor';
import { ServerURLS } from 'src/environments/environment';
import { ScCommonService, UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { CustomPackageComponent } from '../../get-quote/custom-package/custom-package.component';
import { DomSanitizer } from '@angular/platform-browser';
import {  SignaturePopUpComponent } from '../../commons/signature-popup/signature-popup.component';
import SignaturePad from 'signature_pad';
import { error } from 'console';
import { LoadingService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-create-new-comm-invoice',
  templateUrl: './create-new-comm-invoice.component.html',
  styleUrls: ['./create-new-comm-invoice.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: UnitedStatesDateParserFormatter }]
})

export class CreateNewCommInvoiceComponent implements OnInit {
  @ViewChild('startPicker',undefined) pickerStart
  @ViewChild('confirmationModal', { static: true }) confirmationModal;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('addressInput',undefined) addressInput: ElementRef;
  sig: SignaturePad;
  displayCourier = false;
  imageData: any = '';
  sidebarToggle: boolean;
  modelDueDate: NgbDateStruct;
  modelSignDate:NgbDateStruct
  editOrderEnabled: boolean = false
  packageList: any = []
  countryInputPlaceHolder: any = 'Select a Country of Origin'
  shipperStatePlaceHolder: any = 'State / Province';
  shipperCountryPlaceHolder: any = 'Country'
  consigneeStatePlaceHolder: any = 'State / Province'
  consigneeCountryPlaceHolder: any = 'Country'
  purchaserCountryPlaceHolder: any = 'Country'
  purchaserStatePlaceHolder: any = 'State / Province'
  dueDatePlacholder: any = 'Select Due Date'
  bottomDatePlaceholder: any = 'Select Date'
  isEdit: boolean = false;
  dataFrom: FormGroup
  duitableValue: boolean = false

  addSalesTacModeValue: any = '%'
  addFreightCostValue: any = '%'
  // signatureData:any=''
  signatureData: any;
  countryData = {
    success: [
      {
        countryPhCode: "+1",
        countryName: "Canada",
        countryCode: "CA",
        provinceState: [
          {
            code: "AB",
            name: "Alberta"
          },
          {
            code: "BC",
            name: "British Columbia"
          },
          {
            code: "MB",
            name: "Manitoba"
          },
          {
            code: "NB",
            name: "New Brunswick"
          },
          {
            code: "NL",
            name: "Newfoundland and Labrador"
          },
          {
            code: "NT",
            name: "Northwest Territories"
          },
          {
            code: "NS",
            name: "Nova Scotia"
          },
          {
            code: "NU",
            name: "Nunavut"
          },
          {
            code: "ON",
            name: "Ontario"
          },
          {
            code: "PE",
            name: "Prince Edward Island"
          },
          {
            code: "QC",
            name: "Quebec"
          },
          {
            code: "SK",
            name: "Saskatchewan"
          },
          {
            code: "YT",
            name: "Yukon"
          }
        ]
      },
      {
        countryPhCode: "+1",
        countryName: "United States",
        countryCode: "US",
        provinceState: [
          {
            code: "AL",
            name: "Alabama"
          },
          {
            code: "AK",
            name: "Alaska"
          },
          {
            code: "AS",
            name: "American Samoa"
          },
          {
            code: "AZ",
            name: "Arizona"
          },
          {
            code: "AR",
            name: "Arkansas"
          },
          {
            code: "CA",
            name: "California"
          },
          {
            code: "CO",
            name: "Colorado"
          },
          {
            code: "CT",
            name: "Connecticut"
          },
          {
            code: "DE",
            name: "Delaware"
          },
          {
            code: "DC",
            name: "District of Columbia"
          },
          {
            code: "FL",
            name: "Florida"
          },
          {
            code: "GA",
            name: "Georgia"
          },
          {
            code: "GU",
            name: "Guam"
          },
          {
            code: "HI",
            name: "Hawaii"
          },
          {
            code: "ID",
            name: "Idaho"
          },
          {
            code: "IL",
            name: "Illinois"
          },
          {
            code: "IN",
            name: "Indiana"
          },
          {
            code: "IA",
            name: "Iowa"
          },
          {
            code: "KS",
            name: "Kansas"
          },
          {
            code: "KY",
            name: "Kentucky"
          },
          {
            code: "LA",
            name: "Louisiana"
          },
          {
            code: "ME",
            name: "Maine"
          },
          {
            code: "MD",
            name: "Maryland"
          },
          {
            code: "MA",
            name: "Massachusetts"
          },
          {
            code: "MI",
            name: "Michigan"
          },
          {
            code: "MN",
            name: "Minnesota"
          },
          {
            code: "MS",
            name: "Mississippi"
          },
          {
            code: "MO",
            name: "Missouri"
          },
          {
            code: "MT",
            name: "Montana"
          },
          {
            code: "NE",
            name: "Nebraska"
          },
          {
            code: "NV",
            name: "Nevada"
          },
          {
            code: "NH",
            name: "New Hampshire"
          },
          {
            code: "NJ",
            name: "New Jersey"
          },
          {
            code: "NM",
            name: "New Mexico"
          },
          {
            code: "NY",
            name: "New York"
          },
          {
            code: "NC",
            name: "North Carolina"
          },
          {
            code: "ND",
            name: "North Dakota"
          },
          {
            code: "OH",
            name: "Ohio"
          },
          {
            code: "OK",
            name: "Oklahoma"
          },
          {
            code: "OR",
            name: "Oregon"
          },
          {
            code: "PA",
            name: "Pennsylvania"
          },
          {
            code: "PR",
            name: "Puerto Rico"
          },
          {
            code: "RI",
            name: "Rhode Island"
          },
          {
            code: "SC",
            name: "South Carolina"
          },
          {
            code: "SD",
            name: "South Dakota"
          },
          {
            code: "TN",
            name: "Tennessee"
          },
          {
            code: "TX",
            name: "Texas"
          },
          {
            code: "VI",
            name: "U.S. Virgin Islands"
          },
          {
            code: "UT",
            name: "Utah"
          },
          {
            code: "VT",
            name: "Vermont"
          },
          {
            code: "VA",
            name: "Virginia"
          },
          {
            code: "WA",
            name: "Washington"
          },
          {
            code: "WV",
            name: "West Virginia"
          },
          {
            code: "WI",
            name: "Wisconsin"
          },
          {
            code: "WY",
            name: "Wyoming"
          }
        ]
      },
    ]
  };
  transferModeArray: any;
  SelectedTransportMode: any = 0;
  selectedShipmentMode: any;
  brokerList: any = [];
  commercialInvoiceData: any;
  shipperReceiverConsigneeErrorMessage: any
  prePaidNoPrePaidCollectErrorMessage: any;
  relatedNotRelatedErrorMessage: any;
  tranportModeErrorMessage: any;
  shipmentModeErrorMessage: any;

  shipmentItemList: any = []
  quantityType: any = ''
  totalQuantity: any = 0;
  totalWeight: any = 0;
  totalAmount: any = 0.00;
  totalAmountDue: any = 0;
  isSignButtonEnable: boolean = false;
  submitButtonIsEnable: boolean = false;
  orderId: any;
  frieghtClassObj: any;
  mesaureMentType: any = 'imperial'
  // dueDateConfig:FlatpickrOptions
  addressBookShipperAddress: any;
  addressBookConsigneeAddress: any;
  addressBookPurchaseAddress: any;


  isDuitable: boolean = false;
  shipperCheckedBox: boolean = false;
  consigneeCheckedBox: boolean = false;
  purchaserCheckedBox: boolean = false;


  shipperAddressLine1IsGoogle: boolean = true;
  consigeeAddressLine1IsGoogle: boolean = true;
  purchaserAddressLine1IsGoogle: boolean = true;

  shipperStateArray: any;
  consigneeStateArray: any;
  purchaserStateArray: any;

  dataFromGoogleApiLocationForShipper: any;
  dataFromGoogleApiLocationForConsignee: any;
  dataFromGoogleApiLocationForPurchase: any;


  shipperGoogleAddressErrorMessage: boolean = false;
  consigneeGoogleAddressErrorMessage: boolean = false;
  purchaserGoogleAddressErrorMessage: boolean = false;

  isShipperCompanyNameManual: boolean = false;
  isConsigneeCompanyNameManual: boolean = false;
  isPurchaserCompanyNameManual: boolean = false;
  
  
  shipmentModeArray: any = [
    {
      "shippingModeId": "0a13d82b-f7ee-11e9-8462-163046d26cd4",
      "shippingMode": "Courier",
      "description": "Courier",
      "shipmentCode": "CRS",
      "styleCode": "courier",
      "isFreightNeeded": false,
      "isDisabled": false,
      "packagingDetails": [
        {
          "packagingId": "69630cb4-0fa7-11ea-8462-163046d26cd4",
          "packagingType": "Letter",
          "styleCode": "letter"
        },
        {
          "packagingId": "69630f80-0fa7-11ea-8462-163046d26cd4",
          "packagingType": "Parcel",
          "styleCode": "parcel"
        },
        {
          "packagingId": "1e169c81-36e0-11ea-943e-16dd6d5c7fbb",
          "packagingType": "Boxed",
          "styleCode": "package"
        },
        {
          "packagingId": "3c078f73-0d63-11ed-ae4b-4ea80e173030",
          "packagingType": "Custom Package",
          "styleCode": "cp"
        }
      ],
      "shipmentTypeDetails": null,
      "packagingTypeDetails": null
    },
    {
      "shippingModeId": "cd6bdba2-e7ae-11e9-8462-163046d26cd4",
      "shippingMode": "Less than Truckload (LTL)",
      "description": "Less-than-Truckload (LTL) Shipment",
      "shipmentCode": "LTL",
      "styleCode": "truck",
      "isFreightNeeded": true,
      "isDisabled": false,
      "packagingDetails": [
        {
          "packagingId": "6963113f-0fa7-11ea-8462-163046d26cd4",
          "packagingType": "Pallet",
          "styleCode": "pallet"
        }
      ],
      "shipmentTypeDetails": null,
      "packagingTypeDetails": [
        {
          "packagingId": "b8e221f4-f312-11e9-8462-163046d26cd4",
          "packagingName": "Pallets",
          "packagingType": "PLT_48",
          "packagingCode": "pallets",
          "freightNeeded": true
        },
        {
          "packagingId": "b8e223fe-f312-11e9-8462-163046d26cd4",
          "packagingName": "Boxed",
          "packagingType": "BOXD",
          "packagingCode": "boxed",
          "freightNeeded": false
        },
        {
          "packagingId": "b8e22463-f312-11e9-8462-163046d26cd4",
          "packagingName": "Crated",
          "packagingType": "CRTD",
          "packagingCode": "crated",
          "freightNeeded": false
        },
        {
          "packagingId": "b8e224c7-f312-11e9-8462-163046d26cd4",
          "packagingName": "Drums or Barrels",
          "packagingType": "DRM",
          "packagingCode": "drums-barrels",
          "freightNeeded": false
        },
        {
          "packagingId": "zfa20f16-0d62-11ed-ae4b-4ea80e173030",
          "packagingName": "Custom Package",
          "packagingType": "CP",
          "packagingCode": "custom-package",
          "freightNeeded": true
        }
      ]
    },
    {
      "shippingModeId": "cd77897d-e7ae-11e9-8462-163046d26cd4",
      "shippingMode": "Volume (VTL)",
      "description": "Volume(LTL) Shipment",
      "shipmentCode": "VLTL",
      "styleCode": "truck-ltl",
      "isFreightNeeded": true,
      "isDisabled": false,
      "packagingDetails": [
        {
          "packagingId": "6963113f-0fa7-11ea-8462-163046d26cd4",
          "packagingType": "Pallet",
          "styleCode": "pallet"
        }
      ],
      "shipmentTypeDetails": null,
      "packagingTypeDetails": [
        {
          "packagingId": "b8e221f4-f312-11e9-8462-163046d26cd4",
          "packagingName": "Pallets",
          "packagingType": "PLT_48",
          "packagingCode": "pallets",
          "freightNeeded": true
        },
        {
          "packagingId": "b8e223fe-f312-11e9-8462-163046d26cd4",
          "packagingName": "Boxed",
          "packagingType": "BOXD",
          "packagingCode": "boxed",
          "freightNeeded": false
        },
        {
          "packagingId": "b8e22463-f312-11e9-8462-163046d26cd4",
          "packagingName": "Crated",
          "packagingType": "CRTD",
          "packagingCode": "crated",
          "freightNeeded": false
        },
        {
          "packagingId": "b8e224c7-f312-11e9-8462-163046d26cd4",
          "packagingName": "Drums or Barrels",
          "packagingType": "DRM",
          "packagingCode": "drums-barrels",
          "freightNeeded": false
        },
        {
          "packagingId": "zfa20f16-0d62-11ed-ae4b-4ea80e173030",
          "packagingName": "Custom Package",
          "packagingType": "CP",
          "packagingCode": "custom-package",
          "freightNeeded": true
        }
      ]
    },
    {
      "shippingModeId": "cd99368c-e7ae-11e9-8462-163046d26cd4",
      "shippingMode": "Full Truckload (FTL) ",
      "description": "Full Truckload (TL) Shipment",
      "shipmentCode": "FTL",
      "styleCode": "truck-ftl",
      "isFreightNeeded": true,
      "isDisabled": true,
      "packagingDetails": [
        {
          "packagingId": "6963113f-0fa7-11ea-8462-163046d26cd4",
          "packagingType": "Pallet",
          "styleCode": "pallet"
        }
      ],
      "shipmentTypeDetails": [
        {
          "shipmentTypeAssocId": "7ed39746-0c25-11ea-9468-448a5bcf5c91",
          "shipmentType": "53ft Trailer",
          "styleCode": "53ft-trailer"
        },
        {
          "shipmentTypeAssocId": "7ed3ac2c-0c25-11ea-9468-448a5bcf5c91",
          "shipmentType": "Flat Bed",
          "styleCode": "flat-bed"
        },
        {
          "shipmentTypeAssocId": "7ed3b651-0c25-11ea-9468-448a5bcf5c91",
          "shipmentType": "Refrigerated",
          "styleCode": "refrigerated"
        },
        {
          "shipmentTypeAssocId": "7ed3c062-0c25-11ea-9468-448a5bcf5c91",
          "shipmentType": "Rolltite",
          "styleCode": "rolltite"
        },
        {
          "shipmentTypeAssocId": "7ed3ca95-0c25-11ea-9468-448a5bcf5c91",
          "shipmentType": "Step Deck",
          "styleCode": "step-deck"
        }
      ],
      "packagingTypeDetails": null
    }
  ]

  dueDate: string;
  signDate: string;
  commercialInvoiceId:any=''
  defaultaddressbookdata:any;
  addressData:any;
  ConsigneeAddressData:any;
  purchaserAddressData:any;
  customCommInvoiceId: any;
  // dueDateConfig: NgbDateStruct;
  constructor(

    private commanService: ScCommonService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private httpService: ScHttpService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private  _loaderService: LoadingService

  ) {

    // const today = new Date();
    // this.modelDueDate = { month: today.getMonth() + 1, day: today.getDate(), year: today.getFullYear() };

    // console.log(this.modelDueDate)
    
   }

  ngDoCheck() {
    if (this.dataFrom.valid && (this.tranportModeErrorMessage === false && this.tranportModeErrorMessage != undefined) && (this.shipmentModeErrorMessage === false && this.shipmentModeErrorMessage != undefined) && (this.shipperReceiverConsigneeErrorMessage === false && this.shipperReceiverConsigneeErrorMessage != undefined) && (this.prePaidNoPrePaidCollectErrorMessage === false && this.prePaidNoPrePaidCollectErrorMessage != undefined) && (this.relatedNotRelatedErrorMessage === false && this.relatedNotRelatedErrorMessage != undefined)) {
      this.isSignButtonEnable = true;
      if (this.signatureData && this.isSignButtonEnable) {
        this.submitButtonIsEnable = true;
      } else {
        this.submitButtonIsEnable = false;
      }
    } else {

      this.isSignButtonEnable = false;
      this.submitButtonIsEnable = false;
    }
  }
  ngOnInit(): void {

     this.modelDueDate = null;
     this.dataFrom = this.formBuilder.group({
      countryOfOrigin: ['', [Validators.required]],
      dueDate: ['', [Validators.required]],
      // shipperCompanyName: ['', [Validators.required]],
      shipperCompanyName: ['', [Validators.required ]],
      shipperAddressLine1: ['', [Validators.required]],
      shipperAddressLine2: [''],
      shipperCity: ['', [Validators.required, Validators.pattern(/^[A-Za-z\s]+$/)]],
      shipperState: [{ value: '', disabled: this.shipperAddressLine1IsGoogle }, [Validators.required]],
      shipperPostalCode: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9\s]+$/)]],
      shipperCountry: [{ value: '', disabled: this.shipperAddressLine1IsGoogle }, [Validators.required]],
      // consigneeCompanyName: ['', [Validators.required]],
      consigneeCompanyName: ['', [Validators.required ]],
      consigneeAddressLine1: ['', [Validators.required]],
      consigneeAddressLine2: [''],
      consigneeCity: ['', [Validators.required, Validators.pattern(/^[A-Za-z\s]+$/)]],
      consigneeState: [{ value: '', disabled: this.consigeeAddressLine1IsGoogle }, [Validators.required]],
      consigneePostalCode: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9\s]+$/)]],
      consigneeCountry: [{ value: '', disabled: this.consigeeAddressLine1IsGoogle }, [Validators.required]],
      purchaserCompanyName: ['', [Validators.required ]],
      purchaserAddressLine1: ['', [Validators.required]],
      purchaserAddressLine2: [''],
      purchaserCity: ['', [Validators.required, Validators.pattern(/^[A-Za-z\s]+$/)]],
      purchaserState: [{ value: '', disabled: this.purchaserAddressLine1IsGoogle }, [Validators.required]],
      purchaserPostalCode: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9\s]+$/)]],
      purchaserCountry: [{ value: '', disabled: this.purchaserAddressLine1IsGoogle }, [Validators.required]],
      purchaserFirstName: [''],
      purchaserLastName: [''],
      recipientTaxId: ['', [Validators.required]],
      // taxId:['',[Validators.required]],
      sameAsPurchaser: [false],
      duitableReceiver: [],
      duitableShipper: [{ value: '', disabled: true }],
      duitableConsignee: [{value: '', disabled: true }],
      account: ['', [Validators.required]],
      SED: ['', [Validators.required]],
      termOfSale: ['', [Validators.required]],
      termOfPayment: ['', [Validators.required]],
      prePaid: [false],
      noprePaid: [false],
      collect: [false],
      related: [false],
      notrelated: [false],
      searchBroker: ['', [Validators.required, Validators.pattern(/^[A-Za-z.\s]+$/)]],
      firstName: ['', [ Validators.pattern(/^[A-Za-z\s]+$/)]],
      lastName: ['', [Validators.pattern(/^[A-Za-z\s]+$/)]],
      email: ['', [ Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      countryCode: [''],
      phoneNumber: ['', [ Validators.pattern(/^[0-9]+$/), Validators.minLength(10), Validators.maxLength(10)]],
      phoneExt: ['', [Validators.pattern(/^[0-9]+$/)]],
      shipmentItems: this.formBuilder.array([this.addline()]),
      addSalesMode: ['%'],
      addSalesValue: ['', [Validators.required, Validators.pattern(/^(?:\d+|\d+\.\d+)$/)]],
      addFrieghtClassMode: [{value:'$',disabled:true}],
      addFrieghtChargeValue: ['', [Validators.required, Validators.pattern(/^(?:\d+|\d+\.\d+)$/)]],
      date: ['', [Validators.required]],

    })


    this.transferModeArray = [
      { name: "Land", imageName: "Frieght-Truck.svg", id: 1 },
      { name: "Air", imageName: "Plane.svg", id: 2 },
      { name: "Sea", imageName: "Ship.svg", id: 3 }
    ]

 
    this.getFreightClass();
    // this.convertBinaryDataToPDF();
    let url=window.location.href.split('/');
    if(url[url.length-1]==='edit'){
      this.isEdit = true;
      this.populateData();
    }
    this.getDefaultManualAddrList();
  }

  getDefaultManualAddrList(){

    let url = ServerURLS.getAddressBookget.action + '0/10' + '?searchKey='+ '' +'&uniqueCompany=true';
      let promise = this.httpService.getHttpService(url, '');
      promise.then((a)=>{
        if (a['totalPages'] === 0) {
          this.defaultaddressbookdata = [];
 
          return;
        }
       
        this.defaultaddressbookdata = a['response'];
        this.addressData = this.defaultaddressbookdata;
        this.ConsigneeAddressData = this.defaultaddressbookdata;
        this.purchaserAddressData=this.defaultaddressbookdata;
      }).catch((error)=>{
        if (error['status'] === 404) {
         
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      });
  }

  found:any=''
  found2:any=''
  found3:any=''
  selectShipperAddress(e) {
    this.found = '';
    if (e.length > 2) {
      this.found = 'searching...';
      this.addressData = [];
  
      let url = ServerURLS.getAddressBookget.action + '0/10' + '?searchKey='+ e +'&uniqueCompany=true';
      let promise = this.httpService.getHttpService(url, '');
  
      promise.then((a) => {
        // Note: Removed unnecessary second arrow function here
        if (a['totalPages'] === 0) {
          this.addressData = [];
          this.found = 'No data found.';
          return;
        }
  
        this.addressData = a['response'];
        this.found = '';
      }).catch((error) => {
        if (error['status'] === 404) {
          this.found = 'No data found.';
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      });
    }
  
    if (e.length <= 2) {
      this.found = '';
      this.addressData = this.defaultaddressbookdata;
    }
  }
  
  selectConsigneeAddress(e) {
    this.found2 = '';
    if (e.length > 2) {
      this.found2 = 'searching...';
      this.ConsigneeAddressData = [];
      
      let url = ServerURLS.getAddressBookget.action + '0/10' + '?searchKey=' + e + '&uniqueCompany=true';

      let promise = this.httpService.getHttpService(url,'');
      promise.then((a)=>{
        if (a['totalPages'] === 0) {
          this.ConsigneeAddressData = [];
           
          this.found2 = 'No data found.';
          return;
        }
        this.ConsigneeAddressData = a['response'];
        this.found2 = '';
      }).catch((error)=>{
        if (error['status'] === 404) {
          this.found2 = 'No data found.';
           
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      })
    }
    if (e.length <= 2) {
      this.found2 = '';
      this.ConsigneeAddressData = this.defaultaddressbookdata;
    }
  }
  selectPurchaserAddress(e) {
    this.found3 = '';
    if (e.length > 2) {
      this.found3 = 'searching...';
      this.purchaserAddressData = [];

      let url = ServerURLS.getAddressBookget.action + '0/10' + '?searchKey='+ e +'&uniqueCompany=true';
      let promise = this.httpService.getHttpService(url,'');
      promise.then((a)=>{
        if (a['totalPages'] === 0) {
          this.purchaserAddressData = [];
           
          this.found3 = 'No data found.';
          return;
        }
        this.purchaserAddressData = a['response'];
        this.found3 = '';
      }).catch((error)=>{
        if (error['status'] === 404) {
          this.found3 = 'No data found.';
           
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      })
    }
    if (e.length <= 2) {
      this.found3 = '';
      this.purchaserAddressData = this.defaultaddressbookdata;
    }
  }
  getCompanyDetails(i, companyId, flag) {
  
    let url = ServerURLS.getContactDetailsById.action+'?companyId='+companyId;
    let promise = this.httpService.getHttpService(url,'');
    promise.then((res:any)=>{
      let tempObjSet = {
        sourceAddress: {
          companyName:  '',
          latitude: '',
          longitude: '',
          fullAddr: '',
          addrLine1: '',
          addrLine2: '',
          country: '',
          city: '',
          state: '',
          zipCode: '',
          website: '',
          googlePlaceId: ''
        }
      }
      tempObjSet['sourceAddress']['companyName']=res.companyDetails.companyName
      tempObjSet['sourceAddress']['latitude']=res.companyDetails.latitude
      tempObjSet['sourceAddress']['longitude']=res.companyDetails.longitude
      tempObjSet['sourceAddress']['fullAddr']=res.companyDetails.fullAddress
      tempObjSet['sourceAddress']['addrLine1']=res.companyDetails.streetLine1
      tempObjSet['sourceAddress']['addrLine2']=res.companyDetails.streetLine2
      tempObjSet['sourceAddress']['country']=res.companyDetails.country
      tempObjSet['sourceAddress']['city']=res.companyDetails.city
      tempObjSet['sourceAddress']['state']=res.companyDetails.province
      tempObjSet['sourceAddress']['zipCode']=res.companyDetails.zipcode
      tempObjSet['sourceAddress']['website']=res.companyDetails.website
      tempObjSet['sourceAddress']['googlePlaceId']=res.companyDetails.googleMapId
      if (flag == 'shipper') {

        this.dataFromGoogleApiLocationForShipper = tempObjSet;

    this.setDataOfGoogleInShipperContainerField('manual');
    
      }
      else if (flag == 'consignee') {
        this.dataFromGoogleApiLocationForConsignee = tempObjSet;
        this.setDataOfGoogleInCosigneeContainerField('manual');
      }
      else if (flag == 'purchaser') {
        this.dataFromGoogleApiLocationForPurchase = tempObjSet;
        this.setDataOfGoogleInPurchaseField('manual');
      }
    }).catch((error)=>{
      this.toastr.error('Something went wrong.', 'Oops', { timeOut: 10000 });
    })
  }

  imageBlobData:any
  signatureImageBase64:any;
  populateData(){
    this.tranportModeErrorMessage = false;
    this.shipmentModeErrorMessage = false;
    this.relatedNotRelatedErrorMessage = false;
    this.shipperReceiverConsigneeErrorMessage = false;
    this.prePaidNoPrePaidCollectErrorMessage = false;
    let id = sessionStorage.getItem('_admincommercialInvoiceorderId')

//  ********************************************* Edit Mode ***************************************************

    let url = ServerURLS.getComInvData.action+id+'&actionType=ext';

    let promise = this.httpService.getHttpService(url,'');
    promise.then((res:any)=>{
      let obj = res;
      this.customCommInvoiceId = obj.customOrderId;

      this.signatureImageBase64=res.signImageUrl
      this.imageBlobData=  this.base64toBlob(res.signImageUrl)
    
     let test =  res['customBrokerInfo']['email']

      //display the value of transport mode
      obj.transferMode === 'LD' ?
        this.SelectedTransportMode = 1 :
        obj.transferMode === 'AR' ?
          this.SelectedTransportMode = 2 :
          obj.transferMode === 'SA' ?
            this.SelectedTransportMode = 2 : 0

      //display the value of shipment value
      if (obj.shipmentMode == 'Courier') {
        this.selectedShipmentMode = '0a13d82b-f7ee-11e9-8462-163046d26cd4'
        this.setPackageListDropDown('0a13d82b-f7ee-11e9-8462-163046d26cd4')
      } else if (obj.shipmentMode === 'Less than Truckload (LTL)') {
        this.selectedShipmentMode = 'cd6bdba2-e7ae-11e9-8462-163046d26cd4'
        this.setPackageListDropDown('cd6bdba2-e7ae-11e9-8462-163046d26cd4')
      } else if (obj.shipmentMode === 'Volumen (VTL)') {
        this.selectedShipmentMode = 'cd77897d-e7ae-11e9-8462-163046d26cd4'
        this.setPackageListDropDown('cd77897d-e7ae-11e9-8462-163046d26cd4')
      } else if (obj.shipmentMode === 'Full Truckload (FTL)') {
        this.selectedShipmentMode = 'cd99368c-e7ae-11e9-8462-163046d26cd4'
        this.setPackageListDropDown('cd99368c-e7ae-11e9-8462-163046d26cd4')
      }

      //display the valye of country of origin and due date
      this.countryInputPlaceHolder = ''
      this.dataFrom.patchValue({
        countryOfOrigin: obj.countryOrigin,
      })

      const dateParts = obj.dueDate.split('-').map(Number);
      this.modelDueDate={year:parseInt(obj.dueDate.split(' ')[0].split('-')[0]),month:parseInt(obj.dueDate.split(' ')[0].split('-')[1]),day:parseInt(obj.dueDate.split(' ')[0].split('-')[2])}

      this.modelSignDate = {year:parseInt(obj.signDate.split(' ')[0].split('-')[0]),month:parseInt(obj.signDate.split(' ')[0].split('-')[1]),day:parseInt(obj.signDate.split(' ')[0].split('-')[2])}
  
      //display populating the state array of shipper,consignee and purchaser if selected

      this.changeTheStateListAccToCountryCode(obj.shipper.country, 'shipper')
      this.changeTheStateListAccToCountryCode(obj.consignee.country, 'consignee')
      if (!obj.isConsigneeAsPurchaser) {
        this.changeTheStateListAccToCountryCode(obj.consignee.country, 'purchaser')
      }

      //display data of shipper block
      this.shipperStatePlaceHolder = ''
      this.shipperCountryPlaceHolder = ''
      if (obj.shipper.googleMapId === null || obj.shipper.googleMapId === '' || obj.shipper.googleMapId === undefined) {
        this.shipperAddressLine1IsGoogle = false
        this.shipperCheckedBox = true;
        this.dataFrom.get('shipperCity').enable()
        this.dataFrom.get('shipperState').enable()
        this.dataFrom.get('shipperPostalCode').enable()
        this.dataFrom.get('shipperCountry').enable()
      }

      this.dataFrom.patchValue({
        shipperCompanyName: obj.shipper.company,
        shipperAddressLine1: obj.shipper.addrLine1,
        shipperAddressLine2: obj.shipper.addrLine2 ? obj.shipper.addrLine2 : "",
        shipperCity: obj.shipper.city,
        shipperState: obj.shipper.state,
        shipperPostalCode: obj.shipper.zipCode,
        shipperCountry: obj.shipper.country
      })



      //display data of consignee block
      this.consigneeStatePlaceHolder = ''
      this.consigneeCountryPlaceHolder = ''
      if (obj.consignee.googleMapId === null || obj.consignee.googleMapId === '' || obj.consignee.googleMapId === undefined) {
        this.consigeeAddressLine1IsGoogle = false;
        this.consigneeCheckedBox = true
        this.dataFrom.get('consigneeCity').enable();
        this.dataFrom.get('consigneeState').enable();
        this.dataFrom.get('consigneePostalCode').enable();
        this.dataFrom.get('consigneeCountry').enable();
      }

      this.dataFrom.patchValue({
        consigneeCompanyName: obj.consignee.company,
        consigneeAddressLine1: obj.consignee.addrLine1,
        consigneeAddressLine2: obj.consignee.addrLine2,
        consigneeCity: obj.consignee.city,
        consigneeState: obj.consignee.state,
        consigneePostalCode: obj.consignee.zipCode,
        consigneeCountry: obj.consignee.country,
        sameAsPurchaser: obj.isConsigneeAsPurchaser,
        recipientTaxId: obj.recipientsTaxId
      })

      //removing validators from purchaser block is same as purchaser selected
      if (obj.isConsigneeAsPurchaser) {
        this.dataFrom.get('purchaserCompanyName').clearValidators();
        this.dataFrom.get('purchaserAddressLine1').clearValidators();
        this.dataFrom.get('purchaserCity').clearValidators();
        this.dataFrom.get('purchaserState').clearValidators();
        this.dataFrom.get('purchaserPostalCode').clearValidators();
        this.dataFrom.get('purchaserCountry').clearValidators();
        this.dataFrom.get('purchaserFirstName').clearValidators();
        this.dataFrom.get('purchaserLastName').clearValidators();
        this.dataFrom.get('purchaserCompanyName').updateValueAndValidity();
        this.dataFrom.get('purchaserAddressLine1').updateValueAndValidity();
        this.dataFrom.get('purchaserCity').updateValueAndValidity();
        this.dataFrom.get('purchaserState').updateValueAndValidity();
        this.dataFrom.get('purchaserPostalCode').updateValueAndValidity();
        this.dataFrom.get('purchaserCountry').updateValueAndValidity();
        this.dataFrom.get('purchaserFirstName').updateValueAndValidity();
        this.dataFrom.get('purchaserLastName').updateValueAndValidity();
      }else{
        this.purchaserStatePlaceHolder='';
        this.purchaserCountryPlaceHolder=''
        if(obj.purchaser.googleMapId===null || obj.consignee.googleMapId==='' || obj.consignee.googleMapId===undefined){
          this.purchaserAddressLine1IsGoogle=false;
          this.purchaserCheckedBox=true;
          this.dataFrom.get("purchaserCity").enable();
          this.dataFrom.get('purchaserState').enable();
          this.dataFrom.get('purchaserPostalCode').enable();
          this.dataFrom.get('purchaserCountry').enable();
        }
        this.dataFrom.patchValue({
          purchaserCompanyName:obj.purchaser.company,
          purchaserAddressLine1:obj.purchaser.fullAddr,
          purchaserCity:obj.purchaser.city,
          purchaserState:obj.purchaser.state,
          purchaserPostalCode:obj.purchaser.zipCode,
          purchaserCountry:obj.purchaser.country,
          purchaserFirstName:obj.purchaser.firstName,
          purchaserLastName:obj.purchaser.lastName
        })
      }

      //display data in duites and tax consignee block
      this.duitableValue = obj.isDutiable
      this.isDuitable = obj.isDutiable
      if (obj.consigneeTaxes === 'S') {
        this.dataFrom.patchValue({
          duitableShipper: true
        })
      } else if (obj.consigneeTaxes === 'C') {
        this.dataFrom.patchValue({
          duitableConsignee: true
        })
      }

      this.dataFrom.patchValue({
        account: obj.accountNumber,
        SED: obj.sedNumber,
        termOfSale: obj.termOfSale,
        termOfPayment: obj.termOfPayment
      })
      if (obj.freightChargeCollectionMode === 'PI') {
        this.dataFrom.patchValue({
          prePaid: true
        })
      } else if (obj.freightChargeCollectionMode === 'PNI') {
        this.dataFrom.patchValue({
          noprePaid: true
        })
      } else if (obj.freightChargeCollectionMode === 'CL') {
        this.dataFrom.patchValue({
          collect: true
        })
      }

      // diplay data of custom broker block
      this.dataFrom.patchValue({
        searchBroker: obj.customBrokerInfo ? obj.customBrokerInfo.brokerName:'',
        firstName: obj.customBrokerInfo ? obj.customBrokerInfo.firstName:'',
        lastName: obj.customBrokerInfo ? obj.customBrokerInfo.lastName:'',
        email: obj.customBrokerInfo ? obj.customBrokerInfo.email:'',
        countryCode:'US',
        phoneNumber: obj.customBrokerInfo ? obj.customBrokerInfo.phoneNumber:'',
        phoneExt: obj.customBrokerInfo ? obj.customBrokerInfo.extension:'',
      })
      
      if (obj.isPartyRelated) {
        this.dataFrom.patchValue({
          related: true
        })
      } else {
        this.dataFrom.patchValue({
          notrelated: true
        })
      }

      //display data in shipment table
        obj.shipmentItems.forEach((element, index) => {
          this.totalQuantity = this.totalQuantity + element.qty;
          this.totalAmount = this.totalAmount + element.shipmentItemValue;
          <FormArray>this.dataFrom.get('shipmentItems')['controls'].push(
            this.formBuilder.group({
              packageName: [ obj.shipmentMode!='Courier'?  element.packaging:element.crsPackageId, [Validators.required]],
              packagingCode: [this.setPackageCode(element.packaging, index, 'edit')],
              quantity: [element.qty, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
              weight: [element.totalWeight, [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
              width: [element.breadth, [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
              length: [element.length, [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
              height: [element.height, [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
              shipmentValue: [element.shipmentItemValue, [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
              shipmentValueCurrency: [element.shipmentItemValueCurrency],
              class: [element.freightClass],
              nmfc: [element.nmfc],
              stackable: [element.stackable],
              hazmat: [element.hazMat],
              nuNumber: [element.unNumType],
              unNumber: [element.unNumber],
              properShippingName: [element.properShippingName],
              hazardClass: [element.hazardClass],
              packageGroup: [element.packageGroup],
              packageType: [element.hazardPackagingType]
            })
          )
        })
      
      // addSalesMode: ['%'],
      // addSalesValue: ['', [Validators.required, Validators.pattern(/^(?:\d+|\d+\.\d+)$/)]],
      // addFrieghtClassMode: ['%'],
      // addFrieghtChargeValue: ['', [Validators.required, Validators.pattern(/^(?:\d+|\d+\.\d+)$/)]],
      // date: ['', [Validators.required]]
      if (obj.salesTaxIsPercent) {
        this.addSalesTacModeValue = '%'
      } else {
        this.addSalesTacModeValue = ''
      }
      if (obj.freightCostIsPercent) {
        this.addFreightCostValue = '%'
      } else {
        this.addFreightCostValue = ''
      }
      this.dataFrom.patchValue({
        addSalesMode: obj.salesTaxIsPercent ? '%' : '$',
        addSalesValue: obj.salesTaxAmout,
        addFrieghtClassMode: obj.freightCostIsPercent ? '%' : '$',
        addFrieghtChargeValue: obj.freightCost
      })
      this.calculateTotalAmountDueWithSalesTax();
      this.calculateTotalAmountDueWithFeightCharges();
      this.signatureData = {
        'type': obj.signedByRole,
        'name': obj.sgnedBy,
        'signatureValue': obj.signImageUrl
      }
      // this.signDateConfig = {
      //   mode: 'single',
      //   dateFormat: 'm-d-Y',
      //   defaultDate: obj.signDate.split('-')[1] + '-' + obj.signDate.split('-')[2] + '-' + obj.signDate.split('-')[0],
      // };
      this.signatureDate = obj.signDate
      this.dataFrom.get("date").clearValidators();
      this.dataFrom.get('dueDate').clearValidators()
      this.removeShipmentItem(0)
      this.calculdateTotalQuantity()

    })
    
  }

 
  convertBinaryDataToPDF(binaryData, filename) {
    // Create a Blob from the binary data
 
  
    const binaryDat = atob(binaryData);

// Step 2: Create a Blob from the binary data
const blob = new Blob([new Uint8Array(binaryDat.length).map((_, i) => binaryDat.charCodeAt(i))], { type: 'application/pdf' });

// Step 3: Create a URL for the Blob
const blobUrl = URL.createObjectURL(blob);

// Step 4: Create an anchor tag for download
const downloadLink = document.createElement('a');
downloadLink.href = blobUrl;
downloadLink.download = 'document.pdf'; // Set the desired filename

// Step 5: Trigger the download
downloadLink.click();
  }
  
  getFreightClass() {
    let url = ServerURLS.getFreightClass.action;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result) => {
      this.frieghtClassObj = result['freight_info']['data']
    })
  }

  addline(): FormGroup {
    return this.formBuilder.group({
      packageName: ['', [Validators.required]],
      packagingCode: [''],
      quantity: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
      weight: ['', [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
      width: ['', [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
      length: ['', [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
      height: ['', [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
      shipmentValue: ['', [Validators.required, Validators.pattern(/^(?!0*(\.0+)?$)([1-9][0-9]*|0?\.[0-9]+|[1-9][0-9]*\.[0-9]+)$/)]],
      shipmentValueCurrency: ['USD'],
      class: [''],
      nmfc: [''],
      stackable: [''],
      hazmat: [false],
      nuNumber: ['UN'],
      unNumber: [''],
      properShippingName: [''],
      hazardClass: [''],
      packageGroup: [''],
      packageType: ['']
    })
  }
  removePlaceHolder(inputFieldName, countryCode?) {
    if (inputFieldName == 'coo') {
      this.countryInputPlaceHolder = ''
    } else if (inputFieldName === 'shipperState') {
      this.shipperStatePlaceHolder = ''
    } else if (inputFieldName === 'consigneeState') {
      this.consigneeStatePlaceHolder = ''
    } else if (inputFieldName === 'purchaserState') {
      this.purchaserStatePlaceHolder = ''
    } else if (inputFieldName === 'shipperCountry') {
      this.shipperCountryPlaceHolder = ''
      this.changeTheStateListAccToCountryCode(countryCode, 'shipper')
    } else if (inputFieldName === 'consigneeCountry') {
      this.consigneeCountryPlaceHolder = '';
      this.changeTheStateListAccToCountryCode(countryCode, 'consignee')
    } else if (inputFieldName === 'purchaserCountry') {
      this.purchaserCountryPlaceHolder = '';
      this.changeTheStateListAccToCountryCode(countryCode, 'purchaser')
    }
  }
  toggle(type) {
    if (type == 'start') {
      this.pickerStart.flatpickr.toggle()
      this.pickerStart.flatpickr.value = '';
    }
  }
  changeAddSalesTaxMode(value) {
    if (value === '%') {
      this.addSalesTacModeValue = value
    } else {
      this.addSalesTacModeValue = ''
    }
    this.dataFrom.patchValue({
      addSalesValue: ""
    })
    this.calculateTotalAmountDueWithSalesTax();
  }
  changeAddFreightClassMode(value) {
    if (value === '%') {
      this.addFreightCostValue = value
    } else {
      this.addFreightCostValue = ''
    }
    this.dataFrom.patchValue({
      addFrieghtChargeValue: ''
    })
    this.calculateTotalAmountDueWithFeightCharges()
  }

  printForm() {
    // this.sig = new SignaturePad(this.canvas.nativeElement);

    this.imageData = this.sig.toDataURL();

  }
  setDuitableValue(value: boolean) {
    if (value) {
      this.dataFrom.get('duitableShipper').enable();
      this.dataFrom.get('duitableConsignee').enable();
    } 
    
    else {
      const duitableShipperControl = this.dataFrom.get('duitableShipper');
      const duitableConsigneeControl = this.dataFrom.get('duitableConsignee');
      
      if (duitableShipperControl && duitableConsigneeControl) {
        duitableShipperControl.disable();
        duitableShipperControl.setValue('');
    
        duitableConsigneeControl.disable();
        duitableConsigneeControl.setValue('');
      }
      this.dataFrom.get('duitableShipper').updateValueAndValidity();
      this.dataFrom.get('duitableConsignee').updateValueAndValidity();
    }

    console.log(value, "value");
    this.isDuitable = value;

    // Updating validators
    this.dataFrom.get('duitableShipper').updateValueAndValidity();
    this.dataFrom.get('duitableConsignee').updateValueAndValidity();
  }
  setGoogleMapCheckBox(mode, value) {
    if (mode === 'shipper') {
      this.shipperAddressLine1IsGoogle = !value
      if (value) {
        this.dataFrom.get('shipperCity').enable()
        this.dataFrom.get('shipperState').enable()
        this.dataFrom.get('shipperCountry').enable()
        this.dataFrom.get('shipperPostalCode').enable()
      } else {
        this.dataFrom.get('shipperCity').disable()
        this.dataFrom.get('shipperState').disable()
        this.dataFrom.get('shipperCountry').disable()
        this.dataFrom.get('shipperPostalCode').disable()
      }
      this.dataFrom.patchValue({

        shipperAddressLine1: '',
        shipperCity: '',
        shipperState: '',
        shipperCountry: '',
        shipperPostalCode: '',
        shipperAddressLine2: ""
      })
      if (!this.isShipperCompanyNameManual) {
        this.dataFrom.patchValue({
          shipperCompanyName: ""
        })
      }
      this.shipperStatePlaceHolder = 'State / Province';
      this.shipperCountryPlaceHolder = 'Country'
      this.dataFromGoogleApiLocationForShipper = undefined
    } else if (mode === 'consignee') {
      this.consigeeAddressLine1IsGoogle = !value
      if (value) {
        this.dataFrom.get('consigneeCity').enable();
        this.dataFrom.get('consigneeState').enable();
        this.dataFrom.get('consigneePostalCode').enable();
        this.dataFrom.get('consigneeCountry').enable();
      } else {
        this.dataFrom.get('consigneeCity').disable();
        this.dataFrom.get('consigneeState').disable();
        this.dataFrom.get('consigneePostalCode').disable();
        this.dataFrom.get('consigneeCountry').disable();
      }
      this.dataFrom.patchValue({
        consigneeAddressLine1: "",
        consigneeAddressLine2: "",
        consigneeCity: "",
        consigneeState: "",
        consigneePostalCode: "",
        consigneeCountry: ""
      })
      if (!this.isConsigneeCompanyNameManual) {
        this.dataFrom.patchValue({
          consigneeCompanyName: ""
        })
      }
      this.consigneeStatePlaceHolder = 'State / Province';
      this.consigneeCountryPlaceHolder = 'Country';
      this.dataFromGoogleApiLocationForConsignee = undefined
    } else if (mode === 'purchaser') {
      this.purchaserAddressLine1IsGoogle = !value;
      // purchaserCompanyName:[''],
      // purchaserAddressLine1:[''],
      // purchaserAddressLine2:[''],
      // purchaserCity:[''],
      // purchaserState:[''],
      // purchaserPostalCode:[''],
      // purchaserCountry:[''],
      if (value) {
        this.dataFrom.get('purchaserCity').enable();
        this.dataFrom.get('purchaserState').enable();
        this.dataFrom.get('purchaserPostalCode').enable();
        this.dataFrom.get('purchaserCountry').enable();
      } else {
        this.dataFrom.get('purchaserCity').disable();
        this.dataFrom.get('purchaserState').disable();
        this.dataFrom.get('purchaserPostalCode').disable();
        this.dataFrom.get('purchaserCountry').disable();
      }
      this.dataFrom.patchValue({
        purchaserAddressLine1: '',
        purchaserAddressLine2: '',
        purchaserCity: '',
        purchaserState: '',
        purchaserPostalCode: '',
        purchaserCountry: '',
      })
      if (!this.isPurchaserCompanyNameManual) {
        this.dataFrom.patchValue({
          purchaserCompanyName: ''
        })
      }
      this.purchaserStatePlaceHolder = 'State / Province'
      this.purchaserCountryPlaceHolder = 'Country'
    }
  }
  selectTransportMode(id) {

    this.SelectedTransportMode = id
    this.checkTransportModeError()
  }
  selectShipmentMode(id) {
    this.selectedShipmentMode = id
    this.checkShipmentModeError()
    this.setPackageListDropDown(id);
  }
  setPackageListDropDown(id) {
    this.shipmentModeArray.forEach((element, index) => {
      if (element.shippingModeId === id) {
        let arr = []
        if (element.description === 'Courier') {
          element.packagingDetails.forEach((item, index1) => {
            if (item.styleCode != 'cp') {
              arr.push({
                'packageId': item.packagingId,
                'packageName': item.packagingType,
                'packageCode': item.styleCode
              })
            }
          })
        } else {
          if (element.description != 'Full Truckload (TL) Shipment') {
            element.packagingTypeDetails.forEach((item, index2) => {
              if (item.packagingName != 'Custom Package') {
                arr.push({
                  'packageId': item.packagingId,
                  'packageName': item.packagingName,
                  'packageCode': item.packagingCode
                })
              }
            })
          }
        }
        this.packageList = arr
      }
    })
  } 
  setCurrenctForAllTheItems(value){
    this.dataFrom.get('shipmentItems')['controls'].forEach((element,index)=>{
      element.patchValue({
        shipmentValueCurrency:value.toFixed(2)
      })
    })
  }
  signatureDate: any;
  isEditSignatureUpdate:boolean=false;
 
  openSignaturePopUp(mode) {

    let dates = this.commanService.dateFormatter(this.modelDueDate, this.modelSignDate)

     this.dueDate = `${dates[0].year}-${dates[0].month}-${dates[0].date}`;
     this.signDate = `${dates[1].year}-${dates[1].month}-${dates[1].date}`;



    // this.isSignButtonEnable=true
    if (this.isSignButtonEnable) {
      const modelRef = this.modalService.open(SignaturePopUpComponent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'signatureModalPopUp', backdrop: 'static' })
      modelRef.componentInstance.signatureUrl = this.signatureData;
      modelRef.componentInstance.mode = mode
      modelRef.result.then((result) => {
        this.isEditSignatureUpdate=true;
        this.signatureData = result
        this.signatureDate =this.modelSignDate.year+'-'+this.modelSignDate.month+'-'+this.modelSignDate.day;
        this.updateSignatureDate();
      }).catch((result) => {

      })

    } else {
      this.dataFrom.markAllAsTouched();
      if (this.SelectedTransportMode === 0) {
        this.tranportModeErrorMessage = true;
      }
      if (!this.selectedShipmentMode) {
        this.shipmentModeErrorMessage = true;
      }
      if (!this.dataFrom.get('duitableReceiver').value && !this.dataFrom.get('duitableConsignee').value && !this.dataFrom.get('duitableShipper').value) {
        this.shipperReceiverConsigneeErrorMessage = true;
      }
      if (!this.dataFrom.get("prePaid").value && !this.dataFrom.get("noprePaid").value && !this.dataFrom.get("collect").value) {
        this.prePaidNoPrePaidCollectErrorMessage = true;
      }
      if (!this.dataFrom.get("related").value && !this.dataFrom.get("notrelated").value) {
        this.relatedNotRelatedErrorMessage = true;
      }
    }
  }


  updateSignatureDate() {
    this.signatureDate = this.dateFormatter(this.dataFrom.get('date').value[0]);

  }
  closeConfirmationPoPUP() {
    this.modalService.dismissAll();
  }
  setBrokerInformation(brokerId: any) {

    let brokerData = this.brokerList.filter(data => data.brokerId === brokerId)[0];

    this.dataFrom.patchValue({
      firstName: '',
      lastName: '',
      email: brokerData.brokerEmail,
      phoneNumber: brokerData.brokerPhone

    })
  }
  shipmentModeSelectedOrNot: boolean = false;
  shipperReceiverValidOrNot: boolean = false;
  prePaidNoPrePaidValidOrNot: boolean = false;
  relatedNotRelatedValidOrNot: boolean = false;
  transportModeSelectedOrNot:boolean=false;
  submitForm() {
    // console.log(this.dataFrom)
    // let dates = this.commanService.dateFormatter(this.modelFromDate, this.modelExtDate;
    if (!this.dataFrom.valid) {
      this.dataFrom.markAllAsTouched()
    }
    this.shipmentModeSelectedOrNot = this.checkShipmentModeError();
    this.transportModeSelectedOrNot=this.checkTransportModeError();
    this.shipperReceiverValidOrNot = this.checkShipperReciverError();
    this.prePaidNoPrePaidValidOrNot = this.checkPrepaidNoprePaidError();
    this.relatedNotRelatedValidOrNot = this.checkRelatedNotRelatedError();



    if (this.dataFrom.valid && !this.shipmentModeSelectedOrNot && !this.shipperReceiverValidOrNot && !this.prePaidNoPrePaidValidOrNot && !this.relatedNotRelatedValidOrNot && this.signatureData && !this.transportModeSelectedOrNot) {
      this.modalService.open(this.confirmationModal, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'signatureModalPopUp', backdrop: 'static' })
        .result.then((result) => {
          this.displayCourier = true;
            this._loaderService.stringSubject.next(true);
          // let dueDate = new Date(this.dataFrom.get("dueDate").value[0]);
          // let signDate = new Date(this.dataFrom.get('date').value[0])

          // let dueDate = `${dates[0].year}-${dates[0].month}-${dates[0].date}`;
          // let signDate = `${dates[1].year}-${dates[1].month}-${dates[1].date}`;
          let obj = {
            "orderId": "",
            "dueDate":  this.dueDate,
            "shipper": {
              "addressId": "",
              "addressName": null,
              "addrLine1": this.dataFromGoogleApiLocationForShipper ? this.dataFromGoogleApiLocationForShipper.sourceAddress.fullAddr : this.dataFrom.get('shipperAddressLine1').value,
              "addrLine2": this.dataFrom.get('shipperAddressLine2').value ? this.dataFrom.get('shipperAddressLine2').value : "",
              "fullAddr": this.dataFromGoogleApiLocationForShipper ? this.dataFromGoogleApiLocationForShipper.sourceAddress.fullAddr : this.dataFrom.get('shipperAddressLine1').value,
              "city": this.dataFrom.get('shipperCity').value ? this.dataFrom.get('shipperCity').value : '',
              "state": this.dataFrom.get('shipperState').value ? this.dataFrom.get('shipperState').value : '',
              "country": this.dataFrom.get('shipperCountry').value ? this.dataFrom.get('shipperCountry').value : '',
              "zipCode": this.dataFrom.get('shipperPostalCode').value ? this.dataFrom.get('shipperPostalCode').value : '',
              "addrTypeId": "5e83a8cb-df9a-11e9-b6b0-f07959651ad4",
              "company": this.dataFrom.get('shipperCompanyName').value ? this.dataFrom.get('shipperCompanyName').value : '',
              "firstName": "",
              "lastName": "",
              "dept": null,
              "email": "",
              "phoneNumber": "",
              "alternatePhoneNumber": null,
              "fax": null,
              "hasAddrBook": false,
              "latitude": this.dataFromGoogleApiLocationForShipper ? this.dataFromGoogleApiLocationForShipper.sourceAddress.latitude : '',
              "longitude": this.dataFromGoogleApiLocationForShipper ? this.dataFromGoogleApiLocationForShipper.sourceAddress.longitude : '',
              "extraServices": null,
              "hopIndex": 0,
              "pickupDate": null,
              "fromTime": null,
              "toTime": null,
              "companyId": null,
              "googleMapId": this.dataFromGoogleApiLocationForShipper ? this.dataFromGoogleApiLocationForShipper.sourceAddress.googlePlaceId : '',
              "quoteHopsId": null,
              "extension": null
            },
            "consignee": {
              "addressId": "",
              "addressName": null,
              "addrLine1": this.dataFromGoogleApiLocationForConsignee ? this.dataFromGoogleApiLocationForConsignee.sourceAddress.fullAddr : this.dataFrom.get('consigneeAddressLine1').value,
              "addrLine2": this.dataFrom.get('consigneeAddressLine2').value ? this.dataFrom.get('consigneeAddressLine2').value : '',
              "fullAddr": this.dataFromGoogleApiLocationForConsignee ? this.dataFromGoogleApiLocationForConsignee.sourceAddress.fullAddr : this.dataFrom.get('consigneeAddressLine1').value,
              "city": this.dataFrom.get("consigneeCity").value ? this.dataFrom.get('consigneeCity').value : '',
              "state": this.dataFrom.get('consigneeState').value ? this.dataFrom.get("consigneeState").value : "",
              "country": this.dataFrom.get('consigneeCountry').value ? this.dataFrom.get('consigneeCountry').value : "",
              "zipCode": this.dataFrom.get('consigneePostalCode').value ? this.dataFrom.get('consigneePostalCode').value : "",
              "addrTypeId": "5e83a8cb-df9a-11e9-b6b0-f07959651ad4",
              "company": this.dataFrom.get("consigneeCompanyName").value ? this.dataFrom.get("consigneeCompanyName").value : '',
              "firstName": "",
              "lastName": "",
              "dept": null,
              "email": "",
              "phoneNumber": "",
              "alternatePhoneNumber": null,
              "fax": null,
              "hasAddrBook": false,
              "latitude": this.dataFromGoogleApiLocationForConsignee ? this.dataFromGoogleApiLocationForConsignee.sourceAddress.latitude : "",
              "longitude": this.dataFromGoogleApiLocationForConsignee ? this.dataFromGoogleApiLocationForConsignee.sourceAddress.longitude : "",
              "extraServices": null,
              "hopIndex": 0,
              "pickupDate": null,
              "fromTime": null,
              "toTime": null,
              "companyId": null,
              "googleMapId": this.dataFromGoogleApiLocationForConsignee ? this.dataFromGoogleApiLocationForConsignee.sourceAddress.googlePlaceId : '',
              "quoteHopsId": null,
              "extension": null
            },

            "transferMode": this.SelectedTransportMode === 1 ? 'LD' : this.SelectedTransportMode === 2 ? 'AR' : this.SelectedTransportMode === 3 ? 'SA' : "",
            "countryOrigin": this.dataFrom.get('countryOfOrigin').value,
            "customBrokerInfo": {
              "brokerId": "",
              "brokerName": this.dataFrom.get('searchBroker').value ? this.dataFrom.get('searchBroker').value : '',
              "contactId": null,
              "firstName": this.dataFrom.get('firstName').value ? this.dataFrom.get("firstName").value : '',
              "lastName": this.dataFrom.get('lastName').value ? this.dataFrom.get('lastName').value : '',
              "phoneNumber": this.dataFrom.get('phoneNumber').value ? this.dataFrom.get('phoneNumber').value : '',
              "extension": this.dataFrom.get('phoneExt').value ? this.dataFrom.get('phoneExt').value : '',
              "email": this.dataFrom.get('email').value ? this.dataFrom.get('email').value : ''
            },
            'freightCurrency':this.dataFrom.get('shipmentItems')['controls'][0]['controls']['shipmentValueCurrency'].value,
            "salesTaxIsPercent": this.dataFrom.get('addSalesMode').value === '%' ? true : false,
            "salesTaxAmout": this.dataFrom.get('addSalesValue').value,
            "freightCost": this.dataFrom.get('addFrieghtChargeValue').value,
            "freightCostIsPercent": this.dataFrom.get('addFrieghtClassMode').value === '%' ? true : false,
            "isConsigneeAsPurchaser": this.dataFrom.get('sameAsPurchaser').value,
            "isDutiable": this.isDuitable,
            "consigneeTaxes": this.dataFrom.get('duitableShipper').value ? 'S' : this.dataFrom.get("duitableConsignee").value ? "C" : '',
            "recipientsTaxId": this.dataFrom.get('recipientTaxId').value ? this.dataFrom.get('recipientTaxId').value : '',
            "accountNumber": this.dataFrom.get("account").value ? this.dataFrom.get("account").value : "",
            "sedNumber": this.dataFrom.get('SED').value ? this.dataFrom.get('SED').value : '',
            "termOfPayment": this.dataFrom.get('termOfPayment').value ? this.dataFrom.get('termOfPayment').value : "",
            "termOfSale": this.dataFrom.get('termOfSale').value ? this.dataFrom.get('termOfSale').value : "",
            "freightChargeCollectionMode": this.dataFrom.get('prePaid').value ? 'PI' : this.dataFrom.get('noprePaid').value ? 'PNI' : this.dataFrom.get('collect').value ? 'CL' : '',
            "signDate": this.signDate,
            "isPartyRelated": this.dataFrom.get('related').value ? true : this.dataFrom.get("notrelated").value ? false : true,
            "sgnedBy": this.signatureData.name,
            "signedByRole": this.signatureData.type,
            'shipmentMode': this.selectedShipmentMode === '0a13d82b-f7ee-11e9-8462-163046d26cd4' ? 'Courier' : this.selectedShipmentMode === 'cd6bdba2-e7ae-11e9-8462-163046d26cd4' ? 'Less than Truckload (LTL)' : this.selectedShipmentMode === 'cd99368c-e7ae-11e9-8462-163046d26cd4' ? 'Full Truckload (FTL)' : this.selectedShipmentMode === 'cd77897d-e7ae-11e9-8462-163046d26cd4' ? 'Volume (VTL)' : ''
          }

          if (!this.dataFrom.get('sameAsPurchaser').value) {

            obj['purchaser'] = {
              "addressId": "",
              "addressName": null,
              "addrLine1": this.dataFromGoogleApiLocationForPurchase ? this.dataFromGoogleApiLocationForPurchase.sourceAddress.fullAddr : this.dataFrom.get('purchaserAddressLine1').value,
              "addrLine2": this.dataFrom.get('purchaserAddressLine2').value ? this.dataFrom.get('purchaserAddressLine2').value : "",
              "fullAddr": this.dataFromGoogleApiLocationForPurchase ? this.dataFromGoogleApiLocationForPurchase.sourceAddress.fullAddr : this.dataFrom.get('purchaserAddressLine1').value,
              "city": this.dataFrom.get("purchaserCity").value ? this.dataFrom.get("purchaserCity").value : "",
              "state": this.dataFrom.get('purchaserState').value ? this.dataFrom.get('purchaserState').value : "",
              "country": this.dataFrom.get('purchaserCountry').value ? this.dataFrom.get('purchaserCountry').value : "",
              "zipCode": this.dataFrom.get("purchaserPostalCode").value ? this.dataFrom.get('purchaserPostalCode').value : "",
              "addrTypeId": "5e83a8cb-df9a-11e9-b6b0-f07959651ad4",
              "company": this.dataFrom.get('purchaserCompanyName').value ? this.dataFrom.get('purchaserCompanyName').value : '',
              "firstName": this.dataFrom.get('purchaserFirstName').value ? this.dataFrom.get('purchaserFirstName').value : '',
              "lastName": this.dataFrom.get('purchaserLastName').value ? this.dataFrom.get("purchaserLastName").value : "",
              "dept": null,
              "email": "",
              "phoneNumber": "",
              "alternatePhoneNumber": null,
              "fax": null,
              "hasAddrBook": false,
              "latitude": this.dataFromGoogleApiLocationForPurchase ? this.dataFromGoogleApiLocationForPurchase.sourceAddress.latitude : '',
              "longitude": this.dataFromGoogleApiLocationForPurchase ? this.dataFromGoogleApiLocationForPurchase.sourceAddress.longitude : "",
              "extraServices": null,
              "hopIndex": 0,
              "pickupDate": null,
              "fromTime": null,
              "toTime": null,
              "companyId": null,
              "googleMapId": this.dataFromGoogleApiLocationForPurchase ? this.dataFromGoogleApiLocationForPurchase.sourceAddress.googlePlaceId : "",
              "quoteHopsId": null,
              "extension": null
            }
          }
          let shipmenItemArray = [];
          <FormArray>this.dataFrom.get('shipmentItems')['controls'].forEach((element, index) => {
            let obj = {
              "skuId": null,
              "quoteItemId": "",
              "displayOrder": 1,
              "packaging": this.selectedShipmentMode!='0a13d82b-f7ee-11e9-8462-163046d26cd4'? element.value.packageName:"",
              "packagingCode":this.selectedShipmentMode!='0a13d82b-f7ee-11e9-8462-163046d26cd4'?element.value.packagingCode:'',
              "qty": element.value.quantity,
              "totalWeight": element.value.weight,
              "length": element.value.length,
              "breadth": element.value.width,
              "height": element.value.height,
              "unit": this.mesaureMentType === 'metric' ? 'cm' : 'Inch',
              "wghtUnit": this.mesaureMentType === 'metric' ? 'Kg' : 'Lbs',
              "freightClass": element.value.class,
              "lengthType": this.mesaureMentType === 'metric' ? "metric" : "imperial",
              "shipmentItemValueCurrency": element.value.shipmentValueCurrency,
              "description": null,
              "hsTariffNumber": null,
              "shipmentItemValue": element.value.shipmentValue,
              "nmfc": element.value.nmfc,
              "stackable": element.value.stackable,
              "unNumType": element.value.nuNumber,
              "unNumber": element.value.unNumber,
              "properShippingName": element.value.properShippingName,
              "hazardClass": element.value.hazardClass,
              "packageGroup": element.value.packageGroup,
              "hazardPackagingType": element.value.packageType,
              "crsPackageId": this.selectedShipmentMode==='0a13d82b-f7ee-11e9-8462-163046d26cd4'? element.value.packageName:"",
              "crsPackageCode": this.selectedShipmentMode==='0a13d82b-f7ee-11e9-8462-163046d26cd4'?element.value.packagingCode:'',
              "hrsCode": null,
              "hsCode": null,
              "unitPrice": null,
              "eorderItem": null,
              "hazMat": element.value.hazmat,
              "shipSolo": false
            }
            shipmenItemArray.push(obj)
          })
          obj['shipmentItems'] = shipmenItemArray

          const formData = new FormData();
          formData.append('createRequest', JSON.stringify(obj))
          if (!this.isEdit) {
            const byteCharacters = atob(this.signatureData.signatureValue.split(',')[1]);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
            formData.append('signature', blob, 'praful.svg')

          }else{
            formData.append('signature',this.imageBlobData)
          }

          let url = ServerURLS.saveCommercialInvoice.action +'ext';
          let promise =  this.httpService.postFileHttpService(url,formData);
          promise.then((res)=>{
            this.displayCourier = false;
            this._loaderService.stringSubject.next(false);
            this.toastr.success("Commercial Invoice Generated Successfully!");
            this.router.navigateByUrl('shipcarte/commercialInvoiceList')
          }).catch(()=>{
            this.displayCourier = false;
            this._loaderService.stringSubject.next(false);
            this.toastr.error("Something Went Wrong!")
          })

        }, (reason) => {
          "nO"
        })
    } else {
      this.displayCourier = false;
      this._loaderService.stringSubject.next(false);
      this.toastr.error('Please all the required Fields')
    }

      
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  base64toBlob(base64: string) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/octet-stream' });
  }
  dateFormatter(fullDate) {
    let date = fullDate.getDate();
    let month = fullDate.getMonth() + 1;
    let year = fullDate.getFullYear();
    if (date < 10) {
      date = '0' + date;
    }
    if (month < 10) {
      month = '0' + month
    }

    return year + '-' + month + '-' + date


  }
  calculateTotalAmount(value, index) {

    if (value > 0) {
      this.shipmentItemList[index]['totalAmount'] = parseInt(this.shipmentItemList[index]['quantity']) * parseFloat(value);
    } else {
      this.shipmentItemList[index]['totalAmount'] = 0
    }
    this.addTotalAmountOfRows()
  }
  addTotalAmountOfRows() {
    this.totalAmount = 0.00
    this.shipmentItemList.forEach((element) => {
      this.totalAmount = this.totalAmount + (parseFloat(element.totalAmount)* parseInt(element.quantity))
    })
    this.totalAmountDue = this.totalAmount
    this.calculateTotalAmountDueWithSalesTax()
    this.calculateTotalAmountDueWithFeightCharges()
  }
  addSalseTaxAmount: any = 0;
  addFreightClassAmount: any = 0;
  calculateTotalAmountDueWithFeightCharges() {
    let frieghtChargeMode = this.dataFrom.get("addFrieghtClassMode").value;
    let frieghtChargeValue = this.dataFrom.get('addFrieghtChargeValue').value;
    if (frieghtChargeValue > 0) {
      if (frieghtChargeMode === '$') {
        this.addSalseTaxAmount = parseFloat(frieghtChargeValue);
      } else if (frieghtChargeMode === '%') {
        this.addSalseTaxAmount = (((frieghtChargeValue) * (this.totalQuantity*this.totalAmount)) / 100)
      }
    } else {
      this.addSalseTaxAmount = 0
    }

    this.calculateTotalSum()
  }
  calculateTotalAmountDueWithSalesTax() {
    let salesTaxMode = this.dataFrom.get('addSalesMode').value;
    let salesTaxValue = this.dataFrom.get("addSalesValue").value

    if (salesTaxValue > 0) {
      if (salesTaxMode === '$') {
        this.addFreightClassAmount = parseFloat(salesTaxValue);
      } else if (salesTaxMode === '%') {
        this.addFreightClassAmount = ((salesTaxValue * this.totalAmount) / 100)
      }
    } else {
      this.addFreightClassAmount = 0
    }

    this.calculateTotalSum();
  }
  calculateTotalSum() {
    let totalAmountDue = ( this.totalAmount) + this.addSalseTaxAmount + this.addFreightClassAmount;  
    // Format the result to 2 decimal places
    this.totalAmountDue = parseFloat(totalAmountDue.toFixed(2));
  }
  
  goToQuoteList() { }
  checkTransportModeError() {
    if (this.SelectedTransportMode === 0) {
      this.tranportModeErrorMessage = true;
      return true
    } else {
      this.tranportModeErrorMessage = false;
      return false;
    }
  }
  checkShipmentModeError() {
     
    if (this.selectedShipmentMode === '' || this.selectedShipmentMode===undefined) {
      this.shipmentModeErrorMessage = true;
      return true;
    } else {
      this.shipmentModeErrorMessage = false;
      return false;
    }
  }
  checkShipperReciverError(mode?) {
    if (mode === 're') {
      this.dataFrom.patchValue({
        duitableShipper: false,
        duitableConsignee: false
      })
    } else if (mode === 'si') {
      this.dataFrom.patchValue({
        duitableReceiver: false,
        duitableConsignee: false
      })
    } else if (mode === 'co') {
      this.dataFrom.patchValue({
        duitableReceiver: false,
        duitableShipper: false
      })
    }
    let receiver = this.dataFrom.get('duitableReceiver').value;
    let shipper = this.dataFrom.get('duitableShipper').value;
    let consignee = this.dataFrom.get('duitableConsignee').value;
    if (!receiver && !shipper && !consignee) {
      this.shipperReceiverConsigneeErrorMessage = true;
      return true;
    } else {
      this.shipperReceiverConsigneeErrorMessage = false;
      return false;
    }
  }
  checkPrepaidNoprePaidError(mode?) {
    if (mode === 'pre') {
      this.dataFrom.patchValue({
        noprePaid: false,
        collect: false
      })
    } else if (mode === 'noPre') {
      this.dataFrom.patchValue({
        prePaid: false,
        collect: false
      })
    } else if (mode === 'col') {
      this.dataFrom.patchValue({
        prePaid: false,
        noprePaid: false
      })
    }
    let prePaid = this.dataFrom.get('prePaid').value;
    let noPrePaid = this.dataFrom.get('noprePaid').value;
    let collect = this.dataFrom.get('collect').value;
    if (!prePaid && !noPrePaid && !collect) {
      this.prePaidNoPrePaidCollectErrorMessage = true
      return true;
    } else {
      this.prePaidNoPrePaidCollectErrorMessage = false;
      return false;
    }
  }
  checkRelatedNotRelatedError(mode?) {
    if (mode === 're') {
      this.dataFrom.patchValue({
        notrelated: false
      })
    } else if (mode === 'noRe') {
      this.dataFrom.patchValue({
        related: false
      })
    }
    let related = this.dataFrom.get('related').value;
    let notRelated = this.dataFrom.get('notrelated').value;
    if (!related && !notRelated) {
      this.relatedNotRelatedErrorMessage = true;
      return true;
    } else {
      this.relatedNotRelatedErrorMessage = false;
      return false;
    }
  }
  handleAddressChange(address, blockName) {
    this.getzipcodestart(
      address.formatted_address,
      address.address_components,
      address.geometry.location,
      blockName,
      address.name,
      address.place_id
    );
    if (blockName === 'shipper') {
      this.setDataOfGoogleInShipperContainerField(address);
    } else if (blockName === 'consignee') {
      this.setDataOfGoogleInCosigneeContainerField(address);
    } else if (blockName === 'purchaser') {
      this.setDataOfGoogleInPurchaseField(address);
    }
  }
  // getCompanyDetails(i, companyId, flag) {

  //   let getAddressBookById = ServerURLS.getAddressBookById.action + "?companyId=" + companyId;
  //   this.httpService.getHttpService(getAddressBookById, '').then((result) => {
  
  //   }, error => {
    
  //   });
  // }
  searchhAddressInAddressBooK(value, mode) {
  

      let url = ServerURLS.getAddressBookgetquote.action + `0/10/?searchKey=${value}&uniqueCompany=true`;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((result) => {
        if (mode === 'shipper') {
              this.addressBookShipperAddress = result['response']
            }
      })
    
  }
  setDataOfGoogleInShipperContainerField(mode?) {

    let stateArray = this.countryData.success.filter((data) => data.countryCode == this.dataFromGoogleApiLocationForShipper.sourceAddress.country);
    this.shipperStateArray = (stateArray.length > 0 && stateArray[0].provinceState) ? stateArray[0].provinceState : [];
    if (
      (this.dataFromGoogleApiLocationForShipper.sourceAddress.addrLine1.length == 0 &&
        this.dataFromGoogleApiLocationForShipper.sourceAddress.addrLine2.length == 0) ||
      this.dataFromGoogleApiLocationForShipper.sourceAddress.country.length == 0 ||
      this.dataFromGoogleApiLocationForShipper.sourceAddress.city.length == 0 ||
      this.dataFromGoogleApiLocationForShipper.sourceAddress.state.length == 0 ||
      this.dataFromGoogleApiLocationForShipper.sourceAddress.zipCode.length == 0
    ) {
      this.shipperGoogleAddressErrorMessage = true;
    } else {
      this.shipperGoogleAddressErrorMessage = false;
    }
    if(mode=='manual'){
      this.dataFrom.patchValue({
        shipperAddressLine1:this.dataFromGoogleApiLocationForShipper.sourceAddress.addrLine1
      })
    }
    this.dataFrom.patchValue({
      shipperCity: this.dataFromGoogleApiLocationForShipper.sourceAddress.city,
      shipperState: this.dataFromGoogleApiLocationForShipper.sourceAddress.state,
      shipperPostalCode: this.dataFromGoogleApiLocationForShipper.sourceAddress.zipCode,
      shipperCountry: this.dataFromGoogleApiLocationForShipper.sourceAddress.country
    })
    if (!this.isShipperCompanyNameManual) {
      this.dataFrom.patchValue({
        shipperCompanyName: this.dataFromGoogleApiLocationForShipper.sourceAddress.companyName,
      })
    }
    this.shipperStatePlaceHolder = '';
    this.shipperCountryPlaceHolder = ''
  }
  setDataOfGoogleInCosigneeContainerField(mode?) {
    let stateArray = this.countryData.success.filter((data) => data.countryCode == this.dataFromGoogleApiLocationForConsignee.sourceAddress.country);
    this.consigneeStateArray = (stateArray.length > 0 && stateArray[0].provinceState) ? stateArray[0].provinceState : [];
    if (
      (this.dataFromGoogleApiLocationForConsignee.sourceAddress.addrLine1.length == 0 &&
        this.dataFromGoogleApiLocationForConsignee.sourceAddress.addrLine2.length == 0) ||
      this.dataFromGoogleApiLocationForConsignee.sourceAddress.country.length == 0 ||
      this.dataFromGoogleApiLocationForConsignee.sourceAddress.city.length == 0 ||
      this.dataFromGoogleApiLocationForConsignee.sourceAddress.state.length == 0 ||
      this.dataFromGoogleApiLocationForConsignee.sourceAddress.zipCode.length == 0
    ) {
      this.consigneeGoogleAddressErrorMessage = true;
    } else {
      this.consigneeGoogleAddressErrorMessage = false;
    }
    if(mode=='manual'){
      this.dataFrom.patchValue({
        consigneeAddressLine1:this.dataFromGoogleApiLocationForConsignee.sourceAddress.addrLine1
      })
    }
    this.dataFrom.patchValue({
      consigneeCity: this.dataFromGoogleApiLocationForConsignee.sourceAddress.city,
      consigneeState: this.dataFromGoogleApiLocationForConsignee.sourceAddress.state,
      consigneePostalCode: this.dataFromGoogleApiLocationForConsignee.sourceAddress.zipCode,
      consigneeCountry: this.dataFromGoogleApiLocationForConsignee.sourceAddress.country
    })
    if (!this.isConsigneeCompanyNameManual) {
      this.dataFrom.patchValue({
        consigneeCompanyName: this.dataFromGoogleApiLocationForConsignee.sourceAddress.companyName
      })
    }
    this.consigneeStatePlaceHolder = '';
    this.consigneeCountryPlaceHolder = ''
  }
  setDataOfGoogleInPurchaseField(mode?) {
    let stateArray = this.countryData.success.filter((data) => data.countryCode == this.dataFromGoogleApiLocationForPurchase.sourceAddress.country);
    this.purchaserStateArray = (stateArray.length > 0 && stateArray[0].provinceState) ? stateArray[0].provinceState : [];
    if (
      (this.dataFromGoogleApiLocationForPurchase.sourceAddress.addrLine1.length == 0 &&
        this.dataFromGoogleApiLocationForPurchase.sourceAddress.addrLine2.length == 0) ||
      this.dataFromGoogleApiLocationForPurchase.sourceAddress.country.length == 0 ||
      this.dataFromGoogleApiLocationForPurchase.sourceAddress.city.length == 0 ||
      this.dataFromGoogleApiLocationForPurchase.sourceAddress.state.length == 0 ||
      this.dataFromGoogleApiLocationForPurchase.sourceAddress.zipCode.length == 0
    ) {
      this.purchaserGoogleAddressErrorMessage = true;
    } else {
      this.purchaserGoogleAddressErrorMessage = false;
    }
    if(mode=='manual'){
      this.dataFrom.patchValue({
        purchaserAddressLine1:this.dataFromGoogleApiLocationForPurchase.sourceAddress.addrLine1
      })
    }
    this.dataFrom.patchValue({
      purchaserCity: this.dataFromGoogleApiLocationForPurchase.sourceAddress.city,
      purchaserState: this.dataFromGoogleApiLocationForPurchase.sourceAddress.state,
      purchaserPostalCode: this.dataFromGoogleApiLocationForPurchase.sourceAddress.zipCode,
      purchaserCountry: this.dataFromGoogleApiLocationForPurchase.sourceAddress.country
    })
    if (!this.isPurchaserCompanyNameManual) {
      this.dataFrom.patchValue({
        purchaserCompanyName: this.dataFromGoogleApiLocationForPurchase.sourceAddress.companyName
      })
    }
    this.purchaserStatePlaceHolder = '';
    this.purchaserCountryPlaceHolder = ''
  }
  getzipcodestart(formatted_address, address, geometry, factor, companyName, googlePlaceId) {


    let tempObjSet = {
      sourceAddress: {
        companyName: this.stringContainerNumbersOrNot(companyName) ? '' : companyName,
        latitude: '',
        longitude: '',
        fullAddr: '',
        addrLine1: '',
        addrLine2: '',
        country: '',
        city: '',
        state: '',
        zipCode: '',
        website: '',
        googlePlaceId: googlePlaceId
      }
    }

    if (geometry.lat()) {
      tempObjSet['sourceAddress']['latitude'] = geometry.lat();
      tempObjSet['sourceAddress']['longitude'] = geometry.lng();
      tempObjSet['sourceAddress']['fullAddr'] = formatted_address;
    }


    //get city and country and zipCode
    for (let key in address) {
      if (address[key]['types'][0] == 'country') {
        tempObjSet['sourceAddress']['country'] =
          address[key]['short_name'];
      }

      //select city
      if (
        address[key]['types'][0] == 'locality' ||
        address[key]['types'][0] == 'sublocality_level_1'
      ) {
        tempObjSet['sourceAddress']['city'] = address[key]['short_name'];
      }

      //select state
      if (address[key]['types'][0] == 'administrative_area_level_1') {
        tempObjSet['sourceAddress']['state'] = address[key]['short_name'];
      }



      //select postal_code
      if (address[key]['types'][0] == 'postal_code') {
        tempObjSet['sourceAddress']['zipCode'] = address[key]['long_name'];
      }

      //select addressLine1 and 2
      var stepStart = 0;
      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'subpremise'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine1'] =
          address[key]['long_name'];
      }
      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'street_number'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine1'] =
          address[key]['long_name'];
      }

      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'route'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine1'] =
          address[key]['long_name'];
      }

      if (
        tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'route'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine2'] =
          address[key]['long_name'];
      }
      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        !stepStart
      ) {
        //tempObjSet['sourceAddress']['addrLine1'] = formatted_address.split(", ")[0];
        //tempObjSet['sourceAddress']['addrLine2'] = formatted_address.split(", ")[1];
        //tempObjSet['sourceAddress']['addrLine1'] = " ";
        //tempObjSet['sourceAddress']['addrLine2'] = " ";
        // this.showErrorGoogleMap = true;
        // return;
      }

      if (factor == 'shipper') {
        this.dataFromGoogleApiLocationForShipper = tempObjSet;
      }
      else if (factor == 'consignee') {
        this.dataFromGoogleApiLocationForConsignee = tempObjSet;
      }
      else if (factor == 'purchaser') {
        this.dataFromGoogleApiLocationForPurchase = tempObjSet;
      }


    }
  }
  stringContainerNumbersOrNot(name) {
    let num = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    // num.map((element)=>{

    //   if(name.includes(element)){

    //     return true;
    //   }
    // })
    let dataPresent = false;
    for (let element of num) {
      if (name.includes(element)) {
        dataPresent = true;
      }
    }
    return dataPresent

  }
  changeTheStateListAccToCountryCode(countryCode, mode) {
    if (mode === 'shipper') {
      this.dataFrom.patchValue({
        shipperState: ''
      })
      this.shipperStatePlaceHolder = 'State / Province'

      let stateArray = this.countryData.success.filter((data) => data.countryCode == countryCode);
      this.shipperStateArray = (stateArray.length > 0 && stateArray[0].provinceState) ? stateArray[0].provinceState : [];
    } else if (mode === 'consignee') {
      this.dataFrom.patchValue({
        consigneeState: ''
      })
      this.consigneeStatePlaceHolder = 'State / Province';
      let stateArray = this.countryData.success.filter((data) => data.countryCode == countryCode);
      this.consigneeStateArray = (stateArray.length > 0 && stateArray[0].provinceState) ? stateArray[0].provinceState : [];
    } else if (mode === 'purchaser') {
      this.dataFrom.patchValue({
        purchaserState: ""
      })
      this.purchaserStatePlaceHolder = 'State / Province'
      let stateArray = this.countryData.success.filter((data) => data.countryCode == countryCode);
      this.purchaserStateArray = (stateArray.length > 0 && stateArray[0].provinceState) ? stateArray[0].provinceState : []
    }
  }
  setCompanyNameManualOrNot(value, mode) {
    if (mode === 'shipper') {
      if (value != '') {
        this.isShipperCompanyNameManual = true;
      } else {
        this.isShipperCompanyNameManual = false;
      }
    } else if (mode === 'consignee') {
      if (value != '') {
        this.isConsigneeCompanyNameManual = true;
      } else {
        this.isConsigneeCompanyNameManual = false;
      }
    } else if (mode === 'purchaser') {
      if (value != '') {
        this.isPurchaserCompanyNameManual = true;
      } else {
        this.isPurchaserCompanyNameManual = false;
      }
    }
  }
  is: boolean = false
  increaseQuantity(fieldName, index) {
    if (fieldName === 'quantity') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('quantity').value;
      if (value === null || value === undefined || value === '' || value === 0) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          quantity: 1
        })
      } else {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          quantity: parseInt(value) + 1
        })
      }
    } else if (fieldName === 'weight') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('weight').value;
      if (value === null || value === undefined || value === '' || value === 0) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          weight: 1
        })
      } else {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          weight: (parseFloat(value) + 1).toFixed(2)
        })
      }
    } else if (fieldName === 'width') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('width').value;
      if (value === null || value === undefined || value === '' || value === 0) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          width: 1
        })
      } else {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          width: (parseFloat(value) + 1).toFixed(2)
        })
      }
    } else if (fieldName === 'length') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('length').value;
      if (value === null || value === undefined || value === '' || value === 0) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          length: 1
        })
      } else {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          length: (parseFloat(value) + 1).toFixed(2)
        })
      }
    } else if (fieldName === 'height') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('height').value;
      if (value === null || value === undefined || value === '' || value === 0) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          height: 1
        })
      } else {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          height: (parseFloat(value) + 1).toFixed(2)
        })
      }
    }
    this.calculateTotalQuantityAndShipmentValue(fieldName, index)
  }
  decreaseQuantity(fieldName, index) {

    if (fieldName === 'quantity') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('quantity').value;
      if (value === 1) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          quantity: ''
        })
      } else {
        if (value != '' && value > 0 && value != null && value != undefined) {

          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            quantity: parseInt(value) - 1
          })
        }
      }
    } else if (fieldName === 'weight') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('weight').value;
      if (value === 1) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          weight: ''
        })
      } else {
        let data = parseFloat((parseFloat(value) - 1).toFixed(2))
        if (value != '' && value > 0 && value != null && value != undefined) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            weight: data > 0 ? data : ''
          })
        }
      }
    } else if (fieldName === 'width') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('width').value;
      if (value === 1) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          width: ''
        })
      } else {
        let data = parseFloat((parseFloat(value) - 1).toFixed(2));
        if (value !== '' && value > 0 && value != null && value != undefined) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            width: data > 0 ? data : ""
          })
        }
      }
    } else if (fieldName === 'length') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('length').value;
      if (value === 1) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          length: ''
        })
      } else {
        let data = parseFloat((parseFloat(value) - 1).toFixed(2));
        if (value !== '' && value > 0 && value != null && value != undefined) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            length: data > 0 ? data : ""
          })
        }
      }
    } else if (fieldName === 'height') {
      let value = this.dataFrom.get('shipmentItems')['controls'][index].get('height').value;
      if (value === 1) {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          height: ''
        })
      } else {
        let data = parseFloat((parseFloat(value) - 1).toFixed(2));
        if (value !== '' && value > 0 && value != null && value != undefined) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            height: data > 0 ? data : ""
          })
        }
      }
    }
    this.calculateTotalQuantityAndShipmentValue(fieldName, index)
  }
  convertFloatIntoTwoDecimalNumber(fieldName, index) {
     
    let value = this.dataFrom.get('shipmentItems')['controls'][index].get(fieldName).value;
    if (fieldName === 'weight') {
      if (value.includes('.')) {
        if (value.split('.')[1].length > 2) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            weight: parseFloat(value).toFixed(2)
          })
        }
      }
    } else if (fieldName === 'width') {
      if (value.includes('.')) {
        if (value.split('.')[1].length > 2) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            width: parseFloat(value).toFixed(2)
          })
        }
      }
    } else if (fieldName === 'height') {
      if (value.includes('.')) {
        if (value.split('.')[1].length > 2) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            height: parseFloat(value).toFixed(2)
          })
        }
      }
    } else if (fieldName === 'length') {
      if (value.includes('.')) {
        if (value.split('.')[1].length > 2) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            length: parseFloat(value).toFixed(2)
          })
        }
      }
    } 
    else if (fieldName === 'shipmentValue') {
      if (value.includes('.')) {
        if (value.split('.')[1].length > 1) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            shipmentValue: parseFloat(value).toFixed(2)
          })
        }
      }
    }
    this.getFrieghtClassDensity(index);
    this.calculdateTotalQuantity()
  }
  getFrieghtClassDensity(index) {
    let weight = parseFloat(this.dataFrom.get('shipmentItems')['controls'][index].get('weight').value);
    let length = parseFloat(this.dataFrom.get('shipmentItems')['controls'][index].get('length').value);
    let heigth = parseFloat(this.dataFrom.get('shipmentItems')['controls'][index].get('height').value);
    let width = parseFloat(this.dataFrom.get('shipmentItems')['controls'][index].get('width').value);
    if (!length && !width && !heigth) { return }
    if (this.mesaureMentType === 'metric') {
      //cm to inch
      var volume = heigth * (width) * (length );
      weight = weight;
    } else {
      var volume = heigth * width * length;
    }
    var density = weight / (volume / 1728);
    this.freightClassCal(density, index);
  }
  freightClassCal(density, index) {
    if (density >= 30) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[2]['freightType']
      })
    } else if (density >= 22.5 && density < 30) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[3]['freightType']
      })
    } else if (density >= 15 && density < 22.5) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[4]['freightType']
      })
    } else if (density >= 12 && density < 15) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[6]['freightType']
      })
    } else if (density >= 10 && density < 12) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[7]['freightType']
      })
    } else if (density >= 8 && density < 10) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[8]['freightType']
      })
    } else if (density >= 6 && density < 8) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[10]['freightType']
      })
    } else if (density >= 4 && density < 6) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[12]['freightType']
      })
    } else if (density >= 2 && density < 4) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[14]['freightType']
      })
    } else if (density >= 1 && density < 2) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[15]['freightType']
      })
    } else if (density < 1) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        class: this.frieghtClassObj[16]['freightType']
      })
    }
  }
  changeMeasurementType(type) {
    if (type === 'metric') {
      this.mesaureMentType = 'metric'
    } else {
      this.mesaureMentType = 'imperial'
    }
    <FormArray>this.dataFrom.get('shipmentItems')['controls'].forEach((element, index) => {
 
      let quantity=this.dataFrom.get('shipmentItems')['controls'][index].get('quantity').value;
      let length = this.dataFrom.get('shipmentItems')['controls'][index].get('length').value
      let width = this.dataFrom.get('shipmentItems')['controls'][index].get('width').value
      let height = this.dataFrom.get('shipmentItems')['controls'][index].get('height').value
      let weight = this.dataFrom.get('shipmentItems')['controls'][index].get('weight').value
      if (type === 'metric') {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          length: length ? (this.inchToCm(length)).toFixed(2) : ''
        })
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          width: width ? (this.inchToCm(width)).toFixed(2) : ""
        })
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          height: height ? (this.inchToCm(height)).toFixed(2) : ""
        })
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          weight: weight ? (this.lbsToKg(weight)).toFixed(2) : ''
        })
        this.totalWeight=weight?(this.lbsToKg(weight*quantity)).toFixed(2):'0.00'
      } else {
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          length: length ? (this.cmToInch(length)).toFixed(2) : ''
        })
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          width: height ? (this.cmToInch(width)).toFixed(2) : ""
        })
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          height: height ? (this.cmToInch(height)).toFixed(2) : ""
        })
        this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
          weight: weight ? (this.kgToLbs(weight)).toFixed(2) : ""
        })
        this.totalWeight=weight?(this.kgToLbs(weight*quantity)).toFixed(2):'0.00'
      }
    })
  }
  removeDataFromPurchaseBlock(value) {
    this.purchaserAddressLine1IsGoogle = true
    if (value) {
      this.dataFrom.get('purchaserCompanyName').clearValidators();
      this.dataFrom.get('purchaserAddressLine1').clearValidators();
      this.dataFrom.get('purchaserCity').clearValidators();
      this.dataFrom.get('purchaserState').clearValidators();
      this.dataFrom.get('purchaserPostalCode').clearValidators();
      this.dataFrom.get('purchaserCountry').clearValidators();
      this.dataFrom.get('purchaserFirstName').clearValidators();
      this.dataFrom.get('purchaserLastName').clearValidators();
    } else {
      this.dataFrom.get('purchaserCompanyName').setValidators([Validators.required, Validators.pattern(/^[A-Za-z\s]+$/)])
      this.dataFrom.get('purchaserAddressLine1').setValidators([Validators.required])
      this.dataFrom.get('purchaserCity').setValidators([Validators.required, Validators.pattern(/^[A-Za-z\s]+$/)])
      this.dataFrom.get('purchaserState').setValidators([Validators.required])
      this.dataFrom.get('purchaserPostalCode').setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9\s]+$/)])
      this.dataFrom.get('purchaserCountry').setValidators([Validators.required])
      this.dataFrom.get('purchaserFirstName').setValidators([Validators.required])
      this.dataFrom.get('purchaserLastName').setValidators([Validators.required])
    }
    // purchaserCompanyName:['',[Validators.required,Validators.pattern(/^[A-Za-z\s]+$/)]],
    //   purchaserAddressLine1:['',[Validators.required]],
    //   purchaserAddressLine2:[''],
    //   purchaserCity:[{value:'',disabled:this.purchaserAddressLine1IsGoogle},[Validators.required,Validators.pattern(/^[A-Za-z\s]+$/)]],
    //   purchaserState:[{value:'',disabled:this.purchaserAddressLine1IsGoogle},[Validators.required]],
    //   purchaserPostalCode:[{value:'',disabled:this.purchaserAddressLine1IsGoogle},[Validators.required,Validators.pattern(/^[A-Za-z0-9\s]+$/)]],
    //   purchaserCountry:[{value:'',disabled:this.purchaserAddressLine1IsGoogle},[Validators.required]],
    //   purchaserFirstName:['',[Validators.required]],
    //   purchaserLastName:['',[Validators.required]],
    this.dataFrom.patchValue({
      purchaserCompanyName: '',
      purchaserAddressLine1: '',
      purchaserAddressLine2: '',
      purchaserCity: '',
      purchaserState: '',
      purchaserPostalCode: '',
      purchaserCountry: '',
      purchaserFirstName: '',
      purchaserLastName: '',
    })
  }

  totalShipmentShipmentValue: any = 0.00;
  calculateTotalQuantityAndShipmentValue(fieldName, index?) {
    this.convertFloatIntoTwoDecimalNumber(fieldName, index)
 
    this.calculdateTotalQuantity()
    this.calculateTotalAmountDueWithSalesTax()
  }
  calculdateTotalQuantity(){
    this.totalQuantity = 0
    this.totalAmount = 0
    this.totalWeight=0
    this.dataFrom.get('shipmentItems')['controls'].forEach((element, index) => {
      this.totalQuantity = this.totalQuantity + parseInt(element.get('quantity').value ? element.get('quantity').value : 0)
      this.totalAmount = this.totalAmount + parseFloat(element.get('shipmentValue').value ? element.get('shipmentValue').value : 0.00) * parseInt(element.get('quantity').value)
      this.totalWeight=this.totalWeight+(parseFloat(element.get('weight').value ?element.get('weight').value:'0.00')*element.get('quantity').value)
    })

  }
  removeShipmentItem(index) {
    if (this.dataFrom.get('shipmentItems')['controls'].length > 1){
      
      (<FormArray>this.dataFrom.get('shipmentItems')).removeAt(index)
      this.calculdateTotalQuantity()
      this.calculateTotalAmountDueWithSalesTax()
    }   
  }
  cmToInch(cmValue) {
    return cmValue * 0.393701;
  }
  inchToCm(inchValue) {
    return inchValue * 2.54;
  }
  lbsToKg(lbs) {
    return lbs * 0.45359237;
  }
  kgToLbs(kg) {
    return kg * 2.20462262;
  }
  addItemToShipmentList() {
    (<FormArray>this.dataFrom.get('shipmentItems')).push(this.addline())
  }
  setPackageCode(data, index, mode?) {
    if (mode != 'edit') {
      this.packageList.forEach((item, ind) => {
        if (item.packageId === data) {
          this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
            packagingCode: item.packageCode
          })
        }
      })
    } else {
      this.packageList.forEach((item, ind) => {
        if (item.packageId === data) {
          return item.packageCode
        }
      })
    }

  }
  removeValueOfHazmat(value, index) {
    if (!value) {
      this.dataFrom.get('shipmentItems')['controls'][index].patchValue({
        nuNumber: '',
        unNumber: '',
        properShippingName: '',
        hazardClass: '',
        packageGroup: '',
        packageType: ''
      })
      this.dataFrom.controls.shipmentItems['controls'][index].controls.packageGroup.setValidators(null);
      this.dataFrom.controls.shipmentItems['controls'][index].controls.packageGroup.updateValueAndValidity();
      this.dataFrom.controls.shipmentItems['controls'][index].controls.packageType.setValidators(null);
      this.dataFrom.controls.shipmentItems['controls'][index].controls.packageType.updateValueAndValidity();
    }else if(value){
      this.dataFrom.controls.shipmentItems['controls'][index].controls.packageGroup.setValidators([Validators.required]);
      this.dataFrom.controls.shipmentItems['controls'][index].controls.packageType.setValidators([Validators.required]);
    }
  }

  redirectToList(){
    this.router.navigateByUrl('shipcarte/commercialInvoiceList')
  }
  unNumber:any;
  packageGroupOptions = [
    { value: 'na', label: 'N/A' },
    { value: 'i', label: 'I' },
    { value: 'ii', label: 'II' },
    { value: 'iii', label: 'III' },
];

setHazmatDetails(data: any,i:number) {  
  const inputFieldsArray = this.dataFrom.get('shipmentItems') as FormArray;
  const firstFieldGroup = inputFieldsArray.at(i) as FormGroup;

  firstFieldGroup.patchValue({
    properShippingName: data.properShippingNames,
    hazardClass: data.hazardClass,
    packageGroup: data.packageGroup,
  });
}
handleInput(event: Event,i:number) {
  const hazmatUnNumberControl = this.dataFrom.get(`shipmentItems.${i}.unNumber`);

  if (hazmatUnNumberControl) {
      const hazmatUnNumberValue = hazmatUnNumberControl.value as string;

      if (hazmatUnNumberValue.length === 4) {
          this.getHazmatDetails(hazmatUnNumberValue,i);
      }
  }
}
getHazmatDetails(hazmatUnNumberValue: string,i:number) {
  let url = ServerURLS.getHazmatDetails.action + '?un_number=' + hazmatUnNumberValue;
  let promise = this.httpService.getHttpService(url, '');
  promise.then((result)=>{
    this.setHazmatDetails(result,i);
  })
}

}
