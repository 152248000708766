import { Component, Input, OnInit } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-new-custom-package',
  templateUrl: './new-custom-package.component.html',
  styleUrls: ['./new-custom-package.component.scss']
})
export class NewCustomPackageComponent implements OnInit {
  @Input() public shipmentMode;
  @Input() public shipmentModeId;

  selectedShipmentMode='';
  shpmtModeId='';
  shipmentName='';
  selectedPackageType='';
  packageTypeId=''
  boxedSelected:boolean=false;
  PalletSelected:boolean=false;
  crateselected:boolean=false;
  drumsSelected:boolean=false;
  letterSelected:boolean=false;
  parcelSelected:boolean=false;
  packageSelected:boolean=false;
  afterPackageSelected:boolean=false;
  styleCode='';
  customerId:any;
  customDetailsForm : FormGroup;


  constructor(public activeModal: NgbActiveModal ,public formBuilder: FormBuilder, private httpService:ScHttpService) { }

  ngOnInit(): void {

    // Assuming you are inside an object or class
 this.customerId = sessionStorage.getItem('_admincustomerId');

    console.log(this.customerId)
   
    // JSON.parse(
    //   //   localStorage.getItem('loggedInUserDetailsFullData')
    //   // ).loggedInUserId;
    // )  

    this.selectedShipmentMode=this.shipmentMode;
    this.shpmtModeId=this.shipmentModeId;
    
    if(this.shipmentMode=="Courier"){
      this.selectedShipmentMode = "courier"
      this.shipmentName="Courier"
    }
    else if(this.shipmentMode=="LTL" ){
      this.selectedShipmentMode = "truck"
      this.shipmentName="Less Than Truckload (LTL)"
    }
    else if(this.shipmentMode=="Volume VTL" ){
      this.selectedShipmentMode = "truck-ltl";
      this.shipmentName="Volume (VTL)";
    } 

    this.customDetailsForm = this.formBuilder.group({
      measurementUnit:['imperial'],
      PackageName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern("[a-zA-Z][a-zA-Z ]*")]],
      packageDesc:['',[Validators.required, Validators.minLength(2),Validators.maxLength(30),Validators.pattern("[a-zA-Z][a-zA-Z ]*")]],
      length: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      width: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      height: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      weight: ['', [Validators.required,Validators.min(0.001), Validators.max(99999999),Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
    });
  }

  closeModal() { this.activeModal.close(); }
  letter(input,id){
    this.letterSelected=true;
    this.parcelSelected=false;
    this.packageSelected=false;
    this.afterPackageSelected=true;
    this.selectedPackageType =input;
    this.packageTypeId=id;
    this.styleCode='letter';
  }
  parcel(input,id){
    this.letterSelected=false;
    this.parcelSelected=true;
    this.packageSelected=false;
    this.afterPackageSelected=true;
    this.selectedPackageType =input;
    this.packageTypeId=id;
    this.styleCode='parcel';
  }
  package(input,id){
    this.letterSelected=false;
    this.parcelSelected=false;
    this.packageSelected=true;
    this.afterPackageSelected=true;
    this.selectedPackageType =input;
    this.packageTypeId=id;
    this.styleCode='boxed';
  }

  boxed(input,id){
    this.boxedSelected=true;
    this.PalletSelected=false;
    this.crateselected=false;
    this.drumsSelected=false;
    this.afterPackageSelected=true;
    this.selectedPackageType =input;
    this.packageTypeId=id;
    this.styleCode='boxed';
  }
  pallet(input,id){
    this.boxedSelected=false;
    this.PalletSelected=true;
    this.crateselected=false;
    this.drumsSelected=false;
    this.afterPackageSelected=true;
    this.selectedPackageType =input;
    this.packageTypeId=id;
    this.styleCode='pallets';

  }
  crated(input,id){
    this.boxedSelected=false;
    this.PalletSelected=false;
    this.crateselected=true;
    this.drumsSelected=false;
    this.afterPackageSelected=true;
    this.selectedPackageType =input;
    this.packageTypeId=id;
    this.styleCode='crated';


  }
  drums(input,id){
    this.boxedSelected=false;
    this.PalletSelected=false;
    this.crateselected=false;
    this.drumsSelected=true;
    this.afterPackageSelected=true;
    this.selectedPackageType =input;
    this.packageTypeId=id;
    this.styleCode='drums-barrels';

  }
  increamentByOne( measureMentUnit) {
    if (measureMentUnit == "length") {
    ((this.customDetailsForm as FormGroup).get('length').patchValue(parseFloat(((this.customDetailsForm.value.length ? this.customDetailsForm.value.length : 0) + 1).toFixed(2))));
    }
    else if (measureMentUnit == "width") {
    ((this.customDetailsForm as FormGroup).get('width').patchValue(parseFloat(((this.customDetailsForm.value.width ? this.customDetailsForm.value.width : 0) + 1).toFixed(2))));
    }
    else if (measureMentUnit == "height") {
    ((this.customDetailsForm as FormGroup).get('height').patchValue(parseFloat(((this.customDetailsForm.value.height ? this.customDetailsForm.value.height : 0) + 1).toFixed(2))));
    }
    else if (measureMentUnit == "weight") {
    ((this.customDetailsForm as FormGroup).get('weight').patchValue(parseFloat(((this.customDetailsForm.value.weight ? this.customDetailsForm.value.weight : 0) + 1).toFixed(2))));
    }
    }
    decreamentByOne(measureMentUnit) {
      if (measureMentUnit == "length") {
      if (this.customDetailsForm.value.length && this.customDetailsForm.value.length > 1) {
      ((this.customDetailsForm as FormGroup).get('length').patchValue(parseFloat(((this.customDetailsForm.value.length ? this.customDetailsForm.value.length : 0) - 1).toFixed(2))));
      }
      }
      else if (measureMentUnit == "weight") {
        if (this.customDetailsForm.value.weight && this.customDetailsForm.value.weight > 1) {
      ((this.customDetailsForm as FormGroup).get('weight').patchValue(parseFloat(((this.customDetailsForm.value.weight ? this.customDetailsForm.value.weight : 0) - 1).toFixed(2))));
    }
        }
      else if (measureMentUnit == "width") {
        if (this.customDetailsForm.value.width && this.customDetailsForm.value.width > 1) {
          ((this.customDetailsForm as FormGroup).get('width').patchValue(parseFloat(((this.customDetailsForm.value.width ? this.customDetailsForm.value.width : 0) - 1).toFixed(2))));
          }
      }
      else if (measureMentUnit == "height") {
        if (this.customDetailsForm.value.height && this.customDetailsForm.value.height > 1) {
          ((this.customDetailsForm as FormGroup).get('height').patchValue(parseFloat(((this.customDetailsForm.value.height ? this.customDetailsForm.value.height : 0) - 1).toFixed(2))));
          }
      }
     
      }

      onSubmit(){
       
        let inputData={
          customer_id:this.customerId,
          shipment_mode_id:this.shpmtModeId,
          shipment_type_id :this.packageTypeId,
          style_code:this.styleCode,
          package_name : this.customDetailsForm.value.PackageName,
          package_description : this.customDetailsForm.value.packageDesc,
          unit_mode :this.customDetailsForm.value.measurementUnit,          
          package_dimension:{
          length : this.customDetailsForm.value.length,
          width : this.customDetailsForm.value.width,
          height : this.customDetailsForm.value.height,
          weight : this.customDetailsForm.value.weight
          }
        }
                
        let url = ServerURLS.createCustomPacakge.action;
        let promise = this.httpService.postHttpService(url,inputData);
        promise.then((res)=>{
                  this.customDetailsForm.reset();
                  this.activeModal.close({success : true}); 
        })
      }


}
