import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NewCustomPackageComponent } from './new-custom-package/new-custom-package.component';
import { EditCustomPackageComponent } from './edit-custom-package/edit-custom-package.component';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';


@Component({
  selector: 'app-custom-package',
  templateUrl: './custom-package.component.html',
  styleUrls: ['./custom-package.component.scss']
})
export class CustomPackageComponent implements OnInit {

  @Input() public shipmentMode;
  @Input() public spmntModeId;
  @Input() public measurementType;

  checked:boolean[]=[];
  selectedShipmentMode='';
  shipmentModeId='';
  customData:any;
  customPackages=[];
  sortBy: any;
  isDesc: boolean;
  column: any;
  editableCustomPackage=[];
  selectedCustomPackage=[];
  totalElements:any;
  currentPage :number=1;
  perPage:number;
  paginationSize :number;
  searchKey='';
  currentPage1: number;
  test:boolean=false
  checkBoxDis:boolean=false;
  constructor(public activeModal: NgbActiveModal,public config: NgbPaginationConfig, private modalService: NgbModal,
    private toast: ToastrService,public  httpService:ScHttpService) {
    this.config.size = 'sm';
   }

  ngOnInit(): void {
    this.currentPage= 1;
    this.perPage=10;
    this.totalElements = 5;
    this.paginationSize=1
    this.shipmentModeId=this.spmntModeId;
    if(this.shipmentMode=="courier"){
      this.selectedShipmentMode = "Courier"
    }
    else if(this.shipmentMode=="truck" ){
      this.selectedShipmentMode = "LTL"
    }
    else if(this.shipmentMode=="truck-ltl" ){
      this.selectedShipmentMode = "Volume VTL"
    }
    
    this.currentPage1 = this.currentPage-1
   
   this.customPackageList();
   this.sortBy = {
    "packageName": "down",
    "packageType": "down",
    }
  }


  customPackageList(){
    this.customPackages=[];
    let url = ServerURLS.getCustomPackageList.action + this.currentPage1 +"/" + this.perPage +"?shipModeId=" + this.shipmentModeId;
    let promise = this.httpService.getHttpService(url,'');
    promise.then((res)=>{
      this.totalElements = res['totalEntries'];
      this.paginationSize= res['totalPage'];
       // this.customPackages=res['packages'];
       const response = res['packages'].map((item) => { 
        item["isSelected"] = false 
      return item
    })
     this.customPackages = response;
    console.log(this.customPackages)
    });
  }


  closeModal() { this.activeModal.close(); }

  updateSearchKey(searchKey){
    this.searchKey=searchKey;
    // if(searchKey.length>2){
    this.customPackageList();
    // }
  }

  newPackage(){
    const modalRef = this.modalService.open(NewCustomPackageComponent, { size: 'lg', centered: false, backdrop: true,  windowClass:"my-custom-modal13 newCustom"  });
    modalRef.componentInstance.shipmentMode = this.selectedShipmentMode;
    modalRef.componentInstance.shipmentModeId = this.shipmentModeId;
    modalRef.result.then((result) => { 
      if(result && result.success){
        this.customPackageList();
        this.toast.success('Custom package was successfully added to the list.', 'Success', {
          timeOut: 10000,
        });
        // this.activeModal.close({success : true});
      }
      else{
        // this.activeModal.close({success : false});
      }
    });
  }

  checkCheckBoxvalue(event , id , index?,data?){
     
   if(data && index && data.unit_mode == "imperial"){
    const res =  this.customPackages;
    res.map((item) => {
      if(item.unit_mode !== "imperial" ){
        if(event){
          item.isSelected = true;
          this.checked[index] = true  
        }else{
          this.checked[index] = false;
          if(this.selectedCustomPackage.length > 1){
            item.isSelected = true;
          }else{
            item.isSelected = false;
          }
        }
      }
      return item
    })
  }

  if(data && index && data.unit_mode == "metric"){
    const res =  this.customPackages
    res.map((item) => {
      if(item.unit_mode !== "metric" ){
        if(event){
          item.isSelected = true;
          this.checked[index] = true;
        }else{
          this.checked[index] = false;
          if(this.selectedCustomPackage.length > 1){
            item.isSelected = true;
          }else{
            item.isSelected = false;
          }
        }
      }
      return item
    })
  }

    
  //   if(data.unit_mode == "metric"){
  //   if (event) {  
  //     this.checked[index] = true  
  //   } else {  
  //     this.checked[index] = false;   
  //   } 
  // }else{
  //   this.checked[index] = false;
  // }
    
    this.customPackages.filter(element =>{
      if(id==element.package_id){
        if(event == true){
        this.selectedCustomPackage.push(element);
        }
        else{
          var index = this.selectedCustomPackage.indexOf(element);
            if (index > -1) {
                this.selectedCustomPackage.splice(index, 1);
            }
        }
               
      }
    })   
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.sortBy[property] = !this.isDesc ? "down" : "up";
    this.customPackages.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  deleteCustomPackage(id){
  let url = ServerURLS.deleteCustomPackage.action +"?customPackageId="+id;
  let promise = this.httpService.deleteHttpService(url,'');
  promise.then((res)=>{
      this.toast.success('Custom package deleted successfully.', 'Success', {
        timeOut: 10000,
      });
     this.customPackageList();
    })
  }


  editCustomPackage(custompackageId){
    this.editableCustomPackage=[];
    this.customPackages.filter(element =>{
      if(element.package_id == custompackageId){
        this.editableCustomPackage.push(element);
        const modalRef = this.modalService.open(EditCustomPackageComponent, { size: 'lg', centered: false, backdrop: true, windowClass:"my-custom-modal13"  });
          modalRef.componentInstance.editInfo = this.editableCustomPackage;
          modalRef.componentInstance.shipmentMode = this.selectedShipmentMode;
          modalRef.result.then((result) => { 
            if(result && result.success){
            this.customPackageList();
              this.toast.success('Custom package updated successfully.', 'Success', {
                timeOut: 10000,
              });
            }
          });
        }
      })
   }


  perPageItem(event){
    const items = event.target.value;
    this.perPage=items;
    this.customPackageList();
  }

  loadPage(page){
    this.currentPage =page
    this.customPackageList();
  }

  selectCustomPackages(){
    this.activeModal.close(this.selectedCustomPackage); 
  }

}
