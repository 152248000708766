import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, StorageKeys } from '../../../environments/environment';
import { SCToastService } from '../ngb/sctoast.service';
import { Router } from '@angular/router';
import { ScStorageService } from '../storage/sc-storage.service';
import { Server } from 'http';
import { EnvironmentConstants } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ScHttpService {

  private headers = new Headers();
  private multipartHeaders = new Headers();
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      //'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    )

    , withCredentials: false
  };
  constructor(private httpClient: HttpClient, private toastService: SCToastService, private router: Router, private storageService: ScStorageService) {
    this.headers.append('Content-Type', 'application/json');
    this.multipartHeaders.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=gc0p4Jq0M2Yt08jU534c0p');

    if (!environment.production) {
      this.jsonHTTPOptions['withCredentials'] = true;
    }
  }

  isAtLeast30MinutesMore(hour: number, minute: number): boolean {
    const now = new Date();
    const inputTime = new Date();
    inputTime.setHours(hour, minute, 0);

    const diff = inputTime.getTime() - now.getTime();
    return diff >= 30 * 60 * 1000; // Check if the difference is at least 30 minutes (in milliseconds)
  }
  getHttpService(url, data, json?) {

    let promise = new Promise((resolve, reject) => {
      this.httpClient.get(url, this.jsonHTTPOptions).subscribe(

        res => {

          if (res && res["status"] === 401) {


            resolve(res);
            this.router.navigate(['/shipcarte/signin']);
            // this.authService.logout();
          } else {
            resolve(res);
          }
          //this.toastService.show( "Application Error", "Data loaded");
        }, error => {

          if (this.toastService.toasts != undefined) {
            this.toastService.toasts = [];
          }
          this.toastService.show("Application Error", error["message"]);

          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

            resolve(error["error"]);
            this.clearAllStorage();
            this.router.navigate(['/shipcarte/signin']);
          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },
      );
    });
    return promise;
  }

  clearAllStorage() {

    this.storageService.removeBucket("_adminentity");
    this.storageService.removeBucket("_admincurrentUser");
    this.storageService.removeBucket(StorageKeys.LOGGED_USER);
    this.storageService.removeBucket(StorageKeys.LOGGEDIN_ENTITY_TYPE);
    this.storageService.removeBucket("_adminuserName");
    this.storageService.removeBucket("_adminorderMetaInfo");
    this.storageService.removeBucket("_admincompletion-rate");
    this.storageService.removeBucket("_adminapplicationId");

    this.storageService.removeBucket("_adminquote");
    this.storageService.removeBucket("_adminitems");
    this.storageService.removeBucket("_adminshipment");
    this.storageService.removeBucket("_adminquoteService");
    this.storageService.removeBucket("_adminisNet30Customer");
    this.storageService.removeBucket("_adminprofileImage");


  }
  postHttpService(url, data): Promise<any> {

    let promise = new Promise((resolve, reject) => {
      this.httpClient.post(url, JSON.stringify(data), this.jsonHTTPOptions).subscribe(
        res => resolve(res),
        error => {
          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

            reject(error["error"]["data"]);
          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },

      );
    });
    return promise;
  }

  postFileHttpService(url, formdata) {

    let promise = new Promise((resolve, reject) => {
      this.httpClient.post(url, formdata).subscribe(
        res => resolve(res),
        error => {
          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

            reject(error["error"]["data"]);
          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },

      );
    });
    return promise;
  }

  putFileHttpService(url, formdata) {

    let promise = new Promise((resolve, reject) => {
      this.httpClient.put(url, formdata).subscribe(
        res => resolve(res),
        error => {
          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

            reject(error["error"]["data"]);
          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },

      );
    });
    return promise;
  }

  putHttpService(url, data) {

    let promise = new Promise((resolve, reject) => {
      this.httpClient.put(url, JSON.stringify(data), this.jsonHTTPOptions).subscribe(
        res => resolve(res),
        error => {
          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },

      );
    });
    return promise;
  }
  putHttpServicemultipart(url, data) {
    this.jsonHTTPOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data;',
      }
      )

      , withCredentials: false
    };
    let promise = new Promise((resolve, reject) => {
      this.httpClient.put(url, data, this.jsonHTTPOptions).subscribe(
        res => resolve(res),
        error => {
          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },

      );
    });
    return promise;
  }

  deleteHttpService(url, data) {

    this.jsonHTTPOptions['body'] = data;
    let promise = new Promise((resolve, reject) => {
      this.httpClient.delete(url, this.jsonHTTPOptions).subscribe(
        res => resolve(res),
        error => {
          if (error.status === 400) {
            reject(error["error"])
          } else if (error.status === 401) {

          } else if (error.status === 503) {
            error["error"] = { message: "Service Unavailable." };
            reject(error["error"]);
          } else if (error.status === 0) {

            reject(error);
          } else {

            reject(error["error"]);
          }
        },

      );
    });
    return promise;
  }
  getTaxList(url){
    return  this.httpClient.get(url)
  }

  removeLocalData(){
    localStorage.removeItem('setpOneData');
    localStorage.removeItem('probillData')
  }
  // getOrderCardDetailsById(id,tz){
  //   const myheader = new HttpHeaders().set('time-zone', tz);
  //   return this.httpClient.get( "https://shipcarte2.ebizontech.biz/shipcarte-backend/shipcarte/api/quote/getOrderMetaDataById?quoteId=" + id, { withCredentials: true,
  //     headers: myheader
  //    });
  // }
}
