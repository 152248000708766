import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { DocumentService } from 'src/app/services/document.service';
import { PodEmailComponent } from '../pod-email/pod-email.component';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import * as html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-view-pod',
  templateUrl: './view-pod.component.html',
  styleUrls: ['./view-pod.component.scss']
})
export class ViewPodComponent implements OnInit {
  PodData:any;
  carrierEmail:any;
  orderNumber:any;
  sharedData:any;
 
  constructor(private modal : NgbModal,private docService: DocumentService,private toastr: ToastrService,private commanService: ScCommonService, private httpService: ScHttpService,
    private route: Router, ) { 
      this.commanService.currentData.subscribe((data: any) => {
        this.sharedData = data;
      })
    }

  ngOnInit() {
    this.sharedData.appLoader=false;
    this.getData()
    
  }
  
  close() {
    this.modal.dismissAll();
  }
  isDataLoaded: boolean = false;
  getData(){
    this.docService.getData(this.commanService.quoteId).subscribe((result)=>{
    this.PodData=result
    this.carrierEmail= this.PodData.carrierEmail
    this.orderNumber = this.PodData.orderNumber
    this.isDataLoaded = true;
    }, error => {
      this.toastr.error(error['error']["message"],'Please try again.', {
        timeOut: 10000,
      });
    })
  }
  getFormattedDate(): string {
    const currentDate = new Date();
    const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    } as Intl.DateTimeFormatOptions;
    
    const dateString = currentDate.toLocaleDateString('en-US', options);
    const timeString = currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

    return `${dateString} at ${timeString}`;
}
  getFormattedDate1(): string {
    const currentDate = new Date();
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' } as Intl.DateTimeFormatOptions;
    return currentDate.toLocaleDateString('en-US', options);
  }
  async sendEmailToCarrier(modal){
    this.sharedData.appLoader = true;
    await this.savePdf();
    if (modal === 'sendEmail') {   
      
      const modalRef = this.modal.open(PodEmailComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3', keyboard: true });
      // this.convertToPDF();
      modalRef.componentInstance.carrierEmail = this.carrierEmail;
      modalRef.componentInstance.orderNumber= this.orderNumber
      modalRef.componentInstance.savePodFormData = this.savePodFormData;
      this.route.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.result.then((result) => {
        // Handle modal result if needed
      });
    }
  }
  savePodFormData: FormData;

  sendPDFToServer(pdfContent) {

    const formdata: FormData = new FormData();
    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });
    var dummyFile = new File([pdfContent], "POD_request_"+this.PodData.orderNumber + ".pdf");
    formdata.append("podDocumentFile", dummyFile);

    this.savePodFormData = formdata;
    console.log(this.savePodFormData)
    this.sharedData.appLoader = false;
  }
  async savePdf() {
    this.sharedData.appLoader = true;
    var element = document.querySelector('#DownloadPdfData');
    var opt = {
      margin: 0.1,
      filename: "pod_document"+this.PodData.quoteId+".pdf",
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };

    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })
    // console.log(worker);
    this.sendPDFToServer(worker);
  }
  getSum(items: any[], property: string): number {
    if (property !== 'totalWeight') {
      return items.reduce((sum, item) => sum + (parseFloat(item[property]) || 0), 0);
    } else if (property === 'totalWeight') {
      return items.reduce((sum, item) => sum + ((parseFloat(item[property]) || 0) * item['qty']), 0);
    }
    return 0;
  }
}
