import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-view-pod-document',
  templateUrl: './view-pod-document.component.html',
  styleUrls: ['./view-pod-document.component.scss']
})
export class ViewPodDocumentComponent implements OnInit {
  @Input() pdfurl: string;
  sanitizedPdfContent: SafeResourceUrl;
  constructor(public activeModal: NgbActiveModal,   private http: HttpClient, private sanitizer: DomSanitizer,private  _loaderService: LoadingService) { }

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    this.sanitizedPdfContent = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfurl+ '#toolbar=0');
    setTimeout(() => {
    this._loaderService.stringSubject.next(false);
    }, 2500);
  }
  closeModal(): void {
    this.activeModal.close();
  }

}
