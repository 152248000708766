import {
  Component,
  HostListener,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { ScHttpService } from "src/app/services/http/sc-http.service";
import { ServerURLS } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ListDataServiceService } from "src/app/services/list-data-service.service";
import { LoadingService } from "src/app/services/loader.service";

type NewType = void;

@Component({
  selector: "app-sales-reps-list",
  templateUrl: "./sales-reps-list.component.html",
  styleUrls: ["./sales-reps-list.component.scss"],
})
export class SalesRepsComponent implements OnInit {
  @Output() scrollStart = new EventEmitter<any>();
  @ViewChild("loadContent", undefined) loadContent;
  isActive: boolean = false;
  salesRepList: any = [];
  searchkey: string = "";
  page = 0;
  pageSize = 20;

  collectionSize = 0;
  noOfPages: any = 0;
  isDesc: boolean;
  sortBy: any;
  data: any;
  isAscending: boolean = true;
  sortParam: any = "salesRepCustomId";
  ngOnInit(): void {
    localStorage.setItem("_adminreferrer", "salesRep");
    this.listDataService.removeAllOrderListData();
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllDataOfPartnerList();
    this.listDataService.removeAllDataOfQuoteHistory();
    this.listDataService.removeAllDataOfStandardChargeList();
    this.listDataService.removeAllDataOfSettingpackageManageer();
    this.listDataService.removeAllDataSettingUserManagement();
    this.sortBy = {
      salesRepCustomId: "up",
      name: "down",
      email: "down",
      phoneNumber: "down",
      status: "down",
    };

    this.checkDataIsPresentInServiceFileOrNot();
  }
  constructor(
    private route: Router,
    private httpService: ScHttpService,
    private toastr: ToastrService,
    private listDataService: ListDataServiceService,
    private _loaderService: LoadingService
  ) {}
  checkDataIsPresentInServiceFileOrNot() {
    let dataFromServiceFile = this.listDataService.getSalesRepData();
    console.log(dataFromServiceFile);
    if (dataFromServiceFile.dataList.length > 0) {
      this.page = dataFromServiceFile.pageCount;
      this.pageSize = dataFromServiceFile.pageSize;
      this.salesRepList = dataFromServiceFile.dataList;
      if (dataFromServiceFile.searchkey != "") {
        this.searchkey = dataFromServiceFile.searchkey;
      }
      if (dataFromServiceFile.selectedId != "") {
        this.scrollToView(dataFromServiceFile.selectedId);
      }
    } else {
      this.searchkey = "";
      this.listDataService.removeAllSalesRepData();
      this.getListOfSaleRepresentative();
    }
  }
  scrollToView(id) {
    setTimeout(() => {
      let element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }
  isDataLoading: any = "";
  getListOfSaleRepresentative() {
    this.allDataLoaded = false;
    this.isDataLoading = "";
    this._loaderService.stringSubject.next(true);
    let url =
      ServerURLS.getSaleRepresentativeList.action +
      "/" +
      this.page +
      "/" +
      this.pageSize +
      "?isAscending=" +
      this.isAscending;
    if (this.sortParam != "") {
      url = url + "&sortParam=" + this.sortParam;
    }
    if (this.searchkey !== "") {
      url = url + "?searchKey=" + this.searchkey;
    }
    let promise = this.httpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.isDataLoading = "";
        this._loaderService.stringSubject.next(false);
        if (this.page == 0 && res.response.length == 0) {
          this.isDataLoading = "No Data Found!";
          let data = {
            pageCount: this.page,
            pageSize: this.pageSize,
            dataList: [],
          };
          this.listDataService.setAllDataOfSalesRep(data);
        } else {
          this.salesRepList = res.response;
          let data = {
            pageCount: this.page,
            pageSize: this.pageSize,
            dataList: this.salesRepList,
          };
          this.listDataService.setAllDataOfSalesRep(data);
        }
      })
      .catch((err: any) => {
        this._loaderService.stringSubject.next(false);
      });
  }
  redirectToEditGeneralDetails(index) {
    let data = {
      id: this.salesRepList[index].salesRepId,
      name: this.salesRepList[index].salesRepName
        ? this.salesRepList[index].salesRepName
        : "",
      status: this.salesRepList[index].status,
    };
    this.listDataService.setSalesRepId(this.salesRepList[index].salesRepId);
    sessionStorage.setItem("_adminsalesrepsid", JSON.stringify(data));
    this.route.navigateByUrl("shipcarte/salesrep/generalDetails/edit");
  }
  getAllKeysArray() {
    return Object.keys(this.sortBy);
  }
  sort(property: any) {
    this.getAllKeysArray().forEach((element, index) => {
      if (element != property) {
        this.sortBy[element] = "down";
      }
    });
    if (this.sortBy[property] == "up") {
      this.sortBy[property] = "down";
      this.isAscending = false;
      this.sortParam = property;
    } else {
      this.sortBy[property] = "up";
      this.isAscending = true;
      this.sortParam = property;
    }
    this.page = 0;
    // if (this.salesRepList) {
    //   this.isDesc = !this.isDesc; //change the direction
    //   let direction = this.isDesc ? 1 : -1;
    //   this.sortBy[property] = !this.isDesc ? "down" : "up";
    //   console.log(property)
    //   this.salesRepList.sort(function (a, b) {
    //     if (a[property].toLowerCase() < b[property].toLowerCase()) {
    //       return -1 * direction;
    //     }
    //     else if (a[property].toLowerCase() > b[property].toLowerCase()) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }
    this.getListOfSaleRepresentative();
  }
  activateAndDeactivateSaleResprensatative(status, id, index) {
    let url =
      ServerURLS.activeAndInactiveSaleRepresentative.action +
      "?id=" +
      id +
      "&status=" +
      status;
    let promise = this.httpService.putHttpService(url, "");
    promise
      .then((res: any) => {
        if (status === "InActive") {
          this.salesRepList[index]["status"] = "inactive";
          this.toastr.success("Deactivated Successfully");
        } else if (status === "Active") {
          this.salesRepList[index]["status"] = "active";
          this.toastr.success("Activated Successfully");
        }
      })
      .catch((err: any) => {
        this.toastr.error("Something went wrong!");
      });
  }

  timer: any;
  setSearchKey(searchParam) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchParam.length >= 3) {
        this.listDataService.setSalesRepSearchKey(searchParam);
        this.searchkey = searchParam;
        this.page = 0;
        this.getListOfSaleRepresentative();
      } else if (searchParam.length === 0) {
        this.listDataService.setSalesRepSearchKey("");
        this.page = 0;
        this.searchkey = searchParam;
        this.getListOfSaleRepresentative();
      }
    }, 500);
  }
  redirectToAddingSaleReps() {
    sessionStorage.removeItem("_adminsalesrepsid");
    this.route.navigateByUrl("shipcarte/salesrep/generalDetails");
  }
  isLoading: boolean = false;
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false;
  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.scrollHanlderSalesRep(event);
    window.addEventListener("scroll", this.scrollChangeCallback, true);
  }
  allDataLoaded: any = false;
  scrollHanlderSalesRep(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !this.isLoading
      ) {
        this.isLoading = true;
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {
      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    this.page = this.page + 1;
    let url =
      ServerURLS.getSaleRepresentativeList.action +
      "/" +
      this.page +
      "/" +
      this.pageSize +
      "&isAscending=" +
      this.isAscending;
    if (this.sortParam != "") {
      url = url + "&sortParam=" + this.sortParam;
    }
    if (this.searchkey !== "") {
      url = url + "?searchKey=" + this.searchkey;
    }
    let promise = this.httpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.isLoading = false;
        if (res.response.length == 0) {
          this.allDataLoaded = true;
        }
        this.salesRepList = this.salesRepList.concat(res.response);
        let data = {
          pageCount: this.page,
          pageSize: this.pageSize,
          dataList: this.salesRepList,
        };
        this.listDataService.setAllDataOfSalesRep(data);
      })
      .catch((err: any) => {
        this.isLoading = false;
      });
  }
  ngOnDestroy() {
    window.removeEventListener("scroll", this.scrollChangeCallback, true);
  }
}
