import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ScCommonService, UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ServerURLS } from 'src/environments/environment';

@Component({
  selector: 'app-commercial-invoice-list',
  templateUrl: './commercial-invoice-list.component.html',
  styleUrls: ['./commercial-invoice-list.component.scss'],
  providers: [{ provide: NgbDateParserFormatter , useClass: UnitedStatesDateParserFormatter }]
})
export class CommercialInvoiceListComponent implements OnInit {
  customerId: any;
  packageManagerRes: any;
  defaultPageNumber: number = 0;
  defaultListLength: number = 250;
  totalPage:number;
  noDataErr: string;
  apiCall:boolean=false;
  sidebarToggle: boolean;
  loadingCustomPackageOptions: boolean = false;
  totalPagePkgsList: number;
  searchPkgString: string = '';
  sortBy: any;
  isDesc: boolean = false;
  commercialListingData: any=[];
  toDate:string='';
  fromDate:string='';
  modelToDate:NgbDateStruct;
  modelFromDate:NgbDateStruct;
  dateFilter: string;
  orderId: any;

  constructor(
    private router: Router,
    private toasterService: ToastrService,
    private ModelService: NgbModal,
    private httpService:ScHttpService,
    private commenService:ScCommonService,
    private  _loaderService: LoadingService
    
  ) { }

  
  ngOnInit() {
    this.apiCall = false;
    this.sortBy = {
      "extInvoiceNo": "down",
      "createDate": "down",
      "shipperCompanyName": "down",
      "consigneeCompanyName": "down",
      "orderQty": "down",
      "invoiceTotal": "down",
      "dueDate": "down",
      "currency": "down",
      "originCountry": "down",
    }

    this.commercialListing(this.defaultPageNumber,this.defaultListLength,this.searchPkgString,this.dateFilter);
  }

  commercialListing(defaultPageNumber, defaultListLength , searchPkgString,dateFilter){
    this.apiCall = true;
    this._loaderService.stringSubject.next(true);
    let url = ServerURLS.ExtCommercialInvoiceList.action +'/' + defaultPageNumber +'/'+ defaultListLength;
    if (searchPkgString.length > 0) {
      url = url + `?searchKey=${searchPkgString}`
    }
    if (this.modelToDate && this.modelFromDate) {
      let dates = this.commenService.dateFormatter(this.modelFromDate, this.modelToDate)
      let fromDate = `${dates[0].year}-${dates[0].month}-${dates[0].date}`;
      let toDate = `${dates[1].year}-${dates[1].month}-${dates[1].date}`;
      url = url + `?startDate=${fromDate}&endDate=${toDate}`;
    }

    let promise = this.httpService.getHttpService(url,'');
    promise.then((res:any)=>{
      this.commercialListingData = res.response ? res.response :[];
      this.totalPage = res.totalEntries; 
      this.apiCall = false;      
      if (this.commercialListingData.length == 0) {
        this.noDataErr = 'No Data Found';
      }
      this.apiCall = false;
      this._loaderService.stringSubject.next(false);
    }).catch((err)=>{
      this.apiCall = false;
      this._loaderService.stringSubject.next(false);
      this.toasterService.error(`${err.message ? err.message : err.Message ? err.Message : 'Something Went Wrong'}`)
    })
  }

  searchFiterData(searchData){
 // console.log(searchData,'hiii')
    if(searchData.length >= 3){
      this.apiCall = true;
      this.searchPkgString = searchData;
      this.commercialListing(this.defaultPageNumber, this.defaultListLength, this.searchPkgString,this.dateFilter);
    }else if(searchData.length == 0){
      this.searchPkgString = '';
      this.commercialListing(this.defaultPageNumber, this.defaultListLength, this.searchPkgString,this.dateFilter)
    }
  }


  searchKey(){
    if (this.modelFromDate && this.modelToDate) {
      this.commercialListing(this.defaultPageNumber, this.defaultListLength, this.searchPkgString, this.dateFilter);
    } else {
      this.toasterService.error('Please Select Both Dates');
    }
  } 


  onChangeListLength(newDefaultListLength) {
    this.defaultListLength = parseInt(newDefaultListLength);
    this.commercialListing(this.defaultPageNumber, this.defaultListLength, this.searchPkgString,this.dateFilter);
  }

  paginationClick(prevNext: string) {
    if (prevNext == 'previous') {
      if (this.defaultPageNumber > 0) {
        this.defaultPageNumber -= 1;
        this.commercialListing(this.defaultPageNumber, this.defaultListLength, this.searchPkgString,this.dateFilter);
      }
      this.apiCall = false;
    }
    else if (prevNext == 'next') {
      if (this.defaultPageNumber < (this.totalPagePkgsList - 1)) {
        this.defaultPageNumber += 1;
        this.commercialListing(this.defaultPageNumber, this.defaultListLength, this.searchPkgString,this.dateFilter);
      }
    }
  }

  viewFunction(orderId,i){
    this.orderId = orderId;
    sessionStorage.setItem("_admincommercialInvoiceorderId", this.orderId);
    this.router.navigateByUrl('/shipcarte/commercialInvoiceView');
  }

  addNewQuickInvoice(){
    this.router.navigateByUrl('/commercialInvoice');
  }

  editModeFun(order,i){
    sessionStorage.setItem("_admincommercialInvoiceorderId", order);
    this.router.navigateByUrl('commercialInvoice/edit');
  }



  sort(property) {

   // this.isDesc = !this.isDesc; //change the direction

    let direction = this.isDesc ? 1 : -1;

    this.sortBy[property] = !this.isDesc ? "down" : "up";

    this.commercialListingData.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
    this.isDesc = !this.isDesc;
  };

}
