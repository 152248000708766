import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerComponent } from './partner/partner.component';
import { Routes, Router, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import { FormsModule } from '@angular/forms';
import { AuthGuardService } from 'src/app/services/auth/auth-guard.service';
import { SpinnerModule } from 'src/app/spinner/spinner.module';
const routes: Routes = [
  { path: 'shipcarte/partner', component: PartnerComponent,canActivate: [AuthGuardService] },
  { path: 'shipcarte/partner/generalDetails', loadChildren: () => import('./partner-details/partner-detials.module').then(m => m.PartnerDetialsModule),canActivate: [AuthGuardService] }

]

@NgModule({
  declarations: [PartnerComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    NgbModule,
    FormsModule,
    SpinnerModule
  ],
  exports: []
})
export class PartModule { }
