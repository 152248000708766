import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { LoadingService } from 'src/app/services/loader.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-carrier-contract',
  templateUrl: './carrier-contract.component.html',
  styleUrls: ['./carrier-contract.component.scss']
})
export class CarrierContractComponent implements OnInit {

  @Input()CarrierData :any;
  @Input()loginUserData :any;
  @Input()charges :any
  @Input()carrierRateData :any;
  @Input()resultData :any;
  @Input()data :any;
  @Input()ProId :any;
  getPaymentinfoData: any;
  formattedDate:any;
  totalCharges: any = 0;

  constructor( private modal : NgbModal,private  _loaderService: LoadingService, private commonservice: ScCommonService) { }

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    // console.log(this.CarrierData)
    // console.log(this.loginUserData)
    // console.log(this.charges)
    // console.log(this.carrierRateData)
     console.log(this.resultData, "resultData")
     console.log(this.data)
    //  if(this.data.deliveryDate!='--' || this.data.deliveryDate!=''){
    //    this.formattedDate = formatDate(this.data.deliveryDate, 'MM-dd-yyyy', 'en-US');
    //  }
      //console.log("this.data", this.data, this.formattedDate);
    // console.log(this.ProId)
    setTimeout(() => {
      this._loaderService.stringSubject.next(false);
      this.commonservice.getpaymentInfoData$.subscribe(res=>{
        console.log("getpaymentInfoData",res);
        this.getPaymentinfoData = res;
      });
      
    }, 1500);
    
  }

  close(){
this.modal.dismissAll();
  }
  
}
