import { NullTemplateVisitor } from '@angular/compiler';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { CustomValidators } from '../../custom-validators';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  @ViewChild('loadContent', undefined) loadContent;
  @ViewChild('contentWarn', undefined) contentWarn;
  @ViewChild('resetPassword', undefined) resetPassword;
  isEdit: boolean = false;
  imageData: any = null;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  uploadImageShow: any = '';
  showPercentageSign: boolean = false;
  userAddEditForm: FormGroup;
  resetPass: FormGroup;
  userId: any = '';
  userStatus: any = '';
  passwordError: string = '';
  passwordVisible: boolean = true;
  confirmPasswordVisible: boolean = true;
  newPasswordVisible: boolean = true;
  confirmNewPasswordVisible: boolean = true;
  submitClicked: boolean = false;
  userFetchedData: any;
  userCode: string = '';
  customerNames: any;
  displayData = false;
  customerId: string = '';
  // emailNotification:boolean=false
  validationMessages = {

    'newPassword': {
      'required': 'Password is required*',
      'minlength': 'Minimum 4 character required*',
      'maxlength': 'Password limit exceded*',
      'pattern': 'Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character'
    },
    'confirmNewPassword': {
      'required': 'Password is required*',
      'maxlength': 'Password limit exceded*',
      'minlength': 'Minimum 4 character required*',
      'pattern': 'Required Min 8 or Max 20 Characters,one upper and lower case letter, one number and one special character',
      'NoPassswordMatch': 'Password not matched*'
    }
  }

  formErrors = {

    'newPassword': '',
    'confirmNewPassword': '',

  }
  countryData = {
    success: [
      {
        countryPhCode: "+1",
        countryName: "Canada",
        countryCode: "CA",
        provinceState: [
          {
            code: "AB",
            name: "Alberta"
          },
          {
            code: "BC",
            name: "British Columbia"
          },
          {
            code: "MB",
            name: "Manitoba"
          },
          {
            code: "NB",
            name: "New Brunswick"
          },
          {
            code: "NL",
            name: "Newfoundland and Labrador"
          },
          {
            code: "NT",
            name: "Northwest Territories"
          },
          {
            code: "NS",
            name: "Nova Scotia"
          },
          {
            code: "NU",
            name: "Nunavut"
          },
          {
            code: "ON",
            name: "Ontario"
          },
          {
            code: "PE",
            name: "Prince Edward Island"
          },
          {
            code: "QC",
            name: "Quebec"
          },
          {
            code: "SK",
            name: "Saskatchewan"
          },
          {
            code: "YT",
            name: "Yukon"
          }
        ]
      },
      {
        countryPhCode: "+1",
        countryName: "United States",
        countryCode: "US",
        provinceState: [
          {
            code: "AL",
            name: "Alabama"
          },
          {
            code: "AK",
            name: "Alaska"
          },
          {
            code: "AS",
            name: "American Samoa"
          },
          {
            code: "AZ",
            name: "Arizona"
          },
          {
            code: "AR",
            name: "Arkansas"
          },
          {
            code: "CA",
            name: "California"
          },
          {
            code: "CO",
            name: "Colorado"
          },
          {
            code: "CT",
            name: "Connecticut"
          },
          {
            code: "DE",
            name: "Delaware"
          },
          {
            code: "DC",
            name: "District of Columbia"
          },
          {
            code: "FL",
            name: "Florida"
          },
          {
            code: "GA",
            name: "Georgia"
          },
          {
            code: "GU",
            name: "Guam"
          },
          {
            code: "HI",
            name: "Hawaii"
          },
          {
            code: "ID",
            name: "Idaho"
          },
          {
            code: "IL",
            name: "Illinois"
          },
          {
            code: "IN",
            name: "Indiana"
          },
          {
            code: "IA",
            name: "Iowa"
          },
          {
            code: "KS",
            name: "Kansas"
          },
          {
            code: "KY",
            name: "Kentucky"
          },
          {
            code: "LA",
            name: "Louisiana"
          },
          {
            code: "ME",
            name: "Maine"
          },
          {
            code: "MD",
            name: "Maryland"
          },
          {
            code: "MA",
            name: "Massachusetts"
          },
          {
            code: "MI",
            name: "Michigan"
          },
          {
            code: "MN",
            name: "Minnesota"
          },
          {
            code: "MS",
            name: "Mississippi"
          },
          {
            code: "MO",
            name: "Missouri"
          },
          {
            code: "MT",
            name: "Montana"
          },
          {
            code: "NE",
            name: "Nebraska"
          },
          {
            code: "NV",
            name: "Nevada"
          },
          {
            code: "NH",
            name: "New Hampshire"
          },
          {
            code: "NJ",
            name: "New Jersey"
          },
          {
            code: "NM",
            name: "New Mexico"
          },
          {
            code: "NY",
            name: "New York"
          },
          {
            code: "NC",
            name: "North Carolina"
          },
          {
            code: "ND",
            name: "North Dakota"
          },
          {
            code: "OH",
            name: "Ohio"
          },
          {
            code: "OK",
            name: "Oklahoma"
          },
          {
            code: "OR",
            name: "Oregon"
          },
          {
            code: "PA",
            name: "Pennsylvania"
          },
          {
            code: "PR",
            name: "Puerto Rico"
          },
          {
            code: "RI",
            name: "Rhode Island"
          },
          {
            code: "SC",
            name: "South Carolina"
          },
          {
            code: "SD",
            name: "South Dakota"
          },
          {
            code: "TN",
            name: "Tennessee"
          },
          {
            code: "TX",
            name: "Texas"
          },
          {
            code: "VI",
            name: "U.S. Virgin Islands"
          },
          {
            code: "UT",
            name: "Utah"
          },
          {
            code: "VT",
            name: "Vermont"
          },
          {
            code: "VA",
            name: "Virginia"
          },
          {
            code: "WA",
            name: "Washington"
          },
          {
            code: "WV",
            name: "West Virginia"
          },
          {
            code: "WI",
            name: "Wisconsin"
          },
          {
            code: "WY",
            name: "Wyoming"
          }
        ]
      },
    ]
  };
  constructor(
    private route: Router,
    private domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private httpService: ScHttpService,
    private modelService: NgbModal,
    private toastr: ToastrService,
    private listDataService:ListDataServiceService,
    private _loaderService:LoadingService
  ) { }

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    let value = JSON.parse(sessionStorage.getItem('_adminuseridinadminsettings'));
    this.userAddEditForm = this.formBuilder.group({
      emailNotification: [false],
      status: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
      secondName: ['', [Validators.pattern(/^[a-zA-Z]*$/)]],
      username: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/), Validators.minLength(3), CustomValidators.onlySpaceCheckValidator]],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]],
      confirmpassword: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)]],
      currencyCode: ['CA', [Validators.required]],
      phoneNo: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[0-9]*$/)]],
      phoneExt: [''],
      customername: ['', [Validators.required]],
      userrole: ['', [Validators.required]]
    })

    if (value) {
      this.isEdit = true;
      this.userId = value['id'];
      this.userCode = value['userCode']
      this.userStatus = value['status'];
      this.userAddEditForm.get("customername").disable();
      this.resetPass = this.formBuilder.group({
        userId: [{ value: this.userCode, disabled: true }],
        newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]],
        confirmNewPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\|=])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/)]]
      })
      this.resetPass.valueChanges.subscribe((value) => {
        this.logValidationErrors(this.resetPass);
      })
      this.fetchUserDetailsToPopulateDateInFrontEnd();

      // this.getAllCustomerList();
    } else {
      this.isEdit = false;
    }
  }
  logValidationErrors(group: FormGroup = this.resetPass): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key)


      this.formErrors[key] = ''
      if (abstractControl && !abstractControl.valid && ((abstractControl.touched || abstractControl.dirty)) || this.submitClicked) {
        const messages = this.validationMessages[key];
        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            this.formErrors[key] += messages[errorKey] + " ";
          }
        }
      }
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      }
    })
  }
  submitClickedContactShowError() {
    this.logValidationErrors(this.resetPass)
    if (this.resetPass.value.newPassword != this.resetPass.value.confirmNewPassword) {
      this.toastr.error("New password and Confirm password doesn't match", '', {
        timeOut: 3000,
      });
    }
  }
  fetchUserDetailsToPopulateDateInFrontEnd() {
    this.openModel();
    let url = ServerURLS.getDetailsOfUserInAdminSetting.action + '?userId=' + this.userId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.userFetchedData = res;

      this.userAddEditForm.controls['password'].disable();
      this.userAddEditForm.controls["confirmpassword"].disable();
      this.userAddEditForm.patchValue({
        emailNotification: res.emailNotification ? res.emailNotification : "",
        status: res.status ? res.status : "",
        firstName: res.firstName ? res.firstName : "",
        secondName: res.lastName ? res.lastName : "",
        username: res.userName ? res.userName : "",
        password: "",
        // confirmpassword:res.password?res.password:'',
        email: res.emailAddress ? res.emailAddress : "",
        currencyCode: res.phnContryCode ? res.phnContryCode : "",
        phoneNo: res.phoneNumber ? res.phoneNumber : "",
        phoneExt: res.phoneExt ? res.phoneExt : "",
        userrole: res.userRole ? res.userRole : '',
        // customername:res.customerId?res.customerId:''
      })
      this.getCustomerListByName(res.customerName,res.customerId)
    
      this.uploadImageShow = res.image ? res.image : ''
      this.closeModel();
    })
      .catch((err: any) => {
        this.closeModel();
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        this.toastr.error("Something went wrong!")
      })
  }

  toggleShow(value) {
    if (value === 'con') {
      this.showConfirmPassword = !this.showConfirmPassword
    } else if (value === 'pass') {
      this.showPassword = !this.showPassword;

    }
  }
  uploadImage(event): void {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2 && event.target.files[0]) {
        this.uploadImageShow = this.domSanitizer.bypassSecurityTrustUrl(reader.result as string)
        this.imageData = event.target.files[0];
      }
    }
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeImage(): void {
    this.uploadImageShow = ''
    this.imageData = null;
  }
  showPercentageSignInMarkUp(value) {

    if (value === '%') {
      this.showPercentageSign = true;
    } else {
      this.showPercentageSign = false;
    }
  }
  checkPasswordAndConfirmAreSameOrNot() {
    let password = this.userAddEditForm.get('password').value;
    let confirmpassword = this.userAddEditForm.get('confirmpassword').value;

    if (password !== '' && confirmpassword !== '') {
      if (password !== confirmpassword) {
        this.passwordError = 'Both Password Should be Same'
      } else {
        this.passwordError = ''
      }
    }
  }
  saveAndUpdateUserManagement(mode) {

    if (mode === 'save') {
      if (this.userAddEditForm.invalid || this.passwordError !== '') {
        this.toastr.error("Please Fill the required Fields")
        this.userAddEditForm.markAllAsTouched()
      } else {
        this.openModel();
        let generaDetailsData = {
          'user_id': null,
          'customer_id': this.customerId,
          'user_name': this.userAddEditForm.get('username').value ? this.userAddEditForm.get('username').value : '',
          'user_code': null,
          'first_name': this.userAddEditForm.get('firstName').value ? this.userAddEditForm.get('firstName').value : "",
          'last_name': this.userAddEditForm.get('secondName').value ? this.userAddEditForm.get('secondName').value : "",
          "password": this.userAddEditForm.get('password').value ? this.userAddEditForm.get('password').value : "",
          'confirm_password': this.userAddEditForm.get('confirmpassword').value ? this.userAddEditForm.get('confirmpassword').value : "",
          'fax': null,
          'email_address': this.userAddEditForm.get('email').value ? this.userAddEditForm.get('email').value : '',
          'phone_number': this.userAddEditForm.get('phoneNo').value ? this.userAddEditForm.get('phoneNo').value : '',
          'phone_number_ext': this.userAddEditForm.get('phoneExt').value ? this.userAddEditForm.get('phoneExt').value : '',
          'country_code': this.userAddEditForm.get('currencyCode').value ? this.userAddEditForm.get('currencyCode').value : '',
          'status': this.userAddEditForm.get('status').value === 'Active' ? true : false,
          'user_permission': {
            'user_permission_id': '',
            'user_id': '',
            "user_role": this.userAddEditForm.get('userrole').value ? this.userAddEditForm.get('userrole').value : '',
            'enabled': false,
            'is_customer_admin': false,
            'is_user_management': false,
            'view_invoice': false,
            'view_rate': false,
            'view_address_book': false,
            'is_vendor': false,
            "mail_notification_enabled": this.userAddEditForm.get('emailNotification').value ? this.userAddEditForm.get('emailNotification').value : false
          }
        }
        const formData: FormData = new FormData();
        formData.append('userDetails', JSON.stringify(generaDetailsData));
        if (this.imageData) {
          formData.append('userImage', this.imageData);
        }

        let url = ServerURLS.createUserInAdminSetting.action;
        let promise = this.httpService.postFileHttpService(url, formData);
        promise.then((res: any) => {
          this.displayData = false;
          this._loaderService.stringSubject.next(false);
          this.listDataService.removeAllDataSettingUserManagement()
          this.closeModel()


          this.toastr.success("User Added Successfully")

          this.route.navigateByUrl('/shipcarte/settings/userManagement')
        })
          .catch((err) => {
            this.displayData = false;
            this._loaderService.stringSubject.next(false);
            this.closeModel();
            this.toastr.error(`${err.message ? err.message : 'Something went wrong!'}`, '', { timeOut: 3000 })

          })
      }
    } else if (mode === 'update') {
      if (this.userAddEditForm.invalid || this.passwordError !== '') {
        this.toastr.error('Please Fill the required Fields')
        this.userAddEditForm.markAllAsTouched();
      } else {
        this.openModel();
        let generaDetailsData = {
          'user_id': this.userId,
          'user_name': this.userAddEditForm.get('username').value ? this.userAddEditForm.get('username').value : '',
          'user_code': null,
          'first_name': this.userAddEditForm.get('firstName').value ? this.userAddEditForm.get('firstName').value : "",
          'last_name': this.userAddEditForm.get('secondName').value ? this.userAddEditForm.get('secondName').value : "",
          // "password": this.userAddEditForm.get('password').value ? this.userAddEditForm.get('password').value : "",
          // 'confirm_password': this.userAddEditForm.get('confirmpassword').value ? this.userAddEditForm.get('confirmpassword').value : "",
          'fax': null,
          'email_address': this.userAddEditForm.get('email').value ? this.userAddEditForm.get('email').value : '',
          'phone_number': this.userAddEditForm.get('phoneNo').value ? this.userAddEditForm.get('phoneNo').value : '',
          'phone_number_ext': this.userAddEditForm.get('phoneExt').value ? this.userAddEditForm.get('phoneExt').value : '',
          'country_code': this.userAddEditForm.get('currencyCode').value ? this.userAddEditForm.get('currencyCode').value : '',
          'status': this.userAddEditForm.get('status').value === 'Active' ? true : false,
          'user_permission': {
            'user_permission_id': '',
            'user_id': this.userId,
            "user_role": this.userAddEditForm.get('userrole').value ? this.userAddEditForm.get('userrole').value : '',
            'enabled': false,
            'is_customer_admin': false,
            'is_user_management': false,
            'view_invoice': false,
            'view_rate': false,
            'view_address_book': false,
            'is_vendor': false,
            "mail_notification_enabled": this.userAddEditForm.get('emailNotification').value ? this.userAddEditForm.get('emailNotification').value : false
          }
        }
        if (this.uploadImageShow == '') {
          generaDetailsData['is_image_removed'] = true
        }
        const formData: FormData = new FormData();
        formData.append('userDetails', JSON.stringify(generaDetailsData));
        if (this.imageData) {
          formData.append('userImage', this.imageData);
        }
        let url = ServerURLS.updateUserInAdminSetting.action;
        let promise = this.httpService.putFileHttpService(url, formData);
        promise.then((res: any) => {
          this.closeModel();
          this.displayData = false;
          this._loaderService.stringSubject.next(false);
          this.listDataService.removeAllDataSettingUserManagement()
          this.userStatus = this.userAddEditForm.get('status').value;
          sessionStorage.setItem('_adminuseridinadminsettings', JSON.stringify({ 'id': this.userId, 'status': this.userStatus }));
          this.toastr.success("User Updated Successfully");
          this.route.navigateByUrl('/shipcarte/settings/userManagement')

          this.fetchUserDetailsToPopulateDateInFrontEnd();
        })
          .catch((err: any) => {
            this.displayData = false;
            this._loaderService.stringSubject.next(false);
            this.closeModel();
            this.toastr.error(err.message ? err.message : 'Something went wrong', '', { timeOut: 3000 })
          })
      }
    }

  }
  openResetPasswordModel() {
    this.modelService.open(this.resetPassword, { centered: true, backdrop: 'static', backdropClass: 'light-blue-backdrop' }).result.then((result: any) => {
      if (result === 'Save click') {
        this.openModel();
        let resetPassObj = {
          'userId': this.userId,
          "newPassword": this.resetPass.get('newPassword').value
        }
        let resetPassUrl = ServerURLS.resetUserPassword.action;
        let promise = this.httpService.postHttpService(resetPassUrl, resetPassObj);
        promise.then((res: any) => {
          this.displayData = false;
          this._loaderService.stringSubject.next(false);
          this.toastr.success(res.message ? res.message : "User Password has been successfully updated", '', {
            timeOut: 3000
          });
          this.resetPass.get('newPassword').reset();
          this.resetPass.get('confirmNewPassword').reset();
          // this.imageData=null;
          this.closeModel();

        })
          .catch((err: any) => {
            this.displayData = false;
            this._loaderService.stringSubject.next(false);
            this.openResetPasswordModel()
            this.toastr.error(err.message ? err.message : "Something went wrong", '', { timeOut: 3000 })
            this.closeModel();
          })
      }
    }, (err: any) => {
      // this.toastr.error(err.message ? err.message : "Something went wrong!", '', {
      //   timeOut: 3000
      // });
      this.resetPass.get('newPassword').reset();
      this.resetPass.get('confirmNewPassword').reset();
      this.closeModel()
    })
  }
  togglePassword(type) {
    if (type == 'pass') {
      this.passwordVisible = !this.passwordVisible;
    }
    else if (type == 'confirmPass') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
    else if (type == 'newpass') {
      this.newPasswordVisible = !this.newPasswordVisible;
    }
    else if (type == 'confirmNewPass') {
      this.confirmNewPasswordVisible = !this.confirmNewPasswordVisible;
    }
    else if (type == 'newpass') {
      this.newPasswordVisible = !this.newPasswordVisible;
    }
  }
  isEditDone: boolean = false;
  // emailNotification: res.emailNotification ? res.emailNotification : "",
  //       status: res.status ? res.status : "",
  //       firstName: res.firstName ? res.firstName : "",
  //       secondName: res.lastName ? res.lastName : "",
  //       username: res.userName ? res.userName : "",

  //       email: res.emailAddress ? res.emailAddress : "",
  //       currencyCode: res.phnContryCode ? res.phnContryCode : "",
  //       phoneNo: res.phoneNumber ? res.phoneNumber : "",
  //       phoneExt: res.phoneExt ? res.phoneExt : "",
  //       userrole: res.userRole ? res.userRole : ''
  backToUserList() {
    this.isEditDone = false;
    if (!this.isEdit) {
      let data = {
        'emailNotification': false,
        'status': '',
        'firstName': '',
        'secondName': '',
        "username": '',
        'currencyCode': 'CA',
        "phoneNo": '',
        'phoneExt': '',
        "email": '',
        'userrole': '',
      }
      let dataKeys = Object.keys(data);
      dataKeys.map((element, index) => {

        if (data[element] !== this.userAddEditForm.get(element).value) {
          this.isEditDone = true
        }
      })


    } else if (this.isEdit) {

      let data = {
        'emailNotification': !this.userFetchedData['emailNotification'] ? '' : this.userFetchedData['emailNotification'],
        'status': this.userFetchedData['status'] ? 'Active' : '"InActive"',
        'firstName': this.userFetchedData['firstName'],
        'secondName': this.userFetchedData['lastName'],
        "username": this.userFetchedData['userName'],
        'currencyCode': this.userFetchedData['phnContryCode'],
        "phoneNo": this.userFetchedData['phoneNumber'],
        'phoneExt': this.userFetchedData['phoneExt'],
        "email": this.userFetchedData['emailAddress'],
        'userrole': this.userFetchedData['userRole'],
      }

      let dataKeys = Object.keys(data);
      dataKeys.map((element, index) => {

        if (data[element] !== this.userAddEditForm.get(element).value) {
          this.isEditDone = true;
        }
      })
    }


    this.modelService.open(this.contentWarn, { centered: true, backdrop: 'static', backdropClass: 'light-blue-backdrop', windowClass: 'admin-cancel-modal' }).result.then((res: any) => {
      this.route.navigateByUrl('/shipcarte/settings/userManagement')
    }, (reason: any) => {

    })
  }
  getCustomerListByName(value: any,customerId?) {
    let url = ServerURLS.getCustomerListAdmin.action + '/0/50?query=' + value;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.customerNames = res['response'];
      console.log(this.customerNames)
      console.log(customerId)
      if(customerId){
        setTimeout(()=>{
          this.userAddEditForm.patchValue({
            customername:customerId
          })
        },200)
      }
    })
      .catch((err: any) => {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
      })
  }
   
  getCustomerDetailsbyId(value: any) {

    this.customerId = value;
  }
  openModel() {
    // this.modelService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
  }
  closeModel() {
    this.modelService.dismissAll();
  }
}
