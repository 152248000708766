import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { Permission } from '../../commons/pojo/permission';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ScCommonService } from '../../../services/common/sc-common.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-customer-list',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

  customerList: any = [];
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;
  sortBy: any;
  permission: Permission;
  errorMessage: string;
  resetSuccessMsg: any;
  loadData: any;

  closeResult = '';
  customerId: any;
  //status:any;


  approvalForm: FormGroup;
  errMsg: any;
  successMsg: string;

  isCustApproved: boolean = false;
  removedDataWhenClick: boolean = true;
  customers = [
    { id: 1, customerType: "Customer List" },
    { id: 2, customerType: "Pending Customers" },
    { id: 3, customerType: "Active Customers" },
    { id: 4, customerType: "Inactive Customers" }
  ]
  @ViewChild('loadContent', undefined) loadContent;
  selectedCustomerType: number = 1;
  isDesc: boolean;
  searchKey: string = '';
  queryString: string = '';
  filterKey: string = '';

  constructor(private scHttpService: ScHttpService, private modalService: NgbModal, private _formBuilder: FormBuilder, private router: Router, private commonService: ScCommonService, private activatedRoute: ActivatedRoute, private listDataService: ListDataServiceService,private  _loaderService: LoadingService) {

    this.permission = new Permission();

    this.permission.write = true;
    this.permission.read = true;
    this.permission.action = true;

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['status'] === 'Inactive') {
        this.filterKey = params['status'];
        this.selectedCustomerType = 4;
        console.log("kjdmds",this.selectedCustomerType)
      } else if (params['status'] === 'Active') {
        this.filterKey = params['status'];
        this.selectedCustomerType = 3;
      }
   });

  
  }

  ngOnInit() {
    if(localStorage.getItem('_adminreferrer')!='assoc_cust'){
      localStorage.setItem('_adminreferrer','customer');
    }
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfPartnerList()
    this.listDataService.removeAllDataOfQuoteHistory()
    this.listDataService.removeAllDataOfStandardChargeList();
    this.listDataService.removeAllDataOfSettingpackageManageer();
    this.listDataService.removeAllDataSettingUserManagement();
    this.listDataService.removeAllDataOfInvoiceList();
    this.listDataService.removeAllDataOfToAuditInvoiceList();
    this.listDataService.removeAllDataOfToInvoiceList();
    this.listDataService.removeAllDataOfToContractProcessingList();
    this.listDataService.removeAllSalesRepData();

    this.sortBy = {
      "companyName": "up",
    }
    this.loadFormData();
 
    this.checkDataIsPresentInServiceFileOrNot();
  }

  getFormData() {
    this.isDataLoading = ''
    // this.loadCotent(this.loadContent);
    let url2 = ServerURLS.getCreateCustomerForm.action;
    let promise2 = this.scHttpService.getHttpService(url2, '');
    promise2.then((res: any) => {
      this.loadData = res;
      this.listDataService.setDataOfFormDataOfCustomerList(res)
    }).catch((error) => {
      this.isDataLoading = 'Something went Wrong! Please try Again Later.'
    })
  }
  isGetFromDataLoadingAgain: boolean = false;

  checkDataIsPresentInServiceFileOrNot() {
    let dataFromServiceFile = this.listDataService.getAllDataOfCustomerList();
    console.log(dataFromServiceFile);
    if (dataFromServiceFile.formData) {
      this.loadData = dataFromServiceFile.formData
      console.log(this.loadData)
    } else {
      this.getFormData()
    }

    if (dataFromServiceFile.tabId > 0) {
      this.selectedCustomerType = dataFromServiceFile.tabId
      if (this.selectedCustomerType == 2) {
        this.filterKey = 'Pending'
      } else if (this.selectedCustomerType == 3) {
        this.filterKey = 'Active'
      } else if (this.selectedCustomerType == 4) {
        this.filterKey = 'Inactive'
      } else {
        this.filterKey = ''
      }
      if (dataFromServiceFile.tabId == 1) {
        if (dataFromServiceFile.allCustomerList.dataList.length > 0) {
          this.isDataLoading = ''
          this.page = dataFromServiceFile.allCustomerList.pageCount;
          this.pageSize = dataFromServiceFile.allCustomerList.pageSize;
          this.customerList = dataFromServiceFile.allCustomerList.dataList;
          if (dataFromServiceFile.allCustomerList.searchkey != '') {
            this.searchKey = dataFromServiceFile.allCustomerList.searchkey
          }
          if (dataFromServiceFile.allCustomerList.filterKey != '') {
            this.filterKey = dataFromServiceFile.allCustomerList.filterKey
          }
          if (dataFromServiceFile.allCustomerList.selectedId != '') {
            this.scrollToViewMethod(dataFromServiceFile.tabId, dataFromServiceFile.allCustomerList.selectedId)
          }
        } else {
          this.searchKey = ''
          this.loadCustomerList(this.page,this.pageSize)
        }
        this.getAllKeyOfSortByArray().forEach((element,index)=>{
          this.sortBy[element]='up'
        })
        this.isAscending=dataFromServiceFile.allCustomerList.isAscending;
          if(dataFromServiceFile.allCustomerList.sortParam!=''){
            this.sortParam=dataFromServiceFile.allCustomerList.sortParam;
            this.sortBy[dataFromServiceFile.allCustomerList.sortParam]=this.isAscending?'up':'down'
          }else{
            this.sortBy['companyName']='up';
          }
      } else if (dataFromServiceFile.tabId == 2) {
        if (dataFromServiceFile.pendingCustomerList.dataList.length > 0) {
          this.isDataLoading = '';
          this.page = dataFromServiceFile.pendingCustomerList.pageCount;
          this.pageSize = dataFromServiceFile.pendingCustomerList.pageSize;
          this.customerList = dataFromServiceFile.pendingCustomerList.dataList
          if (dataFromServiceFile.pendingCustomerList.searchkey != '') {
            this.searchKey = dataFromServiceFile.pendingCustomerList.searchkey
          }
          if (dataFromServiceFile.pendingCustomerList.selectedId != '') {
            this.scrollToViewMethod(dataFromServiceFile.tabId, dataFromServiceFile.pendingCustomerList.selectedId)
          }
        } else {
          this.searchKey = ''
          this.loadCustomerList(this.page,this.pageSize);
        }
        this.getAllKeyOfSortByArray().forEach((element,index)=>{
          this.sortBy[element]='up'
        })
        this.isAscending=dataFromServiceFile.pendingCustomerList.isAscending;
          if(dataFromServiceFile.pendingCustomerList.sortParam!=''){
            this.sortParam=dataFromServiceFile.pendingCustomerList.sortParam;
            this.sortBy[dataFromServiceFile.pendingCustomerList.sortParam]=this.isAscending?'up':'down'
          }else{
            this.sortBy['companyName']='up';
          }
      } else if (dataFromServiceFile.tabId == 3) {
        if (dataFromServiceFile.activeCustomerList.dataList.length > 0) {
          this.page = dataFromServiceFile.activeCustomerList.pageCount;
          this.pageSize = dataFromServiceFile.activeCustomerList.pageSize;
          this.customerList = dataFromServiceFile.activeCustomerList.dataList
          if (dataFromServiceFile.activeCustomerList.searchkey != '') {
            this.searchKey = dataFromServiceFile.activeCustomerList.searchkey
          }
          if (dataFromServiceFile.activeCustomerList.selectedId != '') {
            this.scrollToViewMethod(dataFromServiceFile.tabId, dataFromServiceFile.activeCustomerList.selectedId)
          }
        } else {
          this.searchKey = ''
          this.loadCustomerList(this.page,this.pageSize);
        }
        this.isAscending=dataFromServiceFile.activeCustomerList.isAscending;
          if(dataFromServiceFile.activeCustomerList.sortParam!=''){
            this.sortParam=dataFromServiceFile.activeCustomerList.sortParam;
            this.sortBy[dataFromServiceFile.activeCustomerList.sortParam]=this.isAscending?'up':'down'
          }else{
            this.sortBy['companyName']='up';
          }
      } else if (dataFromServiceFile.tabId == 4) {
        if (dataFromServiceFile.inactiveCustomerList.dataList.length > 0) {
          this.page = dataFromServiceFile.inactiveCustomerList.pageCount;
          this.pageSize = dataFromServiceFile.inactiveCustomerList.pageSize;
          this.customerList = dataFromServiceFile.inactiveCustomerList.dataList
          if (dataFromServiceFile.inactiveCustomerList.searchkey != '') {
            this.searchKey = dataFromServiceFile.inactiveCustomerList.searchkey
          }
          if (dataFromServiceFile.inactiveCustomerList.selectedId != '') {
            this.scrollToViewMethod(dataFromServiceFile.tabId, dataFromServiceFile.inactiveCustomerList.selectedId)
          }
        } else {
          this.searchKey = ''
          this.loadCustomerList(this.page,this.pageSize)
        }
        this.isAscending=dataFromServiceFile.inactiveCustomerList.isAscending;
          if(dataFromServiceFile.inactiveCustomerList.sortParam!=''){
            this.sortParam=dataFromServiceFile.inactiveCustomerList.sortParam;
            this.sortBy[dataFromServiceFile.inactiveCustomerList.sortParam]=this.isAscending?'up':'down'
          }else{
            this.sortBy['companyName']='up';
          }
      }

    } else {
      this.loadCustomerList(this.page,this.pageSize)
    }
  }
  getAllKeyOfSortByArray(){
    return Object.keys(this.sortBy)
  }
  scrollToViewMethod(tabId: any, selectedItemId: any) {
    setTimeout(() => {
      let element;
      if (tabId == 1) {
        element = document.getElementById(selectedItemId);
      } else if (tabId == 2) {
        element = document.getElementById(selectedItemId);

      } else if (tabId == 3) {
        element = document.getElementById(selectedItemId);

      } else if (tabId == 4) {
        element = document.getElementById(selectedItemId);

      }

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 500)
  }
  ngOnDestroy(): void {
    if (this.removedDataWhenClick) {
      this.listDataService.removeAllDataOfCustomersList();
    }
  }
  loadFormData() {
    this.approvalForm = this._formBuilder.group({
      comment: ['', Validators.required],
      approvalStatus: [],
      custId: [this.customerId]
    });
  }

  get f() { return this.approvalForm.controls; }
 
  getBusinessTypeName(businessTypeId) {
    if (businessTypeId && businessTypeId != undefined) {
      let findBusinessType = this.loadData.business_type.filter((dataBusiness) => dataBusiness.business_id == businessTypeId);
      if (findBusinessType.length > 0) {
        return findBusinessType[0].business_type;
      } else {
        return '- -';
      }
    } else {
      return '- -';
    }
  }

  page = 0;
  pageSize = 20
  collectionSize = 5;
  isDashBoardView: boolean = false;
  isDataLoading: any = ''
  isAscending:boolean=true
  sortParam:any=''
  loadCustomerList(page,pageSize): void {
    this.allDataLoaded=false;
    this.isDataLoading = ''
    this._loaderService.stringSubject.next(true);
    let fromDate = this.activatedRoute.snapshot.paramMap.get('fromDate');
    let toDate = this.activatedRoute.snapshot.paramMap.get('toDate');
     let queryString = '';

// Check if dashboard view
this.isDashBoardView = !!(fromDate && toDate);

// Construct query string based on conditions
if (fromDate && toDate) {
  queryString = `?startDate=${fromDate}&endDate=${toDate}`;
  if (this.filterKey) {
    queryString += `&status=${this.filterKey}`;
  } 
  if (this.searchKey) {
    queryString += `&query=${this.searchKey}`;
  }
} else if (this.searchKey && !this.filterKey) {
  queryString = `?query=${this.searchKey}`;
} else if (!this.searchKey && this.filterKey) {
  queryString = `?status=${this.filterKey}`;
} else if (this.searchKey && this.filterKey) {
  queryString = `?query=${this.searchKey}&status=${this.filterKey}`;
}

if (this.isDashBoardView) {
  queryString += '&isDashBoardView=true';
}

// Assign query string to class variable
this.queryString = queryString;

console.log(queryString);


    let url = ServerURLS.getCustomerListAdmin.action + "/" + (page) + "/" + pageSize + this.queryString;
    url=url+(this.queryString!=''?"&isAscending="+this.isAscending:'?isAscending='+this.isAscending)
    if(this.sortParam!=''){
      url=url+'&sortParam='+this.sortParam
    }
    let promise1 = this.scHttpService.getHttpService(url, '');
    promise1.then((res: any) => {
      this.isDataLoading = ''
      this._loaderService.stringSubject.next(false);
      if (res.response.length == 0 && this.page == 0) {
        this.isDataLoading = 'No Data Found'
      }
      if (res.response.length > 0) {
        res.response.map((data, index) => {
          res.response[index]["checked"] = false;
          res.response[index]["fullName"] = data.customer_name ? data.customer_name : '--';

          // for maping service Name *****************************
          data.services.map((dataServicesCustomer, indexForService) => {
            this.loadData.services_type.map((serviceTypeData) => {
              if (serviceTypeData.services_id == dataServicesCustomer.services_id) {
                res.response[index].services[indexForService]["name"] = serviceTypeData.service_name;
              }
            });
          });
          // **********************************************************

          // for maping package Name *****************************
          data.package_types.map((dataPackageCustomer, indexForService) => {
            this.loadData.package_type.map((packageTypeData) => {
              // below filter is for mapping service name for package
              let findService = this.loadData.services_type.filter((data) => data.services_id == dataPackageCustomer.service_id);

              if (packageTypeData.package_id == dataPackageCustomer.package_id) {
                res.response[index].package_types[indexForService]["name"] = packageTypeData.package_name
                if (findService.length > 0) {
                  res.response[index].package_types[indexForService]["serviceName"] = findService[0].service_name
                }
              }
            });
          });
          //**********************************************************

          // if (data.business_type_id && data.business_type_id.length > 0) {
          //   let findBusinessType = this.loadData.business_type.filter((dataBusiness) => dataBusiness.business_id == data.business_type_id)

          //   res.response[index]["businessId_mapName"] = findBusinessType[0].business_type;
          // }
          // else {
          //   res.response[index]["businessId_mapName"] = '- -';
          // }
        });
        this.customerList = res.response;
        let data = {
          dataList: this.customerList,
          pageCount: this.page,
          pageSize: this.pageSize
        }
        this.listDataService.setAllDataCustomerMainList(data, this.selectedCustomerType);
      } else {
        let data = {
          dataList: [],
          pageCount: this.page,
          pageSize: this.pageSize
        }
        this.listDataService.setAllDataCustomerMainList(data, this.selectedCustomerType)
      }


    }).catch((error) => {
      console.log(error)
      this.isDataLoading = 'Something Went Wrong! Please try Again Later.'

    })


  }
  timer: any;
  setSearchKey(searchParam) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchParam.length >= 3) {
        this.listDataService.setSearchKeyOfCustomerMainList(searchParam, this.selectedCustomerType)
        this.page = 0
        this.searchKey = searchParam
        this.applyFilter();
      } else if (searchParam.length === 0) {
        this.listDataService.setSearchKeyOfCustomerMainList('', this.selectedCustomerType)
        this.page = 0
        this.searchKey = ''
        this.loadCustomerList(this.page,this.pageSize);
      }
    }, 500)

  }
  setFilterValue(filterValue) {
    this.filterKey = filterValue;
    this.listDataService.setFilterValueOfCustomerMainList(filterValue)
  }

  applyFilter() {
    this.page = 0
    this.loadCustomerList(this.page,this.pageSize);
  }

  resetPassword(entityId) {

    let promise = this.scHttpService.putHttpService(ServerURLS.resetPasswordByAdmin.action + "?entityId=" + entityId, '');

    promise.then((result) => {

      this.errorMessage = '';
      this.resetSuccessMsg = result['data'];

    });

    promise.catch((result) => {
      this.errorMessage = result['message'];
      this.resetSuccessMsg = '';
    });

  }

  open(content, custId) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    this.customerId = custId;
  }

  deleteAccessorialServicePackageType(mapId, type, indexForSubPart, customerIndex) {
    // this.loadCotent(this.loadContent);
    let urlForDelete = `${ServerURLS.deleteCustomerServicePackageAccesorial.action}?featureType=${type}&featureMapId=${mapId}`;
    let promiseDelete = this.scHttpService.deleteHttpService(urlForDelete, '');
    promiseDelete.then((value: any) => {

      if (type == 'Accessorial') {
        this.customerList[customerIndex].accessories = this.customerList[customerIndex].accessories.filter((data, index) => index !== indexForSubPart)
      }
      else if (type == 'Services') {
        this.customerList[customerIndex].package_types = this.customerList[customerIndex].package_types.filter((data) => data.service_id !== this.customerList[customerIndex].services[indexForSubPart].services_id)
        this.customerList[customerIndex].services = this.customerList[customerIndex].services.filter((data, index) => index !== indexForSubPart)
      }
      else if (type == 'PackagesType') {
        this.customerList[customerIndex].package_types = this.customerList[customerIndex].package_types.filter((data, index) => index !== indexForSubPart)
      }
      this.closeModal();
    }).catch((err: any) => {

      this.closeModal();
    })
  };



  custApproval(aprvemdl, custId) {
    this.approvalForm.reset();
    this.modalService.open(aprvemdl, { centered: true, ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    this.customerId = custId;
    //this.status = status;
    // if(this.status == "Approved"){
    //   this.isCustApproved = true;
    // }else{
    //   this.isCustApproved = false;
    // }
  }

  cancel(e) {
    if (e) {
      this.modalService.dismissAll();
    }
  }

  // loadCotent(loadContent) {
  //   this.modalService.open(loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
  // }

  closeModal() {
    this.modalService.dismissAll();
  }

  saveapproval(status) {
    let form_data = this.approvalForm.getRawValue();
    form_data.custId = this.customerId;
    form_data.approvalStatus = status;

    let promise = this.scHttpService.postHttpService(ServerURLS.saveCustApproval.action, form_data);
    promise.then((response) => {

      this.errorMessage = "";
      if (response) {
        this.successMsg = response['message'];
        this.closeModal();
        this.loadCustomerList(this.page,this.pageSize);
      }

    }).catch((error) => {
      this.errorMessage = error.message;
    });
  }

  activeInactive(status, customerId) {
    let url = `${ServerURLS.changeCustomerStatus.action}?customerId=${customerId}&isActive=${status}`
    let promise = this.scHttpService.deleteHttpService(url, '');
    promise.then((res) => {

      if (this.selectedCustomerType == 1) {
        //update the data in allcustomerlist
        this.listDataService.updateStatusOfCustomerMainList(status ? 'ACTIVE' : 'INACTIVE', customerId)
        // udpate the active customers list and remove element from inactive customer list
        if (status == true) {
          this.listDataService.refreshTheDataOfCustomerListsWhenChangeTheStatus(3)
          this.listDataService.removeElementFromtheCustomerListByIndex(4, customerId)
        }
        // udpate the inactive customer List and remove elemetn from active customer list
        if (status == false) {
          this.listDataService.refreshTheDataOfCustomerListsWhenChangeTheStatus(4)
          this.listDataService.removeElementFromtheCustomerListByIndex(3, customerId)

        }
      } else if (this.selectedCustomerType == 3) {
        //remove element from the current active customer list
        this.listDataService.removeElementFromtheCustomerListByIndex(3, customerId)
        //refresh the inactive customer list
        this.listDataService.refreshTheDataOfCustomerListsWhenChangeTheStatus(4)
        //update the status in all customer list
        this.listDataService.updateStatusOfCustomerMainList('INACTIVE', customerId)
      } else if (this.selectedCustomerType == 4) {
        //remove element from the current inactive customer list
        this.listDataService.removeElementFromtheCustomerListByIndex(4, customerId)
        //refresh the active customer list
        this.listDataService.refreshTheDataOfCustomerListsWhenChangeTheStatus(3)
        //update the status in all customer list
        this.listDataService.updateStatusOfCustomerMainList('ACTIVE', customerId)
      }
    }).catch((err) => {

    })
  }

  selectCustomerType(customerTypeId) {
    this.filterKey = ''
    this.listDataService.setTabIdCustomerMainList(customerTypeId)
    this.page = 0
    this.selectedCustomerType = customerTypeId;
    this.allDataLoaded = false;
    if (customerTypeId == 1 && this.filterKey !== '') {
      this.filterKey = '';
      this.searchKey = '';
      // this.checkDataIsPresentInServiceFileOrNot()

    }
    else if (customerTypeId == 2 && this.filterKey !== 'Pending') {
      this.filterKey = 'Pending';
      this.searchKey = '';
      // this.checkDataIsPresentInServiceFileOrNot()

    }
    else if (customerTypeId == 3 && this.filterKey !== 'Active') {
      this.filterKey = 'Active';
      this.searchKey = '';
      // this.checkDataIsPresentInServiceFileOrNot()

    }
    else if (customerTypeId == 4 && this.filterKey !== 'Inactive') {
      this.filterKey = 'Inactive';
      this.searchKey = '';
      // this.checkDataIsPresentInServiceFileOrNot()

    }
    this.checkDataIsPresentInServiceFileOrNot()

    // this.customerList=this.dummyData;
  }

  sort(property) {
    if(this.sortBy[property]=='up'){
      this.sortBy[property] = 'down' 
      this.isAscending=false;
      this.sortParam=property
    }else{
      this.sortBy[property] =  'up' 
      this.isAscending=true
      this.sortParam=property
    }
    this.listDataService.setDataOfSortValueInCustomerList(this.isAscending,this.sortParam,this.selectedCustomerType)
    this.loadCustomerList(0,(this.page+1)*20)
    // this.customerList.sort(function (a, b) {

    //   if (a[property].toLowerCase() < b[property].toLowerCase()) {
    //     return -1 * direction;
    //   }
    //   else if (a[property].toLowerCase() > b[property].toLowerCase()) {
    //     return 1 * direction;
    //   }
    //   else {
    //     return 0;
    //   }
    // });
  };


  viewDetails(id) {
    sessionStorage.setItem('_admincustomerId', id)
    this.listDataService.updatedTheValueOfSelectedCustomerInCustomerList(id, this.selectedCustomerType)
    this.commonService.customerId = id;
    this.router.navigateByUrl('shipcarte/customer/list/generalDetail/edit/shippingReceivingHours')
  }
  checkBoxClicked(event) {
    this.customerList.map((prop, index) => {
      if (prop.customer_id == event) {
        this.customerList[index].checked = !this.customerList[index].checked
      }
    })
  }

  addCustomerRoute() {
    sessionStorage.removeItem('_admincustomerId')
    this.commonService.customerId = null;
    this.router.navigateByUrl(`shipcarte/customer/list/generalDetail`)
  }
  routeToEdit(id) {
    this.removedDataWhenClick = false;
    sessionStorage.setItem('_admincustomerId', id)
    this.commonService.customerId = id;
    this.listDataService.updatedTheValueOfSelectedCustomerInCustomerList(id, this.selectedCustomerType)
    this.router.navigateByUrl(`shipcarte/customer/list/generalDetail/edit`)
  }
  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  // ngAfterViewInit() {
  //   this.scrollChangeCallback = () => this.scrollHandlerOfQuoteHistory(event);
  //   window.addEventListener('scroll', this.scrollChangeCallback, true);
  // }
  allDataLoaded: any = false;
  scrollHandlerCustomerList(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      console.log(window.innerHeight + window.scrollY, document.body.offsetHeight)
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {


        this.isLoading = true
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {

      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    this.page = this.page + 1
    // let fromDate = this.activatedRoute.snapshot.paramMap.get('fromDate');
    // let toDate = this.activatedRoute.snapshot.paramMap.get('toDate');
    // if (fromDate && toDate) {
    //   this.isDashBoardView = true;
    // } else {
    //   this.isDashBoardView = false;
    // }


    // // this.loadCotent(this.loadContent);
    // if (this.searchKey.length > 2 && this.filterKey == '' && (!fromDate && !toDate)) {
    //   this.queryString = `?query=${this.searchKey}`
    // }
    // else if (this.searchKey.length == 0 && this.filterKey !== '' && (!fromDate && !toDate)) {
    //   this.queryString = `?status=${this.filterKey}`
    // }
    // else if (this.searchKey.length > 2 && this.filterKey !== '' && (!fromDate && !toDate)) {
    //   this.queryString = `?query=${this.searchKey}&status=${this.filterKey}`
    // } else if ((fromDate && toDate) && (this.searchKey === '' && this.filterKey === '')) {
    //   this.queryString = '?status=Active&startDate=' + fromDate + '&endDate=' + toDate
    // } else if ((fromDate && toDate) && (this.searchKey != '')) {

    //   this.queryString = '?status=Active&startDate=' + fromDate + '&endDate=' + toDate + '&query=' + this.searchKey

    // }
    // else {
    //   this.queryString = ''
    // }
    // if (this.isDashBoardView) {
    //   this.queryString = this.queryString + '&isDashBoardView=true'
    // }


    let fromDate = this.activatedRoute.snapshot.paramMap.get('fromDate');
let toDate = this.activatedRoute.snapshot.paramMap.get('toDate');
let queryString = '';

// Check if dashboard view
this.isDashBoardView = !!(fromDate && toDate);

// Construct query string based on conditions
if (fromDate && toDate) {
  queryString = `?status=${this.filterKey}&startDate=${fromDate}&endDate=${toDate}`;
  if (this.searchKey.length > 2) {
    queryString += `&query=${this.searchKey}`;
  }
} else if (this.searchKey.length > 2 && !this.filterKey) {
  queryString = `?query=${this.searchKey}`;
} else if (!this.searchKey && this.filterKey) {
  queryString = `?status=${this.filterKey}`;
} else if (this.searchKey.length > 2 && this.filterKey) {
  queryString = `?query=${this.searchKey}&status=${this.filterKey}`;
}

if (this.isDashBoardView) {
  queryString += '&isDashBoardView=true';
}

// Assign query string to class variable
this.queryString = queryString;

console.log(queryString);


    let url = ServerURLS.getCustomerListAdmin.action + "/" + (this.page) + "/" + this.pageSize + this.queryString;
    url=url+(this.queryString!=''?"&isAscending="+this.isAscending:'?isAscending='+this.isAscending);
    let promise1 = this.scHttpService.getHttpService(url, '');
    promise1.then((res: any) => {
      this.isLoading = false;

      if (res.response.length > 0) {
        res.response.map((data, index) => {
          res.response[index]["checked"] = false;
          res.response[index]["fullName"] = data.customer_name ? data.customer_name : '--';

          // for maping service Name *****************************
          data.services.map((dataServicesCustomer, indexForService) => {
            this.loadData.services_type.map((serviceTypeData) => {
              if (serviceTypeData.services_id == dataServicesCustomer.services_id) {
                res.response[index].services[indexForService]["name"] = serviceTypeData.service_name;
              }
            });
          });
          // **********************************************************

          // for maping package Name *****************************
          data.package_types.map((dataPackageCustomer, indexForService) => {
            this.loadData.package_type.map((packageTypeData) => {
              // below filter is for mapping service name for package
              let findService = this.loadData.services_type.filter((data) => data.services_id == dataPackageCustomer.service_id);

              if (packageTypeData.package_id == dataPackageCustomer.package_id) {
                res.response[index].package_types[indexForService]["name"] = packageTypeData.package_name
                if (findService.length > 0) {
                  res.response[index].package_types[indexForService]["serviceName"] = findService[0].service_name
                }
              }
            });
          });
          //**********************************************************

          // if (data.business_type_id && data.business_type_id.length > 0) {
          //   let findBusinessType = this.loadData.business_type.filter((dataBusiness) => dataBusiness.business_id == data.business_type_id)
          //   res.response[index]["businessId_mapName"] = findBusinessType[0].business_type;
          // }
          // else {
          //   res.response[index]["businessId_mapName"] = '- -';
          // }
        });
        this.customerList = this.customerList.concat(res.response);
        let data = {
          pageCount: this.page,
          pageSize: this.pageSize,
          dataList: this.customerList
        }
        this.listDataService.setAllDataCustomerMainList(data, this.selectedCustomerType)
      } else {
        this.allDataLoaded = true;
        this.isLoading = false;
      }


    }).catch((error) => {
      this.isLoading=false;

    })
  }
}
