import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { Permission } from '../../commons/pojo/permission';
import { ServerURLS } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-download-invoice',
  templateUrl: './download-invoice.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./download-invoice.component.scss']
})
export class DownloadInvoiceComponent implements OnInit {
  validityForm: FormGroup;
  constructor( private _formBuilder: FormBuilder,private scHttpService: ScHttpService, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.validityForm = this._formBuilder.group({
      startDate: ['', Validators.required],
      expiryDate: [, Validators.required],
    });
  }
  downloadinvoice(){
    let formData = this.validityForm.getRawValue();
    let params = "?startDate=" +this.validityForm.value.startDate+"&endDate="+ this.validityForm.value.expiryDate;
    console.log(params)
    // let promise = this.scHttpService.getinvoice(ServerURLS.downloadinvoice.action+params).subscribe((data)=>{
    //   console.log(data)
    // });
    window.open(ServerURLS.downloadinvoice.action+params)
  } 
 

}
