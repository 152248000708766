import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { CompanyForm } from './companyform';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['../../quote/quote-location/quote-location.component.scss','./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {

  companyInfoForm: FormGroup;
  applicantId: string;
  errMsg: string;
  successMsg: any;
  companyType: any;
  locationType: any;

  choosenLocationType:any;
  isLocationChecked:any;
  
  companyTypes:any;
  companyInfo:CompanyForm;
  isEdit: boolean;
  companyDetail: any;
  optedServices: any[];

  constructor( private route: ActivatedRoute, private storageService: ScStorageService, private router: Router,private _formBuilder: FormBuilder , private scHttpService: ScHttpService) {
      this.companyInfo = new CompanyForm();
   }

  ngOnInit() {

    this.applicantId = this.route.snapshot.paramMap.get('applicant-id');
    this.isEdit = this.route.snapshot.paramMap.get('is-edit') != null;

    // if( this.applicantId != null && this.isEdit){
    //   this.getCompanyDetails(this.applicantId );
    // }
    this.getCompanyDetails(this.applicantId );
    this.loadCompanyTypes();
    this.loadLocationTypes();
    this.loadCompFormData();
    

    this.choosenLocationType = new Set();
    this.isLocationChecked = new Array();

  }

  loadCompFormData(){
     
    this.companyInfoForm = this._formBuilder.group({
      applicantId:[this.applicantId],
      dateOfIncorporation: [],
      locationType: ['',Validators.required],
      companyType: ['',Validators.required],
    });
  }

  saveCompanyInfo () {

    let form_data = this.companyInfoForm.getRawValue();

    this.companyInfo = form_data;

    this.companyInfo.companyType = this.choosenLocationType;

    for (let service of this.choosenLocationType) {  
      this.companyInfo.companyType.add( service ); 
    } 

    this.companyInfo.companyType = Array.from(this.companyInfo.companyType.values());
      let authPromise = this.scHttpService.putHttpService(ServerURLS.updateCompanyInfo.action, this.companyInfo);
        
        authPromise.then( (resolve) => {
          let response = resolve;
          this.errMsg = '';
          this.successMsg = response["message"];
        
          let params = ";applicant-id=" + this.applicantId + ( this.isEdit ? ";is-edit=true" : "");
          this.router.navigateByUrl("shipcarte/credit/applicant/bank/info" + params);
  
        }).catch((error) => {
          this.errMsg = error.error;
          this.successMsg = '';
        });
   
    }

    loadCompanyTypes (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getCompanyTypes.action,'');
      promise1.then( value => {
        this.companyType = value["data"];
        //console.log(this.optedServices)
        if ( this.companyType && this.optedServices != undefined ) {
            this.companyType.forEach(element => {
          if ( this.optedServices.includes ( element.companyTypeId ) ) {
             let values= {};
             values['target'] = {};
             values['target']['value'] = element.companyTypeId ;
             element.checked = 'checked';
             this.setCompanyLocationType ( values, true);
          }
        });
       } 

      });
    
    }

    loadLocationTypes (): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getCompanyLocationTypes.action,'');
      promise1.then( value => {
        this.locationType = value["data"];
      });
    
    }

    setCompanyLocationType(values:any, checked:boolean){

      checked = !this.choosenLocationType.has(values.target.value);

      if( checked ) {
        this.choosenLocationType.add( values.target.value );
      } else {
        this.choosenLocationType.delete( values.target.value );
      }
      
    }

    getCompanyDetails (id): void {

      let promise1 = this.scHttpService.getHttpService( ServerURLS.getApplicantDetails.action +"?id=" +id,'');
      promise1.then( value => {
        this.companyDetail = value["data"];
        //console.log(this.companyDetail)
        this.optedServices = this.companyDetail["companyType"];
        //console.log( this.optedServices)
        this.companyInfoForm.patchValue (this.companyDetail);
      });
    }
}
