import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoItemViewComponent } from './quote-item/no-item-view/no-item-view.component';
import { AddItemComponent } from './quote-item/add-item/add-item.component';
import { ItemsComponent } from './quote-item/items/items.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { QuoteResultsComponent } from './quote-results/quote-results.component';
import { QuoteShipmentComponent } from './quote-shipment/quote-shipment.component';
import { QuoteSummaryComponent } from './quote-summary/quote-summary.component';
import { Ng5SliderModule } from 'ng5-slider';
import { QuoteLocationComponent } from './quote-location/quote-location.component';
import { OrderConfirmComponent } from './order-confirm/order-confirm.component';
import { QuotePaymentComponent } from './quote-payment/quote-payment.component';
import { QuoteDocumentComponent } from './quote-document/quote-document.component';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { OrderListComponent } from './order-list/order-list.component';
import { CreditSubmitComponent } from '../modal/credit-submit/credit-submit.component';
import { ModalModule } from '../modal/modal.module';
import { CarrierViewComponent } from './carrier-view/carrier-view.component';
import { QuotePaymentListComponent } from './quote-payment-list/quote-payment-list.component';
import { AddressModule } from '../address/address.module';
import { QuoteCheckoutComponent } from './quote-checkout/quote-checkout.component';
import { SpinnerModule } from 'src/app/spinner/spinner.module';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/quote/shipment', pathMatch: 'full' },
  { path: 'shipcarte/quote/shipment', component: QuoteShipmentComponent},
  { path: 'shipcarte/quote/rates', component: QuoteResultsComponent},
  { path: 'shipcarte/quote/summary', component: QuoteSummaryComponent},
  { path: 'shipcarte/quote/quote-item-view', component: NoItemViewComponent},
  { path: 'shipcarte/quote/add-item', component: AddItemComponent},
  { path: 'shipcarte/quote/items', component: ItemsComponent},
  { path: 'shipcarte/quote/location', component: QuoteLocationComponent},
  { path: 'shipcarte/quote/make/payment', component: QuotePaymentComponent},
  { path: 'shipcarte/order/successful', component: OrderConfirmComponent},
  { path: 'shipcarte/quote/v2-document', component: QuoteDocumentComponent},
  { path: 'shipcarte/quote/v2-location', component: QuoteLocationComponent},
  { path: 'shipcarte/quote/quote-list', component: QuoteListComponent},
  { path: 'shipcarte/quote/quote-list/:startDate/:endDate', component: QuoteListComponent},
  { path: 'shipcarte/quote/quote-list/:startDate/:endDate/:status', component: QuoteListComponent},
  { path: 'shipcarte/quote/order-list', component: OrderListComponent},
  { path: 'shipcarte/quote/carrier-view', component: CarrierViewComponent},
  { path: 'shipcarte/quote/quote-payment-list', component: QuotePaymentListComponent},
  { path:  'shipcarte/quote/quote-checkout', component: QuoteCheckoutComponent}


];

@NgModule({
  declarations: [QuoteShipmentComponent, QuoteResultsComponent, QuoteSummaryComponent, NoItemViewComponent, AddItemComponent, ItemsComponent, QuoteLocationComponent, QuotePaymentComponent, OrderConfirmComponent, QuoteDocumentComponent, QuoteListComponent, OrderListComponent, CarrierViewComponent, QuotePaymentListComponent, QuoteCheckoutComponent],
  entryComponents: [AddItemComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng5SliderModule,
    ModalModule,
    AddressModule,
    SpinnerModule
  ],
  
})
export class QuoteV2Module { }
