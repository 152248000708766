import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ServerURLS } from '../../../../environments/environment';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { Socialusers } from 'src/app/services/social/socialusers';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  registerForm: FormGroup;
  successMsg:String;
  errorMsg:String;
  pwdPattern = "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{7,20})";
  showCustTab: string;
  showCustForm: string;

  showPartnerTab: string;
  showPartnerForm: string;
  actorType :String;
  socialusers=new Socialusers();

  EMAIL_REGEXP = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  constructor(private _formBuilder: FormBuilder,private scHttpService: ScHttpService, public OAuth: AuthService /*private OAuth: ScSocialAuthServiceService*/  ) { }

  ngOnInit() {
    this.showCustTab = " active";
    this.showCustForm = " show active ";
    this.actorType = 'customer';
    this.loadFormData();
  }

  traverse(tab){

    if(tab == 'partner'){

      this.showPartnerTab = " active ";
      this.showPartnerForm = " show active ";

      this.showCustTab = "";
      this.showCustForm = "";
      this.actorType = tab;

    } else if(tab == 'customer'){

      this.showCustTab = " active";
      this.showCustForm = " show active ";

      this.showPartnerTab = "";
      this.showPartnerForm = "";
      this.actorType = tab;

    }
 
  }

  changeType( actorType){
    if( actorType == 'customer'){
      this.actorType = 'partner'
    } else{
      this.actorType = 'customer';
    }
  }

  loadFormData(){

     this.registerForm = this._formBuilder.group({
    
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.EMAIL_REGEXP)]],
      password: ['', [Validators.required, Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', Validators.required],
      

    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

  }

  get f() { return this.registerForm.controls; }


  signUp():void{
     
    let formData =  this.registerForm.getRawValue();
    let url = "";
    if(  this.actorType == 'customer'){

      url+= ServerURLS.registerCustomer.action;

    } else if( this.actorType == 'partner'){

      url+= ServerURLS.registerPartner.action;
    }

    let promise = this.scHttpService.postHttpService(url, formData);
    promise.then( (result) => {
      this.successMsg =  result['message'];
      
    });
  
    promise.catch ( (result) => {
      this.errorMsg =  result['error'];
      this.successMsg = '';
    });
  } 

  public socialSignUp(socialProvider: string) {  
    let formData = this.registerForm.getRawValue();
    formData.actorType = this.actorType;
    let socialPlatformProvider;  
   
    if (socialProvider === 'facebook') {  
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;  
    } else if (socialProvider === 'google') {  
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;  
    }  

    if( this.OAuth ) {

      // let promise = new Promise((resolve, reject) => {

        this.OAuth.signIn(socialPlatformProvider).then(socialusers => {  
          // console.log(socialProvider, socialusers);  
          // console.log(socialusers);  
          this.socialusers = socialusers;

          if( this.socialusers ) {

            let name = this.socialusers.name;
            let lname = "";

            let nameAttributes =  name.split(" ");

            if( nameAttributes && nameAttributes.length > 1) {
              lname = nameAttributes [ nameAttributes.length - 1 ];

              let regex = new RegExp('(' + lname + ')$');
              name = name.replace ( regex, "")
            }

            let url = '';
            let actorType = formData.actorType;
            formData = {
              "email": this.socialusers.email,
              "actorType": formData.actorType,
              "socialProvider":this.socialusers.provider,
              "firstName" : name,
              "lastName" : lname,
            }

            if( actorType == 'customer') {
              url+= ServerURLS.registerCustomer.action;
            } else if ( actorType == 'partner') {
              url+= ServerURLS.registerPartner.action;
            }
          
            let httpPromise = this.scHttpService.postHttpService(url, formData);
          
            httpPromise.then( (result) => {
              this.successMsg =  result['message'];
              this.errorMsg   = '';
            }).catch ( (result) => {
              this.errorMsg   =  result['error'];
              this.successMsg = '';
            });
          
          
          }
          //this.Savesresponse(socialusers);  
        });  
   /*   }).then( (result) => {

        resolve (result)
        console.log(result['message']);
        // resolve ( result['message'] );
        }).catch ( (result) => {
          console.log(result['message']);
        // resolve ( result['message'] );
        });*/ 
       
    }  else {
 
    }
    
  } 
  
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }

}
