import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-view-insurance',
  templateUrl: './view-insurance.component.html',
  styleUrls: ['./view-insurance.component.scss']
})
export class ViewInsuranceComponent implements OnInit {

  @Input() public url;
  constructor(private modalService:NgbModal,
    private  _loaderService: LoadingService
  ) { }

  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    setTimeout(() => {
    this._loaderService.stringSubject.next(false);
    }, 2000);
  }
  closePopUP(){
    this.modalService.dismissAll()
  }
}
