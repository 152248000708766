import { Component, OnInit, ViewEncapsulation, ViewChild, } from '@angular/core';
import { ServerURLS } from 'src/environments/environment';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { Permission } from '../../commons/pojo/permission';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { start } from 'repl';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./quote-list.component.scss']
})
export class QuoteListComponent implements OnInit {

  quotes: any;
  displayData = true;
  sourceAddress: any;
  destAddress: any;
  activePage: number;
  noOfPages: any;
  startElementCount: any;
  endElementCount: any;

  searchParam: string;
  permission: Permission;

  iconMap: Map<any, any>;
  email: string;
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  startDate: string;
  endDate: string;
  carriers: { name: string; code: string; }[];
  carrierCode: any;
  editRange: any;
  status:any;
  isDataReady = false;
  errorMsg: any;

  closeResult: string;
  @ViewChild('longContent', undefined) longContent;

  constructor(private modalService: NgbModal, private scHttpService: ScHttpService, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    public commonService: ScCommonService, public scAuthService: ScAuthService,private activatedRoute:ActivatedRoute, private  _loaderService: LoadingService) {


    this.permission = new Permission();

    this.permission.read = true;
    this.permission.write = true;
    this.permission.action = true;
 

  }

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    this.loadIcons();

    this.carriers = [
      { name: 'Day & Ross', code: "DR" },
      { name: 'Polaris', code: "POL" },
      { name: 'YRC', code: "YRC" },
      { name: 'Canpar', code: "EP-CP" },
      { name: 'UPS', code: "EP-UPS" },
      { name: 'FEDEX', code: "EP-FED" },
      { name: 'LoomisExpress', code: "EP-LE" }
    ];
    let startDate=this.activatedRoute.snapshot.paramMap.get('startDate')
    let endDate=this.activatedRoute.snapshot.paramMap.get("endDate");
    let status=this.activatedRoute.snapshot.paramMap.get('status');
   

    if(status && startDate && endDate){
      this.status=status
      this.startDate=startDate
      this.endDate=endDate
    }else if(!status && startDate && endDate){
      this.startDate=startDate
      this.endDate=endDate
    }
    console.log(startDate,endDate,status)

  }

  ngAfterViewInit() {
    this.loadQuoteList();
  }
  
  loadPage(page: number) {
    if (page !== this.activePage) {
      this.loadQuoteList();
    }

  }

  page = 1;
  pageSize = 10
  collectionSize = 5;

  searchEmail(key) {
    this.email = key;
  }

  searchDate() {
    this.startDate = this.commonService.formatDate(this.fromDate);
    this.endDate = this.commonService.formatDate(this.toDate);
  }

  setCarrierCode(code) {
    this.carrierCode = code;
  }

  searchQuote(key) {
    this.searchParam = key;
    this.loadQuoteList();
  }

  reset(){
    this.email = null;
    this.carrierCode = null;
    this.startDate = null;
    this.endDate = null;
    this.fromDate = null;
    this.toDate = null;
  }


  loadQuoteList(): void {

    this.openSm(this.longContent);
    let url = ""
    if (this.scAuthService.isAdmnAccess() || this.scAuthService.isPartnerAccess()) {

      url = ServerURLS.getQuotes_Admin.action + "/" + (this.page - 1) + "/" + this.pageSize + "?";

      if (this.email) {
        url += "&email=" + this.email;
      }

      if (this.carrierCode) {
        url += "&carrier-code=" + this.carrierCode;
      }

      if (this.startDate) {
        url += "&from-date=" + this.startDate;
      }

      if (this.endDate) {
        url += "&to-date=" + this.endDate;
      }

      if (this.searchParam) {
        url += "&searchKey=" + this.searchParam;
      }
      if(this.status){
        url+='&status='+this.status
      }

    } else if (this.scAuthService.isCustAccess()) {

      url = ServerURLS.getQuotes.action + "/" + (this.page - 1) + "/" + this.pageSize;

      if (this.searchParam) {
        url += "?searchKey=" + this.searchParam;
      }
    }

    this.isDataReady = false;

    let promise1 = this.scHttpService.getHttpService(url, '');

    promise1.then(value => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      if(value['data']){

      this.quotes = value["data"]["content"];
      this.pageSize = value["data"]["pageable"]["pageSize"];
      this.collectionSize = value["data"]["totalElements"];
      this.page = value["data"]["pageable"]["pageNumber"] + 1;
      this.noOfPages = value["data"]["totalPages"];

      let offset = value["data"]["pageable"]["offset"];
      this.startElementCount = offset + 1;
      this.endElementCount = offset + value["data"]["numberOfElements"];

      this.activePage = this.page;

      this.quotes.forEach(element => {
        this.getSourceAddress(element);
        this.getDestAddress(element);
      });

      this.isDataReady = true;
      this.closeModal();
    }else{
      this.isDataReady=false;
      this.closeModal()
    }

    });

    promise1.catch((error) => {
      this.isDataReady = true;
      this.errorMsg = error.error;
      this.closeModal();
    });

  }

  getSourceAddress(element): void {

    let url = ServerURLS.getAddressById.action + "?id=" + element["sourceAddr"];

    let promise1 = this.scHttpService.getHttpService(url, '');
    promise1.then(value => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.sourceAddress = value["data"];
      element['source_addr'] = value["data"];

    });

  }

  getDestAddress(element): void {

    let url = ServerURLS.getAddressById.action + "?id=" + element["destAddr"];

    let promise1 = this.scHttpService.getHttpService(url, '');
    promise1.then(value => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.destAddress = value["data"];
      element['destination_addr'] = value["data"];

    });

  }
  loadIcons() {

    this.iconMap = new Map();
    this.iconMap.set("truck", "assets/img/sc-icons/Mask Group 48.svg");
    this.iconMap.set("truck-ftl", "assets/img/sc-icons/Mask Group 49.svg");
    this.iconMap.set("rail", "assets/img/sc-icons/Mask Group 45.svg");
    this.iconMap.set("air", "assets/img/sc-icons/Mask Group 46.svg");
    this.iconMap.set("courier", "assets/img/sc-icons/Mask Group 47.svg");
  }

  onDateSelection(date: NgbDate) {

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.searchDate();
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  open(content) {
    this.reset();
    this.modalService.open(content, { size: 'lg', centered: true, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openSm(longContent) {
    // this.modalService.open(longContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal' ,backdrop:'static'})
  }
  private getDismissReason(reason: any): string {
    this.loadQuoteList();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}

