import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { EnvironmentConstants } from 'src/environments/environment';
import { AgmDirectionModule } from 'agm-direction';
import { CalculateDistanceComponent } from './calculate-distance/calculate-distance.component';
import { ZipPostalCodeComponent } from './zip-postal-code/zip-postal-code.component';
import { DimensionalWeightCalculatorComponent } from './dimensional-weight-calculator/dimensional-weight-calculator.component';
import { FreightClassCalculatorComponent } from './freight-class-calculator/freight-class-calculator.component';
import { CommercialInvoiceListComponent } from './commercial-invoice-list/commercial-invoice-list.component';
import { CommercialInvoiceViewComponent } from './commercial-invoice-view/commercial-invoice-view.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateCommercialPdfViewComponent } from './create-commercial-pdf-view/create-commercial-pdf-view.component';
import {CreateEditViewComponent} from './quickAction-Bol/create-edit-view/create-edit-view.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { DocumentsComponent } from './quickAction-Bol/documents/documents.component';
import { EmailDocumentComponent } from './quickAction-Bol/email-document/email-document.component';
import { DownloadLabelComponent } from './quickAction-Bol/download-label/download-label.component';
import { ViewBolDocComponent } from './quickAction-Bol/view-bol-doc/view-bol-doc.component';
import { ViewInvoiceComponent } from './quickAction-Bol/view-invoice/view-invoice.component';
import { ViewCommInvQuickActionComponent } from './quickAction-Bol/view-comm-inv-quick-action/view-comm-inv-quick-action.component';
import { SpinnerModule } from 'src/app/spinner/spinner.module';

const routes:Routes=[
  {path:'shipcarte/commercialInvoiceList', component:CommercialInvoiceListComponent},
  {path:'shipcarte/commercialInvoiceView',component:CommercialInvoiceViewComponent}
]

@NgModule({
  declarations: [CalculateDistanceComponent,ZipPostalCodeComponent,DimensionalWeightCalculatorComponent,CreateEditViewComponent,
    FreightClassCalculatorComponent,
    CommercialInvoiceListComponent,
    CommercialInvoiceViewComponent,
    CreateCommercialPdfViewComponent ,
    DocumentsComponent,
    EmailDocumentComponent,
    DownloadLabelComponent,
    ViewBolDocComponent,
    ViewInvoiceComponent,
    ViewCommInvQuickActionComponent 

  ],
    
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    NgbModule,
    NgSelectModule,
    SpinnerModule,
    AgmCoreModule.forRoot({apiKey: EnvironmentConstants.GooglePlacesApiKey
    }),
    AgmDirectionModule,
    

  ],
  entryComponents:[
    CreateCommercialPdfViewComponent,
    EmailDocumentComponent,
    DownloadLabelComponent,
    ViewBolDocComponent,
    ViewInvoiceComponent,
    ViewCommInvQuickActionComponent 
  ],
})
export class QuickActionsModule { }
