import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import * as jspdf from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import { formatDate } from '@angular/common';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EmailDocumentComponent } from '../email-document/email-document.component';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input() data;
  @Input() quoteIdForEmail;
  @Input() showIcons;
  documentIdForEmail: any;
  printBOL: boolean;
  formattedDate:any;
  charges:any;

  constructor(
    private scHttpService: ScHttpService, 
    public activeModal: NgbActiveModal,
    public common : ScCommonService, 
    private toastr: ToastrService, 
    private modalService: NgbModal,
    private router: Router,
    private docService: DocumentService,
    private  _loaderService: LoadingService) { }

  ngOnInit() {
    // let url = 'https://shipcarte2.ebizontech.biz/shipcarte-backend/shipcarte/api/invoices/getById?orderId=651288f2-0aee-44d9-8920-799d2fb034da';
    // this.scHttpService.getHttpService(url, {withCredentials: true}).then((res)=>{
    //   console.log(res)
    //   debugger
    // }).catch((err)=>{
    //   console.log(err)
    // })
    this._loaderService.stringSubject.next(true);
    this.docService.getData(this.quoteIdForEmail).subscribe(result=>{
      if (result) {
      this._loaderService.stringSubject.next(false);
      }
      this.documentIdForEmail=result['documentId'];
    }, error => {
      this._loaderService.stringSubject.next(false);
      if(error.status==401){
        this.router.navigate(['/signin']);
      }
      this.toastr.error(error['error']["message"],'Please try again.', {
        timeOut: 10000,
      });
    });

    this.printBOL = true;
 
    if(this.common.printInvoiceFromInvoices === true){
      this.sss();
      this.common.printInvoiceFromInvoices= false;
    }
    
    var charges = [];
    this.data.chargeComponents.charges.forEach((item, index) => {
      let keys = Object.keys(item);
      let chargeKey = keys.find(key => key !== 'isAdjust');
      let isAdjust = item['isAdjust'];
  
      if (chargeKey) {
          charges.push({
              charge: [chargeKey, item[chargeKey]],
              isAdjust: isAdjust
          });
      }
  });
  
  this.charges = charges;
    this.formattedDate = formatDate(this.data.orderDate, 'dd/MM/yyyy', 'en-US');
  }

  closeModal() { this.activeModal.close(); }

  print(doc){
 
    if(doc=='bol'){
      this.printBOL = true;
    }
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  sss(){
    setTimeout(() => {
      window.print();
    }, 1000);
  }
  
  downloadInvoice(){
    let compData = this;
    html2canvas(document.querySelector('#contentInvoice'), {useCORS: true}).then(function(canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        }    
        pdf.save("invoice_"+compData['data']['invoiceNumber']+'.pdf');
      };
    });

  }

  dialogOpen(modelToOpn,action){
    this._loaderService.stringSubject.next(true);
    if (modelToOpn==="emailDoc") {
      if(localStorage.getItem("_admincom_documentId_"+this.quoteIdForEmail) == null  && action!= "bol"){
        this.toastr.error('First create commercial invoice.', 'Please', {
          timeOut: 30000,
        });
        setTimeout(() => {
          this._loaderService.stringSubject.next(false);
         }, 1000);
        return;
      }
      setTimeout(() => {
      this._loaderService.stringSubject.next(false);
      const modalRef = this.modalService.open(EmailDocumentComponent, {size: 'lg',centered: false,backdrop: true, windowClass: 'my-custom-modal3'});
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.bolDocId =  action=="commercial" ? localStorage.getItem("_admincom_documentId_"+this.quoteIdForEmail):  this.documentIdForEmail ;
      modalRef.componentInstance.action = action;
      modalRef.result.then((result) => {
        if(result.success){
          this.toastr.success('Email sent successfully.', 'Success', {
            timeOut: 10000,
          });
        }else{
          this.toastr.error('Error, While sending email.', 'Oops', {
            timeOut: 10000,
          });
        }
      });
    }, 1000);
    }
  }

}
