import { Component, NgModuleRef, OnInit, ElementRef, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { error } from 'console';
import { CustomInfoNormalformComponent } from '../custom-info-normalform/custom-info-normalform.component';
import { ContactShipperComponent } from '../contact-shipper/contact-shipper.component';
import { CustomInfoSignatureComponent } from '../custom-info-signature/custom-info-signature.component';
import { ShipperAddressEditComponent } from '../shipper-address-edit/shipper-address-edit.component';
import { FlagService } from '../flag.service';
import { JsonPipe } from '@angular/common';
import { LoadingService } from 'src/app/services/loader.service';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.component.html',
  styleUrls: ['./confirm-order.component.scss']
})
export class ConfirmOrderComponent implements OnInit {
  @ViewChild('instance', null) instance: NgbTypeahead;
  @ViewChild('contentWarn', undefined) confirmModal;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  loggedInUserDetails: any[] = [];
  confOrderForm: FormGroup;
  submitted = false;
  shipmenItems: any;
  quoteId: any;
  max: 999;
  bol: any;
  po: any;
  res: any;
  brokers: any;
  allDropdownValues: any;
  displayData: boolean
  selectedBrId: any;
  brInfo: any;
  email: any;
  mob: any;
  shipingHours: any;
  rec: any[] = [];
  freightClassObj: any[] = [];
  freightDisplayDataObj: any;
  cls: any;
  shipper: any[] = [];
  allAddress: any[] = [];
  consignee: any[] = [];
  shipperMob: any;
  shipperEmail: any;
  consigneeEmail: any;
  consigneeMob: any;
  fromTime: any;
  toTime: any;
  getaddress: any[] = [];
  displayLoader: boolean;
  quoteDetails: any[] = [];
  disabledorNot: any;
  @ViewChild(ContactShipperComponent, null) child: ContactShipperComponent;
  addRes: any[] = [];
  consigneeCheckbox: boolean = true;
  shipperCheckbox: boolean = true;
  // shipperNote: any;
  // consigneeNote: any;
  referenceNo: any;
  poNo: any;
  styleCode: any;
  additionalNote: any;
  pickUpElement = <HTMLInputElement>document.getElementById("pickup");
  checkoutDataObj: any;
  showShipperPrimaryContactError = false;
  bolNumber: string;
  deliveryDetails: any = {};
  shipperModelStatus: any = false;
  latlng = [];
  zoom: number = 13
  addressData = { shipperLattitude: "43.849902", shipperLongitude: "-79.393173", consigneeLatitude: "43.849902", consigneeLongitude: "-79.393173" };
  shipperCompany: any;
  consigneeCompany: any;
  companyName: any;
  easypost: any = false;
  isCustomsRequired: any;
  showhsTariffNumber: any = false;
  showShipmentdescribtions: any = false;
  items = [];
  custominfo = [];
  custombrokerinfo = [];
  consigneeCompanyId: any;
  shipperCompanyId: any;
  cYear: any;
  editable: boolean = false;
  shipmentItemsWeight = 0;
  shipmentItemsQuantity = 0;
  shipmentTotalValue = 0;
  priceSummary: any;
  charges = [];
  ShipmentDetails: any;
  saveForLaterEnabled: boolean = false;
  editableOrderId = '';
  shipperAllItems = [
    {
      name: "(+1)",
      icon: "assets/img/shipment/USA.svg",
    },
    {
      name: "(+1)",
      icon: "assets/img/shipment/CANADA.svg",
    },
  ]
  consigneeAllItems = [
    {
      name: "(+1)",
      icon: "assets/img/shipment/USA.svg",
    },
    {
      name: "(+1)",
      icon: "assets/img/shipment/CANADA.svg",
    },
  ]
  brokerAllItems = [
    {
      name: "(+1)",
      icon: "assets/img/shipment/USA.svg",
    },
    {
      name: "(+1)",
      icon: "assets/img/shipment/CANADA.svg",
    },
  ];
  selectedCountry: any;
  shipperSelected: any;
  dropdownOpen: boolean = false;
  consigneeSelected: any;
  brokerSelected: any;
  blueBoxClosed: boolean = false;
  shipperCountry = '';
  consigneeCountry = '';
  brokerEnabled: boolean;
  pickupInstruction = '';
  DeliveryInstruction = ''
  shipMode: any;
  shipmentModeStyle: any;
  myGroup: FormGroup;
  myBrokerGroup: FormGroup;
  myConsigneeGroup: FormGroup;
  customerId: any = ''
  selectedShipperItem: any;
  selectedConsigneeItem: any;
  insuranceData: any;
  insuranceAmount: any = 0;
  weightUnit: any;
  saveQuoteDataObj;
  selectedDate: NgbDateStruct;
  shipperFromTimeError=false;
  pickUpDateDisplay: any;
  constructor(public formBuilder: FormBuilder, private toastr: ToastrService,
    private listDataService:ListDataServiceService,
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private modalService: NgbModal, 
    private el: ElementRef, 
    private scHttpService: ScHttpService, 
    private http: HttpClient, 
    private flagService: FlagService,   
    private  _loaderService: LoadingService
  ) { }
  ngOnInit(): void {
    this.saveQuoteDataObj= JSON.parse(sessionStorage.getItem('_adminsaveQuoteDataObj'));
    // const today = new Date(this.saveQuoteDataObj.delvryForm.pickupDate);
    var currentDate = new Date(new Date(this.saveQuoteDataObj.delvryForm.pickupDate).getTime() + 24 * 60 * 60 * 1000);
    var day = currentDate.getDate()
    var month = currentDate.getMonth() + 1
    var year = currentDate.getFullYear()
    this.selectedDate = { month: month, day: day, year: year };
    this.saveQuoteDataObj['delvryForm']['pickupDate'] = this.selectedDate.year + '-' + this.selectedDate.month + '-' + this.selectedDate.day;
    this.saveQuoteDataObj['delvryForm']['pickupDate1'] = {
      year: this.selectedDate.year,
      month: this.selectedDate.month,
      day: this.selectedDate.day,
    };
    this._loaderService.stringSubject.next(true);
    this.customerId = sessionStorage.getItem('_admingetQuoteCustomerId');
    this.insuranceData = JSON.parse(sessionStorage.getItem('_adminorderInsuranceData'))
    // if(this.insuranceData){
    //   this.insuranceAmount = this.insuranceData ? this.insuranceData.accepted ? this.insuranceData.amount : 0 : 0
    // }else{
    //   this.insuranceAmount={ accepted: false, certificateNumber: '', amount: 0 }
    // }
    this.myBrokerGroup = new FormGroup({
      selectedBrokerItem: new FormControl(this.brokerAllItems[0].icon) // Set default value
    });

    this.myGroup = new FormGroup({
      selectedShipperItem: new FormControl()
    });
    setTimeout(() => {
      this.myGroup.get('selectedShipperItem').setValue(this.shipperAllItems[0]);
    }, 100);


    this.myConsigneeGroup = new FormGroup({
      selectedConsigneeItem: new FormControl()
    });
    setTimeout(() => {
      this.myConsigneeGroup.get('selectedConsigneeItem').setValue(this.consigneeAllItems[0]);
    }, 100);

    this.flagService.selectedFlagItem$.subscribe(item => {
      if (item) {
        const updatedShipperItem = this.shipperAllItems.find(shipperItem => shipperItem.icon === item.icon);
        if (updatedShipperItem && this.selectedShipperItem !== updatedShipperItem) {
          this.selectedShipperItem = updatedShipperItem;
          this.myGroup.get('selectedShipperItem').setValue(this.selectedShipperItem);
        }

        const updatedConsigneeItem = this.consigneeAllItems.find(consigneeItem => consigneeItem.icon === item.icon);
        if (updatedConsigneeItem && this.selectedConsigneeItem !== updatedConsigneeItem) {
          this.selectedConsigneeItem = updatedConsigneeItem;
          this.myConsigneeGroup.get('selectedConsigneeItem').setValue(this.selectedConsigneeItem);
        }
      }
      this.setFlagValue(item)

    });

    this.quoteDetails['sourceAddrDetails'] = [];
    this.quoteDetails['destAddrDetails'] = [];
    this.addRes['start'] = []
    this.addRes['end'] = []
    this.confOrderForm = this.formBuilder.group({
      shipperCompanyName: ['', Validators.required],

      shipperAddressLine1: ['', Validators.required],
      shipperAddressLine2: [''],
      shipperCountry: ['', Validators.required],
      shipperState: ['', Validators.required],
      shipperCity: ['', Validators.required],
      shipperZip: ['', Validators.required],
      shipperFromTime: ['', Validators.required],
      shipperToTime: ['', Validators.required],
      shipperPrimaryContact: ['', Validators.required],
      shipperPrimaryContactExt: ['',],
      selectedShipperItem: ['',],
      shipperContactPhone: ['', [Validators.required,]],
      shipperContactEmail: ['', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],
      shipperNote: ['', [Validators.maxLength(50)]],



      consigneeCompanyName: ['', Validators.required],
      consigneeAddressLine1: ['', Validators.required],
      consigneeAddressLine2: [''],
      consigneeCountry: ['', Validators.required],
      consigneeState: ['', Validators.required],
      consigneeCity: ['', Validators.required],
      consigneeZip: ['', Validators.required],
      consigneeFromTime: ['', Validators.required],
      consigneeToTime: ['', Validators.required],
      consigneePrimaryContact: ['', Validators.required],
      consigneePrimaryContactExt: [''],
      selectedConsigneeItem: [''],
      consigneeContactPhone: ['', [Validators.required,]],
      consigneeContactEmail: ['', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],
      consigneeNote: ['', [Validators.maxLength(50)]],

      customBrokerPhone: [''],
      ext: [''],
      customBrokerEmail: ['', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]],
      brokerCompanyName: [''],
      brokerContactName: [''],
    });

    this.shipperSelected = this.shipperAllItems[0];
    this.consigneeSelected = this.consigneeAllItems[0];
    this.brokerSelected = this.brokerAllItems[0];
    this.displayData = true
    this.displayLoader = true;
    this.disabledorNot = true;
    this.brokers = [];
    this.bolNumber = '';
    this.shipingHours = [];
    this.quoteId = this.activatedRoute.snapshot.paramMap.get('quoteId');

    this.getBol(this.quoteId);
    this.deliverDetails(this.quoteId);
    let url3 = ServerURLS.quoteDetails.action + '?quoteId=' + this.quoteId;
    let promise3 = this.scHttpService.getHttpService(url3, '');
    promise3.then((c) => {
      this.quoteDetails = c['data']
      //Pulling Default Source address details
      this.getAddress(this.quoteDetails['sourceAddr']);
      this.pickUpDateDisplay = this.quoteDetails['pickupDate'];
      //Pulling Default destinationAddress details
      this.getAddress2(this.quoteDetails['destAddr']);
    });

    let url = ServerURLS.CarrirData.action + '?quoteId=' + this.quoteId
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.shipmenItems = res['items'] ? res['items'] : '';

      this.brokerId = res.brokerId

      this.shipmenItems = res['items'] ? res['items'] : '';
      this.isCustomsRequired = res['isCustomsRequired']
      this.ShipmentDetails = res ? res : '';
      this.shipperCountry = this.ShipmentDetails.shipperAddress.country;
      this.consigneeCountry = this.ShipmentDetails.consigneeAddress.country;
      this.shipMode = this.ShipmentDetails.shipmentModeName;
      this.shipmentModeStyle = this.ShipmentDetails.shipmentModeStyleCode;
      //patching the value of primary into the shipper table and consignee table
      let srcName = ''
      let shipperFistName = res['shipperAddress']['primaryContactInfo'] ? res['shipperAddress']['primaryContactInfo']['firstName'] : "";
      let shipperlastName = res['shipperAddress']['primaryContactInfo'] ? res['shipperAddress']['primaryContactInfo']['lastName'] : "";
      let shipperPhoneNumber = res['shipperAddress']['primaryContactInfo'] ? res['shipperAddress']['primaryContactInfo']['phoneNumber'] : "";
      let shipperEmail = res['shipperAddress']['primaryContactInfo'] ? res['shipperAddress']['primaryContactInfo']['email'] : "";
      let shipperPhoneExt = res['shipperAddress']['primaryContactInfo'] ? res['shipperAddress']['primaryContactInfo']['phoneExt'] : "";
      let shipperCountry = res['shipperAddress']['primaryContactInfo'] ? res['shipperAddress']['primaryContactInfo']['country'] : ""
      let consigneeFistName = res['consigneeAddress']['primaryContactInfo'] ? res['consigneeAddress']['primaryContactInfo']['firstName'] : "";
      let consigneelastName = res['consigneeAddress']['primaryContactInfo'] ? res['consigneeAddress']['primaryContactInfo']['lastName'] : "";
      let consigneePhoneNumber = res['consigneeAddress']['primaryContactInfo'] ? res['consigneeAddress']['primaryContactInfo']['phoneNumber'] : "";
      let consigneeEmail = res['consigneeAddress']['primaryContactInfo'] ? res['consigneeAddress']['primaryContactInfo']['email'] : "";
      let consigneePhoneExt = res['consigneeAddress']['primaryContactInfo'] ? res['consigneeAddress']['primaryContactInfo']['phoneExt'] : "";
      let consigneeCountry = res['consigneeAddress']['primaryContactInfo'] ? res['consigneeAddress']['primaryContactInfo']['country'] : ""

      // if ((res['shipperAddress']['primaryContactInfo'].firstName ? res['shipperAddress']['primaryContactInfo'].firstName : '') != null) {
      //   srcName = res['shipperAddress']['primaryContactInfo'].firstName
      // }
      // if ((res['shipperAddress']['primaryContactInfo'].lastName ? res['shipperAddress']['primaryContactInfo'].lastName : "") != null) {
      //   srcName += ' ' + res['shipperAddress']['primaryContactInfo'].lastName
      // }
      // let conName = ''
      // if ((res['consigneeAddress']['primaryContactInfo'].firstName ? res['consigneeAddress']['primaryContactInfo'].firstName : "") != null) {
      //   conName = res['consigneeAddress']['primaryContactInfo'].firstName
      // }
      // if ((res['consigneeAddress']['primaryContactInfo'].lastName ? res['consigneeAddress']['primaryContactInfo'].lastName : "") != null) {
      //   conName += ' ' + res['consigneeAddress']['primaryContactInfo'].lastName
      // }

      setTimeout(() => {
        this.confOrderForm.controls['shipperPrimaryContact'].setValue(shipperFistName + " " + shipperlastName);
        this.confOrderForm.controls['shipperContactPhone'].setValue(shipperPhoneNumber);
        this.confOrderForm.controls['shipperContactEmail'].setValue(shipperEmail);
        this.confOrderForm.controls['shipperPrimaryContactExt'].setValue(shipperPhoneExt);
        this.setFlagValue((shipperCountry) === 'CA' ? this.shipperAllItems[1] : this.shipperAllItems[0])


        this.confOrderForm.controls['consigneePrimaryContact'].setValue(consigneeFistName + ' ' + consigneelastName);
        this.confOrderForm.controls['consigneeContactPhone'].setValue(consigneePhoneNumber);
        this.confOrderForm.controls['consigneeContactEmail'].setValue(consigneeEmail);
        this.confOrderForm.controls['consigneePrimaryContactExt'].setValue(consigneePhoneExt);
        this.setFlagValue((consigneeCountry) === 'CA' ? this.shipperAllItems[1] : this.shipperAllItems[0])

        this.getListOfPrimaryContact(res['shipperAddress'].company, 'shipper', shipperFistName != '' ? false : true)
        this.getListOfPrimaryContact(res['consigneeAddress'].company, 'consignee', consigneeFistName != '' ? false : true)
      }, 2000)

      if (this.shipperCountry !== this.consigneeCountry) {
        this.getAllBrokerInfo();
        this.brokerEnabled = true;
        this.confOrderForm.controls['brokerCompanyName'].setValidators([Validators.required]);
        this.confOrderForm.controls['brokerContactName'].setValidators([Validators.required])
        this.confOrderForm.controls['customBrokerPhone'].setValidators([Validators.required]);
        this.confOrderForm.controls['customBrokerEmail'].setValidators([Validators.required]);
      }
      else {
        this.brokerEnabled = false;
      }
      // this.pickupInstruction = res['pickupInstructions'];
      // this.DeliveryInstruction = res['dropInstructions'];
      this.confOrderForm.controls['shipperNote'].setValue(res['pickupInstructions']);
      this.confOrderForm.controls['consigneeNote'].setValue(res['dropInstructions']);
      // this.confOrderForm.controls['customBrokerPhone'].setValue(res['brokerPhone']);
      // this.confOrderForm.controls['customBrokerEmail'].setValue(res['brokerEmail']);
      this.selectedCustomBrokerId = res['brokerId'];

      this.shipmenItems.sort(function (a, b) {
        return parseFloat(a.displayOrder) - parseFloat(b.displayOrder)
      });
      this.styleCode = res['shipmentModeStyleCode'];
      const today = new Date();
        const todayStruct: NgbDateStruct = {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate()
        };
      if (res['pickupDelivery']['pickupFromTime'] || res['pickupDelivery']['pickupToTime']) {
        // this.confOrderForm.controls['shipperFromTime'].setValue(res['pickupDelivery']['pickupFromTime'])
        // this.confOrderForm.controls['shipperToTime'].setValue(res['pickupDelivery']['pickupToTime'])
        // this.getTimeDifferent();
        setTimeout(() => {
          if(JSON.parse(sessionStorage.getItem('_adminsaveQuoteDataObj')).delvryForm.pickupDate1.year == todayStruct.year && JSON.parse(sessionStorage.getItem('_adminsaveQuoteDataObj')).delvryForm.pickupDate1.month == todayStruct.month && JSON.parse(sessionStorage.getItem('_adminsaveQuoteDataObj')).delvryForm.pickupDate1.day == todayStruct.day){
            this.shipingHours.forEach(el=>{
              let hourTxt = el.pickupTime.split(":");
              if(this.checkTime(hourTxt[0],hourTxt[1])){
                el.showDisable = false;
              }
              else {
                el.showDisable = true;
              }
            })
            const firstActiveItem = this.shipingHours.find(item => item.showDisable === false);
            const lastActiveItem = this.shipingHours.slice().reverse().find(item => item.showDisable === false);
            // this.orderDetailForm.get('shippers')['controls'][0].patchValue({
            //   shipperPickupFromTime: firstActiveItem.pickupId,
            //   shipperPickupToTime: lastActiveItem.pickupId,
            // });

              this.confOrderForm.controls['shipperFromTime'].setValue(firstActiveItem.pickupId);
              if (firstActiveItem.rankAssigned<=31) {
                this.confOrderForm.controls['shipperToTime'].setValue("c7b3e641-f7ed-11e9-8462-163046d26cd4")
              } else {
                this.confOrderForm.controls['shipperToTime'].setValue("")
              }
          }
          else {
            this.confOrderForm.controls['shipperFromTime'].setValue(res['pickupDelivery']['pickupFromTime'])
            this.confOrderForm.controls['shipperToTime'].setValue(res['pickupDelivery']['pickupToTime'])
          }
        }, 1000);   
      }
      else {
        this.confOrderForm.controls['shipperFromTime'].setValue('c7b3e35a-f7ed-11e9-8462-163046d26cd4')
        this.confOrderForm.controls['shipperToTime'].setValue('c7b3e641-f7ed-11e9-8462-163046d26cd4')
      }
      if (res['pickupDelivery']['dropFromTime'] || res['pickupDelivery']['dropToTime']) {
        setTimeout(() => {
          if(JSON.parse(sessionStorage.getItem('_adminsaveQuoteDataObj')).delvryForm.pickupDate1.year == todayStruct.year && JSON.parse(sessionStorage.getItem('_adminsaveQuoteDataObj')).delvryForm.pickupDate1.month == todayStruct.month && JSON.parse(sessionStorage.getItem('_adminsaveQuoteDataObj')).delvryForm.pickupDate1.day == todayStruct.day){
            this.shipingHours.forEach(el=>{
              let hourTxt = el.pickupTime.split(":");
              if(this.checkTime(hourTxt[0],hourTxt[1])){
                el.showDisable = false;
              }
              else {
                el.showDisable = true;
              }
            })
            const firstActiveItem = this.shipingHours.find(item => item.showDisable === false);
            const lastActiveItem = this.shipingHours.slice().reverse().find(item => item.showDisable === false);
            // this.orderDetailForm.get('shippers')['controls'][0].patchValue({
            //   shipperPickupFromTime: firstActiveItem.pickupId,
            //   shipperPickupToTime: lastActiveItem.pickupId,
            // });
            //this.confOrderForm.controls['consigneeFromTime'].setValue(firstActiveItem.pickupId);
           // this.confOrderForm.controls['consigneeToTime'].setValue(lastActiveItem.pickupId)
           
           this.confOrderForm.controls['consigneeFromTime'].setValue(firstActiveItem.pickupId);
              if (firstActiveItem.rankAssigned<=31) {
                this.confOrderForm.controls['consigneeToTime'].setValue("c7b3e641-f7ed-11e9-8462-163046d26cd4")
              } else {
                this.confOrderForm.controls['consigneeToTime'].setValue("")
              }
           console.log(this.shipingHours);
          }
          else {
            this.confOrderForm.controls['consigneeFromTime'].setValue(res['pickupDelivery']['pickupFromTime'])
            this.confOrderForm.controls['consigneeToTime'].setValue(res['pickupDelivery']['pickupToTime'])
          }
        }, 1000);
      } else {
        // this.deliveryDetails['dropFromTime'] = 'c7b3e35a-f7ed-11e9-8462-163046d26cd4'
        // this.deliveryDetails['dropToTime'] = 'c7b3e641-f7ed-11e9-8462-163046d26cd4'
        this.confOrderForm.controls['consigneeFromTime'].setValue('c7b3e35a-f7ed-11e9-8462-163046d26cd4')
        this.confOrderForm.controls['consigneeToTime'].setValue('c7b3e641-f7ed-11e9-8462-163046d26cd4')
      }
      this.poNo = res['poNumber']
      this.referenceNo = res['referenceNumber']
      if (res["shipperCompanyId"] != null) {
        this.shipperCompanyId = res["shipperCompanyId"]
        this.getCompanyDetails(this.shipperCompanyId, 'start')
      }
      if (res["consigneeCompanyId"] != null) {
        this.consigneeCompanyId = res["consigneeCompanyId"]
        this.getCompanyDetails(this.consigneeCompanyId, 'end')
      }
      for (let i = 0; i < this.shipmenItems.length; i++) {

        if (this.shipmenItems[i] && this.shipmenItems[i].unit == 'cm') {
          this.shipmenItems[i].length = this.shipmenItems[i].length ;
          this.shipmenItems[i].totalWeight = this.shipmenItems[i].totalWeight;
          this.shipmenItems[i].height = this.shipmenItems[i].height ;
          this.shipmenItems[i].breadth = this.shipmenItems[i].breadth;
        }

      }
      for (let j = 0; j < this.shipmenItems.length; j++) {
        this.shipmentItemsWeight += this.shipmenItems[j]['qty'] * this.shipmenItems[j].totalWeight;
        this.shipmentItemsQuantity += this.shipmenItems[j]['qty'];
        this.shipmentTotalValue += this.shipmenItems[j].shipmentItemValue;
        this.weightUnit=this.shipmenItems[j].wghtUnit;
      }
    })

    this.getShippingHours();
    this.bol = Math.floor(Math.random() * (this.max + 1));
    this.po = Math.floor(Math.random() * (this.max + 1));


    let promise2 = this.scHttpService.getHttpService(ServerURLS.getAllDropdownValues.action, '');
    promise2.then(res => {
      this.allDropdownValues = res
    })
    this.getPriceData();

  }
  checkTime(hour: number, minute: number): boolean {
    const result = this.scHttpService.isAtLeast30MinutesMore(hour, minute);
    if (result) {
      return true;
    } else {
      return false;
    }
  }
  removeInsurance() {
    this.displayData = true
    this._loaderService.stringSubject.next(true);
    let url = ServerURLS.removeInsurance.action + '?quoteId=' + this.quoteId;
    let promise = this.scHttpService.putHttpService(url, '');
    promise.then((res: any) => {
      this.toastr.success("Insurance Removed");
      this.insuranceData.accepted = false;
      sessionStorage.setItem('_adminorderInsuranceData', JSON.stringify({
        accepted: false, certificateNumber: this.insuranceData.certificateNumber, amount: this.insuranceData.amount
      }))
      this.insuranceAmount = 0
      this.displayData = false
      this._loaderService.stringSubject.next(false);
    })
      .catch((error: any) => {
        this.toastr.error("Something Went Wrong!")
        this.displayData = false
        this._loaderService.stringSubject.next(false);
      })
  }

  setFlagValue(item) {
    if (item) {
      const updatedShipperItem = this.shipperAllItems.find(shipperItem => shipperItem.icon === item.icon);
      if (updatedShipperItem && this.selectedShipperItem !== updatedShipperItem) {
        this.selectedShipperItem = updatedShipperItem;
        this.myGroup.get('selectedShipperItem').setValue(this.selectedShipperItem);
      }

      const updatedConsigneeItem = this.consigneeAllItems.find(consigneeItem => consigneeItem.icon === item.icon);
      if (updatedConsigneeItem && this.selectedConsigneeItem !== updatedConsigneeItem) {
        this.selectedConsigneeItem = updatedConsigneeItem;
        this.myConsigneeGroup.get('selectedConsigneeItem').setValue(this.selectedConsigneeItem);
      }
    }
  }
  // this.confOrderForm.controls['shipperPrimaryContact'].setValue(shipperFistName + " " + shipperlastName);
  //       this.confOrderForm.controls['shipperContactPhone'].setValue(shipperPhoneNumber);
  //       this.confOrderForm.controls['shipperContactEmail'].setValue(shipperEmail);
  //       this.confOrderForm.controls['shipperPrimaryContactExt'].setValue(shipperPhoneExt);
  //       this.setFlagValue((shipperCountry) === 'CA' ? this.shipperAllItems[1] : this.shipperAllItems[0])


  //       this.confOrderForm.controls['consigneePrimaryContact'].setValue(consigneeFistName + ' ' + consigneelastName);
  //       this.confOrderForm.controls['consigneeContactPhone'].setValue(consigneePhoneNumber);
  //       this.confOrderForm.controls['consigneeContactEmail'].setValue(consigneeEmail);
  //       this.confOrderForm.controls['consigneePrimaryContactExt'].setValue(consigneePhoneExt);
  //       this.setFlagValue((consigneeCountry) === 'CA' ? this.shipperAllItems[1] : this.shipperAllItems[0])
  getListOfPrimaryContact(companyName, mode, patchValue) {
    if (mode === 'shipper') {
      let url = ServerURLS.getPrimaryContactList.action + '?contactType=CS&custId=' + this.customerId + '&companyName=' + companyName;
      let promise = this.scHttpService.getHttpService(url, '');
      promise.then((res: any) => {
        if (res.length > 0) {
          res.forEach((element, index) => {
            let data = {
              companyId: this.shipperCompanyId,
              contactId: null,
              email: element.email,
              extension: element.phoneExt,
              flag: element.country === 'US' ? this.shipperAllItems[0] : this.shipperAllItems[1],
              firstName: element.firstName,
              lastName: element.lastName,
              phoneNumber: element.phoneNumber,
              position: element.title,
              isNotificationOn: false,
              isPrimary: false
            }
            if (patchValue) {
              if (element.primary) {
                this.confOrderForm.controls['shipperPrimaryContact'].setValue(element.firstName + " " + element.lastName);
                this.confOrderForm.controls['shipperContactPhone'].setValue(element.phoneNumber);
                this.confOrderForm.controls['shipperContactEmail'].setValue(element.email);
                this.confOrderForm.controls['shipperPrimaryContactExt'].setValue(element.phoneExt);
                this.setFlagValue((element.country) === 'CA' ? this.shipperAllItems[1] : this.shipperAllItems[0])
              }
            }

            this.addRes['start'] = [data].concat(this.addRes['start'])
          })
        }
      }).catch((error: any) => {

      })
    }
    if (mode === 'consignee') {
      let url = ServerURLS.getPrimaryContactList.action + '?contactType=CC&custId=' + this.customerId + '&companyName=' + companyName;
      let promise = this.scHttpService.getHttpService(url, '');
      promise.then((res: any) => {
        if (res.length > 0) {
          res.forEach((element, index) => {
            let data = {
              companyId: this.shipperCompanyId,
              contactId: null,
              email: element.email,
              extension: element.phoneExt,
              flag: element.country === 'US' ? this.shipperAllItems[0] : this.shipperAllItems[1],
              firstName: element.firstName,
              lastName: element.lastName,
              phoneNumber: element.phoneNumber,
              position: element.title,
              isNotificationOn: false,
              isPrimary: false
            }
            if (patchValue) {
              if (element.primary) {
                this.confOrderForm.controls['consigneePrimaryContact'].setValue(element.firstName + ' ' + element.lastName);
                this.confOrderForm.controls['consigneeContactPhone'].setValue(element.phoneNumber);
                this.confOrderForm.controls['consigneeContactEmail'].setValue(element.email);
                this.confOrderForm.controls['consigneePrimaryContactExt'].setValue(element.phoneExt);
                this.setFlagValue((element.country) === 'CA' ? this.shipperAllItems[1] : this.shipperAllItems[0])
              }
            }
            this.addRes['end'] = [data].concat(this.addRes['end'])
          })
        }
      }).catch((err: any) => {

      })
    }
  }
  getAllBrokerInfo() {
    // let promise1 = this.scHttpService.getHttpService(ServerURLS.getAllBrokers.action ,'');
    let url = ServerURLS.getAllBrokers.action;
    if (this.shipperCountry == 'US' && this.consigneeCountry == 'CA') {
      url = url + '?fromCountry=USA&toCountry=CA'
    } else if (this.shipperCountry == 'CA' && this.consigneeCountry == 'US') {
      url = url + '?fromCountry=CA&toCountry=USA'
    }
    url = url + '&customerId=' + this.customerId
    let promise2 = this.scHttpService.getHttpService(url, '');
    promise2.then((res: any) => {
      this.brokers = res
      res.forEach((element, index) => {
        console.log(element.default, index)
        if (element.default == true) {
          this.confOrderForm.controls['customBrokerPhone'].setValue(element.brokerPhone)
          this.confOrderForm.controls['customBrokerEmail'].setValue(element.brokerEmail);
          let data = {
            'quoteId': this.quoteId,
            'brokerId': element.brokerId,
            'brokerCompany': element.brokerCompany,
            'brokerName': element.brokerName,
            'brokerPhone': element.brokerPhone,
            'contactName': element.contactName,
            'brokerEmail': element.brokerEmail
          }
          if (!this.selectedCustomBrokerId) {
            this.selectedCustomBrokerId = element.brokerId
          }
          this.custombrokerinfo.push(data)

        }
        this.brokers.filter((element) => {
          if (element.brokerId == this.selectedCustomBrokerId) {
            this.selectedCustomBrokerData = {
              "quoteId": this.quoteId,
              "brokerId": element.brokerId,
              "brokerCompany": element.brokerCompany,
              "brokerName": element.brokerName,
              "brokerPhone": element.brokerPhone,
              'contactName': element.contactName,
              "brokerEmail": element.brokerEmail,
              'brokerExt': element.phoneExt
            }

            this.custombrokerinfo.push(this.selectedCustomBrokerData)
            this.confOrderForm.controls['brokerCompanyName'].setValue(this.selectedCustomBrokerData['brokerName']);
            this.confOrderForm.controls['brokerContactName'].setValue(this.selectedCustomBrokerData['contactName'])
            this.confOrderForm.controls['customBrokerPhone'].setValue(this.selectedCustomBrokerData['brokerPhone']);
            this.confOrderForm.controls['customBrokerEmail'].setValue(this.selectedCustomBrokerData['brokerEmail']);
            this.confOrderForm.controls['ext'].setValue(this.selectedCustomBrokerData['brokerExt']);
            // brokerCompanyName: ['', [Validators.required]],
            //   brokerContactName: [
          } else {
            let data = {
              'quoteId': '',
              'brokerId': '',
              'brokerCompany': '',
              'brokerName': '',
              'brokerPhone': '',
              'contactName': '',
              'brokerEmail': '',
              'brokerExt': '',
            }
            this.selectedCustomBrokerData = data
          }
        })
      })
    })









    // promise1.then( res => {
    //   this.brokers = res;
    //   if (this.shipperCountry == 'US' && this.consigneeCountry == "CA") {
    //     if(this.brokers){
    //     this.brokers.filter(element => {
    //       if (element.default == true && element.fromCountry == "USA" && element.toCountry == "CA") {
    //         this.confOrderForm.controls['customBrokerPhone'].setValue(element.brokerPhone);
    //         this.confOrderForm.controls['customBrokerEmail'].setValue(element.brokerEmail);
    //         let data = {
    //           "quoteId": this.quoteId,
    //           "brokerId": element.brokerId,
    //           "brokerCompany": element.brokerCompany,
    //           "brokerName": element.brokerName,
    //           "brokerPhone": element.brokerPhone,
    //           "brokerEmail": element.brokerEmail,
    //         }
    //         this.custombrokerinfo.push(data)
    //       }
    //     })
    //   }
    //   }
    //   else if (this.shipperCountry == 'CA' && this.consigneeCountry == "US") {
    //     this.brokers.filter(element => {
    //       if (element.default == true && element.fromCountry == "CA" && element.toCountry == "USA") {
    //         this.confOrderForm.controls['customBrokerPhone'].setValue(element.brokerPhone);
    //         this.confOrderForm.controls['customBrokerEmail'].setValue(element.brokerEmail);
    //         let data = {
    //           "quoteId": this.quoteId,
    //           "brokerId": element.brokerId,
    //           "brokerCompany": element.brokerCompany, 
    //           "brokerName": element.brokerName,
    //           "brokerPhone": element.brokerPhone,
    //           "brokerEmail": element.brokerEmail,
    //         }
    //         this.custombrokerinfo.push(data)
    //       }
    //     })
    //   }
    //   this.brokers.filter((element) => {
    //     if(element.brokerId == this.selectedCustomBrokerId){
    //       this.selectedCustomBrokerData = {
    //         "quoteId": this.quoteId,
    //         "brokerId": element.brokerId,
    //         "brokerCompany": element.brokerCompany, 
    //         "brokerName": element.brokerName,
    //         "brokerPhone": element.brokerPhone,
    //         "brokerEmail": element.brokerEmail,
    //       }
    //       this.custombrokerinfo.push(this.selectedCustomBrokerData)
    //       this.confOrderForm.controls['customBrokerPhone'].setValue(this.selectedCustomBrokerData['brokerPhone']);
    //       this.confOrderForm.controls['customBrokerEmail'].setValue(this.selectedCustomBrokerData['brokerEmail']);
    //     }
    //   })
    // });
  }
  selectedCustomBrokerId: any;
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  selectOption(option: any) {
    this.shipperSelected = option;
    this.dropdownOpen = false;
  }
  selectedShipperAddressCountry: any;
  selectedCosigneeAddressCountry: any;
  // orderIsWithInCanada: boolean = true;
  classToSet: any[] = [];
  getAddress(id): void {
    let url4 = ServerURLS.getAddressSummary.action + "?id=" + id;
    let promise4 = this.scHttpService.getHttpService(url4, '');

    promise4.then((a) => {
      this.selectedShipperAddressCountry = a['data'].country;

      // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada()
      this.addressData['shipperLattitude'] = a['data']['latitude']
      this.addressData['shipperLongitude'] = a['data']['longitude'];
      this.quoteDetails["sourceAddrDetails"] = a['data'];
      let srcName = ''
      if (this.quoteDetails['sourceAddrDetails'].firstName != null) {
        srcName = this.quoteDetails['sourceAddrDetails'].firstName
      }
      if (this.quoteDetails['sourceAddrDetails'].lastName != null) {
        srcName += ' ' + this.quoteDetails['sourceAddrDetails'].lastName
      }
      if (this.quoteDetails['sourceAddrDetails'].company != this.quoteDetails['sourceAddrDetails'].addrLine1 && '' != this.quoteDetails['sourceAddrDetails'].addrLine1) {
        this.confOrderForm.controls['shipperCompanyName'].setValue(this.quoteDetails['sourceAddrDetails'].company);
      }
      this.confOrderForm.controls['shipperAddressLine1'].setValue(this.quoteDetails['sourceAddrDetails'].addrLine1);
      this.confOrderForm.controls['shipperAddressLine2'].setValue("");
      this.confOrderForm.controls['shipperCountry'].setValue(this.quoteDetails['sourceAddrDetails'].country);
      this.confOrderForm.controls['shipperState'].setValue(this.quoteDetails['sourceAddrDetails'].state);
      this.confOrderForm.controls['shipperCity'].setValue(this.quoteDetails['sourceAddrDetails'].city);
      this.confOrderForm.controls['shipperZip'].setValue(this.quoteDetails['sourceAddrDetails'].zipCode);
      this.confOrderForm.controls['shipperPrimaryContact'].setValue(srcName)
      this.confOrderForm.controls['shipperContactPhone'].setValue(this.quoteDetails['sourceAddrDetails'].phoneNumber)
      this.confOrderForm.controls['shipperPrimaryContactExt'].setValue(this.quoteDetails['sourceAddrDetails'].extension)

      this.confOrderForm.controls['selectedShipperItem'].setValue(this.quoteDetails['sourceAddrDetails'].flag)
      this.confOrderForm.controls['shipperContactEmail'].setValue(this.quoteDetails['sourceAddrDetails'].email)
      this.shipperCompany = this.quoteDetails['sourceAddrDetails'].company;
      this.displayLoader = false;
      this.displayData = false
      this._loaderService.stringSubject.next(false);
    });
  }
  getAddress2(id) {
    let url5 = ServerURLS.getAddressSummary.action + "?id=" + id;
    let promise5 = this.scHttpService.getHttpService(url5, '');

    promise5.then((a) => {
      this.selectedCosigneeAddressCountry = a['data'].country;
      // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada()
      this.addressData['consigneeLatitude'] = a['data']['latitude'];
      this.addressData['consigneeLongitude'] = a['data']['longitude'];
      this.quoteDetails["destAddrDetails"] = a['data'];

      let conName = ''
      if (this.quoteDetails['destAddrDetails'].firstName != null) {
        conName = this.quoteDetails['destAddrDetails'].firstName
      }
      if (this.quoteDetails['destAddrDetails'].lastName != null) {
        conName += ' ' + this.quoteDetails['destAddrDetails'].lastName
      }
      if (this.quoteDetails['destAddrDetails'].company != this.quoteDetails['destAddrDetails'].addrLine1 && '' != this.quoteDetails['destAddrDetails'].addrLine1) {
        this.confOrderForm.controls['consigneeCompanyName'].setValue(this.quoteDetails['destAddrDetails'].company);
      }
      this.confOrderForm.controls['consigneeAddressLine1'].setValue(this.quoteDetails['destAddrDetails'].addrLine1);
      this.confOrderForm.controls['consigneeAddressLine2'].setValue('');
      this.confOrderForm.controls['consigneeCountry'].setValue(this.quoteDetails['destAddrDetails'].country);
      this.confOrderForm.controls['consigneeState'].setValue(this.quoteDetails['destAddrDetails'].state);
      this.confOrderForm.controls['consigneeCity'].setValue(this.quoteDetails['destAddrDetails'].city);
      this.confOrderForm.controls['consigneeZip'].setValue(this.quoteDetails['destAddrDetails'].zipCode);
      this.confOrderForm.controls['consigneePrimaryContact'].setValue(conName)
      this.confOrderForm.controls['consigneeContactPhone'].setValue(this.quoteDetails['destAddrDetails'].phoneNumber)
      this.confOrderForm.controls['consigneeContactEmail'].setValue(this.quoteDetails['destAddrDetails'].email)
      this.confOrderForm.controls['consigneePrimaryContactExt'].setValue(this.quoteDetails['destAddrDetails'].extension)
      this.confOrderForm.controls['selectedConsigneeItem'].setValue(this.quoteDetails['destAddrDetails'].flag)
      this.consigneeCompany = this.quoteDetails['destAddrDetails'].company;
      this.displayLoader = false;
      this.displayData = false
      this._loaderService.stringSubject.next(false);
    });
  }

  // removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada() {
  //   if (this.selectedShipperAddressCountry && this.selectedCosigneeAddressCountry) {

  //     if (this.selectedShipperAddressCountry === 'CA' && this.selectedCosigneeAddressCountry === 'CA') {
  //       this.orderIsWithInCanada = true;
  //       this.classToSet = []
  //     } else {
  //       this.orderIsWithInCanada = false;
  //     }
  //   }
  // }
  closeBlueBox() {
    this.blueBoxClosed = true;
  }
  hsTrafficValidation: boolean = false;
  hsTarffValidation(value, index) {
    let patterm = /^[0-9]+$/;

    if (value == '' || value == null || value == undefined) {
      this.hsTrafficValidation = true;
      document.getElementById('hrTrafficId' + index).style.display = 'block'
      document.getElementById('hrTrafficId' + index).innerText = 'This is required\n'
    } else {
      document.getElementById('hrTrafficId' + index).style.display = 'none'
      document.getElementById('hrTrafficId' + index).innerText = ''
      this.hsTrafficValidation = false;

      if (!patterm.test(value)) {
        document.getElementById('hrTrafficId' + index).style.display = 'block'
        document.getElementById('hrTrafficId' + index).innerText = 'Enter Valid Number\n'
      } else {
        if ((value).length < 6) {
          document.getElementById('hrTrafficId' + index).style.display = 'block'
          document.getElementById('hrTrafficId' + index).innerText = 'Length Should be Greater Than 5\n'
        } else {
          document.getElementById('hrTrafficId' + index).style.display = 'none'
          document.getElementById('hrTrafficId' + index).innerText = ''
        }
      }

    }




  }
  onSubmit() {
    this.submitted = true;
    if (this.shipperFromTimeError) {
      this.toastr.error("Pickup from Time is greater than Pickup to Time", '', {
        timeOut: 5000,
      });
      return;
    }
    if (this.shipperFromTimeWithin2Hr) {
      this.toastr.error("Our partners require a 2 hour pick up window. Please adjust your close time or pick up time to reflect 2 hours. You may schedule for next day if you are not able to adjust time.", '', {
        timeOut: 5000,
      });
      return;
    }
    if (this.consigneeFromTimeError) {
      this.toastr.error(this.consigneeFromTimeErrorText, '', {
        timeOut: 5000,
      });
      return;
    }
    if (this.isCustomsRequired === 1) {
      this.shipmenItems.forEach((element, index) => {
        this.hsTarffValidation(element['hsTariffNumber'], index
        )
      })
    }
    if (this.confOrderForm.invalid) {
      this.toastr.error('Please fill all details', '', {
        timeOut: 5000,
      });
      return;
    }
    else if (this.brokerEnabled && this.isCustomsRequired == 1) {
      let hscodeNdecsmissing = false;
      this.shipmenItems.forEach((element, index) => {
        if (element['hsTariffNumber'] == '' || element['hsTariffNumber'].length < 6) {
          hscodeNdecsmissing = true;
          this.hsTrafficValidation = true;
          this.toastr.error('Please enter tariff number', '', {
            timeOut: 5000,
          });
          return
        }
        else {
          this.hsTrafficValidation = false;
          if (this.brokerEnabled && this.custombrokerinfo.length == 0) {
            this.toastr.error('Please Add Customs Broker Information', '', {
              timeOut: 5000,
            });
            return;
          }
          else {
            this.shipmenItems.forEach((element, index) => {
              let data = {
                "itemId": element['quoteItemId'],
                "hsTariffNumber": element['hsTariffNumber'],
                "description": element['description']
              }
              this.items.push(data)
            });
          }
        }
      });
      if (!hscodeNdecsmissing && this.confOrderForm.valid) {
        this.shipmenItems.forEach((element, index) => {
          let data = {
            "itemId": element['quoteItemId'],
            "hsTariffNumber": element['hsTariffNumber'],
            "description": element['description']
          }
          this.items.push(data)
        });
        let promise1 = this.scHttpService.putHttpService(ServerURLS.hsTariffdescription.action, { items: this.items });
        promise1.then(res => {
          if (this.brokerEnabled) {
            this.brokerSaveAPI()
            this.checkout();
          }
        }, error => {
          this.toastr.error('Something Went wrong.', 'Please try again.', {
            timeOut: 10000,
          });
        });
      } else {
        this.toastr.error("Please fill all the required Fields.")
      }

    }
    else if (this.brokerEnabled && this.isCustomsRequired == 0) {
      this.brokerSaveAPI()
      this.checkout();
    } else {
      this.checkout()
    }
  }

  brokerSaveAPI() {

    let data = {
      "quoteId": this.quoteId,
      "brokerId": this.selectedCustomBrokerId,
      "brokerCompany": null,
      "brokerName": this.confOrderForm.controls['brokerCompanyName'].value ? this.confOrderForm.controls['brokerCompanyName'].value : "",
      "brokerPhone": this.confOrderForm.get('customBrokerPhone').value ? this.confOrderForm.get('customBrokerPhone').value : "",
      'contactName': this.confOrderForm.get('brokerContactName').value ? this.confOrderForm.get('brokerContactName').value : "",
      'fromCountry': this.shipperCountry === 'US' ? 'USA' : 'CA',
      'toCountry': this.consigneeCountry === 'US' ? 'USA' : 'CA',
      "brokerEmail": this.confOrderForm.get('customBrokerEmail').value ? this.confOrderForm.get('customBrokerEmail').value : "",
      'phoneExt': this.confOrderForm.get('ext').value ? this.confOrderForm.get('ext').value : ""
    }
    let saveBrokerApi = this.scHttpService.postHttpService(ServerURLS.saveBroker.action, data);
    saveBrokerApi.then((response) => {
      this.toastr.success('Broker information saved successfully.', 'Success', {
        timeOut: 10000,
      });
    }, (error) => {
      this.toastr.error('Error while saving broker information.', 'Please try again', {
        timeOut: 10000,
      });
    })
  }
  selectedCustomBrokerData: any;
  get f() {
    return this.confOrderForm.controls;
  }

  open(modal) {
    if (modal == 'editaddress') {
      const modalRef = this.modalService.open(ShipperAddressEditComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
    }

  }

  getPriceData() {
    let url = ServerURLS.getPriceData.action + '?quoteId=' + this.quoteId + '&customerId=' + this.customerId
    let promise = this.scHttpService.getHttpService(url, '');
    promise.then((response: any) => {

      this.priceSummary = response;
      this.insuranceAmount = response.scInsurance
      if (this.priceSummary['charges']) {

        this.priceSummary.charges.forEach((item, index) => {
          for (let key of Object.keys(item)) {
            this.charges.push([key, item[key]])
          }
        });
      }
    })
  }

  getShippingHours() {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.getShippingHours.action, '');
    promise1.then(h => {
      this.shipingHours = h["data"]
      this.rec = h["data"]
    });
  }

  getQuoteAddressDetails(id) {
    let url4 = ServerURLS.getAddressSummary.action + "?id=" + id;
    let promise4 = this.scHttpService.getHttpService(url4, '');
    promise4.then((c) => {
      c['data'].zipCode
    })
  }

  updateAddress(value, type) {
    if (value == "-1") {
      if (this.confOrderForm.get('shipperCompanyName').invalid) {
        this.confOrderForm.get('shipperCompanyName').markAsTouched()
        this.toastr.error("Please Enter the Shipper Company Name")
      } else {
        if (type == "start") {
          const modalRef = this.modalService.open(ContactShipperComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal1' });
          modalRef.result.then((result) => {
            if (result != null) {
              this.displayData = true
              this._loaderService.stringSubject.next(true);
              let dataObj = {
                "companyName": this.confOrderForm.get('shipperCompanyName').value ? this.confOrderForm.get('shipperCompanyName').value : "",
                "contactType": "CS",
                "firstName": result.firstName,
                "lastName": result.lastName,
                "title": result.Position,
                "email": result.email,
                "country": result.selectedFlagItem.icon.includes('USA') ? 'US' : 'CA',
                "phoneNumber": result.phone,
                "custId": this.customerId,
                "phoneExt": result.extension
              }
              let url = ServerURLS.savePrimaryContactToList.action;
              let promise = this.scHttpService.postHttpService(url, dataObj);
              promise.then((res: any) => {
                this.displayData = false
                this._loaderService.stringSubject.next(false);
                this.toastr.success("Primary Contact Added Successfully")
              }).catch((err: any) => {
                this.displayData = false
                this._loaderService.stringSubject.next(false);
                this.toastr.error("Something Went Wrong! Please try again lateer")
              })
              this.shipper = result
              modalRef.close();
              let data = {
                companyId: this.shipperCompanyId,
                contactId: null,
                email: result.email,
                extension: result.extension,
                flag: result.selectedFlagItem,
                firstName: result.firstName,
                lastName: result.lastName,
                phoneNumber: result.phone,
                position: result.Position,
                isNotificationOn: false,
                isPrimary: false
              }
              this.addRes['start'] = [data].concat(this.addRes['start']);
              this.updateAddress('0', 'start');
              if (this.shipperCompanyId) {
                let promise1 = this.scHttpService.postHttpService(ServerURLS.addContact.action, '');
                promise1.then(c => {
                  this.displayData = false
                  this._loaderService.stringSubject.next(false);
                  this.displayLoader = false;
                  if (c != null) {
                    this.quoteDetails['sourceAddrDetails']['addressId'] = c['addressId'];
                  }
                  this.displayData = false
                  this._loaderService.stringSubject.next(false);
                  this.displayLoader = false;
                  this.toastr.success('Address has been saved succsessfully.', '', {
                    timeOut: 10000,
                  })
                }, error => {
                  this.displayData = false
                  this._loaderService.stringSubject.next(false);
                  this.displayLoader = false;
                  this.toastr.error('Something Went wrong.', 'Please try again.', {
                    timeOut: 10000,
                  });
                });
              }
              modalRef.close();
            }
          }, (result) => {
            const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="shipperContactPhone"]');
            this.shipperModelStatus = false;
            invalidControl.focus();
          });
        }

      }
      if (this.confOrderForm.get('consigneeCompanyName').invalid) {
        this.confOrderForm.get('consigneeCompanyName').markAllAsTouched();
        this.toastr.error("Please Enter the Consignee Company Name")
      } else {
        if (type === "end") {
          const modalRef = this.modalService.open(ContactShipperComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal1' });
          modalRef.result.then((result) => {
            if (result != null) {
              this.displayData = true
              this._loaderService.stringSubject.next(true);
              let dataObj = {


                "companyName": this.confOrderForm.get('consigneeCompanyName').value ? this.confOrderForm.get('consigneeCompanyName').value : "",
                "contactType": "CC",
                "firstName": result.firstName,
                "lastName": result.lastName,
                "title": result.Position,
                "email": result.email,
                "country": result.selectedFlagItem.icon.includes('USA') ? 'US' : 'CA',
                "phoneNumber": result.phone,
                "custId": this.customerId,
                "phoneExt": result.extension
              }
              let url = ServerURLS.savePrimaryContactToList.action;
              let promise = this.scHttpService.postHttpService(url, dataObj);
              promise.then((res: any) => {
                this.displayData = false
                this._loaderService.stringSubject.next(false);
                this.toastr.success("Primary Contact Added Successfully")
              }).catch((err: any) => {
                this.displayData = false
                this._loaderService.stringSubject.next(false);
                this.toastr.error("Something Went Wrong! Please try again lateer")
              })
              this.consignee = result;
              modalRef.close();
              let data = {
                companyId: this.shipperCompanyId,
                contactId: null,
                email: result.email,
                extension: result.extension,
                flag: result.selectedFlagItem,
                firstName: result.firstName,
                lastName: result.lastName,
                phoneNumber: result.phone,
                position: result.Position,
                isNotificationOn: false,
                isPrimary: false
              }
              this.addRes['end'] = [data].concat(this.addRes['end']);
              this.updateAddress('0', 'end');
              if (this.consigneeCompanyId) {
                let promise1 = this.scHttpService.postHttpService(ServerURLS.addContact.action, '');
                promise1.then(c => {
                  this.displayData = false
                  this._loaderService.stringSubject.next(false);
                  this.displayLoader = false;
                  if (c != null) {
                    this.quoteDetails['destAddrDetails']['addressId'] = c['addressId'];
                  }
                  this.displayData = false
                  this._loaderService.stringSubject.next(false);
                  this.displayLoader = false;
                  this.toastr.success('Address has been saved succsessfully.', '', {
                    timeOut: 10000,
                  })
                }, error => {
                  this.displayData = false
                  this._loaderService.stringSubject.next(false);
                  this.displayLoader = false;
                  this.toastr.error('Something Went wrong.', 'Please try again.', {
                    timeOut: 10000,
                  });
                });
              }
              modalRef.close();
            } else {
              const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="consigneeContactPhone"]');
              invalidControl.focus();
              modalRef.close();
              return;
            }
          });
        }
      }


      return;
    } else {

      let key = parseInt(value);
      if (type == "start") {
        this.quoteDetails['sourceAddrDetails']['firstName'] = this.addRes[type][key].firstName;
        this.quoteDetails['sourceAddrDetails']['lastName'] = this.addRes[type][key].lastName;
        this.quoteDetails['sourceAddrDetails']['phoneNumber'] = this.addRes[type][key].phoneNumber;
        this.quoteDetails['sourceAddrDetails']['email'] = this.addRes[type][key].email;
        this.quoteDetails['sourceAddrDetails']['extension'] = this.addRes[type][key].extension;
        this.quoteDetails['sourceAddrDetails']['flag'] = this.addRes[type][key].flag;
        let srcName = ''
        if (this.quoteDetails['sourceAddrDetails'].firstName != null) {
          srcName = this.quoteDetails['sourceAddrDetails'].firstName
        }
        if (this.quoteDetails['sourceAddrDetails'].lastName != null) {
          srcName += ' ' + this.quoteDetails['sourceAddrDetails'].lastName
        }
        this.confOrderForm.controls['shipperPrimaryContact'].setValue(srcName);
        this.confOrderForm.controls['shipperContactPhone'].setValue(this.quoteDetails['sourceAddrDetails'].phoneNumber);
        this.confOrderForm.controls['shipperContactEmail'].setValue(this.quoteDetails['sourceAddrDetails'].email);
        this.confOrderForm.controls['shipperPrimaryContactExt'].setValue(this.quoteDetails['sourceAddrDetails'].extension);
        // this.myGroup.get('selectedShipperItem').setValue(this.addRes[type][key].flag);

        // this.myGroup.controls['selectedShipperItem'].setValue(this.addRes[type][key].flag);
        // this.myGroup.get('selectedShipperItem').setValue(this.addRes[type][key].flag);
        this.setFlagValue(this.addRes[type][key].flag)
      } else {
        this.quoteDetails['destAddrDetails']['firstName'] = this.addRes[type][key].firstName;
        this.quoteDetails['destAddrDetails']['lastName'] = this.addRes[type][key].lastName;
        this.quoteDetails['destAddrDetails']['phoneNumber'] = this.addRes[type][key].phoneNumber;
        this.quoteDetails['destAddrDetails']['email'] = this.addRes[type][key].email;
        this.quoteDetails['destAddrDetails']['extension'] = this.addRes[type][key].extension;
        this.quoteDetails['destAddrDetails']['flag'] = this.addRes[type][key].flag;
        let conName = ''
        if (this.quoteDetails['destAddrDetails'].firstName != null) {
          conName = this.quoteDetails['destAddrDetails'].firstName
        }
        if (this.quoteDetails['destAddrDetails'].lastName != null) {
          conName += ' ' + this.quoteDetails['destAddrDetails'].lastName
        }
        this.confOrderForm.controls['consigneePrimaryContact'].setValue(conName);
        this.confOrderForm.controls['consigneeContactPhone'].setValue(this.quoteDetails['destAddrDetails'].phoneNumber);
        this.confOrderForm.controls['consigneeContactEmail'].setValue(this.quoteDetails['destAddrDetails'].email);
        this.confOrderForm.controls['consigneePrimaryContactExt'].setValue(this.quoteDetails['destAddrDetails'].extension);
        this.setFlagValue(this.addRes[type][key].flag)

        // this.confOrderForm.controls['selectedConsigneeItem'].setValue(this.addRes[type][key].flag);
      }
    }

    if (type == "shipper") {
      this.shipper = this.addRes['sourceAddrDetails'].find(n => n.addressId == value)
      this.shipper = this.addRes['sourceAddrDetails'].find(n => n.addressId == value)
    }
    if (type == "consignee") {
      this.consignee = this.addRes['destAddrDetails'].find(n => n.addressId == value)
      this.consignee = this.addRes['destAddrDetails'].find(n => n.addressId == value)
    }
  }

  getBol(quoteId) {
    let promise1 = this.scHttpService.getHttpService(ServerURLS.generateBOLNumber.action + "?quoteId=" + quoteId, { withCredentials: true });
    promise1.then(b => {
      this.bolNumber = b['BOLNumber'];
    }, error => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
    });
  }

  saveDataToAddressBook(check) {
    if (check == "end") {

      let consigneeData = {
        "quoteId": this.quoteId,
        "openTimeId": this.confOrderForm.controls['consigneeFromTime'].value,
        "closeTimeId": this.confOrderForm.controls['consigneeToTime'].value,
        "pickupInstructions": this.confOrderForm.controls['shipperNote'].value,
        "dropInstructions": this.confOrderForm.controls['consigneeNote'].value,
        "specialInstructions": this.additionalNote,
        "primaryContact": {
          "firstName": this.quoteDetails['destAddrDetails']['firstName'],
          "lastName": this.quoteDetails['destAddrDetails']['lastName'],
          "position": "",
          "phoneNumber": this.quoteDetails['destAddrDetails']['phoneNumber'],
          "extension": this.quoteDetails['destAddrDetails']['extension'],
          "flag": this.quoteDetails['destAddrDetails']['flag'],
          "email": this.quoteDetails['destAddrDetails']['email'],
          "isNotificationOn": true
        },
        "companyDetails": {
          "companyName": this.confOrderForm.controls['consigneeCompanyName'].value, //this.consigneeCompany,
          "streetLine1": this.quoteDetails['destAddrDetails'].addrLine1,
          "streetLine2": this.quoteDetails['destAddrDetails'].addrLine2,
          "city": this.quoteDetails['destAddrDetails'].city,
          "province": this.quoteDetails['destAddrDetails'].state,
          "country": this.quoteDetails['destAddrDetails'].country,
          "zipcode": this.quoteDetails['destAddrDetails'].zipCode,
          "latitude": this.quoteDetails['destAddrDetails'].latitude,
          "longitude": this.quoteDetails['destAddrDetails'].longitude,
          "fullAddress": this.quoteDetails['destAddrDetails'].fullAddr
        },
        "addressType": "consignee"
      }
      this.displayLoader = true;
      this.displayData = true
      this._loaderService.stringSubject.next(true);
      let promise2 = this.scHttpService.postHttpService(ServerURLS.saveDataToAddressBook.action, consigneeData);
      promise2.then(c => {
        this.displayData = false
        this._loaderService.stringSubject.next(false);
        this.displayLoader = false;
        if (c != null) {

          this.addRes['end'] = [consigneeData].concat(this.addRes['end']);

          this.quoteDetails['destAddrDetails']['addressId'] = c['addressId'];
          this.updateAddress('0', 'end');
        }
        this.displayData = false
        this._loaderService.stringSubject.next(false);
        this.displayLoader = false;
        this.toastr.success('Address has been saved succsessfully.', '', {
          timeOut: 10000,
        })
      }, error => {
        this.displayData = false
        this._loaderService.stringSubject.next(false);
        this.displayLoader = false;
        this.toastr.error('Something Went wrong.', 'Please try again.', {
          timeOut: 10000,
        });
      });

    } else {

      let shipperData = {
        "quoteId": this.quoteId,
        "openTimeId": this.confOrderForm.controls['consigneeFromTime'].value,
        "closeTimeId": this.confOrderForm.controls['consigneeToTime'].value,
        "pickupInstructions": this.confOrderForm.controls['shipperNote'].value,
        "dropInstructions": this.confOrderForm.controls['consigneeNote'].value,
        "specialInstructions": this.additionalNote,
        "primaryContact": {
          "firstName": this.quoteDetails["sourceAddrDetails"]['firstName'],
          "lastName": this.quoteDetails["sourceAddrDetails"]['lastName'],
          "position": "",
          "phoneNumber": this.quoteDetails["sourceAddrDetails"]['phoneNumber'],
          "extension": this.quoteDetails['sourceAddrDetails']['extension'],
          "flag": this.quoteDetails['sourceAddrDetails']['flag'],
          "email": this.quoteDetails["sourceAddrDetails"]['email'],
          "isNotificationOn": true
        },
        "companyDetails": {
          "companyName": this.confOrderForm.controls['shipperCompanyName'].value, //this.shipperCompany,
          "streetLine1": this.quoteDetails["sourceAddrDetails"].addrLine1,
          "streetLine2": this.quoteDetails["sourceAddrDetails"].addrLine2,
          "city": this.quoteDetails["sourceAddrDetails"].city,
          "province": this.quoteDetails["sourceAddrDetails"].state,
          "country": this.quoteDetails["sourceAddrDetails"].country,
          "zipcode": this.quoteDetails["sourceAddrDetails"].zipCode,
          "latitude": this.quoteDetails["sourceAddrDetails"].latitude,
          "longitude": this.quoteDetails["sourceAddrDetails"].longitude,
          "fullAddress": this.quoteDetails["sourceAddrDetails"].fullAddr
        },
        "addressType": "shipper"
      }
      this.displayData = true
      this._loaderService.stringSubject.next(true);
      this.displayLoader = true;

      let promise1 = this.scHttpService.postHttpService(ServerURLS.saveDataToAddressBook.action, shipperData);
      promise1.then(c => {
        if (c != null) {
          this.addRes['start'] = [shipperData].concat(this.addRes['start']);
          this.quoteDetails['sourceAddrDetails']['addressId'] = c['addressId'];
          this.updateAddress('0', 'start');
        }
        this.displayData = false
        this._loaderService.stringSubject.next(false);
        this.displayLoader = false;
        this.toastr.success('Address has been saved succsessfully.', '', {
          timeOut: 10000,
        })
      }, error => {
        this.displayData = false
        this._loaderService.stringSubject.next(false);
        this.displayLoader = false;
        this.toastr.error('Something Went wrong.', 'Please try again', {
          timeOut: 10000,
        });
      });
    }
  }
  brokerId: any = ''
  emailAndNumber(event) {
    this.custombrokerinfo = []

    if (event) {
      this.brInfo = this.brokers.find(o => o.brokerId === event);
      this.brokerId = this.brInfo.brokerId;
      let data =
      {
        "quoteId": this.quoteId,
        "brokerId": this.brInfo['brokerId'],
        "brokerCompany": this.brInfo['brokerCompany'],
        "brokerName": this.brInfo['brokerName'],
        "brokerPhone": this.brInfo['brokerPhone'],
        'contactName': this.brInfo['contactName'],
        "brokerEmail": this.brInfo['brokerEmail'],
        'brokerExt': this.brInfo['phoneExt']
      }
      this.custombrokerinfo.push(data)
      this.confOrderForm.controls['brokerCompanyName'].setValue(this.custombrokerinfo[0].brokerName);
      this.confOrderForm.controls['brokerContactName'].setValue(this.custombrokerinfo[0].contactName)
      this.confOrderForm.controls['customBrokerPhone'].setValue(this.custombrokerinfo[0].brokerPhone)
      this.confOrderForm.controls['customBrokerEmail'].setValue(this.custombrokerinfo[0].brokerEmail)
      this.confOrderForm.controls['ext'].setValue(this.custombrokerinfo[0].brokerExt)
      this.selectedCustomBrokerData = data;
      this.selectedCustomBrokerId = this.brInfo.brokerId
    } else {
      this.brokerId = ''
      let data = {
        'quoteId': '',
        'brokerId': '',
        'brokerCompany': '',
        'brokerName': '',
        'brokerPhone': '',
        'contactName': '',
        'brokerEmail': '',
        'brokerExt': '',
      }
      this.custombrokerinfo.push(data)
      this.confOrderForm.controls['brokerCompanyName'].setValue(this.custombrokerinfo[0].brokerName);
      this.confOrderForm.controls['brokerContactName'].setValue(this.custombrokerinfo[0].contactName)
      this.confOrderForm.controls['customBrokerPhone'].setValue(this.custombrokerinfo[0].brokerPhone)
      this.confOrderForm.controls['customBrokerEmail'].setValue(this.custombrokerinfo[0].brokerEmail)
      this.confOrderForm.controls['ext'].setValue(this.custombrokerinfo[0].brokerExt)
      this.selectedCustomBrokerData = data;
      this.selectedCustomBrokerId = this.brInfo.brokerId

    }
  }

  saveBroker() {


  }

  checkout() {
    this.addUpdate();
  }

  backToQuote() {
    // if (localStorage.getItem('activeQuoteId') != null) {
    //   this.router.navigate(["/shipcarte/carrier-selection/" + localStorage.getItem('activeQuoteId')]);
    // }
    this.router.navigate(["/shipcarte/carrier-selection/" + this.quoteId]);
    // else {
    //   window.history.back();
    // }
  }

  deliverDetails(quoteId): void {
    let promise1 = this.scHttpService.getHttpService(ServerURLS.getQuoteDeliveryDetails.action + "?quoteId=" + quoteId, '');
    promise1.then(c => {
      this.deliveryDetails = c["data"]
    });
  }

  SearchAddress(key, addrType) {

    let promise1 = this.scHttpService.getHttpService(ServerURLS.searchByKeyWord.action, '');
    promise1.then(c => {
      if (addrType == "end")
        this.displayData = false
      this.displayLoader = false; //close loader after all data loaded from saveToAddressBook
      this.addRes[addrType] = c ? c : false;
    }, error => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      this.displayLoader = false;
    });
  }

  onFocus(type) {
  }
  saveForLater() {
    this.saveForLaterEnabled = true;
    this.router.navigateByUrl('shipcarte/quoteHistory/allQuotes')
    // this.onSubmit();
    this.callforUpdateAPI();
  }

  addUpdate() {
    this.displayData = true
    this._loaderService.stringSubject.next(true);
    this.displayLoader = true;

    
    this.callforUpdateAPI();

  }

  callforUpdateAPI(){
    if (!this.addRes['start'].length) {
      this.quoteDetails['sourceAddrDetails']['firstName'] = this.confOrderForm.value['shipperPrimaryContact'];
      this.quoteDetails['sourceAddrDetails']['lastName'] = this.confOrderForm.value['shipperPrimaryContact'];
      this.quoteDetails['sourceAddrDetails']['phoneNumber'] = String(this.confOrderForm.value['shipperContactPhone']);
      this.quoteDetails['sourceAddrDetails']['extension'] = String(this.confOrderForm.value['shipperPrimaryContactExt']);
      this.quoteDetails['sourceAddrDetails']['flag'] = String(this.confOrderForm.value['selectedShipperItem']);
      this.quoteDetails['sourceAddrDetails']['email'] = this.confOrderForm.value['shipperContactEmail'];
      this.quoteDetails['sourceAddrDetails']['company'] = this.confOrderForm.value['shipperCompanyName'];

      this.quoteDetails['sourceAddrDetails']['addressName'] = null;
      delete this.quoteDetails['sourceAddrDetails']['addrBookId'];
      delete this.quoteDetails['sourceAddrDetails']['locationType'];

      this.quoteDetails['sourceAddrDetails']['addrLine1'] = this.confOrderForm.value['shipperAddressLine1'] ? this.confOrderForm.value['shipperAddressLine1'] : " ";
      this.quoteDetails['sourceAddrDetails']['addrLine2'] = this.confOrderForm.value['shipperAddressLine2'] ? this.confOrderForm.value['shipperAddressLine2'] : " ";


    }
    if (!this.addRes['end'].length) {
      this.quoteDetails['destAddrDetails']['addressName'] = null;
      this.quoteDetails['destAddrDetails']['firstName'] = this.confOrderForm.value['consigneePrimaryContact'];
      this.quoteDetails['destAddrDetails']['lastName'] = this.confOrderForm.value['consigneePrimaryContact'];
      this.quoteDetails['destAddrDetails']['phoneNumber'] = String(this.confOrderForm.value['consigneeContactPhone']);
      this.quoteDetails['destAddrDetails']['extension'] = String(this.confOrderForm.value['consigneePrimaryContactExt']);
      this.quoteDetails['destAddrDetails']['flag'] = String(this.confOrderForm.value['selectedConsigneeItem']);
      this.quoteDetails['destAddrDetails']['email'] = this.confOrderForm.value['consigneeContactEmail'];

      this.quoteDetails['destAddrDetails']['company'] = this.confOrderForm.value['consigneeCompanyName'];
      delete this.quoteDetails['destAddrDetails']['addrBookId'];
      delete this.quoteDetails['destAddrDetails']['locationType'];
      this.quoteDetails['destAddrDetails']['addrLine1'] = this.confOrderForm.value['consigneeAddressLine1'] ? this.confOrderForm.value['consigneeAddressLine1'] : " ";
      this.quoteDetails['destAddrDetails']['addrLine2'] = this.confOrderForm.value['consigneeAddressLine2'] ? this.confOrderForm.value['consigneeAddressLine2'] : " ";

    }
    let sourceName = this.confOrderForm.value['shipperPrimaryContact'].trim().split(" ");
    this.quoteDetails['sourceAddrDetails']['firstName'] = sourceName[0];
    this.quoteDetails['sourceAddrDetails']['lastName'] = sourceName[1];
    this.quoteDetails['sourceAddrDetails']['phoneNumber'] = this.confOrderForm.value['shipperContactPhone'];
    this.quoteDetails['sourceAddrDetails']['extension'] = this.confOrderForm.value['shipperPrimaryContactExt'];
    this.quoteDetails['sourceAddrDetails']['flag'] = this.confOrderForm.value['selectedShipperItem'];
    this.quoteDetails['sourceAddrDetails']['email'] = this.confOrderForm.value['shipperContactEmail'];
    this.quoteDetails['sourceAddrDetails']['company'] = this.confOrderForm.value['shipperCompanyName'];
    this.quoteDetails['sourceAddrDetails']['addrLine1'] = this.confOrderForm.value['shipperAddressLine1'] ? this.confOrderForm.value['shipperAddressLine1'] : " ";
    this.quoteDetails['sourceAddrDetails']['addrLine2'] = this.confOrderForm.value['shipperAddressLine2'] ? this.confOrderForm.value['shipperAddressLine2'] : " ";
    this.deliveryDetails['pickupFromTime'] = this.confOrderForm.value['shipperFromTime'];
    this.deliveryDetails['pickupToTime'] = this.confOrderForm.value['shipperToTime'];

    //Update user modified form fields values
    let destName = this.confOrderForm.value['consigneePrimaryContact'].trim().split(" ");
    this.quoteDetails['destAddrDetails']['firstName'] = destName[0];
    this.quoteDetails['destAddrDetails']['lastName'] = destName[1];
    this.quoteDetails['destAddrDetails']['phoneNumber'] = this.confOrderForm.value['consigneeContactPhone'];
    this.quoteDetails['destAddrDetails']['extension'] = this.confOrderForm.value['consigneePrimaryContactExt'];
    this.quoteDetails['destAddrDetails']['flag'] = this.confOrderForm.value['selectedConsigneeItem'];
    this.quoteDetails['destAddrDetails']['email'] = this.confOrderForm.value['consigneeContactEmail'];
    this.quoteDetails['destAddrDetails']['company'] = this.confOrderForm.value['consigneeCompanyName'];
    this.quoteDetails['destAddrDetails']['addrLine1'] = this.confOrderForm.value['consigneeAddressLine1'] ? this.confOrderForm.value['consigneeAddressLine1'] : " ";
    this.quoteDetails['destAddrDetails']['addrLine2'] = this.confOrderForm.value['consigneeAddressLine2'] ? this.confOrderForm.value['consigneeAddressLine2'] : " ";
    this.deliveryDetails['dropFromTime'] = this.confOrderForm.value['consigneeFromTime'];
    this.deliveryDetails['dropToTime'] = this.confOrderForm.value['consigneeToTime'];
    this.quoteDetails['destAddrDetails']['fullAddr'] = this.confOrderForm.value['consigneeAddressLine1']
    this.quoteDetails['sourceAddrDetails']['fullAddr'] = this.confOrderForm.value['shipperAddressLine1']
    let add2 = {
      "hasChangeInSourceAddr": true,
      "hasChangeInDestAddr": true,
      "shipperNote": this.confOrderForm.controls['shipperNote'].value,
      "consigneeNote": this.confOrderForm.controls['consigneeNote'].value,
      "additionalNote": this.additionalNote,
      "poNumber": this.poNo,
      "referenceNumber": this.referenceNo,
      "customFormTypeId": null,
      "customsShpmtValue": null,
      "destinationAddress": this.quoteDetails['destAddrDetails'],
      "hasCustoms": false,
      "isClonedOrder": false,
      "scQuoteDelvryForm": this.deliveryDetails,
      "sourceAddress": this.quoteDetails['sourceAddrDetails']
    }

    add2["destinationAddress"]["hasAddrBook"] = this.consigneeCheckbox;
    add2["sourceAddress"]["hasAddrBook"] = this.shipperCheckbox;
    add2["scQuoteDelvryForm"]["quoteId"] = this.quoteId;
    if (this.shipperCheckbox) {
      this.saveDataToAddressBook('start')
    }
    if (this.consigneeCheckbox) {
      this.saveDataToAddressBook('end')
    }

    let url = ServerURLS.updateQuoteLocation.action + "?isEdit=true"
    let promise = this.scHttpService.putHttpService(url, add2);

    promise.then((n) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      this.displayLoader = false;
      this.toastr.success(n['message'], 'Success', {
        timeOut: 10000,
      })

      if (this.saveForLaterEnabled == false) {
        this.router.navigate(['/shipcarte/payment/' + this.quoteId]);
      }
      else {
        this.saveForLaterEnabled = false;
      }

    }, error => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      this.displayLoader = false;
      if (error && error['status'] === 409) {

        this.toastr.success('Order details has been updated successfully.', 'Success', {
          timeOut: 10000,
        })

        if (this.saveForLaterEnabled == false) {
          this.router.navigate(['/shipcarte/payment/' + this.quoteId]);
        }
        else {
          this.saveForLaterEnabled = false;
        }      //Get user over next page
      } else {
        this.toastr.error("Something went wrong , Please try again.", '', {
          timeOut: 10000,
        });
      }
    })
  }

  open_modal() {
    const modalRef = this.modalService.open(CustomInfoSignatureComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
    modalRef.componentInstance['allDropdownValues'] = this.allDropdownValues;
    modalRef.componentInstance['customDropdownValues'] = this.brokers;
    modalRef.componentInstance['quoteId'] = this.quoteId;
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    this.custominfo = []
    modalRef.result.then((result) => {
      if (!result) {
        return;
      }
      let data = {
        "quoteId": this.quoteId,
        "contentsType": result.contentsType,
        "contentsExplanation": result.contentsExplanation,
        "customsCertified": result.customsCertified,
        "customsSigner": result.customsSigner,
        "restrictionType": result.restrictionType,
        "restrictionDescription": result.restrictionDescription,
        "nonDeliveryOption": result.nonDeliveryOption,

      }
      this.custominfo.push(result)
      this.displayData = true
      this._loaderService.stringSubject.next(true);
      this.displayLoader = true;
      let promise1 = this.scHttpService.postHttpService(ServerURLS.saveCustoms.action, '');
      promise1.then(res => {
        this.toastr.success('Customs Information added successfully.', '', {
          timeOut: 10000,
        });
        this.displayData = false
        this._loaderService.stringSubject.next(false);
        this.displayLoader = false;
      },
        error => {
          this.displayData = false
          this._loaderService.stringSubject.next(false);
          this.displayLoader = false;
          if (error['status'] === 401) {
            localStorage.clear();
            this.router.navigate(['/signin'])
          }
          this.toastr.error('Something went wrong.', '', {
            timeOut: 10000,
          });
        });

    })
  }

  open_modalcustomnormalform() {

    const modalRef = this.modalService.open(CustomInfoNormalformComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal3' });
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.componentInstance['customDropdownValues'] = this.brokers;
    modalRef.componentInstance['quoteId'] = this.quoteId;
    this.custombrokerinfo = []
    modalRef.result.then((result) => {
      if (!result) {
        return;
      }
      this.displayLoader = true;
      this.displayData = true
      this._loaderService.stringSubject.next(true);
      this.custombrokerinfo.push(result)
      let saveBrokerApi = this.scHttpService.postHttpService(ServerURLS.saveBroker.action, this.selectedCustomBrokerData);
      saveBrokerApi.then((res) => {
        let promise1 = this.scHttpService.getHttpService(ServerURLS.getAllBrokers.action, '');
        promise1.then((res) => {
          this.brokers = res
          this.displayLoader = false;
          this.displayData = false
          this._loaderService.stringSubject.next(false);
        })
      }, error => {
        this.toastr.error('Error while saving broker information.', 'Please try again', {
          timeOut: 5000,
        })
      })
    })
  }
  updateSCQuoteDeliveryDetails(key, value) {
    if (key && value) {
      this.deliveryDetails[key] = value;
      this.getTimeDifferent(key);
    }
  }

  getCompanyDetails(companyId, type) {
    let getAddressBookById = ServerURLS.getAddressBookById.action + "?companyId=" + companyId;
    this.displayLoader = true;
    this.displayData = true
    this._loaderService.stringSubject.next(true);
    this.addRes['start'] = []
    this.addRes['end'] = []
    this.scHttpService.getHttpService(getAddressBookById, '').then((response) => {
      this.displayLoader = false;
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      if (type == 'start') {
        this.addRes['start'] = response['contactDetails'];
        // this.shipperNote = response['pickupDeliveryDetails']['pickupInstructions'] ? response['pickupDeliveryDetails']['pickupInstructions'] : '';
        this.confOrderForm.controls['shipperNote'].setValue(response['pickupDeliveryDetails']['pickupInstructions'] ? response['pickupDeliveryDetails']['pickupInstructions'] : '');
      } else {
        this.addRes['end'] = response['contactDetails'];
        // this.consigneeNote = response['pickupDeliveryDetails']['deliveryInstructions'] ? response['pickupDeliveryDetails']['deliveryInstructions'] : '';
        this.confOrderForm.controls['consigneeNote'].setValue(response['pickupDeliveryDetails']['deliveryInstructions'] ? response['pickupDeliveryDetails']['deliveryInstructions'] : '');
      }
    }, error => {
      this.toastr.error('Something went wrong.', '', {
        timeOut: 10000
      });
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      this.displayLoader = false;
    });
  }
  checkDiscountValue(val: string) {
    if (val.charAt(0) == '-') {
      return '(-) $ ' + val.slice(1);
    } else {
      return '$ ' + val;
    }
  }
  shipperFromTimeWithin2Hr=false
  consigneeFromTimeError=false
  consigneeFromTimeErrorText='Drop from Time is greater than Drop to Time'
  getTimeDifferent(key?){
    if (this.shipingHours.length>0) {
    const shipperFromTimeRank=this.shipingHours.find(data=>data.pickupId== this.confOrderForm.controls['shipperFromTime'].value).rankAssigned;
    const shipperToTimeRank=this.shipingHours.find(data=>data.pickupId==this.confOrderForm.controls['shipperToTime'].value).rankAssigned;
    const consigneeFromTimeRank=this.confOrderForm.controls['consigneeFromTime'].value?this.shipingHours.find(data=>data.pickupId== this.confOrderForm.controls['consigneeFromTime'].value).rankAssigned:'';
    const consigneeToTimeRank=this.confOrderForm.controls['consigneeToTime'].value?this.shipingHours.find(data=>data.pickupId==this.confOrderForm.controls['consigneeToTime'].value).rankAssigned:'';
    if(shipperToTimeRank<shipperFromTimeRank){
      this.shipperFromTimeError=true;
      this.toastr.error("Pickup from Time is greater than Pickup to Time", '', {
        timeOut: 5000,
      });
      return
    }else{
      this.shipperFromTimeError=false;
    }
    if (shipperFromTimeRank<=shipperToTimeRank && Math.abs(shipperFromTimeRank - shipperToTimeRank)<4) {
      console.log('show popup');
      if (key=='pickupToTime'||key=='pickupFromTime') {
        
        this.modalService.open(this.confirmModal, { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop', backdrop: 'static', windowClass: "my-custom-modal" }).result.then((result) => {
          if (result == 'Save click') {
            this.shipperFromTimeWithin2Hr=false;
            this.createNewOrder();
          }
        }, (reason) => {
          
        });
      }
    }else{
      this.shipperFromTimeWithin2Hr=false;
    }
    if (consigneeFromTimeRank &&consigneeToTimeRank) {
      if(consigneeToTimeRank<=consigneeFromTimeRank){
        this.consigneeFromTimeError=true;
        if (consigneeToTimeRank==consigneeFromTimeRank) {
          this.consigneeFromTimeErrorText='Drop from Time is same as The Drop to Time'
        } else {
          this.consigneeFromTimeErrorText='Drop from Time is greater than Drop to Time';
        }
        this.toastr.error(this.consigneeFromTimeErrorText, '', {
          timeOut: 5000,
        });
        return;
      }else{
        this.consigneeFromTimeError=false;
      }
    }
  }
  }
  createNewOrder(){
    this._loaderService.stringSubject.next(true);

    let url = ServerURLS.saveQuoteStep_one.action;
    sessionStorage.setItem('_adminsaveQuoteDataObj', JSON.stringify(this.saveQuoteDataObj));
    let promise = this.scHttpService.postFileHttpService(url, this.saveQuoteDataObj);
    promise.then((result) => {
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      this.modalService.dismissAll();
      if (result['data'] == null) {
        this.toastr.error(result['message'], '', {
          timeOut: 10000,
        });
        return;
      }
      localStorage.setItem('_adminactiveQuoteId', result['data']);
      let savedDate = this.saveQuoteDataObj['delvryForm']['pickupDate'].split('-');
      savedDate = savedDate[1] + '-' + savedDate[2] + '-' + savedDate[0];
      let savedDateObj = {
        quoteId: result['data'],
        savedDate: savedDate,
      };
      sessionStorage.setItem('_adminorderPickupDate', JSON.stringify(savedDateObj));
    let data = {
      'origin': {
        'countryCode': this.saveQuoteDataObj.sourceAddress.country,
        'stateCode': this.saveQuoteDataObj.sourceAddress.state,
        'city': this.saveQuoteDataObj.sourceAddress.city
      },
      'destination': {
        'countryCode': this.saveQuoteDataObj.destinationAddress.country,
        'stateCode': this.saveQuoteDataObj.destinationAddress.state,
        'city': this.saveQuoteDataObj.destinationAddress.city
      },
      'shipmentMode':this.saveQuoteDataObj['shipmentMode']
    }
    sessionStorage.setItem('_admincountryDataForMarchInsurance', JSON.stringify(data))
    sessionStorage.setItem('_adminorderPickupDate', JSON.stringify(savedDateObj));
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfQuoteHistory()
      // if (this.saveForLaterEnable === false) {
        sessionStorage.setItem('_admingetQuoteCustomerId', this.customerId)
        this.router.navigate(['shipcarte/carrier-selection/', result['data']]);
      // } else {
      //   this.saveForLaterEnable = false;
      // }
    }).catch((error) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      if (error.status == 401) {
        this.toastr.error("Sorry your session expired!", 'Please re-login and try again!', {
          timeOut: 10000,
        });
        this.router.navigate(['/signin']);
      } else {
        let errorMessage="Please verify your address and other input details!"
        if(error && error['error'] != null && error['error']["message"] && error['error']["message"] !=null){
          errorMessage=error['error']["message"];
        }
        
        this.toastr.error( 'Please try again!', errorMessage, {
          timeOut: 10000,
        });
      }
      
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.modalService.dismissAll();
    });
  }
}
