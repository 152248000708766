import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 
import { PackageManagerComponent } from './package-manager/package-manager.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SettingsComponent } from './setting.component';
import { StandardCharges } from './standard-charges/standard-charges.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserDetailsComponent } from './user-details/user-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuardService } from 'src/app/services/auth/auth-guard.service';
import { SpinnerModule } from 'src/app/spinner/spinner.module';

const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/settings', component: SettingsComponent,canActivate: [AuthGuardService],
  children:[
    {
      path:'',component:StandardCharges,canActivate: [AuthGuardService]
    },
    {
      path:'packageManager',component:PackageManagerComponent,canActivate: [AuthGuardService]
    },{
      path:'userManagement',component:UserManagementComponent,canActivate: [AuthGuardService]
    }
  ]
} ,
{path:'shipcarte/settings/addUser',component:UserDetailsComponent,canActivate: [AuthGuardService]},
{path:'shipcarte/settings/userDetails',component:UserDetailsComponent,canActivate: [AuthGuardService]}

];

@NgModule({
  declarations: [  PackageManagerComponent, UserManagementComponent,SettingsComponent,StandardCharges,UserDetailsComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    NgbModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    SpinnerModule
  ]
})
export class SettingsModule { }
